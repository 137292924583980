import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { countryCodeData as options } from '../../../Utils/countyCodeMapping';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import { editBbasicinfo } from "../../../modules/Api/Pharmacy/NewPharmacyApi";
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import { handleNumber, findCodeFromValue } from '../../../Utils/CountryMobile';
import { DatePickerField } from "./../../../../_metronic/_partials/controls/forms/DatePickerField";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const gstinRegex = new RegExp(/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/)

export default function Details(props) {
  const classes = useStyles();
  const [currentCountry, setCurrentCountry] = React.useState()
  let view_only = false
  if (props.type == 'view') {
    view_only = true
  } else {
    view_only = false
  }
  let intValues = {
    pharmacy_name: props.values.pharmacy_name,
    country_code: props.values.user.country_code,
    mobile_number: props.values.user.mobile_number,
    email: props.values.user.email,
    gstin: props.values.gstin,
    dl_number: props.values.dl_number,
    dl_issuing_authority: props.values.dl_issuing_authority,
    dl_date_of_issue: props.values.dl_date_of_issue,
    dl_valid_upto: props.values.dl_valid_upto,
    dl_file: props.values.dl_file
  }

  // let intValues = {}

  

  const onOpenPdf = () => {
    window.open(intValues.dl_file)
  }

    const onClickNext = (data) => {
      props.nextStep()
      props.updateData(data)
    }

    const editDetails = (data, localData) => {
      const id = props.values.id
      editBbasicinfo(id, data)
        .then(items => {
          onClickNext(localData)
          if (items && items.data && items.data.message) {
            SuccessToast(items.data.message)
          }
          
        })
        .catch(err => {
          ErrorToast(err)
      })
    }

    

    const formik = useFormik({
      initialValues: intValues,
      validationSchema: Yup.object({
        mobile_number: Yup.string()
        .required('Mobile number is required')
        .nullable()
        .test('mobile-num', 'Invalid mobile number', function (value) {
            if (!value) return false;
            const currentCountrycode = currentCountry && currentCountry.code ? currentCountry.code : null
            return handleNumber(value + "", currentCountrycode || "IN")
        })
        ,
        pharmacy_name: Yup.string()
          .required("Pharmacy name is Required!"),
        email: Yup.string()
          .email("Invalid email format")
          .required("Email is Required!"),
        gstin: Yup.string()
          .matches(gstinRegex, "Must be a standard gst number.")
          .max(15, 'Must be 15 characters.')
          .min(15, 'Must be 15 characters.')
          .required('GSTIN number field is required '),
        dl_number: Yup.string()
          .required("DL number is Required!"),
        dl_issuing_authority: Yup.string()
         .required("DL issuing authority is Required!"),
        dl_date_of_issue: Yup.date('Must be a valid date')
          .nullable()
          .max(new Date(), 'Date of issue cannot be a future date' )
          .required('The Date of issue field is required.'),
        dl_valid_upto: Yup.date('Must be a valid date')
          .nullable()
          .min(new Date(), "Date must be in the future")
          .required('The Date of validity field is required.'),
      }),
      onSubmit: values => {
        // console.log("values", values);


      const data = props.values

      data["pharmacy_name"] = values.pharmacy_name
      data["user"]["country_code"] = values.country_code
      data["user"]["mobile_number"] = values.mobile_number
      data["user"]["email"] = values.email
      data["gstin"] = values.gstin
      data["dl_number"] = values.dl_number
      data["dl_issuing_authority"] = values.dl_issuing_authority
      data["dl_date_of_issue"] = values.dl_date_of_issue
      data["dl_valid_upto"] = values.dl_valid_upto
      data["dl_file"] = values.dl_file
    
      const updateValue = {
        pharmacy_name: values.pharmacy_name,
        country_code: values.country_code,
        mobile_number: values.mobile_number,
        email: values.email,
        gstin: values.gstin,
        dl_number: values.dl_number,
        dl_issuing_authority: values.dl_issuing_authority,
        dl_date_of_issue: values.dl_date_of_issue,
        dl_valid_upto: values.dl_valid_upto,
        dl_file: null
      }
      // console.log('submit', data);
      editDetails(updateValue, data)
      }
    });
        
    

      
    

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Basic Information</h6>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={3}>
          <TextField
            disabled={view_only}
            fullWidth
            required
            label="Pharmacy Name"
            name="pharmacy_name"
            type="text"
            value={formik.values.pharmacy_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.pharmacy_name && formik.touched.pharmacy_name && (
            <p style={{color:"red"}}>{formik.errors.pharmacy_name}</p>
             )}
        </Grid>
        <Grid item xs={3}>
        <Select
            required
            disabled
            style={{marginRight:"2%", marginBottom:"2%", width:"30%"}}
            name="country_code"
            type="text"
            value={formik.values.country_code}
            variant="outlined"
            onChange={e => {
              formik.setFieldValue("country_code", e.target.value)
              setCurrentCountry(findCodeFromValue(e.target.value))
            }}
            >
              {
                options.map(
                  (item) => (
                  <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                  )
                )
              }
            </Select>
            <FormControl style={{width:"65%"}} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Mobile<span>&nbsp;*</span></InputLabel>
            <OutlinedInput
          fullWidth
            disabled
                name="mobile_number"
                type="number"
                value={formik.values.mobile_number}
                onChange={formik.handleChange}
                startAdornment={<InputAdornment position="start">{formik.values.country_code}</InputAdornment>}
                labelWidth={50}
                onBlur={formik.handleBlur}
            />
            {formik.errors.mobile_number && formik.touched.mobile_number && (
            <p style={{color:"red"}}>{formik.errors.mobile_number}</p>
             )}
            </FormControl>
        </Grid>
        <Grid item xs={3}>
        <TextField
          fullWidth
          required
          disabled
            label="Email ID"
            name="email"
            type="email"
            value={formik.values.email}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.email && formik.touched.email && (
            <p style={{color:"red"}}>{formik.errors.email}</p>
             )}
        </Grid>
        <Grid item xs={3}>
            <TextField
            disabled={view_only}
          fullWidth
          required
            // style={{margin:"2%", width:"200px"}}
            label="GSTIN Number"
            name="gstin"
            type="text"
            value={formik.values.gstin}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.gstin && formik.touched.gstin && (
            <p style={{color:"red"}}>{formik.errors.gstin}</p>
             )}
        </Grid>
      </Grid>
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Drug License</h6>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={3}>
        <TextField
          fullWidth
          required
          disabled={view_only}
            label="Drug Licence Number"
            name="dl_number"
            type="text"
            value={formik.values.dl_number}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.dl_number && formik.touched.dl_number && (
            <p style={{color:"red"}}>{formik.errors.dl_number}</p>
             )}
        </Grid>
        <Grid item xs={3}>
        <TextField
          fullWidth
          required
          disabled={view_only}
            label="Issuing Authority"
            name="dl_issuing_authority"
            type="text"
            value={formik.values.dl_issuing_authority}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.dl_issuing_authority && formik.touched.dl_issuing_authority && (
            <p style={{color:"red"}}>{formik.errors.dl_issuing_authority}</p>
             )}
        </Grid>
        <Grid item xs={3}>
        {/* <TextField
          fullWidth
          required
          disabled={view_only}
            label="Date Of Issue"
            name="dl_date_of_issue"
            type="date"
            value={formik.values.dl_date_of_issue}
            variant="outlined"
            onChange={e => {
              if (new Date("9999-12-31") >= new Date(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            InputLabelProps={{
                shrink: true,
              }}
              onBlur={formik.handleBlur}
              />
              {formik.errors.dl_date_of_issue && formik.touched.dl_date_of_issue && (
              <p style={{color:"red"}}>{formik.errors.dl_date_of_issue}</p>
               )} */}

                   <DatePickerField 
          disabled={view_only}
                                mandatory
                                name="dl_date_of_issue"
                                label="Date Of Issue"
                                onChange={val => {
                                  formik.setFieldValue('dl_date_of_issue', String(val))
                                }}
                                onBlur={e => {
                                  formik.handleBlur(e);
                                }}
                                value={formik.values.dl_date_of_issue}
                                touched={formik.touched.dl_date_of_issue}
                                errors={formik.errors.dl_date_of_issue}
                                 />
        </Grid>
        <Grid item xs={3}>
        {/* <TextField
          fullWidth
          required
          disabled={view_only}
            label="Drug License Valid Upto"
            name="dl_valid_upto"
            type="date"
            value={formik.values.dl_valid_upto}
            variant="outlined"
            onChange={e => {
              if (new Date("9999-12-31") >= new Date(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            InputLabelProps={{
                shrink: true,
              }}
              onBlur={formik.handleBlur}
              />
              {formik.errors.dl_valid_upto && formik.touched.dl_valid_upto && (
              <p style={{color:"red"}}>{formik.errors.dl_valid_upto}</p>
               )} */}

                   <DatePickerField 
                                mandatory
                                disabled={view_only}
                                name="dl_valid_upto"
                                label="Drug License Valid Upto"
                                onChange={val => {
                                  formik.setFieldValue('dl_valid_upto', String(val))
                                }}
                                onBlur={e => {
                                  formik.handleBlur(e);
                                }}
                                value={formik.values.dl_valid_upto}
                                touched={formik.touched.dl_valid_upto}
                                errors={formik.errors.dl_valid_upto}
                                 />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"5%"}}>
        <Grid  item xs={3}>
        <InputLabel htmlFor="outlined-adornment-amount">Drug Licence</InputLabel>
        <a
            className="btn btn-icon btn-light btn-hover-success btn-sm mx-3"
            onClick={onOpenPdf}
        >
                    <VisibilitySharpIcon color='primary' />
        </a>
        </Grid>
        </Grid>
      
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} > 
        <Grid item xs={3} container justify={"flex-start"} >
        {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
        </Grid>
        <Grid item xs={3}>
        </Grid>
        
        <Grid item xs={3}>
        </Grid>
        {!view_only && <Grid item xs={3} container justify={"flex-end"} >
        <Button  variant="contained" color="primary" type="submit" >Next</Button>
        {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickNext} >Next</Button> */}
        </Grid>}
        {view_only && <Grid item xs={3} container justify={"flex-end"} >
        {/* <Button  variant="contained" color="primary" type="submit" >Next</Button> */}
        <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.nextStep} >Next</Button>
        </Grid>}
      </Grid> 
      </form>
            
    </div>
  );
}
