import React from "react";
import {Card} from 'react-bootstrap';
import DeleteModel from "./DeleteModal"


export function CommentBox (props) {
    const {item, OnDelete} = props
    return (
        <>
        <Card border="primary">
            <Card.Body>
            <div className=" row" >
                <div className="col-lg-11">
                <Card.Text>
                    {item.comment}
                </Card.Text>
                </div>
                <div className="col-lg-1">
                    <DeleteModel id={item.id} OnDelete={OnDelete} />
                </div>
            </div>
            </Card.Body>
        </Card>
        <br />
        </>
    )
}