import http from "../../../Utils/http";

  export function AddDoctor(data) {
    return http.post(`api/administrator/doctor`, data )
  }

  export function loadSpecializationData() {
    const pharams = "paginate=0"
    return http.get(`api/admin/specialization/?${pharams}`);
  }


  export function loadPharmacyData() {
    const pharams = "paginate=0&filter[approved]=1"
    return http.get(`api/administrator/list/pharmacy/?${pharams}`);
  }

  export function loadLaoratoryData() {
    const pharams = "paginate=0&filter[approved]=1"
    return http.get(`api/administrator/list/laboratory/?${pharams}`);
  }