// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import { Input } from "../../../../../_metronic/_partials/controls";
import Button from 'react-bootstrap/Button';
import CheckBoxes from "./CheckBoxes";
import Alert from '@material-ui/lab/Alert';

const numberandfullRex = /^[1-9]\d*(\.\d+)?$/
// Validation schema
const CategoryEditSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Service Name is required"),
});

export function StoreEditForm(props) {

    const {taxData, row}= props
    // console.log('row', row);
    // console.log('taxData', taxData);
        var tList = []
        if (row.taxes != null) {
            tList = row.taxes
        }
    const [reqTaxList, setReqTaxList] = React.useState(tList)
    const [errorMessage, setErrorMessage] = React.useState('')

    const UpdateReqTaxList = (data) => {
        // console.log('data', data);
        setErrorMessage('')
        setReqTaxList([...data])
    }

    const taxList = []
    taxData.map((rowItem) =>{
        rowItem['checked'] = false
        var tList = []
        if (row.taxes != null) {
            tList = row.taxes
        }
        tList.map((item) => {
            if (rowItem.id == item) {
                rowItem['checked'] = true
            }
        })
        taxList.push(rowItem)
    })

    const [loading, setLoading] = React.useState(false)
    const data = {
        name:row.name,
        percent:row.percent
    }


    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={data}
                validationSchema={CategoryEditSchema}
                onSubmit={(values) => {
                    const data = {
                        name : values.name,
                        taxes : reqTaxList
                    }
                    if (reqTaxList.length == 0) {
                        setErrorMessage(<Alert severity="error">Select atleast one Tax !</Alert>)
                    } else {
                        props.onEdit(data, row.id)
                        props.handleHide()
                    }
                }}
            >
            {({ handleSubmit, resetForm, handleChange, handleBlur, touched, values, errors }) => (
                    <>
<Form className="form form-label-right">
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    Select all tax rules that applies for <span style={{color:'blue'}}>{props.row.name}</span>
                                </div>
                                {/* <div className="col-lg-12"
                                    style={{marginTop:"3%", marginBottom:"3%"}}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Service Name"
                                    name="name"
                                    type="text"
                                    margin="dense"
                                    value={values.name}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    />
                                    {errors.name && touched.name && (
                                    <p style={{color:"red"}}>{errors.name}</p>
                                    )}
                                </div> */}
                                <div className="col-lg-12"
                                    style={{marginBottom:"2%", marginTop:"2%"}}>
                                {
                                    taxList.map((item) => <CheckBoxes key={item.id} data={item} reqTaxList={reqTaxList} UpdateReqTaxList={UpdateReqTaxList}/>)
                                }
                                </div>
                                <div className="col-lg-12" style={{marginBottom:"3%"}}>
                                    {errorMessage}
                                </div>
                            </div>
                            <Button style={{margin:'3px'}} variant="secondary" onClick={props.handleHide}>
                                Close
                            </Button>
                            <Button style={{margin:'3px'}} type='submit' variant="primary" >
                                Save Changes
                            </Button>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
