import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
// import { StoresTable } from "./SpecializationTable";
import StoresTable from "./Table";
import { loadTableData, pageLoadData, searchLoadData } from "../../modules/Api/Medicine/MedicineApi";
import { loadNoPageData } from "../../modules/Api/Medicine/CategoryApi";
import Spinner from "../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../modules/Toast/SuccessSnackBar";
import Search from "./Search";

export const ListStores = (props) => {
  const [data, setData] = React.useState(undefined)
  const [Categorydata, setCategoryData] = React.useState([])
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)
  const [category, setCategory] = React.useState(" ")

  const loadCategoryData = () => {
    if (mounted) {
      setMounted(false)
      loadNoPageData()
      .then(items => {
        setMounted(false)
        setLoading(false)
        setCategoryData(items.data)
        // console.log('items.data', items.data);
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
        setMounted(false)
        setLoading(false)
    })
    }
  }

  const loadData = () => {
    if (mounted) {
      setMounted(false)
      loadTableData()
      .then(items => {
        setMounted(false)
        setLoading(false)
        setData(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
        setMounted(false)
        setLoading(false)
    })
    }
  }

  const updateData = () => {
    setData(undefined)
    loadTableData()
      .then(items => {
        setData(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
    })
  }

  const loadPageData = (no) => {
    setData(undefined)
    setLoading(true)
    pageLoadData(no)
      .then(items => {
        setData(items.data)
        setLoading(false)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
    })
  }

  const SearchData = (text) => {
    setData(undefined)
    setLoading(true)
    searchLoadData(text, category)
      .then(items => {
        setLoading(false)
        setData(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        setLoading(false)
        ErrorToast(err)
    })
  }

  const SearchByCategoryData = (category) => {
    setData(undefined)
    setLoading(true)
    const text = ""
    searchLoadData(text, category)
      .then(items => {
        setLoading(false)
        setData(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        setLoading(false)
        ErrorToast(err)
    })
  }

  React.useEffect(() => {
    loadData()
    loadCategoryData()
  }, [loadData, loadCategoryData])

  if (loading) {
    return (
      <Spinner />
    )
  }

  return (
    <Card>
      <CardHeader title="Medicine list">
        <CardHeaderToolbar>
          
        <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-amount">Category</InputLabel>
              <Select
              style={{marginRight:'10px', width:'200px'}}
              margin="dense"
              required
              label="Category"
              type="text"
              value={category}
              variant="outlined"
              onChange={e => {
                setCategory(e.target.value)
                SearchByCategoryData(e.target.value)
              }}
              >
                <MenuItem value=" ">All</MenuItem>
                  {Categorydata.map((item) => (
                    <MenuItem key={item.id} value={item.name}>{item.name}</MenuItem>
                  ))}
          </Select>
          </FormControl>

          <Search SearchData={SearchData} />
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => props.history.push('/medicine/add')}
          >
            Add new Medicine
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {
          data && <StoresTable loadPageData={loadPageData} data={data} Categorydata={Categorydata} updateData={updateData} />
        }
      </CardBody>
    </Card>
  );
};
