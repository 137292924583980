
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { Formik, Form } from "formik";

const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
});

export default function CustomizedInputBase(props) {
  const classes = useStyles();
  const [disableSubmit, setDisableSubmit] = React.useState(true)

  return (
    
    <Formik
    enableReinitialize={true}
    initialValues={{
        name : props.value
    }}
    onSubmit={(values) => {
        setDisableSubmit(true)
        props.SearchData(values.name)
    }}
>
    {({ handleSubmit, setFieldValue, values}) => (
        <>
            <Form className="form form-label-right">
            <Paper className={classes.root}>
            <InputBase
                className={classes.input}
                placeholder="Search"
                inputProps={{ 'aria-label': 'Search' }}
                name='name'
                onChange={e => {
                    setFieldValue("name", e.target.value)
                    props.setSearchText(e.target.value)
                    setDisableSubmit(false)
                }}
                value={values.name}
            />
            
            <Divider className={classes.divider} />
            <IconButton disabled={disableSubmit} type="submit" onSubmit={() => handleSubmit()} className={classes.iconButton} aria-label="Search">
                <SearchIcon />
            </IconButton>
            </Paper>
            </Form>
        </>
    )}
</Formik>
  );
}
