import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import {Form} from "./Form";
import { useHistory } from "react-router-dom";
import { logOut } from "../../Logout";

export const RegisterEmployee = (props) => {

useEffect(() => {
  logOut()
}, [logOut])


  const history = useHistory();
  const saveStore = () => { }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  return (
    <Card>
      <CardHeader title="Register Health Associate">
        <CardHeaderToolbar>
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={() => history.push('/auth/login')}
          >
            Back to Login
            </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
        {<Form
            // actionsLoading={actionsLoading}
            // data={data}
            btnRef={btnRef}
            resetbtnRef={resetbtnRef}
            saveProduct={saveStore}
          >
          </Form>}
        </div>
      </CardBody>
    </Card>
  )
};
