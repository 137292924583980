/* eslint-disable no-restricted-imports */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from "react-router-dom";
import Spinner from "../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import { editBankinfo, confirmClick, rejectClick } from "../../../modules/Api/Laboratory/NewLaboratoryApi";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  buttonProgress: {
    color: "green",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function Details(props) {
  const history = useHistory();
    const classes = useStyles();
    let view_only = false
    if (props.type == 'view') {
      view_only = true
    } else {
      view_only = false
    }

    const onClickBackList = () => {
      history.push('/laboratory/approved')
    }

    const [errorMessage, setErrorMessage] = React.useState('')
    const [successMessage, setSuccessMessage] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [open, setOpen] = React.useState(false);

    const [openApprove, setOpenApprove] = React.useState(false)

    const handleCloseApprove = () => {
      setOpenApprove(false)
    }
  
    const handleShowApprove = () => {
      setOpenApprove(true)
    }

    const onConfirm = () => {
      const id = props.values.user.id
      setLoading(true)
      handleCloseApprove()
      confirmClick(id)
        .then(items => {
          setLoading(false)
          history.push('/laboratory/new')
          if (items && items.data && items.data.message) {
            SuccessToast(items.data.message)
          }
          
        })
        .catch(err => {
          setLoading(false)
          ErrorToast(err)
      })
    }

    const ApproveModel = <Modal show={openApprove} onHide={handleCloseApprove}>
                            <Modal.Header closeButton>
                              <Modal.Title>Laboratory Approve</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to approve ?</Modal.Body>
                            <Modal.Footer>
                              <Button color="secondary" onClick={handleCloseApprove}>
                                Cancel
                              </Button>
                              <Button color="primary" onClick={onConfirm}>
                                Approve
                              </Button>
                            </Modal.Footer>
                          </Modal>

const [openReject, setOpenReject] = React.useState(false)

const handleCloseReject = () => {
  setOpenReject(false)
}

const handleShowReject = () => {
  setOpenReject(true)
}
var onReject = ''
if (props.type == 'edit_and_approve')
{onReject = () => {
  const id = props.values.user.id
  handleCloseReject()
  setLoading(true)
  rejectClick(id)
    .then(items => {
      setLoading(false)
      history.push('/laboratory/new')
      if (items && items.data && items.data.message) {
        SuccessToast(items.data.message)
      }
      
    })
    .catch(err => {
      setLoading(false)
      ErrorToast(err)
  })
}}


if (props.type == 'edit'){onReject = () => {
  const id = props.values.user.id
  handleCloseReject()
  setLoading(true)
  rejectClick(id)
    .then(items => {
      setLoading(false)
      history.push('/laboratory/approved')
      if (items && items.data && items.data.message) {
        SuccessToast(items.data.message)
      }
      
    })
    .catch(err => {
      setLoading(false)
      ErrorToast(err)
  })
}}

const RejectModel = <Modal show={openReject} onHide={handleCloseReject}>
                        <Modal.Header closeButton>
                          <Modal.Title>Laboratory Reject</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to Reject ?</Modal.Body>
                        <Modal.Footer>
                          <Button color="secondary" onClick={handleCloseReject}>
                            Cancel
                          </Button>
                          <Button color="primary" onClick={onReject}>
                            Reject
                          </Button>
                        </Modal.Footer>
                      </Modal>
      var editDetails = ''
      if (props.type == 'edit_and_approve')
      {editDetails = (data, localData) => {
        const id = props.values.bank_account_details[0].id
        editBankinfo(id, data)
          .then(items => {
            onupdateData(localData)
            setLoading(false)
            handleShowApprove()
            
          })
          .catch(err => {
            setLoading(false)
            ErrorToast(err)
        })
      }}

      
      if (props.type == 'edit')
      {editDetails = (data, localData) => {
        const id = props.values.bank_account_details[0].id
        editBankinfo(id, data)
          .then(items => {
            onupdateData(localData)
            setLoading(false)
            history.push('/laboratory/approved')
            if (items && items.data && items.data.message) {
              SuccessToast(items.data.message)
            }
            
          })
          .catch(err => {
            setLoading(false)
            ErrorToast(err)
        })
      }}

    const initValues = {
      bank_account_number: props.values.bank_account_details[0].bank_account_number,
      bank_account_holder: props.values.bank_account_details[0].bank_account_holder,
      bank_name:props.values.bank_account_details[0].bank_name,
      bank_city: props.values.bank_account_details[0].bank_city,
      bank_ifsc: props.values.bank_account_details[0].bank_ifsc,
      bank_account_type: props.values.bank_account_details[0].bank_account_type,
    }


    const onupdateData = (data) => {
      props.updateData(data)
  }

    const onClickPrevious = () => {
        props.previosStep()
    }

    

      

      const ifsc_code = new RegExp(/^[A-Za-z]{4}[a-zA-Z0-9]{7}$/)

    const formik = useFormik({
      initialValues: initValues,
      validationSchema: Yup.object({
        // bank_account_number: Yup.string()
        // .matches(/^[0-9]*$/, 'Account number must be number')
        //   .required("Account number is Required!"),
        // bank_account_holder: Yup.string()
        // .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field must be alphabets')
        //   .required("Account holder name is Required!"),
        // bank_name: Yup.string()
        //   .required("Bank name is Required!"),
        // bank_city: Yup.string()
        //  .required("Bank city is Required!"),
        // bank_ifsc: Yup.string()
        // .matches(ifsc_code, 'Must be a valid IFSC code')
        // .required('The IFSC field is required.'),
        // bank_account_type: Yup.string()
        //   .required("Account type is Required!"),
      }),
      onSubmit: values => {
        setLoading(true)
        console.log('submit', values);

        const data = props.values

        
        data["bank_account_details"][0]["bank_account_number"] = values.bank_account_number
        data["bank_account_details"][0]["bank_account_holder"] = values.bank_account_holder
        data["bank_account_details"][0]["bank_name"] = values.bank_name
        data["bank_account_details"][0]["bank_city"] = values.bank_city
        data["bank_account_details"][0]["bank_ifsc"] = values.bank_ifsc
        data["bank_account_details"][0]["bank_account_type"] = values.bank_account_type


      const updateValues = {
        bank_account_number: values.bank_account_number,
        bank_account_holder: values.bank_account_holder,
        bank_name:values.bank_name,
        bank_city: values.bank_city,
        bank_ifsc: values.bank_ifsc,
        bank_account_type: values.bank_account_type,
      }
      editDetails(updateValues, data)
      // onupdateData(data)
      }
    });
    

            

            
    if (loading) {
      return (
        <Spinner />
      )
    }
            

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
    <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Banking Details</h6>
    
    {ApproveModel}
        {RejectModel}
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          
          disabled={view_only}
            label="Bank Account Number"
            name="bank_account_number"
            type="text"
            value={formik.values.bank_account_number}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_account_number && formik.touched.bank_account_number ? (
            <p style={{color:"red"}}>{formik.errors.bank_account_number}</p>
             ) : <p> </p>}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          
          disabled={view_only}
            label="Account Holder Full Name"
            name="bank_account_holder"
            type="text"
            value={formik.values.bank_account_holder}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_account_holder && formik.touched.bank_account_holder ? (
            <p style={{color:"red"}}>{formik.errors.bank_account_holder}</p>
             ) : <p></p>}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          
          disabled={view_only}
            label="Bank Name"
            name="bank_name"
            type="text"
            value={formik.values.bank_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_name && formik.touched.bank_name ? (
            <p style={{color:"red"}}>{formik.errors.bank_name}</p>
             ) : <p></p>}
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        
      <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          
          disabled={view_only}
            label="Bank City"
            name="bank_city"
            type="text"
            value={formik.values.bank_city}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_city && formik.touched.bank_city ? (
            <p style={{color:"red"}}>{formik.errors.bank_city}</p>
             ) : <p></p>}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          
          disabled={view_only}
            label="Bank IFSC Code"
            name="bank_ifsc"
            type="text"
            value={formik.values.bank_ifsc}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            
            {formik.errors.bank_ifsc && formik.touched.bank_ifsc ? (
            <p style={{color:"red"}}>{formik.errors.bank_ifsc}</p>
             ) : <p></p>}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
        <FormControl fullWidth variant="outlined" className={classes.formControl}>
        <InputLabel fullWidth htmlFor="outlined-age-native-simple">Account Type</InputLabel>
        <Select
        fullWidth
        disabled={view_only}
          value={formik.values.bank_account_type}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label="Account Type"
          name="bank_account_type"
        >
          <option value={'CURRENT'}>CURRENT</option>
          <option value={'SAVINGS'}>SAVINGS</option>
          <option value={'SALARY'}>SALARY</option>
          <option value={'FIXED DEPOSIT'}>FIXED DEPOSIT</option>
          <option value={'RECURRING DEPOSIT'}>RECURRING DEPOSIT</option>
          <option value={'NRI'}>NRI</option>
          <option value={'NRO'}>NRO</option>
          <option value={'NRE'}>NRE</option>
          <option value={'FCNR'}>FCNR</option>
        </Select>
      </FormControl>
            {formik.errors.bank_account_type && formik.touched.bank_account_type ? (
            <p style={{color:"red"}}>{formik.errors.bank_account_type}</p>
             ) : <p></p>}
        </Grid>
      </Grid>
      
      {errorMessage}
        {successMessage}
      {props.type == 'edit_and_approve' && <Grid container spacing={3}>
        <Grid item xs={3} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
        </Grid>
        <Grid item xs={7}>
        </Grid>
        
        <Grid item xs={1} container justify={"flex-end"}>
            <Button onClick={handleShowReject} style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="secondary" >Reject</Button>
        </Grid>
        <Grid item xs={1} container justify={"flex-end"}>
            <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Approve</Button>
            </Grid>
      </Grid> }
      {props.type == 'edit' && <Grid container spacing={3}>
        <Grid item xs={3} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
        </Grid>
        <Grid item xs={8}>
        </Grid>
        <Grid item xs={1} container justify={"flex-end"}>
            <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Save</Button>
            </Grid>
      </Grid> }
      {view_only && <Grid container spacing={3}>
        <Grid item xs={3} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
        </Grid>
        <Grid item xs={8}>
        </Grid>
        <Grid item xs={1} container justify={"flex-end"}>
            <Button onClick={onClickBackList} style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Back</Button>
            </Grid>
      </Grid> }
      </form>
            
    </div>
  );
}
