import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '75%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
},
}));

export default function SimpleSelect(props) {
  const classes = useStyles();
  const [test_value, set_test_value] = React.useState('')
  const [err_msg, set_err_msg] = React.useState('')

  const formik = useFormik({
    initialValues: {'test_value':test_value},
    validationSchema: Yup.object({
        // test_value: Yup.string()
        // .required("Select any test from the test list"),
    }),
    onSubmit: values => {
      console.log('values', values);
      if (values.test_value == '')
      {
        set_err_msg(<p style={{color:"red"}}>Select any test from the test list</p>)
      } 
      else
        {const data = {
            id:values.test_value,
            sample_collect:0
        }
        formik.setFieldValue('test_value','')
        props.onAdd(data)}
    }
  });

  

  return (
    <div style={{margin:"5px"}}>
        <form className={classes.root} noValidate autoComplete="off"  onSubmit={formik.handleSubmit}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Test</InputLabel>
        <Select
        style={{height:'50px'}}
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          name="test_value"
          value={formik.values.test_value}
          // onChange={formik.handleChange}
          onChange={e => {
            formik.setFieldValue('test_value', e.target.value)
            set_err_msg('')
          }}
          onBlur={formik.handleBlur}
          label="Test"
        >
           { props.TestList.map((item) => 
            <MenuItem value={item.id}>{item.name}</MenuItem>
           )}
        </Select>
        {formik.errors.test_value && formik.touched.test_value && (
                <p style={{color:"red"}}>{formik.errors.test_value}</p>
                )}
      </FormControl>
      <Button style={{height:'50px',width:"20%"}} type="submit" variant="contained" color="primary">
        Add Test
      </Button>
      </form>
      {err_msg}
    </div>
  );
}
