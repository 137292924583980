import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import PharmacyForm from "./Form";
import { useHistory } from "react-router-dom";
import { loadEmployeeProfileData, loadHealthAssosiateProfileData } from "../../../modules/Api/Profile/profileApi";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import { connect } from 'react-redux'

const ApprovedForm = (props) => {
  const [mounted, setMounted] = React.useState(true)
  const [data, setData] = React.useState()
  const [user_type, setUser_type] = React.useState('')

  const loadEmployeData = () => {
    loadEmployeeProfileData()
    .then(items => {
        setData(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
    })
}

const loadHealthAssosiateData = () => {
  loadHealthAssosiateProfileData()
  .then(items => {
      setData(items.data)
      if (items && items.data && items.data.message) {
        SuccessToast(items.data.message)
      }
      
    })
    .catch(err => {
      ErrorToast(err)
  })
}

React.useEffect(() => {
  setUser_type(props.me && props.me.user_type)
  if (user_type == 'HEALTHASSOCIATE') {
    if (mounted) {
      setMounted(false)
      loadHealthAssosiateData()
    }
  }
  if (user_type == 'EMPLOYEE') {
    if (mounted) {
      setMounted(false)
      loadEmployeData()
    }
  }
  
}, [loadEmployeData, loadHealthAssosiateData])


  const history = useHistory();
  const saveStore = () => { }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  return (
    <Card>
      <CardHeader title="Profile Info">
        <CardHeaderToolbar>
          {/* <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={() => history.push('/employee')}
          >
            Back to List
            </button> */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
        {data && <PharmacyForm
            // actionsLoading={actionsLoading}
            data={data}
            btnRef={btnRef}
            resetbtnRef={resetbtnRef}
            saveProduct={saveStore}
            user_type={user_type}
          >
          </PharmacyForm>}
        </div>
      </CardBody>
    </Card>
  )
};

const mapStateToProps = function (state) {
  return {
    me: state.me,
  }
}

export default connect(mapStateToProps)(ApprovedForm);
