import React from "react";
import Alert from '@material-ui/lab/Alert';
import ProfileDetails from './ProfileDetails';
import AddressDetails from './AddressDetails';
import BankDetails from "./BankDetails";
import CareerProfile from "./CareerProfile";
import { loadSpecializationData, loadPharmacyData, loadLaoratoryData } from "../../../modules/Api/Doctor/AddDoctor";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";

export default function Form (props) {
    const [step, setStep] = React.useState(1)
    const [specializationList, setSpecializationList] = React.useState([])
    const [pharmacyList, setPharmacyList] = React.useState([])
    const [laboratoryList, setLaboratoryList] = React.useState([])
    const [mounted, setMounted] = React.useState(true)
    const [data, setData] = React.useState({
        first_name: '',
        middle_name	: '',
        last_name : '',
        gender : '',
        date_of_birth : '',
        age : '',
        qualification : '',
        specialization : [],
        years_of_experience : '',
        mobile_number : '',
        country_code : '+91',
        alt_mobile_number : '',
        alt_country_code : '+91',
        email : '',
        clinic_name : '',
        pincode: '',
        country: '',
        state: '',
        district: '',
        city_village: '',
        street_name: '',
        latitude: '',
        longitude: '',
        address : [],
        career_profile : '',
        education_training : '',
        clinical_focus : '',
        awards_achievements : '',
        memberships : '',
        experience : '',
        profile_photo : null,
        my_pic : './profile_logo.png',
        service : 'INPATIENT',
        currency_code: 'INR',
        appointment_type_online : 0,
        appointment_type_offline : 0,
        consulting_online_fee : 0,
        consulting_offline_fee : 0,
        emergency_appointment_ticked: false,
        emergency_fee : 0,
        emergency_appointment : 0,
        no_of_followup : 1,
        followups_after : 1,
        cancel_time_period : 12,
        reschedule_time_period : 12,
        bank_account_number: '',
        bank_account_holder: '',
        bank_name: '',
        bank_city: '',
        bank_ifsc: '',
        bank_account_type: '',
        registration_number: '',
        payout_period: 0,
    })

    const loadspec = () => {
        if (mounted) {
        setMounted(false)
        loadSpecializationData()
        .then(items => {
            setMounted(false)
            setSpecializationList(items.data)
            if (items && items.data && items.data.message) {
              SuccessToast(items.data.message)
            }
            
          })
          .catch(err => {
            setMounted(false)
            ErrorToast(err)
        })
        }
    }

    const loadPharmacy = () => {
        if (mounted) {
        setMounted(false)
        loadPharmacyData()
        .then(items => {
            setMounted(false)
            setPharmacyList(items.data)
            if (items && items.data && items.data.message) {
              SuccessToast(items.data.message)
            }
            
          })
          .catch(err => {
            setMounted(false)
            ErrorToast(err)
        })
        }
    }

    const loadLaboratory = () => {
        if (mounted) {
        setMounted(false)
        loadLaoratoryData()
        .then(items => {
            setMounted(false)
            setLaboratoryList(items.data)
            if (items && items.data && items.data.message) {
              SuccessToast(items.data.message)
            }
            
          })
          .catch(err => {
            setMounted(false)
            ErrorToast(err)
        })
        }
    }

    React.useEffect(() => {
        loadspec()
        loadLaboratory()
        loadPharmacy()
    }, [loadspec, loadLaboratory, loadPharmacy])

    

          const nextStep = () => {
              setStep(step + 1)
          }

          const updateStep = (no) => {
            setStep(no)
        }

          const updataData = (data) => {
            setData(data)
        }

          const previousStep = () => {
            setStep(step - 1)
        }

          switch (step) {
            case 1 :
                return (
                    <div>
                    {data && <ProfileDetails
                    values={data}
                    data={props.data}
                    nextStep={nextStep}
                    handleClose={props.handleClose}
                    specializationList={specializationList}
                    updataData={updataData}
                    />}
                </div>
                )
            case 2 :
                return (
                    <div>
                    {data && <AddressDetails
                    values={data}
                    data={props.data}
                    pharmacyList={pharmacyList}
                    laboratoryList={laboratoryList}
                    nextStep={nextStep}
                    previousStep={previousStep}
                    handleClose={props.handleClose}
                    updataData={updataData}
                    />}
                </div>
                )
        
            case 3 :
                return (
                    <div>
                    < BankDetails
                    values={data}
                    data={props.data}
                    nextStep={nextStep}
                    previousStep={previousStep}
                    handleClose={props.handleClose}
                    updataData={updataData}
                    />
                </div>
                )
                case 4 :
                return (
                    <div>
                    <CareerProfile
                    values={data}
                    data={props.data}
                    nextStep={nextStep}
                    previousStep={previousStep}
                    handleClose={props.handleClose}
                    updataData={updataData}
                    updateStep={updateStep}
                    />
                </div>
                )
            default:
                return (
                    <div></div>
                )
    }

}