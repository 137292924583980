import React from "react";
import {Button, Modal} from "react-bootstrap";


export function DoctorModal (props) {
  const {row} = props
    const [show, setShow] = React.useState(false)
    // console.log('row', row);
  
    const handleClose = () => {
        setShow(false)
    }
  
    const handleShow = () => {
        setShow(true)
    }

      return (
        <>
        <Button variant="link" onClick={handleShow}>{row?.doctor?.first_name} {row?.doctor?.middle_name ? row?.doctor?.middle_name : ""} {row?.doctor?.last_name}</Button>
  
          <Modal centered show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Doctor Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="row form-row">
            <div className="col-lg-6 col-md-12">
              Doctor Name:
            </div>
            <div className="col-lg-6 col-md-12">
            Dr. {row?.doctor?.first_name} {row?.doctor?.middle_name ? row?.doctor?.middle_name : ""} {row?.doctor?.last_name}
            </div>
            </div>

            <div style={{marginTop:"10px"}} className="row form-row">
            <div className="col-lg-6 col-md-12">
              Specialization:
            </div>
            <div className="col-lg-6 col-md-12">
            {row?.doctorinfo?.specialization.map((item) => item.name+", ")}
            </div>
            </div>
            
            <div style={{marginTop:"10px"}} className="row form-row">
            <div className="col-lg-6 col-md-12">
              Education:
            </div>
            <div className="col-lg-6 col-md-12">
            {row?.doctorinfo?.education_training ? row?.doctorinfo?.education_training : "NA"}
            </div>
            </div>
            
            <div style={{marginTop:"10px"}} className="row form-row">
            <div className="col-lg-6 col-md-12">
              Services:
            </div>
            <div className="col-lg-6 col-md-12">
            {row?.doctorinfo?.service ? row?.doctorinfo?.service : "NA"}
            </div>
            </div>
            
            <div style={{marginTop:"10px"}} className="row form-row">
            <div className="col-lg-6 col-md-12">
              Experience:
            </div>
            <div className="col-lg-6 col-md-12">
            {row?.doctorinfo?.years_of_experience ? row?.doctorinfo?.years_of_experience+" years" : "NA"}
            </div>
            </div>
            
            <div style={{marginTop:"10px"}} className="row form-row">
            <div className="col-lg-6 col-md-12">
              Awards & Achievements:
            </div>
            <div className="col-lg-6 col-md-12">
            {row?.doctorinfo?.awards_achievements ? row?.doctorinfo?.awards_achievements : "NA"}
            </div>
            </div>

            <div style={{marginTop:"10px"}} className="row form-row">
            <div className="col-lg-6 col-md-12">
              Email:
            </div>
            <div className="col-lg-6 col-md-12">
            {row?.doctor?.email}
            </div>
            </div>
            
            <div style={{marginTop:"10px"}} className="row form-row">
            <div className="col-lg-6 col-md-12">
              Mobile Number:
            </div>
            <div className="col-lg-6 col-md-12">
            {row?.doctor?.country_code} {row?.doctor?.mobile_number}
            </div>
            </div>

            {row?.clinic_address && <div style={{marginTop:"10px"}} className="row form-row">
            <div className="col-lg-6 col-md-12">
              Clinic Address:
            </div>
            <div className="col-lg-6 col-md-12">
            {row?.clinic_address?.clinic_name} - {row?.clinic_address?.street_name}, {row?.clinic_address?.city_village}, {row?.clinic_address?.district}, {row?.clinic_address?.state}, {row?.clinic_address?.country}, {row?.clinic_address?.pincode}
            </div>
            </div>}

            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
  }
  