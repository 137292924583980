import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import PharmacyForm from "./Form";
import { useHistory } from "react-router-dom";

export const ApprovedForm = (props) => {
  const [data, setData] = React.useState()
  const [type, setType] = React.useState('')
  const [userType, setUserType] = React.useState('EMPLOYEE')
  React.useEffect(() => {
    setData(props.location.state.detail)
    setType(props.location.state.type)
    setUserType(props.location.state.userType)
  }, [setData, setType])

  let header = ''
  if (type == 'edit') {
    header = "Edit Employee"
  }

  if (type == 'view') {
    header = "View Employee"
  }


  const history = useHistory();
  const saveStore = () => { }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  return (
    <Card>
      <CardHeader title={header}>
        <CardHeaderToolbar>
            {(type == 'edit_and_approve') && <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={() => history.push('/employee/new')}
          >
            Back to List
            </button>}
            {(type == 'edit' || type == 'view') && <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={() => history.push('/employee')}
          >
            Back to List
            </button>}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
        {data && <PharmacyForm
            // actionsLoading={actionsLoading}
            data={data}
            type={type}
            userType={userType}
            btnRef={btnRef}
            resetbtnRef={resetbtnRef}
            saveProduct={saveStore}
          >
          </PharmacyForm>}
        </div>
      </CardBody>
    </Card>
  )
};
