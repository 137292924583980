import React, { useState, useMemo } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../_redux/authRedux";
import { SendEmailOtp, SendMobileOtp, VerifyEmailAndMobileOtp, VerifyEmailOtp, VerifyMobileOtp } from "../../../Api/Auth/OtpVerificationApi.js";
import { ErrorToast } from "../../../Toast/ErrorSnakBar";
import { SuccessToast } from "../../../Toast/SuccessSnackBar";
import Alert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";
import {Timer} from "../../../../Utils/Components/Timer";


function Registration(props) {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(<p></p>);
  const [disableEmailSend, setDisableEmailSend] = useState(true);
  const [disableMobileSend, setDisableMobileSend] = useState(true);
  const [mounted, setMounted] = useState(true);


  const data = props.location.state.data
  var VerifyOtp =''
  var email = null
  var isEmail = false
  if (data.errors.email) {
    email = data.errors.email[0]
    isEmail = true
    VerifyOtp = VerifyEmailOtp
  }
  var mobile_number = null
  var isMobile = false
  if (data.errors.mobile_number) {
    mobile_number = data.errors.mobile_number[0]
    isMobile = true
    VerifyOtp = VerifyMobileOtp
  }
  
  if (data.errors.mobile_number && data.errors.email) {
    VerifyOtp = VerifyEmailAndMobileOtp
  }
  const initialValues = {
    email: email,
    isEmail : isEmail,
    mobile_number: mobile_number,
    isMobile : isMobile,
    mobile_otp: "",
    email_otp: "",
  };
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  
  const otp6 = new RegExp(/^[0-9]+$/)
  const RegistrationSchema = Yup.object().shape({
    isMobile : Yup.boolean(),
    mobile_otp: Yup.string().when('isMobile', {
      is: true,
      then: Yup.string()
      .nullable()
      .matches(otp6,"Must be a valid number")
      .length(6, "Must be a 6 digit")
      .required('The Mobile OTP field is required.'),
    }),
    isEmail : Yup.boolean(),
    email_otp: Yup.string().when('isEmail', {
      is: true,
      then: Yup.string()
      .nullable()
      .matches(otp6,"Must be a valid number")
      .length(6, "Must be a 6 digit")
      .required('The email OTP field is required.'),
    }),
      });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

      // Random component
    const Completionist = () => ''

    

    

    const sendEmailOTP = () => {
      SendEmailOtp({'email': email})
      .then((items) => {
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
      })
      .catch((err) => {
          ErrorToast(err)
          setDisableEmailSend(true)
      });
    }
  const onClickSendEmailOTP = () => {
    setDisableEmailSend(false)
    sendEmailOTP()
  };

    const SendMobileOTP = () => {
      SendMobileOtp({'mobile_number': mobile_number})
      .then((items) => {
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
      })
      .catch((err) => {
          ErrorToast(err)
          setDisableMobileSend(true)
      });
    }

  const onClickSendMObileOTP = () => {
    setDisableMobileSend(false)
    SendMobileOTP()
  };

  React.useEffect(() => {
    if(mounted) {
      setMounted(false)
      onClickSendMObileOTP()
      onClickSendEmailOTP()
    }
    
  }, [onClickSendEmailOTP, onClickSendMObileOTP])

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      VerifyOtp(values)
        .then((data) => {
          if (data && data.data && data.data.message) {
            SuccessToast(data.data.message)
          }
          if (data.data.user_type == "PHARMACIST" || "LABORATORY") {
            const accessToken = data.data.access_token
            if (data.data.first_login == 1){ 
            } else {
              history.push({
                pathname: '/auth/changePassword',
                state: { 'detail': data.data, 'values': props.location.state.values }
              })
            }
          } else {
            setErrorMessage(<Alert severity="error">Not an authorized user</Alert>)
            setSubmitting(false);
          }
          disableLoading();
          setSubmitting(false);
        })
        .catch((err) => {
            ErrorToast(err)
          setSubmitting(false);
          disableLoading();
        });
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          {/* <FormattedMessage id="AUTH.REGISTER.TITLE" /> */}
          OTP Verification
        </h3>
        {/* <p className="text-muted font-weight-bold">
          Enter your new password
        </p> */}
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}


        {/* begin: Email */}
        {email && <div className="form-group fv-plugins-icon-container">
          <input
          disabled
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}


          <div style={{marginTop:'20px'}} className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email OTP"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email_otp"
            )}`}
            name="email_otp"
            {...formik.getFieldProps("email_otp")}
          />
          {formik.touched.email_otp && formik.errors.email_otp ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email_otp}</div>
            </div>
          ) : null}
        </div>
        {/* <div class="text-right">
          <Countdown
              date={emailTime}
              renderer={renderer_email}
            /></div> */}
        
        {disableEmailSend && <div class="text-right">
            <button
            onClick={onClickSendEmailOTP}
              type="button"
              className="btn btn-light-primary font-weight-bold py-4 my-3"
            >
              Send Email OTP
            </button>
            </div>}
        </div>}
        {/* end: Email */}

        {!disableEmailSend && <Timer 
        id='email'
            setDisable={setDisableEmailSend}
            />}
        

        {mobile_number && <div className="form-group fv-plugins-icon-container">
          <input
          disabled
            placeholder="Mobile Number"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6`}
            name="mobile_number"
            {...formik.getFieldProps("mobile_number")}
          />
          {formik.touched.mobile_number && formik.errors.mobile_number ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.mobile_number}</div>
            </div>
          ) : null}


          <div style={{marginTop:'20px'}} className="form-group fv-plugins-icon-container">
          <input
            placeholder="Mobile OTP"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "mobile_otp"
            )}`}
            name="mobile_otp"
            {...formik.getFieldProps("mobile_otp")}
          />
          {formik.touched.mobile_otp && formik.errors.mobile_otp ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.mobile_otp}</div>
            </div>
          ) : null}
        </div>
            {/* <div class="text-right">
          <Countdown
              date={mobileTime}
              renderer={renderer_mobile}
            /></div> */}
            {disableMobileSend && <div class="text-right">
            <button
            onClick={onClickSendMObileOTP}
              type="button"
              className="btn btn-light-primary font-weight-bold py-4 my-3"
            >
              Send Mobile OTP
            </button>
            </div>}
        </div>}
        
            {!disableMobileSend && <Timer 
            id='mobile'
            setDisable={setDisableMobileSend}
            />}
        
        {errorMessage}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid 
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Verify</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
