// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, {useState} from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { AddData } from "../../modules/Api/Test/TestApi";
import Spinner from "../../Utils/Components/CustomSpinner";
import { ErrorToast } from "../../modules/Toast/ErrorSnakBar";
import { SuccessToast } from "../../modules/Toast/SuccessSnackBar";
import TextField from '@material-ui/core/TextField';
import { CurrencySelect } from '../../Utils/Components/CurrencySelect';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'

const FILE_SIZE = 1024 * 1024 * 2;
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
];

const numberandfullRex = /^[1-9]\d*(\.\d+)?$/

// Validation schema
const CategoryEditSchema = Yup.object().shape({
    name: Yup.string()
        .required("Test Name field is required"),
    price: Yup.string()
        .required("MRP is required")
        .matches(numberandfullRex, 'Invalid Data!')
        .nullable(),
    image: Yup.mixed()
        .test(
            "fileSize",
            "Image file size should not be greater than 2mb",
            function (value) {
                if (!value) return true;
                return value.size <= FILE_SIZE
            })
        .test(
            "fileFormat",
            "Image format should be jpg, jpeg, png",
            function (value) {
                if (!value) return true;
                return SUPPORTED_FORMATS.includes(value.type)
            })
});

export function StoreEditForm(props) {
    const {
        data,
        btnRef,
        resetbtnRef,
        saveProduct,
    } = props
    const [loading, setLoading] = React.useState(false)
    const [desc,setDesc]=useState('')
    data['image_name'] = ''
    if (loading) {
        return (
            <Spinner />
        )
    }
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={data}
                validationSchema={CategoryEditSchema}
                onSubmit={(values) => {
                    setLoading(true)
                    let formData = new FormData();
                    formData.set('name', values.name)
                    formData.set('image', values.image)
                    formData.set('code', values.code)
                    formData.set('price', values.price)
                    formData.set('currency_code', values.currency_code)
                    formData.set('short_disc', desc)
                    AddData(formData)
                        .then(items => {
                            setLoading(false)
                            if (items && items.data && items.data.message) {
                                SuccessToast(items.data.message)
                            }

                        })
                        .catch(err => {
                            ErrorToast(err)
                            setLoading(false)
                        })
                }}
            >
                {({ handleSubmit, setFieldValue, resetForm, handleChange, handleBlur, setTouched, touched, values, errors }) => (
                    <>
                        <Form className="form form-label-right">
                            {/* <label>Medicine name<span className="text-danger">&nbsp;*</span></label> */}
                            <div className=" row">
                                <div className="col-lg-8">
                                    <TextField
                                        fullWidth
                                        style={{ marginBottom: '3%' }}
                                        required
                                        label="Test Name"
                                        name="name"
                                        type="text"
                                        value={values.name}
                                        variant="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    // error={errors.name}
                                    />
                                    {errors.name && touched.name && (
                                        <p style={{ color: "red" }}>{errors.name}</p>
                                    )}
                                </div>
                            </div>



                            <div className=" row">
                                <div className="col-lg-8">
                                    <TextField
                                        style={{ marginBottom: '3%' }}
                                        fullWidth
                                        label="Test Code"
                                        name="code"
                                        type="text"
                                        value={values.code}
                                        variant="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>
                            </div>


                            <div className=" row">
                                <div className="col-lg-8">
                                    <CurrencySelect
                                        value={values.price}
                                        onChange={value => {
                                            setFieldValue("price", value)
                                        }}
                                        onBlur={handleBlur}
                                        label='MRP'
                                        name="price"
                                    // errors={errors.price}
                                    />
                                    {errors.price && touched.price && (
                                        <p style={{ color: "red" }}>{errors.price}</p>
                                    )}
                                </div>
                            </div>

                            <div className=" row">
                                <div className="col-lg-8">
                                    <ReactQuill style={{marginBottom:'20px'}} theme="snow" value={desc} onChange={setDesc} />
                                </div>
                            </div>
                            
                            <div className=" row">
                                <div className="col-lg-8">
                                    <input
                                        style={{ display: "none" }}
                                        id="image"
                                        name='image'
                                        errors={errors.image}
                                        onChange={e => {
                                            console.log(e.target.files[0]);
                                            setFieldValue("image", e.target.files[0])
                                        }}
                                        onBlur={handleBlur}
                                        variant="outlined"
                                        type='file'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label='Drug Licence'
                                    />
                                    <label htmlFor={'image'} className="fileUpload" style={props.labelStyle || {}}>
                                        <>
                                            <span style={{
                                                padding: '5px 10px',
                                                marginTop: '10px',
                                                border: 'none',
                                                borderRadius: '4px',
                                                background: '#e7e7e7',
                                                // color: '#fff',
                                                border: '2px solid',
                                                fontSize: '14px',
                                                cursor: 'pointer',
                                                transition: '.3s background',
                                                '&:hover': {
                                                    background: '#40a9ff'
                                                }
                                            }}>Choose Image
                                            </span></>

                                    </label>
                                </div>
                                <div className="col-lg-8">{
                                    values.image && values.image.name ? (<><span className="ml-2">{values.image.name}</span></>) : (
                                        <p></p>
                                    )
                                }</div>
                            </div>
                            {errors.image && touched.image && (
                                <p style={{ color: "red" }}>{errors.image}</p>
                            )}

                            <button
                                type="submit"
                                style={{ display: "none" }}
                                ref={btnRef}
                                onSubmit={() => handleSubmit()}
                            ></button>
                            <button
                                type="button"
                                style={{ display: "none" }}
                                ref={resetbtnRef}
                                onClick={() => resetForm()}
                            ></button>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
