import http from "../../../Utils/http";

  export function SendEmailOtp(data, accessToken) {
    return http.post(`api/oauth/otp/resend/email`, data )
  }

  export function SendMobileOtp(data, accessToken) {
    return http.post(`api/oauth/otp/resend/mobile`, data )
  }

  export function VerifyEmailAndMobileOtp(data, accessToken) {
    return http.post(`api/oauth/otp/verify/mobileandemail`, data )
  }

  export function VerifyEmailOtp(data, accessToken) {
    return http.post(`api/oauth/otp/verify/email`, data )
  }

  export function VerifyMobileOtp(data, accessToken) {
    return http.post(`api/oauth/otp/verify/mobile`, data )
  }


  export function SendHealthassociateEmailOtp(data, accessToken) {
    return http.post(`api/oauth/healthassociate/otp/resend/email`, data )
  }

  export function SendHealthassociateMobileOtp(data, accessToken) {
    return http.post(`api/oauth/healthassociate/otp/resend/mobile`, data )
  }

  export function VerifyHealthassociateEmailAndMobileOtp(data, accessToken) {
    return http.post(`api/oauth/healthassociate/otp/verify/mobileandemail`, data )
  }