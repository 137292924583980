import React from "react";
import Alert from '@material-ui/lab/Alert';
import ProfileDetails from './ProfileDetails';
import AddressDetails from './AddressDetails';
import BankDetails from './BankDetails';
import CareerProfile from "./CareerProfile";
import { loadBasicinfo, loadAddressinfo, loadCareerinfo } from "../../../modules/Api/Doctor/NewDoctorApi";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import { loadPharmacyData, loadLaoratoryData } from "../../../modules/Api/Doctor/AddDoctor";

export default function Form (props) {
    const [errorMessage, setErrorMessage] = React.useState('')
    const [mounted, setMounted] = React.useState({})
    const [profileData, setProfileData] = React.useState(undefined)
    const [CareerData, setCareerData] = React.useState(undefined)
    const [data, setData] = React.useState(undefined)
    const [AddressData, setAddressData] = React.useState([])
    const [pharmacyList, setPharmacyList] = React.useState([])
    const [laboratoryList, setLaboratoryList] = React.useState([])
    const [step, setStep] = React.useState(1)

    const loadData = () => {
      setData(props.data)
    }

    const updateData = (data) => {
      setData(data)
    }

    const ProfileLoadData = () => {
      if (mounted) {
        const id = props.data.user.id
        setMounted(false)
        loadBasicinfo(id)
        .then(items => {
          // console.log(items);
          setMounted(false)
          setProfileData(items.data)
          if (items && items.data && items.data.message) {
            SuccessToast(items.data.message)
          }
          
        })
        .catch(err => {
          // ErrorToast(err)
          setMounted(false)
      })
      }
    }
    const updateProfileData = (data) => {
        setProfileData(data)
      }

      const AddressLoadData = () => {
        if (mounted) {
          const id = props.data.user.id
          setMounted(false)
          loadAddressinfo(id)
          .then(items => {
            // console.log(items.data.data);
            setMounted(false)
            setAddressData(items.data.data)
            if (items && items.data && items.data.message) {
              SuccessToast(items.data.message)
            }
            
          })
          .catch(err => {
            // ErrorToast(err)
            setMounted(false)
        })
        }
      }

        
            const updateAddressData = (data) => {
              setAddressData([...data])
            }


            const CareerLoadData = () => {
              if (mounted) {
                const id = props.data.user.id
                setMounted(false)
                loadCareerinfo(id)
                .then(items => {
                  setMounted(false)
                  setCareerData(items.data)
                  if (items && items.data && items.data.message) {
                    SuccessToast(items.data.message)
                  }
                  
                })
                .catch(err => {
                  // ErrorToast(err)
                  setMounted(false)
              })
              }
            }

            
                const updateCareerData = (data) => {
                  setCareerData(data)
                }

                const loadPharmacy = () => {
                  if (mounted) {
                  setMounted(false)
                  loadPharmacyData()
                  .then(items => {
                      setMounted(false)

                      // console.log(items.data);
                      setPharmacyList(items.data)
                      if (items && items.data && items.data.message) {
                        SuccessToast(items.data.message)
                      }
                      
                    })
                    .catch(err => {
                      setMounted(false)
                      // ErrorToast(err)
                  })
                  }
              }
          
              const loadLaboratory = () => {
                  if (mounted) {
                  setMounted(false)
                  loadLaoratoryData()
                  .then(items => {
                      setMounted(false)
                      setLaboratoryList(items.data)
                      if (items && items.data && items.data.message) {
                        SuccessToast(items.data.message)
                      }
                      
                    })
                    .catch(err => {
                      setMounted(false)
                      // ErrorToast(err)
                  })
                  }
              }

        React.useEffect(() => {
            loadData()
            ProfileLoadData()
            AddressLoadData()
            CareerLoadData()
            loadLaboratory()
            loadPharmacy()
          }, [loadData, ProfileLoadData, AddressLoadData, CareerLoadData, loadPharmacy, loadLaboratory])

          const nextStep = () => {
              setStep(step + 1)
          }

          const previousStep = () => {
            setStep(step - 1)
        }

          switch (step) {
            case 1 :
                return (
                    <div>
                    {profileData && <ProfileDetails
                    profileData={profileData}
                    data={props.data}
                    type={props.type}
                    nextStep={nextStep}
                    updateProfileData={updateProfileData}
                    handleClose={props.handleClose}
                    />}
                </div>
                )
            case 2 :
                return (
                    <div>
                    {<AddressDetails
                    AddressData={AddressData}
                    pharmacyList={pharmacyList}
                    laboratoryList={laboratoryList}
                    data={props.data}
                    type={props.type}
                    nextStep={nextStep}
                    previousStep={previousStep}
                    updateAddressData={updateAddressData}
                    handleClose={props.handleClose}
                    />}
                </div>
                )
                case 3 :
                  return (
                      <div>
                      {data && <BankDetails
                      data={data}
                      type={props.type}
                      updateData={updateData}
                      nextStep={nextStep}
                      previousStep={previousStep}
                      handleClose={props.handleClose}
                      />}
                  </div>
                  )
            case 4 :
                return (
                    <div>
                    {CareerData && <CareerProfile
                    CareerData={CareerData}
                    data={props.data}
                    type={props.type}
                    nextStep={nextStep}
                    previousStep={previousStep}
                    updateCareerData={updateCareerData}
                    handleClose={props.handleClose}
                    />}
                </div>
                )
            default:
                return (
                    <div></div>
                )
    }

}