import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import { ListNew } from "./index"
import ListApproved from "./ListApprove"
import {Form} from "./Details/Laboratory";
import {AddForm} from "./Add/Laboratory";
export const Laboratory = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Laboratory");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/laboratory"
        to="/laboratory/approved"
      />
      <ContentRoute from="/laboratory/new" component={ListNew} />
      <ContentRoute from="/laboratory/approved" component={ListApproved} />
      <ContentRoute from="/laboratory/add" component={AddForm} />
      <ContentRoute from="/laboratory/view" component={Form} />
    </Switch>
  );
};
