import React from "react";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import getSymbolFromCurrency from 'currency-symbol-map'
import DeleteModal from "./page-components/DeleteModal";
import EditModel from "./page-components/EditModel";
import { deleteData, editData } from "../../modules/Api/Medicine/MedicineApi";
import IconButton from '@material-ui/core/IconButton';
import {ErrorToast} from "../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../modules/Toast/SuccessSnackBar";
import { makeStyles } from '@material-ui/core/styles';
import { ViewDetails } from "./ViewDetails";
import Hoc from "../../Utils/HOC";
import { GetCurrencyCode } from "../../Utils/GetData";

const useStyles2 = makeStyles(theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    table: {
      minWidth: 500,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }));

export function TableRowData (props) {
    const classes = useStyles2();
    const {rows, row, page, loadPageData, Categorydata} = props
    const [expanded, setExpanded] = React.useState(false);

    const currency_code = GetCurrencyCode()

    const clickView =() => {
        setExpanded(!expanded)
      }



      const onEdit = (values, id) => {
        editData(values, id)
              .then(items => {
                  loadPageData(page+1)
                  if (items && items.data && items.data.message) {
                  SuccessToast(items.data.message)
                  }
                  
              })
              .catch(err => {
                  ErrorToast(err)
              })
      }

      const onDelete = (id) => {
        let newPage = page + 1
        if (rows.data.length == 1) {
            newPage = page
        } else {
            newPage = page + 1
        }
        deleteData(id)
            .then(items => {
                loadPageData(newPage)
                if (items && items.data && items.data.message) {
                SuccessToast(items.data.message)
                }
                
            })
            .catch(err => {
                ErrorToast(err)
            })
    }
    return (
        <Hoc>
        <TableRow key={row.id}>
            <TableCell align="center">{row.slNo}</TableCell>
            <TableCell component="th" scope="row">{row.item.sku}</TableCell>
            <TableCell component="th" scope="row">
                {row.item.name}
            </TableCell>
            <TableCell component="th" scope="row">
            {row.item.weight ? (row.item.weight+' '+row.item.weight_unit) : "NA"}
            </TableCell>
            <TableCell component="th" scope="row">
            {row.item.qty_per_strip ? (row.item.qty_per_strip+' Nos') : "NA"}
            </TableCell>
            <TableCell component="th" scope="row">
            {getSymbolFromCurrency(currency_code)} {row.item.price_per_strip}
            </TableCell>
            <TableCell align="center">
                <EditModel row={row.item} Categorydata={Categorydata} onEdit={onEdit} />
                <DeleteModal row={row.item} onDelete={onDelete} />
                <IconButton
                className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                })}
                onClick={clickView}
                aria-expanded={expanded}
                aria-label="Show more"
                >
                <ExpandMoreIcon />
                </IconButton>
            </TableCell>
        </TableRow>
        {expanded && <ViewDetails row={row} Categorydata={Categorydata} />}
        </Hoc>
    )
}