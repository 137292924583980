import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import { View } from "./index";

export const ChangePassword = (props) => {

  const suhbeader = useSubheader();
  suhbeader.setTitle("ChangePassword");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/change-password"
        to="/change-password/view"
      />
      <ContentRoute from="/change-password/view" component={View} />
    </Switch>
  );
};
