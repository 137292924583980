import React, { Component } from 'react';
import Details from './Details';
import Address from './Address';
import EmailAndMobileOTP from "./EmailAndMobileOTP";
import {ErrorToast} from "../../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../../modules/Toast/SuccessSnackBar";
import {loadListRole } from "../../../../modules/Api/Emloyee/ListEmployeeApi";


export class Form extends Component {
    constructor(props) {
        super(props);
        
        // this.updateData = this.updateData.bind(this)
    
        this.state = {
            step: 1,
            user_id:undefined,
            roleList:undefined,
            mounted:true,
            data:{}
        }
        };

    // next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        })
    }

    updateData = (id, data) => {
        this.setState({user_id:id})
        this.setState({data:data})
    }

    // previous step
    previosStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        })
    }


    render() {

        console.log('form');

        const { step } = this.state

        switch (step) {
            case 1 :
                return (
                    <div>
                    {<Details
                    nextStep={this.nextStep}
                    updateData={this.updateData}
                    roleList={this.state.roleList}
                    />}
                    </div>
                )
            case 2 :
                return (
                    <Address
                    user_id={this.state.user_id}
                    nextStep={this.nextStep}
                    previosStep={this.previosStep}
                    updateData={this.updateData}
                    />
                )
            case 3 :
                return (
                    <>
                    {this.state.data && <EmailAndMobileOTP
                    user_id={this.state.user_id}
                    nextStep={this.nextStep}
                    previosStep={this.previosStep}
                    updateData={this.updateData}
                    data={this.state.data}
                    />}
                    </>
                )
            default:
                return (
                    <Details
                    values={this.state.formData}
                    nextStep={this.nextStep}
                    previosStep={this.previosStep}
                    updateData={this.updateData}
                    />
                )
        }
    }
}