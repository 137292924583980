import http from "../../../Utils/http";

export function loadTableData() {
  const pharams = "filter[active]=0&user_type=HEALTHASSOCIATE"
    return http.get(`api/admin/list/employee/?${pharams}`);
  }

  
  export function pageLoadData(no) {
    const pharams = 'filter[active]=0&user_type=HEALTHASSOCIATE&page='+no
    return http.get(`api/admin/list/employee/?${pharams}`);
  }

  export function searchLoadData(text) {
    const pharams = "filter[active]=0&user_type=HEALTHASSOCIATE&filter[name]="+text
    return http.get(`api/admin/list/employee/?${pharams}`);
  }

  export function EditBasicInfo(id, data) {
    return http.post(`api/admin/employee/basicinfo/${id}`, data )
  }

  export function EditAddress(id, data) {
    return http.post(`api/admin/employee/address/${id}`, data )
  }

  export function loadListRole() {
    return http.get(`api/admin/list/roles/`);
  }


  export function confirmClick(id, comment) {
    const reqData = {
      user_id : id,
      action : "APPROVE",
      user_type : "HEALTHASSOCIATE",
      comment : comment
    }
    return http.post(`api/admin/action/approveorreject`, reqData )
  }

  export function rejectClick(id, comment) {
    const reqData = {
      user_id : id,
      action : "REJECT",
      user_type : "HEALTHASSOCIATE",
      comment : comment
    }
    return http.post(`api/admin/action/approveorreject`, reqData )
  }