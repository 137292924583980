// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Input } from "../../../../../_metronic/_partials/controls";
import Button from 'react-bootstrap/Button';

const numberandfullRex = /^[1-9]\d*(\.\d+)?$/
// Validation schema
const CategoryEditSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Tax Name is required"),
    percent: Yup.string()
        .required("Percentage is required")
        .matches(numberandfullRex, 'Percentage field accepts only integer or decimal')
        .nullable(),
});

export function StoreEditForm(props) 
{
    const [loading, setLoading] = React.useState(false)
    const data = {
        name:props.row.name,
        percent:props.row.percent
    }


    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={data}
                validationSchema={CategoryEditSchema}
                onSubmit={(values) => {
                        props.onEdit(values, props.row.id)
                        props.handleHide()
                    
                }}
            >
            {({ handleSubmit, resetForm, handleChange, handleBlur, touched, values, errors }) => (
                    <>
<Form className="form form-label-right">
                            <div className="form-group row">
                                <div className="col-lg-8"
                                    style={{marginBottom:"3%"}}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Tax Name"
                                    name="name"
                                    type="text"
                                    margin="dense"
                                    value={values.name}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    />
                                    {errors.name && touched.name && (
                                    <p style={{color:"red"}}>{errors.name}</p>
                                    )}
                                </div>
                                <div className="col-lg-8">
                                <TextField
                                    id="outlined-adornment-height"
                                    fullWidth
                                    required
                                    margin="dense"
                                    variant="outlined"
                                    label="Percentage"
                                    name='percent'
                                    value={values.percent}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    />
                                    {errors.percent && touched.percent && (
                                    <p style={{color:"red"}}>{errors.percent}</p>
                                    )}
                                </div>
                            </div>
                            <Button style={{margin:'3px'}} variant="secondary" onClick={props.handleHide}>
                                Close
                            </Button>
                            <Button style={{margin:'3px'}} type='submit' variant="primary" >
                                Save Changes
                            </Button>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
