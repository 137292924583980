import React from "react";
import Typography from '@material-ui/core/Typography';
import { ImageLoad } from "../../Utils/Components/ImageLoad";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Hoc from "../../Utils/HOC";
import { makeStyles } from '@material-ui/core/styles';
import getSymbolFromCurrency from 'currency-symbol-map'
import { GetCurrencyCode } from "../../Utils/GetData";

const useStyles = makeStyles(theme => ({
    label: {
        color: '#77777A',
        height: '21px',
        marginTop: '10px',
        marginBottom: '5px',

        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '21px',
      },
    content1 : {
        width: '415px',

        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '26px',
        letterSpacing: '-0.008em',

      },
    content2 : {
        width: '150px',

        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '26px',
        letterSpacing: '-0.008em',

      }
  }));

export function ViewDetails (props) {
    const classes = useStyles();
    const {row, Categorydata} =props
    const category_list = (Categorydata.filter((item) => item.id == row?.item?.category_id))[0]
    const category = category_list ? category_list.name : "NA"
    var rx_required = 'Yes'
    if (row.item.rx_required == 0) {
        rx_required = 'No'
    }
    const currency_code = GetCurrencyCode()
    return (
        <TableRow key={row.id+"2"}>
              <TableCell style={{verticalAlign:"top"}} component="th" scope="row" colSpan={2} >

                <Hoc>
                <Typography className={classes.label}>
                  Product Description
                </Typography>

                <Typography className={classes.content1}>
                  {row.item.short_desc != 'null'&& row.item.short_desc != null ? <div dangerouslySetInnerHTML={{ __html: row.item.short_desc }} /> : "NA"}
                </Typography>
                </Hoc>

                <Hoc>
                <Typography className={classes.label}>
                  Product Cart Description
                </Typography>

                <Typography className={classes.content1}>
                  {row.item.cart_desc != 'null' && row.item.cart_desc != null ? <div dangerouslySetInnerHTML={{ __html: row.item.cart_desc }} /> : "NA"}
                </Typography>
                </Hoc>
              </TableCell>

              <TableCell colSpan={2} style={{verticalAlign:"top"}} >
              <Typography className={classes.label}>
                  Quantity per strip
                </Typography>

                <Typography className={classes.content2}>
                  {row.item.qty_per_strip ? (row.item.qty_per_strip) : "NA"}
                </Typography>

                
                <Typography className={classes.label}>
                  Medicine type
                </Typography>

                <Typography className={classes.content2}>
                  {row.item.medicine_type ? (row.item.medicine_type) : "NA"}
                </Typography>

                
                <Typography className={classes.label}>
                  Category ID
                </Typography>

                <Typography className={classes.content2}>
                  {category}
                </Typography>


                <Typography className={classes.label}>
                  Manufacturer
                </Typography>

                <Typography className={classes.content2}>
                  {row.item.manufacturer ? (row.item.manufacturer) : "NA"}
                </Typography>
                <div style={{marginTop:"10px"}}>
                  <ImageLoad image={row.item.image_url} style={{width:'100%', height:'auto'}} />
                </div>

              </TableCell>

              <TableCell style={{verticalAlign:"top"}} colSpan={2} >
                

                
              <Typography className={classes.label}>
                  Price per strip
                </Typography>

                <Typography className={classes.content2}>
                {row.item.price_per_strip ? (getSymbolFromCurrency(currency_code)+" "+row.item.price_per_strip) : "NA"}
                </Typography>

                
                <Typography className={classes.label}>
                  Drug Type
                </Typography>

                <Typography className={classes.content2}>
                  {row.item.drug_type ? (row.item.drug_type) : "NA"}
                </Typography>

                
                <Typography className={classes.label}>
                  RX Required
                </Typography>

                <Typography className={classes.content2}>
                  {rx_required ? rx_required : "NA"}
                </Typography>
              </TableCell>

              </TableRow>
    )
}