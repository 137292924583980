import React from "react";
import HOC from "../HOC";
import TextField from '@material-ui/core/TextField';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useSelector } from "react-redux";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

export function CurrencySelect (props) {
    const { disabled=false, type = "text", value, name, label, onChange = () => { }, errors, onBlur = () => { } } = props

    console.log('errors', errors);

    const { user } = useSelector((state) => state.auth);
    var currency_code = user.currency_code
    if (currency_code == null || currency_code == 'null') {
        currency_code = 'INR'
    }


    return (
        <HOC>
            <TextField
            style={{marginRight:"2%", marginBottom:"2%", width:"30%"}}
            disabled
            type="text"
            value={currency_code}
            variant="outlined"
            />
                <FormControl style={{width:'68%'}} variant="outlined" 
                required>
                <InputLabel htmlFor="outlined-adornment-amount">{label}</InputLabel>
                <OutlinedInput
                required
                disabled={disabled}
                    name={name}
                    type={type}
                    value={value}
                    onBlur={onBlur}
                    onChange={event => {
                        onChange(event.target.value)
                    }}
                    startAdornment={<InputAdornment position="start">{getSymbolFromCurrency(currency_code)}</InputAdornment>}
                    label={label}
                />
                {errors && <label style={{color:'red' }}
                >{errors}</label>}
                </FormControl>
        </HOC>
    )
}