import React from "react";
import {Button, Modal} from "react-bootstrap";
import { Formik, Form } from "formik";
import getSymbolFromCurrency from 'currency-symbol-map'
import { GetCurrencyCode } from "../../../Utils/GetData";
import moment from "moment";
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';

const numberandfullRex = /^[0-9]\d*(\.\d+)?$/
const validationS = Yup.object({
  is_valid_pns: Yup.string()
      .required("PNS valid is required")
      .nullable(),
  is_refunded: Yup.string()
      .required("Refund is required")
      .nullable(),
  payed_amount: Yup.string(),
  refund_amount: Yup.string()
      .required("Refund amount is required")
      .matches(numberandfullRex, 'Amount field accepts only integer or decimal')
      .test(
          "less",
          "Refund amount must be less than paid amount",
          function (value) {
              const { payed_amount } = this.parent;
              console.log();
              if (parseFloat(value) < parseFloat(payed_amount)) {
                return true;
              } else {
                return false;
              }
          })
      .nullable(),
  // admin_pns_comment: Yup.string()
  //     .required("Comment is required")
  //     .nullable(),
  })

export function EditPNSModal (props) {
    const {row, onEdit} = props
    const [show, setShow] = React.useState(false)
    // console.log('row', row);

    const initialValues = {
      appointment_id: row.id,
      is_valid_pns: row.is_valid_pns || 1,
      is_refunded: row.is_refunded || 1,
      payed_amount: row.total,
      refund_amount: row.refund_amount,
      admin_pns_comment: row.admin_pns_comment,
    }
  
    const handleClose = () => {
        setShow(false)
    }
  
    const handleShow = () => {
        setShow(true)
    }

      return (
        <>
          <Button variant="link" onClick={handleShow}>Update PNS</Button>

          <Formik
        initialValues={initialValues}
        validationSchema={validationS}
        onSubmit={(values) => {
          console.log(values, "values")
          onEdit(values)
          handleClose()
        }
        }
      >{({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        dirty
        /* and other goodies */
      }) => (
        <Form className="form form-label-right">
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update PNS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        <div className="form-group row">
              <div className="col-lg-6">
                Doctor Comment : 
              </div>
              <div className="col-lg-6">
                {row.pns_comment ? row.pns_comment : "NA"}
              </div>
          </div>
          <div className="form-group row">
              <div className="col-lg-12">
              <FormControl fullWidth variant="outlined">
                    <InputLabel required htmlFor="outlined-adornment-amount">PNS Valid</InputLabel>
                    <Select
                    label="PNS Valid"
                    type="text"
                    name='is_valid_pns'
                    value={values.is_valid_pns}
                    variant="outlined"
                    onChange={e => {
                      setFieldValue("is_valid_pns", e.target.value)
                      if (e.target.value === 0) {
                        setFieldValue("is_refunded", 0)
                        setFieldValue("refund_amount", 0)
                      }
                      if (e.target.value === 1) {
                        setFieldValue("is_refunded", "")
                        setFieldValue("refund_amount", "")
                      }
                    }}
                    onBlur={handleBlur}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                </Select>
                </FormControl>
                  {errors.is_valid_pns && touched.is_valid_pns && (
                  <p style={{color:"red"}}>{errors.is_valid_pns}</p>
                  )}
              </div>
          </div>
          
          <div className="form-group row">
              <div className="col-lg-12">
              <FormControl fullWidth variant="outlined">
                    <InputLabel required htmlFor="outlined-adornment-amount">Refund</InputLabel>
                    <Select
                    disabled={values.is_valid_pns === 0}
                    label="Refund"
                    type="text"
                    name='is_refunded'
                    value={values.is_refunded}
                    variant="outlined"
                    onChange={e => {
                      setFieldValue("is_refunded", e.target.value)
                      if (e.target.value === 0) {
                        setFieldValue("refund_amount", 0)
                      }
                      if (e.target.value === 1) {
                        setFieldValue("refund_amount", "")
                      }
                    }}
                    onBlur={handleBlur}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                </Select>
                </FormControl>
                  {errors.is_refunded && touched.is_refunded && (
                  <p style={{color:"red"}}>{errors.is_refunded}</p>
                  )}
              </div>
          </div>
          
          <div className="form-group row">
              <div className="col-lg-12">
                  <TextField
                  disabled
                  fullWidth
                    label="Paid Amount"
                    name='payed_amount'
                    value={values.payed_amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{getSymbolFromCurrency(GetCurrencyCode())}</InputAdornment>,
                    }}
                    variant="outlined"
                  />
                  {errors.payed_amount && touched.payed_amount && (
                  <p style={{color:"red"}}>{errors.payed_amount}</p>
                  )}
              </div>
          </div>
          
          <div className="form-group row">
              <div className="col-lg-12">
                  <TextField
                  required
                  fullWidth
                  disabled = {values.is_refunded === 0}
                    label="Refund Amount"
                    name='refund_amount'
                    value={values.refund_amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{getSymbolFromCurrency(GetCurrencyCode())}</InputAdornment>,
                    }}
                    variant="outlined"
                  />
                  {errors.refund_amount && touched.refund_amount && (
                  <p style={{color:"red"}}>{errors.refund_amount}</p>
                  )}
              </div>
          </div>

          <div className="form-group row">
              <div className="col-lg-12">
                <TextField
                fullWidth
                // required
                  label="Comment"
                  name="admin_pns_comment"
                  value={values.admin_pns_comment}
                  multiline
                  rows={2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                />
                  {errors.admin_pns_comment && touched.admin_pns_comment && (
                  <p style={{color:"red"}}>{errors.admin_pns_comment}</p>
                  )}
              </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button disabled={!dirty} variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
        </Modal.Footer>
      </Modal>
      </Form>
        )}
      </Formik>
        </>
      );
  }
  