// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from 'react-bootstrap/Button';
import TextField from '@material-ui/core/TextField';
import { ViewCard } from "./ViewCard";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import getSymbolFromCurrency from 'currency-symbol-map'
import { GetCurrencyCode } from "../../../../../../../Utils/GetData";

const numberandfullRex = /^[1-9]\d*(\.\d+)?$/

// Validation schema
const CategoryEditSchema = Yup.object().shape({
    // payout: Yup.string()
    //     .required("Payout field is required")
    //     .nullable(),
    amount: Yup.string()
        .required("Amount field is required")
        .matches(numberandfullRex, 'Invalid Data!')
        .nullable(),
    reference: Yup.string()
        .required("Reference field is required")
        .nullable(),
});

export function StoreEditForm(props) 
{
    const currency_code = GetCurrencyCode()
    const [loading, setLoading] = React.useState(false)
    const { row, rows, OnSinglePayment, handleHide } = props
    // console.log('row', row);
    // console.log('rows', rows);

    const [data, setData] = React.useState(row)
    const payoutList = []
    rows.map((item) => item.status == 0 && payoutList.push(item.payout_id))

    const ChangePayout = (e) => {
        const newData = (rows.filter((item) => e.target.value == item.payout_id))[0]
        setData(newData)
    }


    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    payout_id : data.id,
                    payout : data.payout_id,
                    reference : '',
                    amount : data.current_due,
                    comment : '',
                }}
                validationSchema={CategoryEditSchema}
                onSubmit={(values) => {
                        OnSinglePayment(values)
                        handleHide()
                    
                }}
            >
            {({ handleSubmit, setFieldValue, resetForm, handleChange, handleBlur, touched, values, errors }) => (
                <>
                        <Form className="form form-label-right">
                            {/* <label>Medicine name<span className="text-danger">&nbsp;*</span></label> */}
                            <div className=" row">
                                <div className="col-lg-12">
                                    {/* <TextField
                                    disabled
                                    fullWidth
                                    style={{marginBottom:'3%'}}
                                    required
                                        label="Payout ID"
                                        name="payout"
                                        type="text"
                                        value={values.payout}
                                        variant="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        /> */}
                                    
                                    <FormControl fullWidth variant="outlined"
                                    style={{marginBottom:'3%'}} >
                                        <InputLabel htmlFor="outlined-age-native-simple">Select Payout<span>&nbsp;*</span></InputLabel>
                                        <Select
                                        native
                                        label="Select Payout"
                                        name="payout"
                                        type="text"
                                        value={values.payout}
                                        variant="outlined"
                                        onChange={(e) => {
                                            setFieldValue("payout", e.target.value)
                                            ChangePayout(e)
                                        }}
                                        onBlur={handleBlur}
                                        >
                                            {
                                                payoutList.map((item) => (<option value={item}>{item}</option>))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className=" row">
                                <div className="col-lg-12">
                                    <ViewCard data={data} />
                                </div>
                            </div>

                            <div className=" row">
                                <div className="col-lg-6">
                                    {/* <TextField
                                    fullWidth
                                    style={{marginBottom:'3%'}}
                                    required
                                        label="Payout Amount"
                                        name="amount"
                                        type="text"
                                        value={values.amount}
                                        variant="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputLabelProps={{
                                            shrink: true,
                                          }}
                                        /> */}
                                        <FormControl fullWidth  variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-amount">Payout Amount</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            name="amount"
                                            type="text"
                                            value={values.amount}
                                            variant="outlined"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Payout Amount"
                                            startAdornment={<InputAdornment position="start">{getSymbolFromCurrency(GetCurrencyCode())}</InputAdornment>}
                                        />
                                        </FormControl>
                                        {errors.amount && touched.amount && (
                                        <p style={{color:"red"}}>{errors.amount}</p>
                                        )}
                                </div>
                                
                                <div className="col-lg-6">
                                    <TextField
                                    fullWidth
                                    style={{marginBottom:'3%'}}
                                    required
                                        label="Payout Refrence"
                                        name="reference"
                                        type="text"
                                        value={values.reference}
                                        variant="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        />
                                        {errors.reference && touched.reference && (
                                        <p style={{color:"red"}}>{errors.reference}</p>
                                        )}
                                </div>
                            </div>

                            <div className=" row">
                                <div className="col-lg-12">
                                    <TextField
                                    fullWidth
                                    multiline
                                    style={{marginBottom:'3%'}}
                                        label="Payout Comments"
                                        name="comment"
                                        type="text"
                                        value={values.comment}
                                        variant="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        />
                                        {errors.comment && touched.comment && (
                                        <p style={{color:"red"}}>{errors.comment}</p>
                                        )}
                                </div>
                            </div>
                            <Button style={{margin:'3px'}} variant="secondary" onClick={props.handleHide}>
                                Close
                            </Button>
                            <Button style={{margin:'3px'}} type='submit' variant="primary" >
                                Save Payment Details and Notify Vendor
                            </Button>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
