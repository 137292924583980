import http from "../../../Utils/http";

export function LoadData() {
    return http.get(`api/tax/`);
  }

  export function pageLoadData(no) {
    const pageNo = 'page='+no
    return http.get(`api/tax?${pageNo}`);
  }

  export function AddData(data) {
    return http.post(`api/admin/tax`, data);
  }

  export function deleteData(id) {
    return http.post(`api/admin/tax/${id}`, {
      _method:'DELETE',
      });
  }

  export function editData(data, id) {
    return http.post(`api/admin/tax/${id}`, data);
  }