import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Spinner from "../../../Utils/Components/CustomSpinner";
import { useHistory } from "react-router-dom";
import {getCode} from '../../../Utils/googleApi';
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import { EditAddress } from "../../../modules/Api/Profile/profileApi";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Details(props) {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)

  const onClickPrevious = () => {
    props.previosStep()
  }

  const changePincode = (event) => {
    const pincodeVal = event.target.value
    formik.setFieldValue("pincode", pincodeVal)
    getCode(pincodeVal, addr => {
      console.log(addr);
      if (!addr) {
        return
      }
      if (addr.country){
        formik.setFieldValue("country", addr.country)
      } else {
        formik.setFieldValue("country", '')
      }
      if (addr.state){
        formik.setFieldValue("state", addr.state)
      } else {
        formik.setFieldValue("state", '')
      }
      if (addr.district){
        formik.setFieldValue("district", addr.district)
      } else {
        formik.setFieldValue("district", '')
      }
      if (addr.position.lng){
        formik.setFieldValue("longitude", addr.position.lng)
      }
      if (addr.position.lat){
        formik.setFieldValue("latitude", addr.position.lat)
      }

    })
  }

  const {values} = props
  let initValues = {
    pincode: '',
    country: '',
    state: '',
    district: '',
    city_village: '',
    street_name: '',
    latitude: '',
    longitude: '',}

    if (values.address && values.address[0]) {
      initValues = {
        address_id:values.address[0].id,
        pincode: values.address[0].pincode,
        country: values.address[0].country,
        state: values.address[0].state,
        district: values.address[0].district,
        city_village: values.address[0].city_village,
        street_name: values.address[0].street_name,
        latitude: values.address[0].latitude,
        longitude: values.address[0].longitude,}
    }

    
    const editDetails = (data) => {
      const id = data.address_id
      EditAddress(data)
        .then(items => {
          setLoading(false)
          history.push('/profile')
          if (items && items.data && items.data.message) {
            SuccessToast("Profile data Saved successfully")
          }
          
        })
        .catch(err => {
          setLoading(false)
          ErrorToast(err)
      })
    }
    


    const formik = useFormik({
      initialValues: initValues,
      validationSchema: Yup.object({
        pincode: Yup.number()
          .min(100000, "Must be 6 digit")
          .max(999999, "Must be 6 digit")
          .nullable()
          .required("Pincode is Required!"),
        country: Yup.string()
         .required("Country is Required!"),
        state: Yup.string()
          .required("State is Required!"),
        district: Yup.string()
          .required("District is Required!"),
        city_village: Yup.string()
          .required("City/Village/Society is Required!"),
        street_name: Yup.string()
         .required("Area/Street/Sector is Required!"),
      }),
      onSubmit: values => {
        setLoading(true)
        editDetails(values)
      }
    });
    

    if (loading) {
      return (
        <Spinner />
      )
    }

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Address</h6>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="Pincode"
            name="pincode"
            type="number"
            value={formik.values.pincode}
            variant="outlined"
            // onChange={(e) => changePincode.bind(e)}
            onChange={changePincode}
            onBlur={formik.handleBlur}
            />
            {formik.errors.pincode && formik.touched.pincode && (
            <p style={{color:"red"}}>{formik.errors.pincode}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="Country"
            name="country"
            type="text"
            value={formik.values.country}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.errors.country && formik.touched.country && (
            <p style={{color:"red"}}>{formik.errors.country}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="State"
            name="state"
            type="text"
            value={formik.values.state}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.errors.state && formik.touched.state && (
            <p style={{color:"red"}}>{formik.errors.state}</p>
             )}
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="District"
            name="district"
            type="text"
            value={formik.values.district}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.errors.district && formik.touched.district && (
            <p style={{color:"red"}}>{formik.errors.district}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="City/Village/Society"
            name="city_village"
            type="text"
            value={formik.values.city_village}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.city_village && formik.touched.city_village && (
            <p style={{color:"red"}}>{formik.errors.city_village}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="Area/Street/Sector"
            name="street_name"
            type="text"
            value={formik.values.street_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
              {formik.errors.street_name && formik.touched.street_name && (
              <p style={{color:"red"}}>{formik.errors.street_name}</p>
               )}
        </Grid>
      </Grid>

<Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item xs={3} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
        </Grid>
        <Grid item xs={3}>
        </Grid>
        
        <Grid item xs={3}>
        </Grid>
        <Grid item xs={3} container justify={"flex-end"} >
            <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Save Changes</Button>
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.nextStep} >Next</Button> */}
            </Grid>
      </Grid> 
      </form>
            
    </div>
  );
}
