import axios from "axios"
import store from "../../redux/store";

const getToken = () => store.getState().auth.authToken;
export const base_url = process.env.REACT_APP_API_URL

const http = axios.create({
    baseURL: base_url
    
});

http.interceptors.request.use(
     (config) => {
        const token = getToken() ? getToken() : "";
        config.headers.Authorization = `Bearer ${token}`;
        config.headers.post["Content-Type"] = "application/json";
        return config;
    },
     (err) => {
        return Promise.reject(err);
    }
);

export default http;

