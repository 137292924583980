import React from 'react';
import Countdown from 'react-countdown';



export function Timer (props) {
  let countDownTimeInminutes = React.useMemo(() => Date.now() + (1000 * 60 * 2) , []); // 2 minutes
  // Random component
const Completionist = () => '';

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    props.setDisable(true)
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <div
    id={props.id} style={{
      alignItems: 'baseline',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '1px 8px',
      borderRadius: '7px'
  }}> 
    <i className="mr-2 fas fa-stopwatch"></i>
    <span style={{
      backgroundColor: 'rgb(235 249 228)',
    }}>
        {hours}:{minutes}:{seconds}
    </span>
  </div>;
  }
};
  return (
    <Countdown
    id={props.id}
      date={countDownTimeInminutes}
      renderer={renderer}
    />
  );
}