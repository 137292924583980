import http from "../../../Utils/http";

export function LoadData() {
    return http.get(`api/taxservice`);
  }

  export function pageLoadData(no) {
    const pageNo = 'page='+no
    return http.get(`api/taxservice?${pageNo}`);
  }

  export function LoadTaxData() {
      return http.get(`api/tax?paginate=0`);
    }
  
  export function AddData(data) {
    return http.post(`api/admin/taxservice`, data);
  }

  export function deleteData(id) {
    return http.post(`api/admin/taxservice/${id}`, {
      _method:'DELETE',
      });
  }

  export function editData(data, id) {
    return http.post(`api/admin/taxservice/${id}`, data);
  }