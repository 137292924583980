import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
// import { StoresTable } from "./SpecializationTable";
import StoresTable from "./Table";
import { loadTableData, DoctorList, downloadReport } from "../../modules/Api/Appointments/AppointmentsApi";
import Spinner from "../../Utils/Components/CustomSpinner";
import NoRecordFound from "../../Utils/Components/NoRecordFound";
import {ErrorToast} from "../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../modules/Toast/SuccessSnackBar";
import Search from "./Search";
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const List = (props) => {
  const user_type = props && props.me && props.me.user_type
  const [data, setData] = React.useState(undefined)
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)
  const [search, set_search] = React.useState("")
  const [doctorList, setDoctorList] = React.useState([])
  const [start_date, set_start_date] = React.useState("");
  const [end_date, set_end_date] = React.useState("");
  const [date_error, set_date_error] = React.useState(<p></p>);
  const [start_fee, set_start_fee] = React.useState("");
  const [end_fee, set_end_fee] = React.useState("");
  const [fee_error, set_fee_error] = React.useState(<p></p>);
  const [doctor_id, set_doctor_id] = React.useState("");
  const [status, set_status] = React.useState(" ");
  const [consultation_type, set_consultation_type] = React.useState(" ");
  const [followup, set_followup] = React.useState(" ");

  const loadData = (data) => {
    setLoading(true)
    setData(undefined)
    loadTableData(data)
    .then(items => {
      // console.log(items);
      setLoading(false)
      setData(items.data)
      if (items && items.data && items.data.message) {
        SuccessToast(items.data.message)
      }
      
    })
    .catch(err => {
      ErrorToast(err)
      setLoading(false)
  })
  }

  const onDownloadReport = () => {
    const data = {
      no : 1,
      start_date : start_date,
      end_date : end_date,
      doctor_id : doctor_id,
      status : status,
      consultation_type : consultation_type,
      start_fee : start_fee,
      end_fee : end_fee,
      followup : followup,
      search : search,
      report : 1
    }
    setLoading(true)
    downloadReport(data)
    .then(response => {
      setLoading(false)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.xlsx');
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => {
      ErrorToast(err)
      setLoading(false)
  })
  }

  const loadDoctorData = () => {
    DoctorList()
      .then(items => {
        setLoading(false)
        const list = []
        items.data.map((item) => {
          if (item.middle_name != null) {
          list.push({
            label : item.first_name + ' ' + item.middle_name + ' ' + item.last_name,
            value : item.id,
          })}
          if (item.middle_name == null) {
            list.push({
              label : item.first_name + ' ' + item.last_name,
              value : item.id,
            })}
        })
        setDoctorList(list)
      })
      .catch(err => {
        setLoading(false)
        ErrorToast(err)
    })
  }

  React.useEffect(() => {
    const data = {
      no : 1,
      start_date : start_date,
      end_date : end_date,
      doctor_id : doctor_id,
      status : status,
      consultation_type : consultation_type,
      start_fee : start_fee,
      end_fee : end_fee,
      followup : followup,
      search : search
    }
    if (mounted) {
      setMounted(false)
      loadData(data)
      loadDoctorData()
    }
  }, [loadData])

  const SearchData = (text) => {
    const data = {
      no : 1,
      start_date : start_date,
      end_date : end_date,
      doctor_id : doctor_id,
      status : status,
      consultation_type : consultation_type,
      start_fee : start_fee,
      end_fee : end_fee,
      followup : followup,
      search : text
    }
    loadData(data)
  }

  const loadPageData = (no) => {
    const data = {
      no : no,
      start_date : start_date,
      end_date : end_date,
      doctor_id : doctor_id,
      status : status,
      consultation_type : consultation_type,
      start_fee : start_fee,
      end_fee : end_fee,
      followup : followup,
      search : search
    }
    loadData(data)
  }
  

  // if (loading) {
  //   return (
  //     <Spinner />
  //   )
  // }

  return (
    <Card>
      <CardHeader title="Appointments list">
        <CardHeaderToolbar>
          <Search SearchData={SearchData} name={search} set_name={set_search} />
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        
      <div className="row form-row" style={{marginBottom:"10px"}}>
            <div className="col-lg-4 col-md-12">
          <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Appointment Type</InputLabel>
                <Select
                margin="dense"
                label="Appointment Type"
                type="text"
                value={consultation_type}
                variant="outlined"
                onChange={e => {
                  set_consultation_type(e.target.value)
                  const data = {
                    no : 1,
                    start_date : start_date,
                    end_date : end_date,
                    doctor_id : doctor_id,
                    status : status,
                    consultation_type : e.target.value,
                    start_fee : start_fee,
                    end_fee : end_fee,
                    followup : followup,
                    search : search
                  }
                  loadData(data)
                }}
                >
                  <MenuItem value=" ">All</MenuItem>
                  <MenuItem value="ONLINE">Online</MenuItem>
                  <MenuItem value="INCLINIC">In-clinic</MenuItem>
                  <MenuItem value="EMERGENCY">Emergency</MenuItem>
            </Select>
            </FormControl>
            </div>
            <div className="col-lg-4 col-md-12">
          <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Status</InputLabel>
                <Select
                margin="dense"
                label="Status"
                type="text"
                value={status}
                variant="outlined"
                onChange={e => {
                  set_status(e.target.value)
                  const data = {
                    no : 1,
                    start_date : start_date,
                    end_date : end_date,
                    doctor_id : doctor_id,
                    status : e.target.value,
                    consultation_type : consultation_type,
                    start_fee : start_fee,
                    end_fee : end_fee,
                    followup : followup,
                    search : search
                  }
                  loadData(data)
                }}
                >
                  <MenuItem value=" ">All</MenuItem>
                  <MenuItem value="PNS">PNS</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                  <MenuItem value="Not completed">Not Completed</MenuItem>
            </Select>
            </FormControl>
            </div>
            <div className="col-lg-4 col-md-12">
          <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Followup</InputLabel>
                <Select
                margin="dense"
                label="Followup"
                type="text"
                value={followup}
                variant="outlined"
                onChange={e => {
                  set_followup(e.target.value)
                  const data = {
                    no : 1,
                    start_date : start_date,
                    end_date : end_date,
                    doctor_id : doctor_id,
                    status : status,
                    consultation_type : consultation_type,
                    start_fee : start_fee,
                    end_fee : end_fee,
                    followup : e.target.value,
                    search : search
                  }
                  loadData(data)
                }}
                >
                  <MenuItem value=" ">All</MenuItem>
                  <MenuItem value="FREE">Free</MenuItem>
                  <MenuItem value="PAID">Paid</MenuItem>
            </Select>
            </FormControl>
            </div>
          </div>
          
      <div className="row form-row">
            <div className="col-lg-4 col-md-12">
              <Autocomplete
                margin="dense"
                  id="combo-box-demo"
                  options={doctorList}
                  getOptionLabel={(option) => option.label}
                  value={(doctorList.filter((item) => item.value === doctor_id))[0] || ''}
                  onChange={(event, newValue) => {
                    var val = ""
                    if (newValue?.label) {
                      val = newValue.value
                    }
                    set_doctor_id(val)
                    const data = {
                      no : 1,
                      start_date : start_date,
                      end_date : end_date,
                      doctor_id : val,
                      status : status,
                      consultation_type : consultation_type,
                      start_fee : start_fee,
                      end_fee : end_fee,
                      followup : followup,
                      search : search
                    }
                    loadData(data)
                  }}
                  renderInput={(params) => <TextField fullWidth margin="dense"
                   {...params} label="Search Doctor" variant="outlined" />}
                />
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="row form-row">
                <div className="col-lg-6 col-md-6">
                  <TextField
                    fullWidth
                    id="start_date"
                    label="Start Date"
                    type="date"
                    variant="outlined"
                    onChange={e => {
                      if (new Date("9999-12-31") >= new Date(e.target.value)) {
                        set_start_date(e.target.value)
                        set_date_error(<p></p>)
                        if (e.target.value && end_date) {
                          if (e.target.value <= end_date) {
                            const data = {
                              no : 1,
                              start_date : e.target.value,
                              end_date : end_date,
                              doctor_id : doctor_id,
                              status : status,
                              consultation_type : consultation_type,
                              start_fee : start_fee,
                              end_fee : end_fee,
                              followup : followup,
                              search : search
                            }
                            loadData(data)
                          } else {
                            set_date_error(<p style={{color:"red"}}>Start date should not be higher than End date!</p>)
                          }
                        }
                      }
                    }}
                    margin="dense"
                    name='start_date'
                    defaultValue={start_date}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
            <div className="col-lg-6 col-md-6">
              <TextField
                fullWidth
                id="date"
                label="End Date"
                type="date"
                variant="outlined"
                onChange={e => {
                  if (new Date("9999-12-31") >= new Date(e.target.value)) {
                    set_end_date(e.target.value)
                    set_date_error(<p></p>)
                    if (e.target.value && start_date) {
                      if (e.target.value >= start_date) {
                        const data = {
                          no : 1,
                          start_date : start_date,
                          end_date : e.target.value,
                          doctor_id : doctor_id,
                          status : status,
                          consultation_type : consultation_type,
                          start_fee : start_fee,
                          end_fee : end_fee,
                          followup : followup,
                          search : search
                        }
                        loadData(data)
                      } else {
                        set_date_error(<p style={{color:"red"}}>Start date should not be higher than End date!</p>)
                      }
                    }
                  }
                }}
                margin="dense"
                name='end_date'
                defaultValue={end_date}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            {date_error}
          </div>
          </div>
          
          <div className="col-lg-4 col-md-12">
              <div className="row form-row">
                <div className="col-lg-6 col-md-6">
          <TextField
            fullWidth
            label="Start Fee"
            type="number"
            variant="outlined"
            onChange={e => {
              if (parseFloat(e.target.value) >= 0 || e.target.value === '') {
                set_start_fee(e.target.value)
                set_fee_error(<p></p>)
                if (e.target.value && end_fee) {
                  if (parseFloat(e.target.value) <= parseFloat(end_fee)) {
                    const data = {
                      no : 1,
                      start_date : start_date,
                      end_date : end_date,
                      doctor_id : doctor_id,
                      status : status,
                      consultation_type : consultation_type,
                      start_fee : e.target.value,
                      end_fee : end_fee,
                      followup : followup,
                      search : search
                    }
                    loadData(data)
                  } else {
                    set_fee_error(<p style={{color:"red"}}>Start fee should not be higher than End fee!</p>)
                  }
                }
                if (e.target.value === '' && end_fee === '') {
                  const data = {
                    no : 1,
                    start_date : start_date,
                    end_date : end_date,
                    doctor_id : doctor_id,
                    status : status,
                    consultation_type : consultation_type,
                    start_fee : e.target.value,
                    end_fee : end_fee,
                    followup : followup,
                    search : search
                  }
                  loadData(data)
                }
              }
            }}
            margin="dense"
            value={start_fee}
            InputLabelProps={{
              shrink: true,
            }}
          />
          </div>
                <div className="col-lg-6 col-md-6">
          <TextField
            fullWidth
            label="End Fee"
            variant="outlined"
            type="number"
            onChange={e => {
              if (parseFloat(e.target.value) >= 0 || e.target.value === '') {
                set_end_fee(e.target.value)
                set_fee_error(<p></p>)
                if (e.target.value && start_fee) {
                  if (parseFloat(e.target.value) >= parseFloat(start_fee)) {
                    const data = {
                      no : 1,
                      start_date : start_date,
                      end_date : end_date,
                      doctor_id : doctor_id,
                      status : status,
                      consultation_type : consultation_type,
                      start_fee : start_fee,
                      end_fee : e.target.value,
                      followup : followup,
                      search : search
                    }
                    loadData(data)
                  } else {
                    set_fee_error(<p style={{color:"red"}}>Start fee should not be higher than End fee!</p>)
                  }
                }
                if (e.target.value === '' && start_fee === '') {
                  const data = {
                    no : 1,
                    start_date : start_date,
                    end_date : end_date,
                    doctor_id : doctor_id,
                    status : status,
                    consultation_type : consultation_type,
                    start_fee : start_fee,
                    end_fee : e.target.value,
                    followup : followup,
                    search : search
                  }
                  loadData(data)
                }
              }
            }}
            margin="dense"
            value={end_fee}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        {fee_error}
        </div>
            </div>
          </div>
          {
            data &&  <div style={{margin:"20px"}}>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <button
              disabled={loading}
                type="button"
                className="btn btn-primary"
                onClick={onDownloadReport}
            >
                Download Report
            </button>
            </div>
          </div>
          }
          {
            loading && <Spinner />
          }
        {
          !loading && data && <StoresTable loadPageData={loadPageData} data={data} user_type={user_type}/>
        }
        {
          !loading && !data && <div style={{marginTop:"30px"}}>
            <NoRecordFound/>
          </div>
        }

      </CardBody>
    </Card>
  );
};
const mapStateToProps = function (state) {
  return {
    me: state.me,
  }
}

export default connect(mapStateToProps)(List);
