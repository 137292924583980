import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AddressCard from "./Address/AddressCard";
import AddButton from "./Address/AddButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Details(props) {
  const classes = useStyles();
  let view_only = false
  if (props.type == 'view') {
    view_only = true
  } else {
    view_only = false
  }
  const addressData = props.AddressData

  // console.log(addressData);

  const onClickPrevious = () => {
    props.previousStep()
}

const onClickNext = (data) => {
    props.nextStep(data)
  }

  return (
        <div className={classes.root}>
        <form>
        <h5 style={{marginTop:"2%", marginBottom:"2%"}} >Clinic Address</h5>
        {/* <div>
        {
            addressData && addressData.map((item) => 
            <div>
                <AddressCard data={item}/>
            </div>
            )
        }
        </div> */}
        {/* <AddButton /> */}
        <AddressCard view_only={view_only} pharmacyList={props.pharmacyList} laboratoryList={props.laboratoryList} updateAddressData={props.updateAddressData} values={addressData} updateMdelData={props.updateMdelData} />

        <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item xs={3} container justify={"flex-start"} >
        {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
        <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
        </Grid>
        <Grid item xs={3}>
        </Grid>

        <Grid item xs={3}>
        </Grid>
        {!view_only && <Grid item xs={3} container justify={"flex-end"} >
        <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickNext} >Next</Button>
        </Grid>}
        {view_only && <Grid item xs={3} container justify={"flex-end"} >
        <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.nextStep} >Next</Button>
        </Grid>}
        </Grid> 
      </form>
            
    </div>
  );
}
