import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Alert from '@material-ui/lab/Alert';
import ListOfTestsTable from "./ListOfTests/Table";
import AddTests from "./ListOfTests/AddTests";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import { editListOfTests } from "../../../modules/Api/Laboratory/NewLaboratoryApi";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Details(props) {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [TestValues, setTestValues] = React.useState(props.values.lab_tests)
  let TestList = props.selectTestData
  var [messgeValue, setMessageValue] = React.useState(<p></p>)
  let select_data = TestList
  TestValues.map((item) => {
    select_data = select_data.filter(row => parseInt(row.id) != item.id)
})

  




const changeValue = (data) => {
  setTestValues(data)
}

const onChangeSampleCollect = (value, index) => {
  // const rows = TestValues.filter((item) => 
  //   item.id !== value.id
  // )
  // rows.push(value)
  var foundIndex = TestValues.findIndex(x => x.id == value.id);
  console.log("foundIndex", foundIndex);
  const rows = [...TestValues]
  rows[foundIndex] = value;
  console.log("rows", rows);
  changeValue(rows)
}

const onDelete = (id) => {
  const rows = TestValues.filter((item) => 
    item.id !== id
  )
  changeValue(rows)
}
const onAdd = (value) => {
  var dup = true
  setMessage('')
  
  TestValues.map((item) => {
  if (item.id == value.id) {
    dup = false
    setMessageValue(<p style={{color:'red', margin:'2px'}} >Test already added</p>)
    setTimeout(() => {
      setMessageValue('');
    }, 2000);
  }
  })
  if (dup){
    setMessageValue(<p style={{color:'blue', margin:'2px'}} >Test added</p>)
  const row = [...TestValues]
  row.push(value)
  changeValue(row)}
  setTimeout(() => {
    setMessageValue('');
  }, 2000);
}


      
    const onClickNext = () => {
      if (TestValues.length > 0){
        const data = props.values
        data["lab_tests"] = TestValues
        props.updateData(data)
        props.nextStep()
        // editDetails()
      } else {
        setMessage(<Alert style={{margin:'5px'}} severity="error">Add atleast one test</Alert>)
      }
    }

    const onClickPrevious = () => {
        props.previosStep()
    }
    
    


    
    

    

  return (
    <div className={classes.root}>
    <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Lists of Tests Available</h6>
      
      
      {errorMessage}
      <AddTests TestList={select_data} onAdd={onAdd} />
      {messgeValue}
      <ListOfTestsTable onDelete={onDelete} onChangeSampleCollect={onChangeSampleCollect} TestValues={TestValues} TestList={TestList} />
      {message}
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item xs={3} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
        </Grid>
        <Grid item xs={3}>
        </Grid>
        
        <Grid item xs={3}>
        </Grid>
        <Grid item xs={3} container justify={"flex-end"} >
            {/* <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Next</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickNext} >Next</Button>
            </Grid>
      </Grid> 
            
    </div>
  );
}
