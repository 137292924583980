import React, { Component } from 'react';
import Details from './PharmacyDetails';
import Address from './PharmacyAddress';
import AdditionalDetails from './PharmacyAdditionalDetails';
// import Details from "./error";


export default class UserForm extends Component {
    constructor(props) {
        super(props);
        
        // this.updateData = this.updateData.bind(this)
    
        this.state = {
            step: 1,
            formData:{}
        }
        };

        

    

    loadData = () => {
        this.setState({formData : this.props.data})
    }
    
    componentWillMount (){
        this.loadData()
        
      }

    // next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        })
    }

    updateData = (data) => {
        this.setState({formData:data})
    }

    // previous step
    previosStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        })
    }


    render() {

        const { step } = this.state

        switch (step) {
            case 1 :
                return (
                    <Details
                    values={this.state.formData}
                    type={this.props.type}
                    nextStep={this.nextStep}
                    />
                )
            case 2 :
                return (
                    <Address
                    values={this.state.formData}
                    type={this.props.type}
                    nextStep={this.nextStep}
                    previosStep={this.previosStep}
                    updateData={this.updateData}
                    />
                )
            case 3 :
                return (
                    <AdditionalDetails
                    values={this.state.formData}
                    type={this.props.type}
                    nextStep={this.nextStep}
                    updateData={this.updateData}
                    previosStep={this.previosStep}
                    />
                )
            default:
                return (
                    <Details
                    values={this.state.formData}
                    nextStep={this.nextStep}
                    previosStep={this.previosStep}
                    updateData={this.updateData}
                    />
                )
        }
    }
}