export const RelationShips = [
    {label:'FATHER',value:'FATHER'},
    {label:'MOTHER',value:'MOTHER'},
    {label:'DAUGHTER',value:'DAUGHTER'},
    {label:'SON',value:'SON'},
    {label:'HUSBAND',value:'HUSBAND'},
    {label:'WIFE',value:'WIFE'},
    {label:'GRANDFATHER',value:'GRANDFATHER'},
    {label:'GRANDMOTHER',value:'GRANDMOTHER'},
    {label:'OTHERS',value:'OTHERS'},
]