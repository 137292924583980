// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Input } from "../../../../../_metronic/_partials/controls";
import Button from 'react-bootstrap/Button';

const numberandfullRex = /^[0-9]\d*(\.\d+)?$/
// Validation schema
const CategoryEditSchema = Yup.object().shape({
    online: Yup.string()
        .required("Online Commission Field is required")
        .matches(numberandfullRex, 'Online Commission field accepts only integer or decimal')
        .nullable(),
    in_clinic: Yup.string()
        .required("In-clinic Commission Field is required")
        .matches(numberandfullRex, 'In-clinic Commission field accepts only integer or decimal')
        .nullable(),
    emergency: Yup.string()
        .required("Emergency Commission Field is required")
        .matches(numberandfullRex, 'Emergency Commission field accepts only integer or decimal')
        .nullable(),
});

export function StoreEditForm(props) 
{
    const [loading, setLoading] = React.useState(false)
    // console.log('props.row', props.row);
    const data = {
        commission_id:props.row.id,
        online:props.row.online,
        in_clinic:props.row.in_clinic,
        emergency:props.row.emergency
    }


    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={data}
                validationSchema={CategoryEditSchema}
                onSubmit={(values) => {
                        props.onEdit(values)
                        props.handleHide()
                    
                }}
            >
            {({ handleSubmit, resetForm, handleChange, handleBlur, touched, values, errors }) => (
                    <>
<Form className="form form-label-right">
                            <div className="form-group row">
                                <div className="col-lg-12"
                                    style={{marginTop:"3%", marginBottom:"3%"}}>
                                <TextField
                                    id="outlined-adornment-height"
                                    fullWidth
                                    required
                                    variant="outlined"
                                    label="Online Commission Percentage"
                                    name='online'
                                    value={values.online}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    />
                                    {errors.online && touched.online && (
                                    <p style={{color:"red"}}>{errors.online}</p>
                                    )}
                                </div>
                            </div>

                            
                            <div className="form-group row">
                                <div className="col-lg-12"
                                    style={{marginTop:"3%", marginBottom:"3%"}}>
                                <TextField
                                    id="outlined-adornment-height"
                                    fullWidth
                                    required
                                    variant="outlined"
                                    label="In-clinic Commission Percentage"
                                    name='in_clinic'
                                    value={values.in_clinic}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    />
                                    {errors.in_clinic && touched.in_clinic && (
                                    <p style={{color:"red"}}>{errors.in_clinic}</p>
                                    )}
                                </div>
                            </div>

                            
                            <div className="form-group row">
                                <div className="col-lg-12"
                                    style={{marginTop:"3%", marginBottom:"3%"}}>
                                <TextField
                                    id="outlined-adornment-height"
                                    fullWidth
                                    required
                                    variant="outlined"
                                    label="Emergency Commission Percentage"
                                    name='emergency'
                                    value={values.emergency}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    />
                                    {errors.emergency && touched.emergency && (
                                    <p style={{color:"red"}}>{errors.emergency}</p>
                                    )}
                                </div>
                            </div>
                            <Button style={{margin:'3px'}} variant="secondary" onClick={props.handleHide}>
                                Close
                            </Button>
                            <Button style={{margin:'3px'}} type='submit' variant="primary" >
                                Save Changes
                            </Button>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
