import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import { ListNew, ListApproved } from "./index"
import {ApprovedForm} from "./Details/Employee";
import { AddForm } from "./AddEmployee/Employee";

export const Employee = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Employee");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/employee"
        to="/employee/approved"
      />
      <ContentRoute from="/employee/approved" component={ListApproved} />
      <ContentRoute from="/employee/new" component={ListNew} />
      <ContentRoute from="/employee/add" component={AddForm} />
      <ContentRoute from="/employee/view" component={ApprovedForm} />
    </Switch>
  );
};
