import React from 'react';
// import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function CheckboxLabels(props) {

  const {data, reqTaxList, UpdateReqTaxList}=props
  const [state, setState] = React.useState({
    checkedA: data.checked,
  });

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
    if (event.target.checked == true) {
        reqTaxList.push(data.id)
    }
    if (event.target.checked == false) {
        const index = reqTaxList.indexOf(data.id);
        if (index > -1) {
            reqTaxList.splice(index, 1);
        }
    }
    UpdateReqTaxList(reqTaxList)
  };

  return (
    <FormControlLabel
        control={
          <Checkbox checked={state.checkedA} onChange={handleChange('checkedA')} value="checkedA" />
        }
        label={data.name}
      />
  );
}
