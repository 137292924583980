import React from 'react'
import Forms from './AdditionalContactsForm'
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import Spinner from "../../../Utils/Components/CustomSpinner";
import { GetAdditionalConatact } from "../../../modules/Api/Patient/ListPatientApi";

function Form  (props) {
  const [mount, setMount] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const [conatctData, setContactData] = React.useState(undefined)

  const intValues = {
    current_medication	: false,
    povert_line_checked : false,
    bpl_file_number	: '',
    bpl_file	: '',
    first_name_primary	: '',
    middle_name_primary	: '',
    last_name_primary	: '',
    mobile_number_primary	: '',
    country_code_primary	: '+91',
    relationship_primary	: '',
    secondary_check : false,
    first_name_secondary	: '',
    middle_name_secondary	: '',
    last_name_secondary	: '',
    mobile_number_secondary	: '',
    country_code_secondary	: '+91',
    relationship_secondary	: '',
  }

  const loadData = () => {
    const id = props.data.user.id
    setContactData(undefined)
    GetAdditionalConatact(id)
      .then(items => {
        setLoading(false)
        console.log('items.data', items.data);
        setContactData(items.data)
        // if (items && items.data && items.data.message) {
        //   SuccessToast(items.data.message)
        // }
        
      })
      .catch(err => {
        // ErrorToast(err)
        setContactData(intValues)
        setLoading(false)
    })
  }

  React.useEffect(() => {
    if(mount) {
      setMount(false)
      loadData()}
  }, [loadData])

  if (loading) {
    return (
      <Spinner />
    )
  }

  return (
    <div>
      {conatctData && <Forms 
      data={props.data}
      type={props.type}
      conatctData={conatctData}
      nextStep={props.nextStep}
      previosStep={props.previosStep}
      updateStep={props.updateStep}
      loadData={loadData} />}
    </div>
  )

}

export default Form;