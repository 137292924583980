import http from "../../../Utils/http";

export function loadTableData() {
    return http.get(`api/admin/medicine`);
  }

  export function searchLoadData(text, category) {
    const pharams = `name=${text}&category=${category}`
    return http.get(`api/search/medicine/?${pharams}`)
  }

  export function pageLoadData(no) {
    const pharams = 'page='+no
    return http.get(`api/admin/medicine/?${pharams}`);
  }

  export function AddData(data) {
    return http.post(`api/admin/medicine`, data);
  }

  export function deleteData(id) {
    return http.post(`api/admin/medicine/${id}`, {
      _method:'DELETE',
      });
  }

  export function editData(data, id) {
    return http.post(`api/admin/medicine/${id}`, data);
  }