import { ME_DATA } from "./meTypes";

export const meAction = data => {
    return {
        type: ME_DATA,
        payload: data
    }
}

export default {
    meAction
}