// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../_metronic/_partials/controls";
import Button from 'react-bootstrap/Button';
import {FileUpload} from "../../../Utils/Components/FileUpload/FileUpload";
import TextField from '@material-ui/core/TextField';
import { ImageLoad } from "../../../Utils/Components/ImageLoad";

const FILE_SIZE = 1024 * 1024 * 2;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
];

// Validation schema
const CategoryEditSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Specialization Name field is required"),
    image: Yup.mixed()
        .test(
        "fileSize",
        "Image file size should not be greater than 2mb",
        function (value) {
            if (!value) return true;
            return value.size <= FILE_SIZE
        })
        .test(
        "fileFormat",
        "Image format should be jpg, jpeg, png",
        function (value) {
            if (!value) return true;
            return SUPPORTED_FORMATS.includes(value.type)
        })
});

export function StoreEditForm(props) 
{
    const [loading, setLoading] = React.useState(false)
    const data = {'name':props.row.name, 'image':props?.row?.image ? props?.row?.image : ''}
    console.log('props.row', props.row);


    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={data}
                validationSchema={CategoryEditSchema}
                onSubmit={(values) => {
                    let formData = new FormData();
                    formData.set('name', values.name)
                    formData.set('image', values.image)
                        props.onEdit(formData, props.row.id)
                        props.handleHide()
                    
                }}
            >
                {({ handleSubmit, resetForm, setFieldValue, handleBlur, handleChange, touched, values, errors }) => (
                    <>
                        <Form className="form form-label-right">
                            <div className="form-group row">
                                <div className="col-lg-12">
                                <TextField
                                    fullWidth
                                    required
                                    label="Specialization name"
                                    name="name"
                                    type="text"
                                    value={values.name}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    />
                                    {errors.name && touched.name && (
                                    <p style={{color:"red"}}>{errors.name}</p>
                                    )}
                                </div>
                            </div>
                            <div className="form-group row">
                            {!values.image.name && <ImageLoad image={props.row.image} style={{width:'50px', height:'50px', margin:'3%'}} />}
                            </div>
                                <div className="form-group row">
                                <div className="col-lg-12">
                                    <FileUpload
                                        // mandatory
                                        label='Upload Specialization Logo'
                                        errors={errors.image}
                                        name="image"
                                        value={values.image}
                                        onChange={value => {
                                        setFieldValue("image", value)
                                        }}
                                    />
                                </div>
                                </div>
                            {/* <button
                                type="submit"
                                style={{ display: "none" }}
                                // ref={btnRef}
                                onSubmit={() => handleSubmit()}
                            ></button>
                            <button
                                type="button"
                                style={{ display: "none" }}
                                // ref={resetbtnRef}
                                onClick={() => resetForm()}
                            ></button> */}
                            <Button style={{margin:'3px'}} variant="secondary" onClick={props.handleHide}>
                                Close
                            </Button>
                            <Button style={{margin:'3px'}} type='submit' variant="primary" >
                                Save Changes
                            </Button>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
