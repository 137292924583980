import React from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { countryCodeData as options } from '../../../Utils/countyCodeMapping';
import { RelationShips } from '../../../Utils/RelationShips';
import { BoodGroup } from '../../../Utils/BoodGroup';
import { MaterialStatus } from '../../../Utils/MaterialStatus';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import makeAnimated from 'react-select/animated';
import {getCode} from '../../../Utils/googleApi';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useHistory } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import Spinner from "../../../Utils/Components/CustomSpinner";
import { EditAdditionalContact, DownloadBPLFile } from "../../../modules/Api/Patient/ListPatientApi";
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp';
import { IconButton } from '@material-ui/core';

const animatedComponents = makeAnimated();
    


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function Details (props) {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false)
  const theme = useTheme();
    const classes = useStyles();

    
    const {data, conatctData} = props
    var intValues = conatctData
    let view_only = false
    if (props.type == 'view') {
      view_only = true
    } else {
      view_only = false
    }

    const onClickBackList = () => {
      history.push('/patient')
    }

    intValues['bpl_file_temp'] = ''
    intValues['bpl_num_disable'] = true
    
    

    if (intValues.current_medication == '' || intValues.current_medication == null) {
      intValues['medication_check'] = false
      intValues['current_medication'] = ''
    } else {
      intValues['medication_check'] = true
    }

    if (intValues.bpl_file_number == null || intValues.bpl_file_number == '') {
      intValues['povert_line_checked'] = false
      intValues['bpl_file'] = ''
      intValues['bpl_file_number'] = ''
    } else {
      intValues['povert_line_checked'] = true
    }

    if (intValues.middle_name_primary == '' || intValues.middle_name_primary == null) {
      intValues['middle_name_primary'] = ''
    }

    if (intValues.first_name_secondary == '' || intValues.first_name_secondary == null) {
      intValues['first_name_secondary'] = ''
      intValues['middle_name_secondary'] = ''
      intValues['last_name_secondary'] = ''
      intValues['mobile_number_secondary'] = ''
      intValues['country_code_secondary'] = '+91'
      intValues['relationship_secondary'] = ''
      intValues['secondary_check'] = false
    } else {
      intValues['secondary_check'] = true
    }

    console.log('intValues', intValues);


    const DownLoadFile = () => {
      const id = props.data.user.id
      DownloadBPLFile(id)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', intValues.bpl_file);
        document.body.appendChild(link);
        link.click();
        link.remove();
})
        .catch(err => {
          ErrorToast(err)
      })
    }
    


    
    const onClickPrevious = () => {
      props.previosStep()
    }

    const EditDetails = (data) => {
      const id = props.data.user.id
      EditAdditionalContact(id, data)
        .then(items => {
          setLoading(false)
          history.push('/patient')
          if (items && items.data && items.data.message) {
            SuccessToast(items.data.message)
          }
          
        })
        .catch(err => {
          setLoading(false)
          ErrorToast(err)
          if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.mobile_number){
            props.updateStep(1)
          }
          if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.email){
            props.updateStep(1)
          }
      })
    }
    
        

          function getAge(dateString) {
            if(!dateString){
                return 0
            }
            const today = new Date();
            const birthDate = new Date(dateString);
            if (today < birthDate) {
                return 0
            }
            const m = today.getMonth() - birthDate.getMonth();
            let age = today.getFullYear() - birthDate.getFullYear();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }
        const numbRex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
        const FILE_SIZE = 1024 * 1024 * 2;
        const SUPPORTED_FORMATS = [
          "image/jpg",
          "image/jpeg",
          "image/png",
          "application/pdf"
        ];
    
        const formik = useFormik({
          initialValues: intValues,
          validationSchema: Yup.object({
            first_name_primary: Yup.string()
              .required("First name is Required!")
              .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field must be alphabets')
              .nullable(),
            last_name_primary: Yup.string()
             .required("Last Name is Required!")
             .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field must be alphabets')
             .nullable(),
            middle_name_primary: Yup.string()
             .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field must be alphabets')
             .nullable(),
            mobile_number_primary: Yup.string()
            .required("Mobile number is Required!")
            .max(10, 'Invalid phone number')
            .min(10, 'Invalid phone number')
            .nullable(),
            relationship_primary: Yup.string()
              .required("Relationship is Required!")
              .nullable(),

            medication_check : Yup.boolean(),
            current_medication: Yup.string().when('medication_check', {
              is: true,
              then: Yup.string()
              .required("Current medication is Required!")
              .nullable(),
            }),


            povert_line_checked : Yup.boolean(),
            bpl_file_number: Yup.string().when('povert_line_checked', {
              is: true,
              then: Yup.string()
              .required("BPL File number is Required!")
              .nullable(),
            }),
            bpl_file: Yup.string().when('povert_line_checked', {
              is: true,
              then: Yup.string()
              .required("BPL File is Required!")
              .nullable(),
            }),
            bpl_file_temp: Yup.mixed().when('povert_line_checked', {
              is: true,
              then: Yup.mixed()
              .nullable()
              .test(
                "fileSize",
                "File size too large",
                function (value) {
                  if (!value) return true;
                  return value.size <= FILE_SIZE
              }
              )
              .test(
                "fileFormat",
                "Invalid File Format",
                function (value) {
                  if (!value) return true;
                  return SUPPORTED_FORMATS.includes(value.type)
              }
              ),
            }),



            secondary_check : Yup.boolean(),
            first_name_secondary: Yup.string().when('secondary_check', {
              is: true,
              then: Yup.string()
              .required("First name is Required!")
              .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field must be alphabets')
              .nullable(),
            }),
            middle_name_secondary: Yup.string().when('secondary_check', {
              is: true,
              then: Yup.string()
              .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field must be alphabets')
              .nullable(),
            }),
            last_name_secondary: Yup.string().when('secondary_check', {
              is: true,
              then: Yup.string()
              .required("Last Name is Required!")
              .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field must be alphabets')
              .nullable(),
            }),
            mobile_number_secondary: Yup.string().when('secondary_check', {
              is: true,
              then: Yup.string()
              .required("Mobile number is Required!")
              .max(10, 'Invalid phone number')
              .min(10, 'Invalid phone number')
              .nullable()
            }),
            relationship_secondary: Yup.string().when('secondary_check', {
              is: true,
              then: Yup.string()
              .required("Relationship is Required!")
              .nullable()
            }),
          }),


          onSubmit: values => {
            console.log('sumit');
            setLoading(true)
        
            let formData = new FormData();
            formData.set('current_medication', values.current_medication)
            formData.set('bpl_file_number', values.bpl_file_number)
            // if (values.bpl_file_temp) {
              formData.set('bpl_file', values.bpl_file_temp)
            // }
            formData.set('first_name_primary', values.first_name_primary)
            formData.set('middle_name_primary', values.middle_name_primary)
            formData.set('last_name_primary', values.last_name_primary)
            formData.set('mobile_number_primary', values.mobile_number_primary)
            formData.set('country_code_primary', values.country_code_primary)
            formData.set('relationship_primary', values.relationship_primary)
            formData.set('first_name_secondary', values.first_name_secondary)
            formData.set('middle_name_secondary', values.middle_name_secondary)
            formData.set('last_name_secondary', values.last_name_secondary)
            formData.set('mobile_number_secondary', values.mobile_number_secondary)
            formData.set('country_code_secondary', values.country_code_secondary)
            formData.set('relationship_secondary', values.relationship_secondary)
            formData.set('national_health_id', null)
            EditDetails(formData)
          }
        });
            
        
        if (loading) {
          return (
            <Spinner />
          )
        }
          
        
    
      return (
        <div className={classes.root}>
          <form onSubmit={formik.handleSubmit}>
      <h5 style={{marginTop:"2%", marginBottom:"2%"}} >Additional Contacts</h5>
      <Grid container spacing={3}>
        <Grid item>
          
      <FormControlLabel
        control={
          <Checkbox
          disabled={view_only}
            checked={formik.values.medication_check}
            onChange={e => {
              if (!formik.values.medication_check == false) {
                formik.setFieldValue("current_medication", '')
              }
              formik.setFieldValue("medication_check", !formik.values.medication_check)
          }}
            name="medication_check"
            color="secondary"
          />
        }
        label="Taking any medication currently"
      />
        </Grid>
      </Grid>
      {formik.values.medication_check && 
      <Grid container spacing={3} style={{marginBottom:"2%"}}>
            <Grid item xs={12}>
            <TextField
                disabled={view_only}
                style={{
                  width:'30%'
                }}
                required
                label="Current Medication"
                name="current_medication"
                type="text"
                value={formik.values.current_medication}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.current_medication && formik.touched.current_medication && (
                <p style={{color:"red"}}>{formik.errors.current_medication}</p>
                 )}
            </Grid>
      </Grid>}
      <Grid container spacing={3} >
      <Grid item>
      <FormControlLabel
        control={
          <Checkbox
          disabled={view_only}
            checked={formik.values.povert_line_checked}
            
            onChange={e => {
              if (!formik.values.povert_line_checked == false) {
                formik.setFieldValue("bpl_file_number", '')
                formik.setFieldValue("bpl_file_temp", '')
                formik.setFieldValue("bpl_file", '')
              }
              formik.setFieldValue("povert_line_checked", !formik.values.povert_line_checked)
          }}
            name="povert_line_checked"
            color="secondary"
          />
        }
        label="Below poverty line"
        name='povert_line_checked'
      />
      </Grid>
      </Grid>
      
      {formik.values.povert_line_checked && <Grid container spacing={3} style={{marginBottom:"2%"}}>
            <Grid item xs={12}>
            <TextField
                disabled={view_only}
                style={{
                  width:'30%'
                }}
                required
                // disabled={formik.values.bpl_num_disable}
                label="BPL File Number"
                name="bpl_file_number"
                type="text"
                value={formik.values.bpl_file_number}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.bpl_file_number && formik.touched.bpl_file_number && (
                <p style={{color:"red"}}>{formik.errors.bpl_file_number}</p>
                 )}
            </Grid>
            <Grid item xs={1}>
            {!formik.values.bpl_file_temp && formik.values.bpl_file && <IconButton>
              <GetAppSharpIcon onClick={DownLoadFile} />
            </IconButton>}
            </Grid>
            <Grid item xs={11}style={{
          alignItems: "center"
        }}>
                            
                            {!view_only && <div className="form-group row">
                                <div className="col-lg-8">
                                <input
                                    style={{ display: "none" }}
                                    id="bpl_file_temp"
                                    name='bpl_file_temp'
                                    errors={formik.errors.bpl_file_temp}
                                    onChange={e => {
                                        console.log(e.target.files[0]);
                                        formik.setFieldValue("bpl_file_temp", e.target.files[0])
                                        formik.setFieldValue("bpl_num_disable", false)
                                        formik.setFieldValue("bpl_file", 'yes')
                                    }}
                                    margin="dense"
                                    variant="outlined"
                                    type='file'
                                    InputLabelProps={{
                                        shrink: true,
                                      }}
                                      label='Drug Licence'
                                />
                                <label htmlFor={'bpl_file_temp'} className="fileUpload" style={props.labelStyle || {} }>
                                <>
                                            <span style={{
                                                padding: '5px 10px',
                                                border: 'none',
                                                borderRadius: '4px',
                                                background: '#e7e7e7',
                                                // color: '#fff',
                                                border: '2px solid',
                                                fontSize: '14px',
                                                cursor: 'pointer',
                                                transition: '.3s background',
                                                '&:hover': {
                                                  background: '#40a9ff'
                                                }
                                              }}>BPL File
                                            <span>&nbsp;*</span>
                                            </span></>
                                    {
                                        formik.values.bpl_file_temp && formik.values.bpl_file_temp.name ? (<><span className="ml-2">{formik.values.bpl_file_temp.name}</span></>) : (
                                            <p></p>
                                        )
                                    }
                                                                {
                                        !formik.values.bpl_file_temp && formik.values.bpl_file ? (<><span className="ml-2">{formik.values.bpl_file}</span></>) : (
                                            <p></p>
                                        )
                                    }
                                </label>
                                </div>
                            </div>}

                            {formik.errors.bpl_file_temp  && (
                            <p style={{color:"red"}}>{formik.errors.bpl_file_temp}</p>)}
                            {formik.errors.bpl_file  && (
                            <p style={{color:"red"}}>{formik.errors.bpl_file}</p>)}
            </Grid>
      
      </Grid>
      }
      <h5 style={{marginTop:"2%", marginBottom:"2%"}} >Emergency Contact</h5>
      <label>Primary</label>
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                disabled={view_only}
                label="First Name"
                name="first_name_primary"
                type="text"
                value={formik.values.first_name_primary}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.first_name_primary && formik.touched.first_name_primary && (
                <p style={{color:"red"}}>{formik.errors.first_name_primary}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                disabled={view_only}
                label="Middle Name"
                name="middle_name_primary"
                type="text"
                value={formik.values.middle_name_primary}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.middle_name_primary && formik.touched.middle_name_primary && (
                <p style={{color:"red"}}>{formik.errors.middle_name_primary}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                disabled={view_only}
                label="Last Name"
                name="last_name_primary"
                type="text"
                value={formik.values.last_name_primary}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.last_name_primary && formik.touched.last_name_primary && (
                <p style={{color:"red"}}>{formik.errors.last_name_primary}</p>
                 )}
            </Grid>
          </Grid>
          
      
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={4}>
            <Select
                disabled={view_only}
                style={{marginRight:"5%", marginBottom:"2%", width:"30%"}}
                name="country_code_primary"
                type="text"
                value={formik.values.country_code_primary}
                variant="outlined"
                onChange={formik.handleChange}
                >
                  {
                    options.map(
                      (item) => (
                      <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                      )
                    )
                  }
                </Select>
                <FormControl style={{width:"65%"}}  variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Mobile Number<span>&nbsp;*</span></InputLabel>
                <OutlinedInput
                required
                disabled={view_only}
                    name="mobile_number_primary"
                    type="number"
                    value={parseInt(formik.values.mobile_number_primary)}
                    onChange={formik.handleChange}
                    startAdornment={<InputAdornment position="start">{formik.values.country_code_primary}</InputAdornment>}
                    labelWidth={100}
                    onBlur={formik.handleBlur}
                />
                {formik.errors.mobile_number_primary && formik.touched.mobile_number_primary && (
                <p style={{color:"red"}}>{formik.errors.mobile_number_primary}</p>
                 )}
                </FormControl>
            
            </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl variant="outlined" fullWidth>
            <InputLabel fullWidth >RelationShip<span>&nbsp;*</span></InputLabel>
            <Select
            required
            fullWidth
            disabled={view_only}
              value={formik.values.relationship_primary}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="RelationShip"
              name="relationship_primary"
            >
            {
                  RelationShips.map(
                    (item) => (
                    <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                    )
                  )
                }
            </Select>
                {formik.errors.relationship_primary && formik.touched.relationship_primary && (
                <p style={{color:"red"}}>{formik.errors.relationship_primary}</p>
                 )}
          </FormControl>
          </Grid>
          </Grid>
          <FormGroup row>
          <FormControlLabel
            control={<Checkbox
              disabled={view_only}
              checked={formik.values.secondary_check}
                onChange={e => {
                  if (!formik.values.secondary_check == false) {
                    formik.setFieldValue("first_name_secondary", '')
                    formik.setFieldValue("middle_name_secondary", '')
                    formik.setFieldValue("last_name_secondary", '')
                    formik.setFieldValue("mobile_number_secondary", '')
                    formik.setFieldValue("relationship_secondary", '')
                  }
                  formik.setFieldValue("secondary_check", !formik.values.secondary_check)
              }}
                name="secondary_check" />}
            label="Secondary"
          />
          </FormGroup>

          {/* 
          Secondary
           */}

          {formik.values.secondary_check && (
          <div>
            
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                disabled={view_only}
                label="First Name"
                name="first_name_secondary"
                type="text"
                value={formik.values.first_name_secondary}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.first_name_secondary && formik.touched.first_name_secondary && (
                <p style={{color:"red"}}>{formik.errors.first_name_secondary}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                disabled={view_only}
                label="Middle Name"
                name="middle_name_secondary"
                type="text"
                value={formik.values.middle_name_secondary}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.middle_name_secondary && formik.touched.middle_name_secondary && (
                <p style={{color:"red"}}>{formik.errors.middle_name_secondary}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                disabled={view_only}
                label="Last Name"
                name="last_name_secondary"
                type="text"
                value={formik.values.last_name_secondary}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.last_name_secondary && formik.touched.last_name_secondary && (
                <p style={{color:"red"}}>{formik.errors.last_name_secondary}</p>
                 )}
            </Grid>
          </Grid>
          
      
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={4}>
            <Select
                disabled={view_only}
                style={{marginRight:"5%", marginBottom:"2%", width:"30%"}}
                name="country_code_secondary"
                type="text"
                value={formik.values.country_code_secondary}
                variant="outlined"
                onChange={formik.handleChange}
                >
                  {
                    options.map(
                      (item) => (
                      <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                      )
                    )
                  }
                </Select>
                <FormControl style={{width:"65%"}}  variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Mobile Number<span>&nbsp;*</span></InputLabel>
                <OutlinedInput
                required
                disabled={view_only}
                    name="mobile_number_secondary"
                    type="number"
                    value={parseInt(formik.values.mobile_number_secondary)}
                    onChange={formik.handleChange}
                    startAdornment={<InputAdornment position="start">{formik.values.country_code_secondary}</InputAdornment>}
                    labelWidth={100}
                    onBlur={formik.handleBlur}
                />
                {formik.errors.mobile_number_secondary && formik.touched.mobile_number_secondary && (
                <p style={{color:"red"}}>{formik.errors.mobile_number_secondary}</p>
                 )}
                </FormControl>
            
            </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl variant="outlined" fullWidth>
            <InputLabel fullWidth >RelationShip<span>&nbsp;*</span></InputLabel>
            <Select
            required
            fullWidth
            disabled={view_only}
              value={formik.values.relationship_secondary}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="RelationShip"
              name="relationship_secondary"
            >
            {
                  RelationShips.map(
                    (item) => (
                    <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                    )
                  )
                }
            </Select>
                {formik.errors.relationship_secondary && formik.touched.relationship_secondary && (
                <p style={{color:"red"}}>{formik.errors.relationship_secondary}</p>
                 )}
          </FormControl>
          </Grid>
          </Grid>
          </div>
          )}
          
          {!view_only && <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} > 
            <Grid item xs={6} container justify={"flex-start"} >
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
            </Grid>
            {/* <Grid item xs={3}>
            </Grid>
            
            <Grid item xs={3}>
            </Grid> */}
            <Grid item xs={6} container justify={"flex-end"} >
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" type="submit" >Save</Button>
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickNext} >Next</Button> */}
                
            </Grid>
          </Grid>}

          {view_only && <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} > 
            <Grid item xs={6} container justify={"flex-start"} >
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
            </Grid>
            {/* <Grid item xs={3}>
            </Grid>
            
            <Grid item xs={3}>
            </Grid> */}
            <Grid item xs={6} container justify={"flex-end"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" type="submit" >Save</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickBackList} >Back</Button>
                
            </Grid>
          </Grid>} 
          </form>
                
        </div>
      );



}