import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import { ListNew } from "./index"
import ListApproved from "./ListApprove"
import {Form} from "./Details/Doctor";
import {AddForm} from "./Add/Doctor";
export const Doctor = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Doctor");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/doctor"
        to="/doctor/approved"
      />
      <ContentRoute from="/doctor/new" component={ListNew} />
      <ContentRoute from="/doctor/approved" component={ListApproved} />
      <ContentRoute from="/doctor/add" component={AddForm} />
      <ContentRoute from="/doctor/view" component={Form} />
    </Switch>
  );
};
