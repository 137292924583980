import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MessageIcon from '@material-ui/icons/Message';
import { View } from "./View";

class Example extends React.Component {
    constructor(props, context) {
      super(props, context);
  
      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
  
      this.state = {
        show: false,
      };
    }
  
    handleClose() {
      this.setState({ show: false });
    }
  
    handleShow() {
      this.setState({ show: true });
    }
  
    render() {
      return (
        <>
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm"
            onClick={this.handleShow}
        >
            <span className="svg-icon svg-icon-md svg-icon-primary">
                <MessageIcon />
            </span>
        </a>
  
          <Modal 
          show={this.state.show} 
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="example-modal-sizes-title-lg"
          >
            
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">Comments</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <View id={this.props.row.user.id} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
  
  export default Example;
  
  