import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { countryCodeData as options } from '../../../Utils/countyCodeMapping';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from "react-router-dom";
import Spinner from "../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import { AddPharmacy } from "../../../modules/Api/Pharmacy/AddPharmay";
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import {FileUpload} from "../../../Utils/Components/FileUpload/FileUpload";
import { handleNumber, findCodeFromValue } from '../../../Utils/CountryMobile';
import { DatePickerField } from "./../../../../_metronic/_partials/controls/forms/DatePickerField";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  buttonProgress: {
    color: "green",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

export default function Details(props) {
  const history = useHistory();
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false)
    const [altCurrentCountry, setAltCurrentCountry] = React.useState()

    const FILE_SIZE = 1024 * 1024 * 2;
    const SUPPORTED_FORMATS = [
      "image/jpg",
      "image/jpeg",
      "image/png",
       "application/pdf"
    ];

    

    
    
    var initValues = props.values
    console.log(initValues);

    const onOpenPdf = () => {
      window.open(initValues.reg_certificate)
    }

    const onupdateData = (data) => {
      props.updateData(data)
  }

    const onClickPrevious = () => {
        props.previosStep()
    }

    

    

    

   







      const addDetails = (data) => {
        AddPharmacy(data)
          .then(items => {
            setLoading(false)
            history.push('/pharmacy/approved')
            if (items && items.data && items.data.message) {
              SuccessToast(items.data.message)
            }
            
          })
          .catch(err => {
            setLoading(false)
            ErrorToast(err)
            if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.mobile_number){
              props.updateStep(1)
            }
            if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.email){
              props.updateStep(1)
            }
        })
      }

      const ifsc_code = new RegExp(/^[A-Za-z]{4}[a-zA-Z0-9]{7}$/)
      const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


    const formik = useFormik({
      initialValues: initValues,
      validationSchema: Yup.object({
        pharmacist_name: Yup.string()
        .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field must be alphabets')
          .required("Pharmacist name is Required!"),
        course: Yup.string()
         .required("Course is Required!"),
        pharmacist_reg_number: Yup.string()
          .required("Register number Required!"),
        reg_date: Yup.date('Must be a valid date')
          .nullable()
          .max(new Date(), 'Date of issue cannot be future dates' )
          .required('The Date of issue field is required.'),
        reg_valid_upto: Yup.date('Must be a valid date')
          .nullable()
          .min(new Date(), "Date must be in the future")
          .required('The Date of validity field is required.'),
        issuing_authority: Yup.string()
          .required("Issuing authority is Required!"),
          alt_mobile_number: Yup.string()
          .nullable()
          .test('mobile-num', 'Invalid mobile number', function (value) {
              if (!value) return true;
              const currentCountrycode = altCurrentCountry && altCurrentCountry.code ? altCurrentCountry.code : null
              return handleNumber(value + "", currentCountrycode || "IN")
          })
          ,
        // bank_account_number: Yup.string()
        // .matches(/^[0-9]*$/, 'Account number must be number')
        //   .required("Account number is Required!"),
        // bank_account_holder: Yup.string()
        // .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field must be alphabets')
        //   .required("Account holder name is Required!"),
        // bank_name: Yup.string()
        //   .required("Bank name is Required!"),
        // bank_city: Yup.string()
        //  .required("Bank city is Required!"),
        // bank_ifsc: Yup.string()
        // .matches(ifsc_code, 'Must be a valid IFSC code')
        // .required('The IFSC field is required.'),
        reg_certificate:   Yup.mixed()
        .required("A registration certificate is required")
        .test(
          "fileSize",
          "File size too large",
          value => value && value.size <= FILE_SIZE
        )
        .test(
          "fileFormat",
          "Invalid File Format",
          value => value && SUPPORTED_FORMATS.includes(value.type)
        ),
        
        course_name: Yup.string().when('course', {
          is: 'Others',
          then: Yup.string().required('Course name is required')
        }),
      }),


      onSubmit: values => {
        setLoading(true)
        console.log('submit', values);
        props.updateData(values)
        
        let formData = new FormData();
        formData.set('pharmacy_name', values.pharmacy_name)
        formData.set('country_code', values.country_code)
        formData.set('mobile_number', values.mobile_number)
        formData.set('email', values.email)
        formData.set('gstin', values.gstin)
        formData.set('dl_number', values.dl_number)
        formData.set('dl_issuing_authority', values.dl_issuing_authority)
        formData.set('dl_date_of_issue', values.dl_date_of_issue)
        formData.set('dl_valid_upto', values.dl_valid_upto)
        formData.set('dl_file', values.dl_file)
        formData.set('pincode', values.pincode)
        formData.set('country', values.country)
        formData.set('state', values.state)
        formData.set('district', values.district)
        formData.set('city_village', values.city_village)
        formData.set('street_name', values.street_name)
        formData.set('home_delivery', values.home_delivery)
        formData.set('order_amount', values.order_amount)
        formData.set('currency_code', values.currency_code)
        formData.set('latitude', values.latitude)
        formData.set('longitude', values.longitude)
        formData.set('pharmacist_name', values.pharmacist_name)
        if (values.course !== 'Others'){
          formData.append('course', values.course)
        } else {
            formData.append('course', values.course_name)
        }
        formData.set('pharmacist_reg_number', values.pharmacist_reg_number)
        formData.set('issuing_authority', values.issuing_authority)
        formData.set('reg_certificate', values.reg_certificate)
        formData.set('alt_country_code', values.alt_country_code)
        formData.set('alt_mobile_number', values.alt_mobile_number)
        formData.set('reg_date', values.reg_date)
        formData.set('reg_valid_upto', values.reg_valid_upto)
        formData.set('bank_account_number', values.bank_account_number)
        formData.set('bank_account_holder', values.bank_account_holder)
        formData.set('bank_name', values.bank_name)
        formData.set('bank_city', values.bank_city)
        formData.set('bank_ifsc', values.bank_ifsc)
        formData.set('bank_account_type', values.bank_account_type)
        addDetails(formData)
      }
    });
    

            

    if (loading) {
      return (
        <Spinner />
      )
    }

            

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Additional Details</h6>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item md={4} xs={12}>
        <TextField
          fullWidth
          required
            label="Name of Pharmacist"
            name="pharmacist_name"
            type="text"
            value={formik.values.pharmacist_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.pharmacist_name && formik.touched.pharmacist_name && (
            <p style={{color:"red"}}>{formik.errors.pharmacist_name}</p>
             )}
        </Grid>
        <Grid item md={4} xs={12}>
        <FormControl fullWidth variant="outlined" className={classes.formControl}>
          <InputLabel fullWidth htmlFor="outlined-age-native-simple">Course</InputLabel>
          <Select
          fullWidth
            value={formik.values.course}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="course"
            name="course"
          >
            <option value={'D.Pharm'}>D.Pharm</option>
            <option value={'B.Pharm'}>B.Pharm</option>
            <option value={'Pharm.D'}>Pharm.D</option>
            <option value={'Others'}>Others</option>
          </Select>
        </FormControl>
        
        {formik.values.course === 'Others' &&(
                <TextField
                style={{marginTop:'10px'}}
                fullWidth
                required
                  label="Course Name"
                  name="course_name"
                  type="text"
                  value={formik.values.course_name}
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  />
                )}
                
                {formik.errors.course_name && formik.touched.course_name && (
                  <p style={{color:"red"}}>{formik.errors.course_name}</p>
                   )}
            {formik.errors.course && formik.touched.course && (
            <p style={{color:"red"}}>{formik.errors.course}</p>
             )}

          

          
        </Grid>
        <Grid item md={4} xs={12}>
        <TextField
          fullWidth
          required
            label="Registration Number of pharmacist"
            name="pharmacist_reg_number"
            type="text"
            value={formik.values.pharmacist_reg_number}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.pharmacist_reg_number && formik.touched.pharmacist_reg_number && (
            <p style={{color:"red"}}>{formik.errors.pharmacist_reg_number}</p>
             )}
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item md={4} xs={12}>
        <TextField
          fullWidth
          required
            label="Issuing Authority"
            name="issuing_authority"
            type="text"
            value={formik.values.issuing_authority}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.issuing_authority && formik.touched.issuing_authority && (
            <p style={{color:"red"}}>{formik.errors.issuing_authority}</p>
             )}
        </Grid>
        
        <Grid item md={4} xs={12}>
        <Select
        required
            style={{marginRight:"2%", marginBottom:"2%", width:"30%"}}
            name="alt_country_code"
            type="text"
            value={formik.values.alt_country_code}
            variant="outlined"
            onChange={e => {
              formik.setFieldValue("alt_country_code", e.target.value)
              setAltCurrentCountry(findCodeFromValue(e.target.value))
            }}
            onBlur={formik.handleBlur}
            >
              {
                options.map(
                  (item) => (
                  <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                  )
                )
              }
            </Select>
            <FormControl style={{width:"65%"}} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Alternative Number</InputLabel>
            <OutlinedInput
                name="alt_mobile_number"
                type="number"
                value={formik.values.alt_mobile_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                startAdornment={<InputAdornment position="start">{formik.values.alt_country_code}</InputAdornment>}
                labelWidth={130}
            />
            </FormControl>
            {formik.errors.alt_mobile_number && formik.touched.alt_mobile_number && (
            <p style={{color:"red"}}>{formik.errors.alt_mobile_number}</p>
             )}
        </Grid>
        <Grid item md={4} xs={12}>
        {/* <TextField
          fullWidth
          required
            label="Registration date"
            name="reg_date"
            type="date"
            value={formik.values.reg_date}
            variant="outlined"
            onChange={e => {
              if (new Date("9999-12-31") >= new Date(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            onBlur={formik.handleBlur}
            InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.errors.reg_date && formik.touched.reg_date && (
            <p style={{color:"red"}}>{formik.errors.reg_date}</p>
             )} */}

<DatePickerField 
                                mandatory
                                name="reg_date"
                                label="Registration date"
                                onChange={val => {
                                  formik.setFieldValue('reg_date', String(val))
                                }}
                                onBlur={e => {
                                  formik.handleBlur(e);
                                }}
                                value={formik.values.reg_date}
                                touched={formik.touched.reg_date}
                                errors={formik.errors.reg_date}
                                 />
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item md={4} xs={12}>
        {/* <TextField
          fullWidth
          required
            label="Registration valid up to"
            name="reg_valid_upto"
            type="date"
            value={formik.values.reg_valid_upto}
            variant="outlined"
            onChange={e => {
              if (new Date("9999-12-31") >= new Date(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            onBlur={formik.handleBlur}
            InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.errors.reg_valid_upto && formik.touched.reg_valid_upto && (
            <p style={{color:"red"}}>{formik.errors.reg_valid_upto}</p>
             )} */}

<DatePickerField 
                                mandatory
                                name="reg_valid_upto"
                                label="Registration valid up to"
                                onChange={val => {
                                  formik.setFieldValue('reg_valid_upto', String(val))
                                }}
                                onBlur={e => {
                                  formik.handleBlur(e);
                                }}
                                value={formik.values.reg_valid_upto}
                                touched={formik.touched.reg_valid_upto}
                                errors={formik.errors.reg_valid_upto}
                                 />
        </Grid>
        
        
        <Grid item md={4} xs={12} container justify='center'>
        <FileUpload
            mandatory
            label='Registration certificate'
            errors={formik.errors.reg_certificate}
            name="reg_certificate"
            value={formik.values.reg_certificate}
            onChange={value => {
              formik.setFieldValue("reg_certificate", value)
            }}
        />
        </Grid>
        <Grid item md={4} xs={12}>
        </Grid>
      </Grid>

      
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Banking Details</h6>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item md={4} xs={12}>
        <TextField
          fullWidth
          
            label="Bank Account Number"
            name="bank_account_number"
            type="text"
            value={formik.values.bank_account_number}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_account_number && formik.touched.bank_account_number && (
            <p style={{color:"red"}}>{formik.errors.bank_account_number}</p>
             )}
        </Grid>
        <Grid item md={4} xs={12}>
        <TextField
          fullWidth
          
            label="Account Holder Full Name"
            name="bank_account_holder"
            type="text"
            value={formik.values.bank_account_holder}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_account_holder && formik.touched.bank_account_holder && (
            <p style={{color:"red"}}>{formik.errors.bank_account_holder}</p>
             )}
        </Grid>
        <Grid item md={4} xs={12}>
        <TextField
          fullWidth
          
            label="Bank Name"
            name="bank_name"
            type="text"
            value={formik.values.bank_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_name && formik.touched.bank_name && (
            <p style={{color:"red"}}>{formik.errors.bank_name}</p>
             )}
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
      <Grid item md={4} xs={12}>
        <TextField
          fullWidth
          
            label="Bank City"
            name="bank_city"
            type="text"
            value={formik.values.bank_city}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_city && formik.touched.bank_city && (
            <p style={{color:"red"}}>{formik.errors.bank_city}</p>
             )}
        </Grid>
        <Grid item md={4} xs={12}>
        <TextField
          fullWidth
          
            label="Bank IFSC Code"
            name="bank_ifsc"
            type="text"
            value={formik.values.bank_ifsc}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            
            {formik.errors.bank_ifsc && formik.touched.bank_ifsc && (
            <p style={{color:"red"}}>{formik.errors.bank_ifsc}</p>
             )}
        </Grid>
        <Grid item md={4} xs={12}>
        <FormControl fullWidth variant="outlined" className={classes.formControl}>
        <InputLabel fullWidth htmlFor="outlined-age-native-simple">Account Type</InputLabel>
        <Select
        fullWidth
          value={formik.values.bank_account_type}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label="Account Type"
          name="bank_account_type"
        >
          <option value={'CURRENT'}>CURRENT</option>
          <option value={'SAVINGS'}>SAVINGS</option>
          <option value={'SALARY'}>SALARY</option>
          <option value={'FIXED DEPOSIT'}>FIXED DEPOSIT</option>
          <option value={'RECURRING DEPOSIT'}>RECURRING DEPOSIT</option>
          <option value={'NRI'}>NRI</option>
          <option value={'NRO'}>NRO</option>
          <option value={'NRE'}>NRE</option>
          <option value={'FCNR'}>FCNR</option>
        </Select>
      </FormControl>
            {formik.errors.bank_account_type && formik.touched.bank_account_type && (
            <p style={{color:"red"}}>{formik.errors.bank_account_type}</p>
             )}
        </Grid>
      </Grid>
      
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item md={4} xs={12} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
        </Grid>
        <Grid item md={4} xs={12}>
        </Grid>
        <Grid item md={4} xs={12}>
        </Grid>
        
        <Grid item md={1} xs={12} container justify={"flex-end"}>
            {/* <Button onClick={handleShowReject} style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="secondary" >Reject</Button> */}
        </Grid>
        <Grid item md={1} xs={12} container justify={"flex-end"}>
            <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Add</Button>
            </Grid>
      </Grid> 
      </form>
            
    </div>
  );
}
