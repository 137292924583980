import React from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { countryCodeData as options } from '../../../Utils/countyCodeMapping';
import { genderData } from '../../../Utils/Gender';
import { BoodGroup } from '../../../Utils/BoodGroup';
import { MaterialStatus } from '../../../Utils/MaterialStatus';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import makeAnimated from 'react-select/animated';
import FileUpload from "../../../Utils/Components/FileUpload";
import img from '../profile_logo.png'
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import Spinner from "../../../Utils/Components/CustomSpinner";
import { EditBasicInfo } from "../../../modules/Api/Patient/ListPatientApi";
import { handleNumber, findCodeFromValue } from '../../../Utils/CountryMobile';
import { DatePickerField } from "./../../../../_metronic/_partials/controls/forms/DatePickerField";

const animatedComponents = makeAnimated();
    
const FILE_SIZE = 1024 * 1024 * 2;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function Details (props) {
  const [loading, setLoading] = React.useState(false)
  const [currentCountry, setCurrentCountry] = React.useState()
  const [altCurrentCountry, setAltCurrentCountry] = React.useState()
  const theme = useTheme();
    const classes = useStyles();
    const data = props.data
    let view_only = false
    if (props.type == 'view') {
      view_only = true
    } else {
      view_only = false
    }
    const intValues = {
                title : data.title,
                first_name	: data.user.first_name,
                middle_name	: data.user.middle_name,
                last_name : data.user.last_name,
                gender : data.gender,
                date_of_birth : data.date_of_birth,
                age	: data.age,
                blood_group	: data.blood_group,
                height	: data.height,
                weight	: data.weight,
                marital_status	: data.marital_status,
                occupation	: data.occupation,
                email	: data.user.email,
                mobile_number	: data.user.mobile_number,
                country_code	: data.user.country_code,
                profile_photo_url	: data.user.profile_photo_url,
                alt_mobile_number	: data.alt_mobile_number,
                alt_country_code	: data.alt_country_code,
                national_health_id : data.national_health_id,
    }

    if (intValues.marital_status == null) {
      intValues['marital_status'] = ''
    }

    if (intValues.alt_country_code == null || intValues.alt_country_code == 'null' || intValues.alt_country_code == '') {
      intValues['alt_country_code'] = '+91'
    }

    if (intValues.alt_mobile_number == null || intValues.alt_mobile_number == 'null' || intValues.alt_mobile_number == '') {
      intValues['alt_mobile_number'] = ''
    }

    if (intValues.middle_name == null || intValues.middle_name == 'null' || intValues.middle_name == '') {
      intValues['middle_name'] = ''
    }

    if (intValues.occupation == null || intValues.occupation == 'null' || intValues.occupation == '') {
      intValues['occupation'] = ''
    }

    if (intValues.weight == null || intValues.weight == 'null' || intValues.weight == '') {
      intValues['weight'] = ''
    }

    if (intValues.height == null || intValues.height == 'null' || intValues.height == '') {
      intValues['height'] = ''
    }


    const editDetails = (data, localData) => {
      const id = props.data.user.id
      // console.log(id);
      EditBasicInfo(id, data)
        .then(items => {
          onClickNext(localData)
          setLoading(false)
          // if (items && items.data && items.data.message) {
          //   SuccessToast(items.data.message)
          // }
          
        })
        .catch(err => {
          ErrorToast(err)
          setLoading(false)
      })
    }

    

    

    const onClickNext = (data) => {
      props.updateData(data)
      props.nextStep()
    }
    
        

          function getAge(dateString) {
            if(!dateString){
                return 0
            }
            const today = new Date();
            const birthDate = new Date(dateString);
            if (today < birthDate) {
                return 0
            }
            const m = today.getMonth() - birthDate.getMonth();
            let age = today.getFullYear() - birthDate.getFullYear();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }

        const numberandfullRex = /^[1-9]\d*(\.\d+)?$/
        const alpha_numeric = /^[a-z0-9]+$/i
    
        const formik = useFormik({
          initialValues: intValues,
          validationSchema: Yup.object({
            profile_photo: Yup.mixed()
                .test(
                "fileSize",
                "Image file size should not be greater than 2mb",
                function (value) {
                    if (!value) return true;
                    return value.size <= FILE_SIZE
                })
                .test(
                "fileFormat",
                "Image format should be jpg, jpeg, png",
                function (value) {
                    if (!value) return true;
                    return SUPPORTED_FORMATS.includes(value.type)
                }),
            title: Yup.string()
              .required("Title is Required!")
              .nullable(),
            first_name: Yup.string()
              .required("First name is Required!")
              .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field must be alphabets')
              .nullable(),
            last_name: Yup.string()
             .required("Last Name is Required!")
             .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field must be alphabets')
             .nullable(),
             middle_name: Yup.string()
             .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field must be alphabets')
             .nullable(),
             national_health_id: Yup.string()
              //  .required("National Health ID is Required!")
               .matches(alpha_numeric, "Invalid Data!")
               .max(20, "Must be 20 characters or less.")
               .nullable(),
            gender: Yup.string()
              .required("Gender is Required!")
              .nullable(),
            blood_group: Yup.string()
              .required("Blood Group is Required!")
              .nullable(),
            height: Yup.string()
            .matches(numberandfullRex, 'Height field must be integer or decimal')
            .nullable(),
            weight: Yup.string()
            .matches(numberandfullRex, 'Weight field must be integer or decimal')
            .nullable(),
            date_of_birth: Yup.date('Must be a valid date')
            .nullable()
            .max(new Date(), 'Date of birth cannot be a future date' )
            .required('The Date of birth field is required.'),
            email: Yup.string()
              .email("Invalid email format")
              .required("Email is Required!")
              .nullable(),
              alt_mobile_number: Yup.string()
              .nullable()
              .test('mobile-num', 'Invalid mobile number', function (value) {
                  if (!value) return true;
                  const currentCountrycode = altCurrentCountry && altCurrentCountry.code ? altCurrentCountry.code : null
                  return handleNumber(value + "", currentCountrycode || "IN")
              })
              ,
              mobile_number: Yup.string()
              .required('Mobile number is required')
              .nullable()
              .test('mobile-num', 'Invalid mobile number', function (value) {
                  if (!value) return false;
                  const currentCountrycode = currentCountry && currentCountry.code ? currentCountry.code : null
                  return handleNumber(value + "", currentCountrycode || "IN")
              })
              ,
          }),
          onSubmit: values => {
            console.log(values);
            setLoading(true)

            const localData = data
            localData['title'] = values.title
            localData['user']['first_name'] = values.first_name
            localData['user']['middle_name'] = values.middle_name
            localData['user']['last_name'] = values.last_name
            localData['gender'] = values.gender
            localData['date_of_birth'] = values.date_of_birth
            localData['age'] = values.age
            localData['blood_group'] = values.blood_group
            localData['height'] = values.height
            localData['weight'] = values.weight
            localData['marital_status'] = values.marital_status
            localData['occupation'] = values.occupation
            localData['user']['email'] = values.email
            localData['user']['mobile_number'] = values.mobile_number
            localData['user']['country_code'] = values.country_code
            localData['alt_mobile_number'] = values.alt_mobile_number
            localData['alt_country_code'] = values.alt_country_code
            localData['national_health_id'] = values.national_health_id
        
            let formData = new FormData();
            formData.set('title', values.title)
            formData.set('first_name', values.first_name)
            formData.set('middle_name', values.middle_name)
            formData.set('last_name', values.last_name)
            formData.set('gender', values.gender)
            formData.set('date_of_birth', values.date_of_birth)
            formData.set('age', values.age)
            formData.set('blood_group', values.blood_group)
            formData.set('height', values.height)
            formData.set('weight', values.weight)
            formData.set('marital_status', values.marital_status)
            formData.set('occupation', values.occupation)
            formData.set('email', values.email)
            formData.set('mobile_number', values.mobile_number)
            formData.set('country_code', values.country_code)
            formData.set('national_health_id', values.national_health_id)
            if (values.profile_photo) {
              formData.set('profile_photo', values.profile_photo)
            }
            formData.set('alt_mobile_number', values.alt_mobile_number)
            formData.set('alt_country_code', values.alt_country_code)

            editDetails(formData, localData)
          }  
        });
            
        
        if (loading) {
          return (
            <Spinner />
          )
        }
          
        
    
      return (
        <div className={classes.root}>
          <form onSubmit={formik.handleSubmit}>
      <h5 style={{marginTop:"2%", marginBottom:"2%"}} >Personal Information</h5>
      <div className="ml-auto" style={{ border: "1px solid #ccc", overflow: "hidden", width: "120px", fontSize: "13px", marginRight: "20px" }}>

            <img src={formik.values.profile_photo_url || img}
                width="120"
                style={{ merginTop: "10px", border: "1px solid #ccc" }}
                height="120" />

            {/* <FileUpload
                labelStyle={{ border: "none" }}
                errors={formik.errors.profile_photo}
                name="profile_photo"
                required
                onChange={value => {
                    formik.setFieldValue("profile_photo", value)
                }}
                onBlur={formik.setTouched}
            /> */}
        </div>
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl variant="outlined" fullWidth>
            <InputLabel fullWidth >Title<span>&nbsp;*</span></InputLabel>
            <Select
            required
            fullWidth
            disabled={view_only}
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Title"
              name="title"
            >
            <MenuItem value={'Mr.'}>Mr.</MenuItem>
            <MenuItem value={'Ms.'}>Ms.</MenuItem>
            <MenuItem value={'Mrs.'}>Mrs.</MenuItem>
            <MenuItem value={'Master.'}>Master.</MenuItem>
            </Select>
                {formik.errors.title && formik.touched.title && (
                <p style={{color:"red"}}>{formik.errors.title}</p>
                 )}
          </FormControl>
          </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                disabled={view_only}
                label="First Name"
                name="first_name"
                type="text"
                value={formik.values.first_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.first_name && formik.touched.first_name && (
                <p style={{color:"red"}}>{formik.errors.first_name}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                disabled={view_only}
                label="Middle Name"
                name="middle_name"
                type="text"
                value={formik.values.middle_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.middle_name && formik.touched.middle_name && (
                <p style={{color:"red"}}>{formik.errors.middle_name}</p>
                 )}
            </Grid>
          </Grid>
          
      
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                disabled={view_only}
                label="Last Name"
                name="last_name"
                type="text"
                value={formik.values.last_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.last_name && formik.touched.last_name && (
                <p style={{color:"red"}}>{formik.errors.last_name}</p>
                 )}
            </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {/* <TextField
              fullWidth
              required
              disabled={view_only}
                label="Date Of Birth"
                name="date_of_birth"
                type="date"
                value={formik.values.date_of_birth}
                variant="outlined"
                onChange={e => {
                  if (new Date("9999-12-31") >= new Date(e.target.value)) {
                    formik.setFieldValue('age', getAge(e.target.value))
                    formik.handleChange(e);
                  }
                }}
                InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={formik.handleBlur}
                  />
                  {formik.errors.date_of_birth && formik.touched.date_of_birth && (
                  <p style={{color:"red"}}>{formik.errors.date_of_birth}</p>
                   )} */}

                   <DatePickerField 
                                mandatory
                                disabled={view_only}
                                name="date_of_birth"
                                label="Date Of Birth"
                                placeholder="Date Of Birth"
                                onChange={val => {
                                  formik.setFieldValue('date_of_birth', String(val))
                                  formik.setFieldValue('age', getAge(val))
                                }}
                                onBlur={e => {
                                  formik.handleBlur(e);
                                }}
                                value={formik.values.date_of_birth}
                                touched={formik.touched.date_of_birth}
                                errors={formik.errors.date_of_birth}
                                 />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <TextField
                disabled
                fullWidth
                label="Age"
                name="age"
                type="text"
                value={formik.values.age}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                />
                {formik.errors.age && formik.touched.age && (
                <p style={{color:"red"}}>{formik.errors.age}</p>
                 )}
            
            </Grid>
          </Grid>

<Grid container spacing={3}style={{marginTop:"2%", marginBottom:"2%"}}>
<Grid item xs={12} md={6} lg={4}>
  <FormControl variant="outlined" fullWidth>
  <InputLabel fullWidth >Gender<span>&nbsp;*</span></InputLabel>
  <Select
  required
  fullWidth
  disabled={view_only}
    value={formik.values.gender}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    label="Gender"
    name="gender"
  >
    {
          genderData.map(
            (item) => (
            <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
            )
          )
        }
  </Select>
      {formik.errors.gender && formik.touched.gender && (
      <p style={{color:"red"}}>{formik.errors.gender}</p>
       )}
</FormControl>
</Grid>
<Grid item xs={12} md={6} lg={4}>
  <FormControl variant="outlined" fullWidth>
  <InputLabel fullWidth >Blood Group<span>&nbsp;*</span></InputLabel>
  <Select
  required
  fullWidth
  disabled={view_only}
    value={formik.values.blood_group}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    label="Blood Group"
    name="blood_group"
  >
    {
          BoodGroup.map(
            (item) => (
            <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
            )
          )
        }
  </Select>
      {formik.errors.blood_group && formik.touched.blood_group && (
      <p style={{color:"red"}}>{formik.errors.blood_group}</p>
       )}
</FormControl>
</Grid>
<Grid item xs={12} md={6} lg={4}>
  <FormControl variant="outlined" fullWidth>
  <InputLabel fullWidth >Marital Status</InputLabel>
  <Select
  fullWidth
  disabled={view_only}
    value={formik.values.marital_status}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    label="Marital Status"
    name="marital_status"
  >
    {
          MaterialStatus.map(
            (item) => (
            <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
            )
          )
        }
  </Select>
      {formik.errors.marital_status && formik.touched.marital_status && (
      <p style={{color:"red"}}>{formik.errors.marital_status}</p>
       )}
</FormControl>
</Grid>
</Grid>
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={4}>
                 <TextField
            disabled={view_only}
                  id="outlined-adornment-height"
                  fullWidth
                  variant="outlined"
                  label="Height"
                  name='height'
                  value={formik.values.height}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                  }}
                />
                {formik.errors.height && formik.touched.height && (
                <p style={{color:"red"}}>{formik.errors.height}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                 <TextField
            disabled={view_only}
                  id="outlined-adornment-weight"
                  fullWidth
                  variant="outlined"
                  label="Weight"
                  name='weight'
                  value={formik.values.weight}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                  }}
                />
                {formik.errors.weight && formik.touched.weight && (
                <p style={{color:"red"}}>{formik.errors.weight}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
            disabled={view_only}
                fullWidth
                label="Occupation"
                name="occupation"
                type="text"
                value={formik.values.occupation}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.occupation && formik.touched.occupation && (
                <p style={{color:"red"}}>{formik.errors.occupation}</p>
                 )}
            </Grid>
          </Grid>
          
          <Grid container spacing={3}style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={4}>
            <Select
            disabled
                style={{marginRight:"5%", marginBottom:"2%", width:"30%"}}
                name="country_code"
                type="text"
                value={formik.values.country_code}
                variant="outlined"
                onChange={e => {
                  formik.setFieldValue("alt_country_code", e.target.value)
                  setAltCurrentCountry(findCodeFromValue(e.target.value))
                }}
                >
                  {
                    options.map(
                      (item) => (
                      <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                      )
                    )
                  }
                </Select>
                <FormControl style={{width:"65%"}}  variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Mobile Number<span>&nbsp;*</span></InputLabel>
                <OutlinedInput
                required
                disabled
                    name="mobile_number"
                    type="number"
                    value={parseInt(formik.values.mobile_number)}
                    onChange={formik.handleChange}
                    startAdornment={<InputAdornment position="start">{formik.values.country_code}</InputAdornment>}
                    labelWidth={100}
                    onBlur={formik.handleBlur}
                />
                {formik.errors.mobile_number && formik.touched.mobile_number && (
                <p style={{color:"red"}}>{formik.errors.mobile_number}</p>
                 )}
                </FormControl>
            
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <Select
            disabled={view_only}
                style={{marginRight:"5%", marginBottom:"2%", width:"30%"}}
                name="alt_country_code"
                type="text"
                value={formik.values.alt_country_code}
                variant="outlined"
                onChange={formik.handleChange}
                >
                  {
                    options.map(
                      (item) => (
                      <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                      )
                    )
                  }
                </Select>
                <FormControl style={{width:"65%"}}  variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Alternative Mobile Number</InputLabel>
                <OutlinedInput
            disabled={view_only}
                    name="alt_mobile_number"
                    type="number"
                    label='Alternative Mobile Number'
                    value={parseInt(formik.values.alt_mobile_number)}
                    onChange={formik.handleChange}
                    startAdornment={<InputAdornment position="start">{formik.values.alt_country_code}</InputAdornment>}
                    labelWidth={100}
                    onChange={e => {
                      formik.setFieldValue("alt_country_code", e.target.value)
                      setAltCurrentCountry(findCodeFromValue(e.target.value))
                    }}
                />
                {formik.errors.alt_mobile_number && formik.touched.alt_mobile_number && (
                <p style={{color:"red"}}>{formik.errors.alt_mobile_number}</p>
                 )}
                </FormControl>
            
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
            required
                fullWidth
                disabled
                label="Email ID"
                name="email"
                type="text"
                value={formik.values.email}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email && (
                <p style={{color:"red"}}>{formik.errors.email}</p>
                 )}
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
            disabled={view_only}
                  // required
                fullWidth
                label="National Health ID"
                name="national_health_id"
                type="text"
                value={formik.values.national_health_id}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.national_health_id && formik.touched.national_health_id && (
                <p style={{color:"red"}}>{formik.errors.national_health_id}</p>
                 )}
            </Grid>
          </Grid>


    
          
          {!view_only && <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} > 
            <Grid item xs={6} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            </Grid>
            {/* <Grid item xs={3}>
            </Grid>
            
            <Grid item xs={3}>
            </Grid> */}
            <Grid item xs={6} container justify={"flex-end"} >
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" type="submit" >Next</Button>
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.nextStep} >Next</Button> */}
                
            </Grid>
          </Grid> }

          {view_only && <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} > 
            <Grid item xs={6} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            </Grid>
            {/* <Grid item xs={3}>
            </Grid>
            
            <Grid item xs={3}>
            </Grid> */}
            <Grid item xs={6} container justify={"flex-end"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" type="submit" >Next</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.nextStep} >Next</Button>
                
            </Grid>
          </Grid> }
          </form>
                
        </div>
      );



}