
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import RadioButton from "./radioButton";
import TestDelete from "./TestDelete";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

export default function SimpleTable(props) {
  const classes = useStyles();
  const rowData = props.TestValues
  const TestList = props.TestList
  const rows =[]


  
  rowData.map((item) => {
      const data = TestList.filter(row => row.id == item.id)
      item['data'] = data
    rows.push(item)
  })
  console.log("rows", rows);

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
          <TableCell align="center">Sl No</TableCell>
            <TableCell align="center">Test ID</TableCell>
            <TableCell align="left">Test Name</TableCell>
            <TableCell align="center">Sample Collect from Home</TableCell>
            <TableCell align="center">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {rows.map((row, index) => (
            <TableRow key={row.id}>
              <TableCell align="center">
                {index+1}
              </TableCell>
              <TableCell align="center">
                {row.data[0].unique_id}
              </TableCell>
              <TableCell  align="left">
                {row.data[0].name}
              </TableCell>
              <TableCell  align="center">
                <RadioButton onChangeSampleCollect={props.onChangeSampleCollect} rows={rows} value={row} index={index} />
              </TableCell>
              <TableCell  align="center">
                <TestDelete onDelete={props.onDelete} row={row} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
