// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Grid from '@material-ui/core/Grid';
import { MultiplePayment } from "../../../../../../modules/Api/PaymentAdmin/Payment/PayOutsApi";
import Spinner from "../../../../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../../../../modules/Toast/SuccessSnackBar";
import Button from 'react-bootstrap/Button';
import {FileUpload} from "../../../../../../Utils/Components/FileUpload/FileUpload";

const FILE_SIZE = 1024 * 1024 * 2;
const SUPPORTED_FORMATS = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ".xlsx"];

// Validation schema
const CategoryEditSchema = Yup.object().shape({
    file: Yup.mixed()
    .nullable()
    .required("File is required")
    .test("FILE_SIZE", "Uploaded file is too big.", 
        value => !value || (value && value.size <= FILE_SIZE))
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", 
        value => !value || (value && SUPPORTED_FORMATS.includes(value.type)))
});

export function StoreEditForm(props) {
    const {  type, loadData } = props
    const [loading, setLoading] = React.useState(false)

    const initialValues = {}

    const SendPayout = (data) => {
        props.handleHide()
        MultiplePayment(data, type)
        .then(items => {
          if (items && items.data && items.data.message) {
            SuccessToast(items.data.message)
          }
          props.handleHide()
          setLoading(false)
          loadData()
        })
        .catch(err => {
          ErrorToast(err)
          setLoading(false)
          loadData()
      })
    }

    if (loading) {
        return (
          <Spinner />
        )
      }


    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={CategoryEditSchema}
                onSubmit={(values) => {
                    setLoading(true)
                    let formData = new FormData();
                    formData.set('file', values.file)
                    SendPayout(formData)
                }}
            >
               {({ handleSubmit, setFieldValue, resetForm, handleChange, handleBlur, touched, values, errors }) => (
                    <>
                        <Form className="form form-label-right">

                            
                            <Grid container spacing={3} style={{marginBottom:"2%"}}>
                            <Grid item xs={7}>
                            <FileUpload
                                // mandatory
                                label='Upload File'
                                errors={errors.file}
                                name="file"
                                value={values.file}
                                onChange={value => {
                                setFieldValue("file", value)
                                }}
                            />
                            </Grid>
                            </Grid>
                            <Grid container spacing={3} style={{marginTop:"2%"}}>
                            <Grid item xs={10}>
                            <Button style={{margin:'3px'}} variant="secondary" onClick={props.handleHide}>
                                Close
                            </Button>
                            <Button style={{margin:'3px'}} type='submit' variant="primary" >
                            Save Payment Details and Notify Vendor
                            </Button>
                            </Grid>
                            </Grid>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
