import http from "../../../Utils/http";

export function loadTableData() {
    const pharams = "filter[approved]=0"
    return http.get(`api/administrator/list/laboratory/?${pharams}`);
  }

  export function pageLoadData(no) {
    const pharams = 'page='+no+"&filter[approved]=0"
    return http.get(`api/administrator/list/laboratory/?${pharams}`);
  }

  export function searchLoadData(text) {
    const pharams = "filter[approved]=0&filter[name]="+text
    return http.get(`api/administrator/list/laboratory/?${pharams}`);
  }

  export function loadTestData() {
    const pharams = "paginate=0"
    return http.get(`api/guest/labtest/?${pharams}`);
  }

  export function editBbasicinfo(id, data) {
    return http.post(`api/admin/laboratory/basicinfo/${id}`, data )
  }

  export function editAddressinfo(id, data) {
    return http.post(`api/admin/laboratory/address/${id}`, data )
  }

  export function editListOfTests(id, data) {
    return http.post(`api/admin/laboratory/testlist/${id}`, data )
  }

  export function editBankinfo(id, data) {
    return http.post(`api/admin/laboratory/bankdetails/${id}`, data )
  }

  export function confirmClick(id) {
    const reqData = {
      user_id : id,
      action : "APPROVE",
      user_type : "LABORATORY",
      comment : ''
    }
    return http.post(`api/admin/action/approveorreject`, reqData )
  }

  export function rejectClick(id) {
    const reqData = {
      user_id : id,
      action : "REJECT",
      user_type : "LABORATORY",
      comment : ''
    }
    return http.post(`api/admin/action/approveorreject`, reqData )
  }