import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import { ListNew } from "./index"
import ListApproved from "./ListApprove"
import {Form} from "./Details/Pharmacy";
import { AddForm } from "./AddPharmacy/Pharmacy";

export const Pharmacy = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Pharmacy");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/pharmacy"
        to="/pharmacy/approved"
      />
      <ContentRoute from="/pharmacy/new" component={ListNew} />
      <ContentRoute from="/pharmacy/approved" component={ListApproved} />
      <ContentRoute from="/pharmacy/add" component={AddForm} />
      <ContentRoute from="/pharmacy/view" component={Form} />
    </Switch>
  );
};
