import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
// import { StoresTable } from "./SpecializationTable";
import StoresTable from "./TableNew";
import { loadTableData, pageLoadData, searchLoadData } from "../../modules/Api/Pharmacy/NewPharmacyApi";
import Spinner from "../../Utils/Components/CustomSpinner";
import NoRecordFound from "../../Utils/Components/NoRecordFound";
import {ErrorToast} from "../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../modules/Toast/SuccessSnackBar";
import Search from "./Search";

export const ListNew = (props) => {
  const [data, setData] = React.useState(undefined)
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)

  const loadData = () => {
    if (mounted) {
      setMounted(false)
      loadTableData()
      .then(items => {
        console.log(items);
        setMounted(false)
        setLoading(false)
        setData(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
        setMounted(false)
        setLoading(false)
    })
    }
  }

  const SearchData = (text) => {
    setData(undefined)
    searchLoadData(text)
      .then(items => {
        setData(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
    })
  }

  const loadPageData = (no) => {
    // setData(undefined)
    pageLoadData(no)
      .then(items => {
        setData(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
    })
  }

  React.useEffect(() => {
    loadData()
  }, [loadData])

  if (loading) {
    return (
      <Spinner />
    )
  }

  return (
    <Card>
      <CardHeader title="New Pharmacy list">
        <CardHeaderToolbar>
          <Search SearchData={SearchData} />
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {
          data && <StoresTable loadPageData={loadPageData} data={data}/>
        }
        {
          !data && <NoRecordFound/>
        }

      </CardBody>
    </Card>
  );
};
