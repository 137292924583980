import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import { AddStores, ListStores } from "./index"
import { Category } from "./Category"



export const Medicine = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Medicine");

  
  
  

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/medicine"
        to="/medicine/list"
      />
      <ContentRoute from="/medicine/add" component={AddStores} />
      <ContentRoute from="/medicine/list" component={ListStores} />
      <ContentRoute path="/medicine/category" component={Category} />
    </Switch>
  );
};
