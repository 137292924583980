import axios from "axios"
import {base_url} from "../../../Utils/http";

const axiosApi = axios.create({
    baseURL: base_url
      
});



export function ChangePassword(data, accessToken) {
  axiosApi.defaults.headers.common['Authorization'] = "Bearer "+accessToken;
  return axiosApi.post(`api/oauth/password/change`, data)
}

