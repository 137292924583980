import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { StoreEditForm } from "./PayoutInformationForm";
class Example extends React.Component {
    constructor(props, context) {
      super(props, context);
  
      this.state = {
        show: false,
      };
  
      this.handleShow = () => {
        this.setState({ show: true });
      };
  
      this.handleHide = () => {
        this.setState({ show: false });
      };
    }

    
  
    render() {
      return (
        <>
        <Button style={{margin:'3px', width:"160px"}} type='submit' variant={this.props.disabled ? "secondary" : "success"}
        disabled={this.props.disabled}
        onClick={this.handleShow}
         >
           Complete Payment
         </Button>
  
          <Modal
            show={this.state.show}
            onHide={this.handleHide}
            dialogClassName="modal-90w"
            size="lg"
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                Enter Payout Information
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <StoreEditForm onEdit={this.props.onEdit} handleHide={this.handleHide} row={this.props.row} rows={this.props.rows} OnSinglePayment={this.props.OnSinglePayment} />
            </Modal.Body>
          </Modal>
        </>
      );
    }
  }
  
 export default Example;
  