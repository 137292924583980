
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Graph from "./Graph";
import { graphLoadData } from "../../../../../../modules/Api/PaymentAdmin/Payment/CurrentSalesApi";
import Spinner from "../../../../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../../../../modules/Toast/SuccessSnackBar";
import { GetCurrencyCode } from "../../../../../../Utils/GetData";
import getSymbolFromCurrency from 'currency-symbol-map';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    marginBottom: 15,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px 0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard(props) {
  const {type} = props
  const classes = useStyles();
  const [graphData, setGraphData] = React.useState(undefined)
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)

  const currency_code = GetCurrencyCode()

  
  const loadGraphData = () => {
    graphLoadData(type)
    .then(items => {
      setLoading(false)
      setGraphData(items.data)
      if (items && items.data && items.data.message) {
        SuccessToast(items.data.message)
      }
      
    })
    .catch(err => {
      ErrorToast(err)
      setLoading(false)
  })
}


  const bull = <span className={classes.bullet}>•</span>;

  React.useEffect(() => {
    if (mounted) {
      loadGraphData()
      setMounted(false)
    }
  }, [loadGraphData])

  if (loading) {
    return (
      <Spinner />
    )
  }

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Sales v/s Earnings ({getSymbolFromCurrency(currency_code)})
        </Typography>
        {graphData && <Graph graphData={graphData} />}
      </CardContent>
    </Card>
  );
}
