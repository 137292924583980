import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import { PaymentPharmacy, PaymentDoctor, PaymentLaboratory } from "./index"
export const PaymentAdmin = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Payment");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/paymentAdmin"
        to="/paymentAdmin/paymentPharmacy"
      />
      <ContentRoute from="/paymentAdmin/paymentPharmacy" component={PaymentPharmacy} />
      <ContentRoute from="/paymentAdmin/paymentLaboratory" component={PaymentLaboratory} />
      <ContentRoute from="/paymentAdmin/paymentDoctor" component={PaymentDoctor} />
    </Switch>
  );
};
