
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SaleCards from "./SaleCards";
import { GetCurrencyCode } from "../../../../../../Utils/GetData";
import getSymbolFromCurrency from 'currency-symbol-map'

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    marginBottom: 15,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard(props) {
  const { type, data } = props
  const currency_code = GetCurrencyCode()
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {type === "MED" && "Pharmacy Current Sales"} {type === "LAB" && "Laboratory Current Sales"} {type === "DOC" && "Doctor Current Sales"} ({currency_code}) {getSymbolFromCurrency(currency_code)}
        </Typography>
        {data && <SaleCards data={data} currency_code={currency_code} />}
      </CardContent>
    </Card>
  );
}
