
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useHistory } from "react-router-dom";
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';

// import { deleteData, editData } from "../../modules/Api/TestApi";
import {ErrorToast} from "../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../modules/Toast/SuccessSnackBar";
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import CommentModel from "../../Components/Comment/Model";


const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));



const useStyles2 = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

export default function CustomPaginationActionsTable(props) {
  const history = useHistory();
  const classes = useStyles2();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const rows = props.data
  const {loadPageData} = props
  const [page, setPage] = React.useState(rows.current_page - 1);

  
  const fakeData = []
  for (var i=1; i<=page*10; i++)  
  {  
    fakeData.push({
        pharmacy_unique_id: '',
        pharmacy_name : '',
        address : [{
          country : '',
          state: '',
          city_village : '',
        }],
        mobile_number : '',
        email: '',
    }) 
  }  

  const data = rows.data
  const rowData = [...fakeData, ...data];

  console.log(rowData);


  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    function handleFirstPageButtonClick(event) {
      onChangePage(event, 0);
      loadPageData(1)
    }
  
    function handleBackButtonClick(event) {
      onChangePage(event, page - 1);
      loadPageData(page)
    }
  
    function handleNextButtonClick(event) {
      onChangePage(event, page + 1);
      loadPageData(page + 2)
    }
  
    function handleLastPageButtonClick(event) {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      loadPageData(Math.max(0, Math.ceil(count / rowsPerPage)))
    }
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: '#64ADED',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
  }

//   const onDelete = (id) => {
//     let newPage = page + 1
//     if (rows.data.length == 1) {
//         newPage = page
//     } else {
//         newPage = page + 1
//     }
//     deleteData(id)
//         .then(items => {
//             loadPageData(newPage)
//             if (items && items.data && items.data.message) {
//             SuccessToast(items.data.message)
//             }
            
//         })
//         .catch(err => {
//             ErrorToast(err)
//         })
// }

// const onEdit = (values, id) => {
//   editData(values, id)
//         .then(items => {
//             loadPageData(page+1)
//             if (items && items.data && items.data.message) {
//             SuccessToast(items.data.message)
//             }
            
//         })
//         .catch(err => {
//             ErrorToast(err)
//         })
// }

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
        <TableHead>
          <TableRow>
          			 		
            {/* <StyledTableCell component="th" scope="row">SL no</StyledTableCell> */}
            <StyledTableCell  align="center">Pharmacy Id</StyledTableCell>
            <StyledTableCell align="center">Pharmacy Name</StyledTableCell>
            <StyledTableCell align="center">Country</StyledTableCell>
            <StyledTableCell  align="center">State</StyledTableCell>
            <StyledTableCell align="center">City</StyledTableCell>
            <StyledTableCell align="center">Mobile No</StyledTableCell>
            <StyledTableCell  align="center">Email Id</StyledTableCell>
            <StyledTableCell align="center">View & Approve</StyledTableCell>
          </TableRow>
        </TableHead>
          <TableBody>
            {rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">{row.pharmacy_unique_id}</TableCell>
              <TableCell align="center">{row.pharmacy_name}</TableCell>
              <TableCell align="center"> {row.address[0].country}</TableCell>
              <TableCell align="center">{row.address[0].state}</TableCell>
              <TableCell align="center">{row.address[0].city_village}</TableCell>
              <TableCell align="center"> {row.user.mobile_number}</TableCell>
              <TableCell align="center"> {row.user.email}</TableCell>
                
                <TableCell align="center">
                <div className="d-flex justify-content-center">
                  <div className="p-2 col-example text-left">
                <a
                    className="btn btn-icon btn-light btn-hover-success btn-sm"
                    onClick={() => history.push({
                      pathname: '/pharmacy/view',
                      state: { detail: row,
                      type: 'edit_and_approve' }
                    })}
                >
                    {/* <span className="svg-icon svg-icon-md svg-icon-success">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")} />
                    </span> */}
                    <VisibilitySharpIcon color='primary' />
                </a>
                  </div>
                  <div className="p-2 col-example text-left">
                  <CommentModel row={row} />
                  </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 48 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={10}
                // colSpan={3}
                count={rows.total}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'Rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </Paper>
  );
}
