import http from "../../../Utils/http";

export function loadTableData(data) {
  const pharams = `filter[approved]=${data.active}&filter[name]=${data.name}&page=${data.no}`
    return http.get(`api/administrator/list/laboratory/?${pharams}`);
  }

  

  export function rejectClick(id) {
    const reqData = {
      user_id : id,
      action : "REJECT",
      user_type : "LABORATORY",
      comment : ''
    }
    return http.post(`api/admin/action/approveorreject`, reqData )
  }

  export function confirmClick(id) {
    const reqData = {
      user_id : id,
      action : "APPROVE",
      user_type : "LABORATORY",
      comment : ''
    }
    return http.post(`api/admin/action/approveorreject`, reqData )
  }