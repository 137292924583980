import http from "../../../Utils/http";

export function loadTableData(data) {
  const pharams = `start_date=${data.start_date}
  &end_date=${data.end_date}
  &doctor_id=${data.doctor_id}
  &status=${data.status}
  &consultation_type=${data.consultation_type}
  &start_fee=${data.start_fee}
  &end_fee=${data.end_fee}
  &followup=${data.followup}
  &search=${data.search}
  &page=${data.no}
  &report=${" "}`
    return http.get(`api/administrator/list/appointments?${pharams}`);
  }

  export function downloadReport(params) {
      return http.get(`api/administrator/list/appointments`, {
        params : params,
        responseType: 'blob',
    }
      );
    }

  export function DoctorList() {
    return http.get(`api/list/doctor`);
  }

  export function editData(data) {
    return http.post(`api/administrator/appointments/updatepns`, data);
  }