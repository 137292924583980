import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { StoreEditForm } from "./EditForm";
import { loadNoPageData } from "../../modules/Api/Medicine/CategoryApi";
import Spinner from "../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../modules/Toast/SuccessSnackBar";

export const AddStores = (props) => {
  const [Categorydata, setCategoryData] = React.useState(undefined)
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)

  const [initialValues, setInitialValues] = React.useState({
    category_id: "",
    composition: "",
    weight: "",
    weight_unit: "",
    name: "",
    manufacturer: "",
    medicine_type: "",
    drug_type: "",
    qty_per_strip: "",
    price_per_strip: "",
    rate_per_unit: "",
    rx_required: "",
    short_desc: "",
    long_desc: "",
    cart_desc: "",
    image: "",
    currency_code: 'INR'
})

  const backToStoresList = () => props.history.push('/medicine/list')
  const saveStore = () => { }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  const saveStoreClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  
  const loadCategoryData = () => {
    if (mounted) {
      setMounted(false)
      loadNoPageData()
      .then(items => {
        setMounted(false)
        setLoading(false)
        setCategoryData(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        setCategoryData([])
        // ErrorToast(err)
        setMounted(false)
        setLoading(false)
    })
    }
  }


  const resetForm = () => {
    if (resetbtnRef && resetbtnRef.current) {
      resetbtnRef.current.click()
    }
  }

  React.useEffect(() => {
    loadCategoryData()
  }, [loadCategoryData])

  
  if (loading) {
    return (
      <Spinner />
    )
  }
  return (
    <Card>
      <CardHeader title="Add Medicine">
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToStoresList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
              Back to list
            </button>
          {`  `}
          {/* <button
            className="btn btn-light ml-2"
            onClick={resetForm}>
            <i className="fa fa-redo"></i>
              Reset
            </button>
          {`  `} */}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveStoreClick}
          >
            Add
            </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          {Categorydata && <StoreEditForm
          initialValues={initialValues}
          setInitialValues={setInitialValues}
            Categorydata={Categorydata}
            btnRef={btnRef}
            resetbtnRef={resetbtnRef}
            saveProduct={saveStore}
          >
          </StoreEditForm>}
        </div>
      </CardBody>
    </Card>
  )
};
