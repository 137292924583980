import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {getCode} from '../../../Utils/googleApi';
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import { editAddressinfo } from "../../../modules/Api/Laboratory/NewLaboratoryApi";
import {CurrencySelect} from '../../../Utils/Components/CurrencySelect';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Details(props) {
  const classes = useStyles();
  let view_only = false
  if (props.type == 'view') {
    view_only = true
  } else {
    view_only = false
  }

  const changePincode = (event) => {
    const pincodeVal = event.target.value
    // formik.setFieldValue("pincode", pincodeVal)
    getCode(pincodeVal, addr => {
      if (!addr) {
        return
      }
      if (addr.country){
        formik.setFieldValue("country", addr.country)
      } else {
        formik.setFieldValue("country", '')
      }
      if (addr.state){
        formik.setFieldValue("state", addr.state)
      } else {
        formik.setFieldValue("state", '')
      }
      if (addr.district){
        formik.setFieldValue("district", addr.district)
      } else {
        formik.setFieldValue("district", '')
      }
      if (addr.position.lng){
        formik.setFieldValue("longitude", addr.position.lng)
      }
      if (addr.position.lat){
        formik.setFieldValue("latitude", addr.position.lat)
      }

    })
  }


  const initValues = {
            pincode: props.values.address[0].pincode,
            country: props.values.address[0].country,
            state: props.values.address[0].state,
            district: props.values.address[0].district,
            city_village: props.values.address[0].city_village,
            street_name: props.values.address[0].street_name,
            sample_collection: String(props.values.sample_collection),
            order_amount: props.values.order_amount,
            currency_code: props.values.user.currency_code,
            latitude: props.values.address[0].latitude,
            longitude: props.values.address[0].longitude,
      }

      if (!initValues.currency_code || initValues.currency_code == '' || null) {
        initValues['currency_code'] = 'INR'
      }
      const onClickNext = (data) => {
        console.log('update', data);
        props.nextStep()
        props.updateData(data)
    }
    const onClickPrevious = () => {
        props.previosStep()
    }
    
    const editDetails = (data, localData) => {
      const id = props.values.id
      editAddressinfo(id, data)
        .then(items => {
          onClickNext(localData)
          if (items && items.data && items.data.message) {
            SuccessToast(items.data.message)
          }
          
        })
        .catch(err => {
          ErrorToast(err)
      })
    }
    
    

    const numberandfullRex = /^[1-9]\d*(\.\d+)?$/

    const formik = useFormik({
      initialValues: initValues,
      validationSchema: Yup.object({
        pincode: Yup.string()
        .matches(/^[0-9]*$/, 'Invalid Pincode')
        .required("Pincode is Required!")
        // .test('len', 'Invalid Pincode', val => val.length === 6)
        .nullable(),
        country: Yup.string()
         .required("Country is Required!"),
        state: Yup.string()
          .required("State is Required!"),
        district: Yup.string()
          .required("District is Required!"),
        city_village: Yup.string()
          .required("City/Village/Society is Required!"),
        street_name: Yup.string()
         .required("Area/Street/Sector is required!"),
        sample_collection : Yup.string(),
        order_amount: Yup.string().when('sample_collection', {
           is: "1",
           then: Yup.string()
           .required("Amount is Required!")
           .matches(numberandfullRex, 'Invalid Data!')
           .nullable(),
           }),
      }),
      onSubmit: values => {

        const data = props.values

        data["address"][0]["pincode"] = values.pincode
        data["address"][0]["country"] = values.country
        data["address"][0]["state"] = values.state
        data["address"][0]["city_village"] = values.city_village
        data["address"][0]["street_name"] = values.street_name
        data["address"][0]["district"] = values.district
        data["user"]["currency_code"] = values.currency_code
        data["sample_collection"] = values.sample_collection
        data["order_amount"] = values.order_amount

        const upValue = values
        // upValue['laboratory_id_1'] = null
        // upValue['pharmacy_id_1'] = null
        // upValue['id'] = props.values.id
        editDetails(upValue, data)
      }
    });
    

    let valueField = <p></p>
    if (formik.values.sample_collection === "1") {
        valueField = <div>
            <CurrencySelect
              value={formik.values.order_amount}
              onChange={value => {
                formik.setFieldValue("order_amount", value)
              }}
              onBlur={formik.handleBlur}
              label='Minimum value of sample collection from home'
              name="order_amount"
              errors={formik.errors.order_amount}
                />
        
            </div>
    } else if (formik.values.sample_collection === "0") {
      formik.values.order_amount = 0.00
        valueField = <p></p>
    }

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Address</h6>
      <Grid container spacing={3}  >
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            disabled={view_only}
            label="Pincode"
            name="pincode"
            value={formik.values.pincode}
            variant="outlined"
            onBlur={(e) => {
              changePincode(e)
              formik.handleBlur(e)
            }}
            onChange={formik.handleChange}
            />
            {formik.errors.pincode && formik.touched.pincode && (
            <p style={{color:"red"}}>{formik.errors.pincode}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            disabled={view_only}
            label="Country"
            name="country"
            type="text"
            value={formik.values.country}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.country && formik.touched.country && (
            <p style={{color:"red"}}>{formik.errors.country}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            disabled={view_only}
            label="State"
            name="state"
            type="text"
            value={formik.values.state}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.state && formik.touched.state && (
            <p style={{color:"red"}}>{formik.errors.state}</p>
             )}
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            disabled={view_only}
            label="District"
            name="district"
            type="text"
            value={formik.values.district}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.district && formik.touched.district && (
            <p style={{color:"red"}}>{formik.errors.district}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            disabled={view_only}
            label="City/Village/Society"
            name="city_village"
            type="text"
            value={formik.values.city_village}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.city_village && formik.touched.city_village && (
            <p style={{color:"red"}}>{formik.errors.city_village}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            disabled={view_only}
            label="Area/Street/Sector"
            name="street_name"
            type="text"
            value={formik.values.street_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
              {formik.errors.street_name && formik.touched.street_name && (
              <p style={{color:"red"}}>{formik.errors.street_name}</p>
               )}
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item xs={4}>
        <FormControl component="fieldset" 
            fullWidth>
            <FormLabel component="legend">Sample Collection From Home</FormLabel>
            {!view_only && <RadioGroup row aria-label="sample_collection" name="sample_collection" value={formik.values.sample_collection} onChange={formik.handleChange}>
                <FormControlLabel value= "1" control={<Radio color="primary"  />} label="Yes" />
                <FormControlLabel value= "0" control={<Radio color="primary"  />} label="No" />
            </RadioGroup>}
            {view_only && <RadioGroup row aria-label="sample_collection" name="sample_collection" value={formik.values.sample_collection}>
                <FormControlLabel value= "1" control={<Radio color="primary"  />} label="Yes" />
                <FormControlLabel value= "0" control={<Radio color="primary"  />} label="No" />
            </RadioGroup>}
            </FormControl>

            
        </Grid>
        <Grid item xs={4}>
        {valueField}
        </Grid>
      </Grid>

<Grid container spacing={3} >
        <Grid item xs={3} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
        </Grid>
        <Grid item xs={3}>
        </Grid>
        
        <Grid item xs={3}>
        </Grid>
        {!view_only && <Grid item xs={3} container justify={"flex-end"} >
            <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Next</Button>
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.nextStep} >Next</Button> */}
            </Grid>}
        {view_only && <Grid item xs={3} container justify={"flex-end"} >
        {/* <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Next</Button> */}
        <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.nextStep} >Next</Button>
        </Grid>}
      </Grid> 
      </form>
            
    </div>
  );
}
