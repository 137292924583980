import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import { List } from "./index"
export const Appointments = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Appointments");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/appointments"
        to="/appointments/list"
      />
      <ContentRoute from="/appointments/list" component={List} />
    </Switch>
  );
};
