import React from 'react';
import { Card } from 'react-bootstrap';
import getSymbolFromCurrency from 'currency-symbol-map'

export default function (props) {
    const {type, data, currency_code } = props;
    return (
        <>
        <div className="d-flex justify-content-center" style={{width:'100%'}} >
                <div className="p-2 col-example text-left" style={{width:'100%'}} >
                    <Card bg="primary" text="white">
                        <Card.Body>
                        <Card.Title>Total Sales</Card.Title>
                        <Card.Text>
                        {getSymbolFromCurrency(currency_code)} {data.total_sales}
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="p-2 col-example text-left" style={{width:'100%'}} >
                    <Card bg="primary" text="white">
                        <Card.Body>
                        <Card.Title>Our Earnings</Card.Title>
                        <Card.Text>
                        {getSymbolFromCurrency(currency_code)} {data.earnings}
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="p-2 col-example text-left" style={{width:'100%'}} >
                    <Card bg="primary" text="white">
                    <Card.Body>
                    <Card.Title>Over Due</Card.Title>
                    <Card.Text>
                    {getSymbolFromCurrency(currency_code)} {data.over_due}
                    </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </div>
</>
    )
}