import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { StoreEditForm } from "./MultiplePayoutModelForm";
class Example extends React.Component {
    constructor(props, context) {
      super(props, context);
  
      this.state = {
        show: false,
      };
  
      this.handleShow = () => {
        this.setState({ show: true });
      };
  
      this.handleHide = () => {
        this.setState({ show: false });
      };
    }

    
  
    render() {
      return (
        <>
          <Button style={{margin:'3px', width:"160px"}} type='submit' variant="success"
          onClick={this.handleShow}
          >
           Bulk Payment
         </Button>
  
          <Modal
            show={this.state.show}
            onHide={this.handleHide}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
            Bulk Payment
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <StoreEditForm onEdit={this.props.onEdit} handleHide={this.handleHide} type={this.props.type} loadData={this.props.loadData} />
            </Modal.Body>
          </Modal>
        </>
      );
    }
  }
  
 export default Example;
  