import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import PharmacyForm from "./PharmacyForm";
import { useHistory } from "react-router-dom";

export const Form = (props) => {
  const [data, setData] = React.useState()
  const [type, setType] = React.useState('')
  React.useEffect(() => {
    setData(props.location.state.detail)
    setType(props.location.state.type)
  }, [setData])
  const history = useHistory();
  // setdata(props.location.state)
  const backToStoresList = () => props.history.goBack()
  const saveStore = () => { }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  const saveStoreClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };
  const resetForm = () => {
    if (resetbtnRef && resetbtnRef.current) {
      resetbtnRef.current.click()
    }
  }
  return (
    <Card>
      <CardHeader title="Pharmacy Details">
        <CardHeaderToolbar>
          {/* <button
            type="button"
            onClick={backToStoresList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
              Back
            </button> */}
          {`  `}
          {/* <button
            className="btn btn-light ml-2"
            onClick={resetForm}>
            <i className="fa fa-redo"></i>
              Reset
            </button>
          {`  `} */}
          {type == 'edit_and_approve' && <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={() => history.push('/pharmacy/new')}
          >
            Back to List
            </button>}
          {(type == 'edit' || type == 'view') && <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={() => history.push('/pharmacy/approved')}
          >
            Back to List
            </button>}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
        {data && <PharmacyForm
            // actionsLoading={actionsLoading}
            data={data}
            type={type}
            btnRef={btnRef}
            resetbtnRef={resetbtnRef}
            saveProduct={saveStore}
          >
          </PharmacyForm>}
        </div>
      </CardBody>
    </Card>
  )
};
