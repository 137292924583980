import { PhoneNumberUtil } from 'google-libphonenumber'
import { countryCodeData } from "./countyCodeMapping";

export const handleNumber = (input, countryCodeInt) => {

    // Get an instance of `PhoneNumberUtil`.
    const phoneUtil = PhoneNumberUtil.getInstance();
    if (!input || !countryCodeInt) return false;
  
    try {
        const number = phoneUtil.parseAndKeepRawInput(input, countryCodeInt);
        const isValid = phoneUtil.isValidNumber(number);
        return isValid;
    } catch (e) {
        console.log(e)
    }
    return false;
  }

  export const findCodeFromValue = (value) => {
    return (countryCodeData.filter((item) => item.value == value))[0]
  }