import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Spinner from "../../../Utils/Components/CustomSpinner";
import { useHistory } from "react-router-dom";
import {getCode} from '../../../Utils/googleApi';
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import { EditAddress, confirmClick, rejectClick } from "../../../modules/Api/Emloyee/ListHealthRepresentativeApi";
import Modal from 'react-bootstrap/Modal';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Details(props) {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [comment, setComment] = React.useState('')

    
  const {data, conatctData} = props
  var intValues = conatctData
  let view_only = false
  let edit_and_approve = false
  let edit_only = false
  if (props.type == 'view') {
    view_only = true
  } 
  if (props.type == 'edit_and_approve') {
    edit_and_approve = true
  }
  if (props.type == 'edit') {
    edit_only = true
  }

  const onClickBackList = () => {
    history.push('/employee')
  }


  const changePincode = (event) => {
    const pincodeVal = event.target.value
    // formik.setFieldValue("pincode", pincodeVal)
    getCode(pincodeVal, addr => {
      if (!addr) {
        return
      }
      if (addr.country){
        formik.setFieldValue("country", addr.country)
      } else {
        formik.setFieldValue("country", '')
      }
      if (addr.state){
        formik.setFieldValue("state", addr.state)
      } else {
        formik.setFieldValue("state", '')
      }
      if (addr.district){
        formik.setFieldValue("district", addr.district)
      } else {
        formik.setFieldValue("district", '')
      }
      if (addr.position.lng){
        formik.setFieldValue("longitude", addr.position.lng)
      }
      if (addr.position.lat){
        formik.setFieldValue("latitude", addr.position.lat)
      }

    })
  }

  const values = props.data
  let initValues = {
    pincode: '',
    country: '',
    state: '',
    district: '',
    city_village: '',
    street_name: '',
    latitude: '',
    longitude: '',}

    if (values.address && values.address[0]) {
      initValues = {
        pincode: values.address[0].pincode,
        country: values.address[0].country,
        state: values.address[0].state,
        district: values.address[0].district,
        city_village: values.address[0].city_village,
        street_name: values.address[0].street_name,
        latitude: values.address[0].latitude,
        longitude: values.address[0].longitude,}
    }

    const [openApprove, setOpenApprove] = React.useState(false)

    const handleCloseApprove = () => {
      setOpenApprove(false)
    }
  
    const handleShowApprove = () => {
      setOpenApprove(true)
    }

    const onConfirm = () => {
      const id = props.data.user.id
      setLoading(true)
      handleCloseApprove()
      confirmClick(id, comment)
        .then(items => {
          setLoading(false)
          history.push('/employee/new')
          if (items && items.data && items.data.message) {
            SuccessToast(items.data.message)
          }
          
        })
        .catch(err => {
          setLoading(false)
          ErrorToast(err)
      })
    }

    const ApproveModel = <Modal show={openApprove} onHide={handleCloseApprove}>
                            <Modal.Header closeButton>
                              <Modal.Title>Employee Approve</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to approve ?</Modal.Body>
                            <Modal.Footer>
                              <Button color="secondary" onClick={handleCloseApprove}>
                                Cancel
                              </Button>
                              <Button color="primary" onClick={onConfirm}>
                                Approve
                              </Button>
                            </Modal.Footer>
                          </Modal>

const [openReject, setOpenReject] = React.useState(false)

const handleCloseReject = () => {
  setOpenReject(false)
}

const handleShowReject = () => {
  setOpenReject(true)
}

const onReject = () => {
  const id = props.data.user.id
  handleCloseReject()
  setLoading(true)
  rejectClick(id, comment)
    .then(items => {
      setLoading(false)
      history.push('/employee/new')
      if (items && items.data && items.data.message) {
        SuccessToast(items.data.message)
      }
      
    })
    .catch(err => {
      setLoading(false)
      ErrorToast(err)
  })
}


const RejectModel = <Modal show={openReject} onHide={handleCloseReject}>
                        <Modal.Header closeButton>
                          <Modal.Title>Employee Reject</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to Reject ?</Modal.Body>
                        <Modal.Footer>
                          <Button color="secondary" onClick={handleCloseReject}>
                            Cancel
                          </Button>
                          <Button color="primary" onClick={onReject}>
                            Reject
                          </Button>
                        </Modal.Footer>
                      </Modal>
      var editDetails = ''

      if (edit_only)  {
        editDetails = (data) => {
        const id = props.data.user.id
        EditAddress(id, data)
          .then(items => {
            setLoading(false)
            history.push('/employee')
            if (items && items.data && items.data.message) {
              SuccessToast(items.data.message)
            }
            
          })
          .catch(err => {
            setLoading(false)
            ErrorToast(err)
        })
      }
      }

      if (edit_and_approve) {
        editDetails = (data) => {
        const id = props.data.user.id
        EditAddress(id, data)
          .then(items => {
            setLoading(false)
            handleShowApprove()
            
          })
          .catch(err => {
            setLoading(false)
            ErrorToast(err)
        })
      }
    }



    
    
    


    const formik = useFormik({
      initialValues: initValues,
      validationSchema: Yup.object({
        pincode: Yup.string()
        .matches(/^[0-9]*$/, 'Invalid Pincode')
        .required("Pincode is Required!")
        // .test('len', 'Invalid Pincode', val => val.length === 6)
        .nullable(),
        country: Yup.string()
         .required("Country is Required!"),
        state: Yup.string()
          .required("State is Required!"),
        district: Yup.string()
          .required("District is Required!"),
        city_village: Yup.string()
          .required("City/Village/Society is Required!"),
        street_name: Yup.string()
         .required("Area/Street/Sector is Required!"),
      }),
      onSubmit: values => {
        setLoading(true)
        editDetails(values)
      }
    });
    

    if (loading) {
      return (
        <Spinner />
      )
    }

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
        {ApproveModel}
        {RejectModel}
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Address</h6>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            disabled={view_only}
            label="Pincode"
            name="pincode"
            value={formik.values.pincode}
            variant="outlined"
            onBlur={(e) => {
              changePincode(e)
              formik.handleBlur(e)
            }}
            onChange={formik.handleChange}
            />
            {formik.errors.pincode && formik.touched.pincode && (
            <p style={{color:"red"}}>{formik.errors.pincode}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            disabled={view_only}
            label="Country"
            name="country"
            type="text"
            value={formik.values.country}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.errors.country && formik.touched.country && (
            <p style={{color:"red"}}>{formik.errors.country}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            disabled={view_only}
            label="State"
            name="state"
            type="text"
            value={formik.values.state}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.errors.state && formik.touched.state && (
            <p style={{color:"red"}}>{formik.errors.state}</p>
             )}
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            disabled={view_only}
            label="District"
            name="district"
            type="text"
            value={formik.values.district}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.errors.district && formik.touched.district && (
            <p style={{color:"red"}}>{formik.errors.district}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            disabled={view_only}
            label="City/Village/Society"
            name="city_village"
            type="text"
            value={formik.values.city_village}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.city_village && formik.touched.city_village && (
            <p style={{color:"red"}}>{formik.errors.city_village}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            disabled={view_only}
            label="Area/Street/Sector"
            name="street_name"
            type="text"
            value={formik.values.street_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
              {formik.errors.street_name && formik.touched.street_name && (
              <p style={{color:"red"}}>{formik.errors.street_name}</p>
               )}
        </Grid>
      </Grid>

      {/* {props.type == 'edit_and_approve' && <Grid container spacing={3} style={{marginTop:"2%"}}>
  <Grid item xs={12} md={6} lg={8}>
  <TextField
    fullWidth
      label="Comment"
      name="comment"
      multiline
      rows={4}
      value={comment}
      variant="outlined"
      onChange={e => {
        setComment(e.target.value)
      }}
      onBlur={formik.handleBlur}
      InputLabelProps={{
        shrink: true,
      }}
      />
  </Grid>
</Grid>} */}

{edit_only && <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item xs={3} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.previosStep}>Previous</Button>
        </Grid>
        <Grid item xs={3}>
        </Grid>
        
        <Grid item xs={3}>
        </Grid>
        <Grid item xs={3} container justify={"flex-end"} >
            <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Save Changes</Button>
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.nextStep} >Next</Button> */}
            </Grid>
      </Grid>} 

      {view_only && <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item xs={3} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.previosStep}>Previous</Button>
        </Grid>
        <Grid item xs={3}>
        </Grid>
        
        <Grid item xs={3}>
        </Grid>
        <Grid item xs={3} container justify={"flex-end"} >
            {/* <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Save Changes</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickBackList} >Back</Button>
            </Grid>
      </Grid>} 
      {edit_and_approve && <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={4} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.previosStep}>Previous</Button>
        </Grid>
        <Grid item xs={4}>
        </Grid>
        <Grid item xs={2}>
        </Grid>
        
        <Grid item xs={1} container justify={"flex-end"}>
            <Button onClick={handleShowReject} style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="secondary" >Reject</Button>
        </Grid>
        <Grid item xs={1} container justify={"flex-end"}>
            <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Approve</Button>
        </Grid>
      </Grid> }
      </form>
            
    </div>
  );
}
