import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ContentRoute } from "../../../_metronic/layout";
import ApprovedForm from "./Details/Employee";

export const Profile = (props) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Profile");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/profile"
        to="/profile/view"
      />
      <ContentRoute from="/profile/view" component={ApprovedForm} />
    </Switch>
  );
};

