import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AddressCard from "./Address/AddressCard";
import AddButton from "./Address/AddButton";
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Details(props) {
  const classes = useStyles();
  const [addressData, setAddressData] = React.useState(props.values.address)
  const [errorMEssage, setErrorMessage] = React.useState('')

  const onClickPrevious = () => {
    props.previousStep()
}

const onClickNext = () => {
  if (addressData && addressData.length && addressData.length > 0){
    props.nextStep()
  } else {
    setErrorMessage(<Alert severity="error">Add atleast one address</Alert>);
  }
    
  }


  const onAddAddress = (data) => {
    var list = [...addressData, data]
    console.log(list);
    const value = props.values
    value['address'] = list
    props.updataData(undefined)
    props.updataData(value)
  }

  function updateAddressData  (data, index)  {
    var list = [...addressData]
    list[index] = data
    const value = props.values
    value['address'] = list
    console.log(value);
    props.updataData(undefined)
    props.updataData(value)
  }

  const onDeleteAddress = (index) => {
    var list = addressData
    list.splice(index, 1)
    const value = props.values
    value['address'] = list
    console.log(value);
    setAddressData([...list])
    props.updataData(value)
  }


  return (
        <div className={classes.root}>
        <form>
        <h5 style={{marginTop:"2%", marginBottom:"2%"}} >Clinic Address</h5>
        {/* <div>
        {
            addressData && addressData.map((item) => 
            <div>
                <AddressCard data={item}/>
            </div>
            )
        }
        </div> */}
        <AddButton setErrorMessage={setErrorMessage} pharmacyList={props.pharmacyList} laboratoryList={props.laboratoryList} onAddAddress={onAddAddress} />
        {addressData && <AddressCard pharmacyList={props.pharmacyList} laboratoryList={props.laboratoryList} updateAddressData={updateAddressData} onDeleteAddress={onDeleteAddress} addressData={addressData} values={props.values}  />}
        {errorMEssage}
        <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item xs={3} container justify={"flex-start"} >
        {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
        <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
        </Grid>
        <Grid item xs={3}>
        </Grid>

        <Grid item xs={3}>
        </Grid>
        <Grid item xs={3} container justify={"flex-end"} >
        {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.nextStep} >Next</Button> */}
        <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickNext} >Next</Button>
        </Grid>
        </Grid> 
      </form>
            
    </div>
  );
}
