import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import { TaxCatalog, ServiceTag, Commission } from "./index"
export const PaymentManagement = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Payment Management");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/paymentManagement"
        to="/paymentManagement/tax"
      />
      <ContentRoute from="/paymentManagement/tax" component={TaxCatalog} />
      <ContentRoute from="/paymentManagement/service" component={ServiceTag} />
      <ContentRoute from="/paymentManagement/commission" component={Commission} />
    </Switch>
  );
};
