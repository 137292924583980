
import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Cards from "./Cards";
import { recentTransactionLoadData } from "../../../../../../modules/Api/PaymentAdmin/Payment/CurrentSalesApi";
import Spinner from "../../../../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../../../../modules/Toast/SuccessSnackBar";

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    height:'590px',
    marginBottom: 15,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const divStyle = {
  overflowY: 'scroll',
  height:'500px',
  position:'relative'
};

export default function SimpleCard(props) {
  const {type} = props
  const [recentData, setRecentData] = React.useState(undefined)
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)

  const loadData = () => {
    recentTransactionLoadData(type)
    .then(items => {
      setLoading(false)
      setRecentData(items.data)
      if (items && items.data && items.data.message) {
        SuccessToast(items.data.message)
      }
      
    })
    .catch(err => {
      ErrorToast(err)
      setLoading(false)
  })
}
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  React.useEffect(() => {
    if (mounted) {
      loadData()
      setMounted(false)
    }
  }, [loadData, setLoading])

  if (loading) {
    return (
      <Spinner />
    )
  }

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Recent Transactions
        </Typography>
        {recentData && <div style={divStyle}><Cards recentData={recentData} /></div>}
      </CardContent>
    </Card>
  );
}
