import React, { Component } from 'react';
import Details from './PharmacyDetails';
import Address from './PharmacyAddress';
import AdditionalDetails from './PharmacyAdditionalDetails';
// import Details from "./error";


export default class UserForm extends Component {
    constructor(props) {
        super(props);
        
        // this.updateData = this.updateData.bind(this)
    
        this.state = {
            step: 1,
            formData:{
                pharmacy_name: '',
                country_code: '+91',
                mobile_number: '',
                email: '',
                gstin: '',
                dl_number: '',
                dl_issuing_authority: '',
                dl_date_of_issue: '',
                dl_valid_upto: '',
                dl_file: '',
                pincode: '',
                country: '',
                state: '',
                district: '',
                city_village: '',
                street_name: '',
                home_delivery: '0',
                order_amount: 0,
                currency_code: 'INR',
                latitude: '',
                longitude: '',
                pharmacist_name: '',
                course: 'D.Pharm',
                course_name: '',
                pharmacist_reg_number: '',
                issuing_authority: '',
                reg_certificate: '',
                alt_country_code: '+91',
                alt_mobile_number: '',
                reg_date: '',
                reg_valid_upto: '',
                bank_account_number: '',
                bank_account_holder: '',
                bank_name: '',
                bank_city: '',
                bank_ifsc: '',
                bank_account_type: '',
              }
        }
        };

        

    

    // loadData = () => {
    //     this.setState({formData : this.props.data})
    // }
    
    // componentWillMount (){
    //     this.loadData()
        
    //   }

    // next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        })
    }

    updateStep = (no) => {
        const { step } = this.state;
        this.setState({
            step: no
        })
    }

    updateData = (data) => {
        this.setState({formData:data})
    }

    // previous step
    previosStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        })
    }


    render() {

        const { step } = this.state

        switch (step) {
            case 1 :
                return (
                    <Details
                    values={this.state.formData}
                    nextStep={this.nextStep}
                    updateData={this.updateData}
                    />
                )
            case 2 :
                return (
                    <Address
                    values={this.state.formData}
                    nextStep={this.nextStep}
                    previosStep={this.previosStep}
                    updateData={this.updateData}
                    />
                )
            case 3 :
                return (
                    <AdditionalDetails
                    values={this.state.formData}
                    nextStep={this.nextStep}
                    updateData={this.updateData}
                    previosStep={this.previosStep}
                    updateStep={this.updateStep}
                    />
                )
            default:
                return (
                    <Details
                    values={this.state.formData}
                    nextStep={this.nextStep}
                    previosStep={this.previosStep}
                    updateData={this.updateData}
                    />
                )
        }
    }
}