import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSubheader } from "../../../../_metronic/layout";
import { CurrentSales, Payout } from "./index"
export const PaymentLaboratory = () => {
  // const type = "MED"
  const type = "LAB"
  const suhbeader = useSubheader();
  suhbeader.setTitle("Laboratory");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/paymentAdmin/paymentLaboratory"
        to="/paymentAdmin/paymentLaboratory/currentSales"
      />
      <Route path="/paymentAdmin/paymentLaboratory/currentSales/" render={() => <CurrentSales type={type} />} /> 
      <Route path="/paymentAdmin/paymentLaboratory/payouts/" render={() => <Payout type={type} />} /> 
    </Switch>
  );
};
