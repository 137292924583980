import http from "../../../Utils/http";

export function loadTableData() {
    return http.get(`api/guest/category`);
  }

  export function loadNoPageData() {
    const pharams = 'paginate=0'
    return http.get(`api/guest/category/?${pharams}`);
  }

  export function pageLoadData(no) {
    const pharams = 'page='+no
    return http.get(`api/guest/category/?${pharams}`);
  }

  export function AddData(data) {
    return http.post(`api/admin/category`, data);
  }

  export function deleteData(id) {
    return http.post(`api/admin/category/${id}`, {
      _method:'DELETE',
      });
  }

  export function editData(data, id) {
    return http.post(`api/admin/category/${id}`, data);
  }