import React from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MultiSelect from 'react-select';
import MenuItem from '@material-ui/core/MenuItem';
import { countryCodeData as options } from '../../../Utils/countyCodeMapping';
import { genderData } from '../../../Utils/Gender';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import makeAnimated from 'react-select/animated';
import {getCode} from '../../../Utils/googleApi';
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import { editBbasicinfo } from "../../../modules/Api/Doctor/NewDoctorApi";
import { handleNumber, findCodeFromValue } from '../../../Utils/CountryMobile';
import { DatePickerField } from "./../../../../_metronic/_partials/controls/forms/DatePickerField";

const animatedComponents = makeAnimated();
    


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function Details (props) {
  const theme = useTheme();
    const classes = useStyles();
    const [currentCountry, setCurrentCountry] = React.useState()
    const [altCurrentCountry, setAltCurrentCountry] = React.useState()
    let view_only = false
    if (props.type == 'view') {
      view_only = true
    } else {
      view_only = false
    }
    const intValues = props.profileData
    if (intValues.alt_country_code == null) {
      intValues['alt_country_code'] = '+91'
    }

    

    // const specList = []
    // if(intValues.specialization) {
    //   intValues.specialization.map(
    //     (item) => {
    //       if (item.id) {
    //         specList.push(item.id)
    //       }  else {
    //         specList.push(item)
    //       }
    //     }
    //   )}
    // intValues['specialization'] = specList

    const changePincode = (event) => {
      const pincodeVal = event.target.value
      // formik.setFieldValue("pincode", pincodeVal)
      getCode(pincodeVal, addr => {
        if (!addr) {
          return
        }
        if (addr.country){
          formik.setFieldValue("country", addr.country)
        } else {
          formik.setFieldValue("country", '')
        }
        if (addr.state){
          formik.setFieldValue("state", addr.state)
        } else {
          formik.setFieldValue("state", '')
        }
        if (addr.district){
          formik.setFieldValue("district", addr.district)
        } else {
          formik.setFieldValue("district", '')
        }
        if (addr.position.lng){
          formik.setFieldValue("longitude", addr.position.lng)
        }
        if (addr.position.lat){
          formik.setFieldValue("latitude", addr.position.lat)
        }
  
      })
    }

    const onClickNext = (data) => {
      props.nextStep()
      props.updateProfileData(data)
    }

    const editDetails = (localdata, data) => {
      const id = props.data.user.id
      editBbasicinfo(id, data)
        .then(items => {
          onClickNext(localdata)
          // if (items && items.data && items.data.message) {
          //   SuccessToast(items.data.message)
          // }
          
        })
        .catch(err => {
          ErrorToast(err)
      })
    }
    
        

          function getAge(dateString) {
            if(!dateString){
                return 0
            }
            const today = new Date();
            const birthDate = new Date(dateString);
            if (today < birthDate) {
                return 0
            }
            const m = today.getMonth() - birthDate.getMonth();
            let age = today.getFullYear() - birthDate.getFullYear();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }
        const numbRex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    
        const formik = useFormik({
          initialValues: intValues,
          validationSchema: Yup.object({
            first_name: Yup.string()
              .required("First name is Required!")
              .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field only accepts alphabets')
              .nullable(),
            last_name: Yup.string()
             .required("Last Name is Required!")
             .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field only accepts alphabets')
             .nullable(),
             middle_name: Yup.string()
             .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field only accepts alphabets')
             .nullable(),
            gender: Yup.string()
              .required("Gender is Required!")
              .nullable(),
            date_of_birth: Yup.date('Must be a valid date')
            .nullable()
            .max(new Date(), 'Date of birth cannot be a future date' )
            .required('The Date of birth field is required.'),
            qualification: Yup.string()
              .required("Qualification is Required!")
              .nullable(),
            specialization: Yup.array()
              .required('Specialization is Required!' ),
            years_of_experience: Yup.string()
             .required("Years of experience is Required!")
             .nullable(),
            // clinic_name: Yup.string()
            //   .required("Clinic name is Required!")
            //   .nullable(),
            email: Yup.string()
              .email("Invalid email format")
              .required("Email is Required!")
              .nullable(),
              alt_mobile_number: Yup.string()
              .test('mobile-num', 'Invalid mobile number', function (value) {
                  if (!value) return true;
                  const currentCountrycode = altCurrentCountry && altCurrentCountry.code ? altCurrentCountry.code : null
                  return handleNumber(value + "", currentCountrycode || "IN")
              })
              .nullable()
              ,
              mobile_number: Yup.string()
              .required('Mobile number is required')
              .nullable()
              .test('mobile-num', 'Invalid mobile number', function (value) {
                  if (!value) return false;
                  const currentCountrycode = currentCountry && currentCountry.code ? currentCountry.code : null
                  return handleNumber(value + "", currentCountrycode || "IN")
              })
              ,
              pincode: Yup.string()
              .matches(/^[0-9]*$/, 'Invalid Pincode')
              .required("Pincode is Required!")
              // .test('len', 'Invalid Pincode', val => val.length === 6)
              .nullable(),
            country: Yup.string()
              .required("Country is Required!")
              .nullable(),
            state: Yup.string()
              .required("State is Required!")
              .nullable(),
            district: Yup.string()
              .required("District is Required!")
              .nullable(),
            city_village: Yup.string()
              .required("City/Village/Society is Required!")
              .nullable(),
            street_name: Yup.string()
              .required("Area/Street/Sector is Required!")
              .nullable(),
          }),
          onSubmit: values => {
            const data = {...values}
            if (values.specialization && values.specialization.length > 0) {
              data['specialization'] = values.specialization.map(item => (item.id))
            }
          editDetails(values, data)
          }
        });
            
        
    
          
        
    
      return (
        <div className={classes.root}>
          <form onSubmit={formik.handleSubmit}>
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Personal Information</h6>
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={4} >
                <TextField
                disabled
                fullWidth
                label="Title"
                type="text"
                value="Dr"
                variant="outlined"
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                disabled={view_only}
                label="First Name"
                name="first_name"
                type="text"
                value={formik.values.first_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.first_name && formik.touched.first_name && (
                <p style={{color:"red"}}>{formik.errors.first_name}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                disabled={view_only}
                label="Middle Name"
                name="middle_name"
                type="text"
                value={formik.values.middle_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.middle_name && formik.touched.middle_name && (
                <p style={{color:"red"}}>{formik.errors.middle_name}</p>
                 )}
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                disabled={view_only}
                label="Last Name"
                name="last_name"
                type="text"
                value={formik.values.last_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.last_name && formik.touched.last_name && (
                <p style={{color:"red"}}>{formik.errors.last_name}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <FormControl variant="outlined" fullWidth>
            <InputLabel fullWidth >Gender<span>&nbsp;*</span></InputLabel>
            <Select
            required
            fullWidth
            disabled={view_only}
              value={formik.values.gender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Gender"
              name="gender"
            >
              {
                    genderData.map(
                      (item) => (
                      <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                      )
                    )
                  }
            </Select>
          </FormControl>
          {formik.errors.gender && formik.touched.gender && (
                  <p style={{color:"red"}}>{formik.errors.gender}</p>
                   )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            {/* <TextField
              fullWidth
              required
              disabled={view_only}
                label="Date Of Birth"
                name="date_of_birth"
                type="date"
                value={formik.values.date_of_birth}
                variant="outlined"
                onChange={e => {
                  if (new Date("9999-12-31") >= new Date(e.target.value)) {
                    formik.setFieldValue('age', getAge(e.target.value))
                    formik.handleChange(e);
                  }
                }}
                InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={formik.handleBlur}
                  />
                  {formik.errors.date_of_birth && formik.touched.date_of_birth && (
                  <p style={{color:"red"}}>{formik.errors.date_of_birth}</p>
                   )} */}

                   <DatePickerField 
                                mandatory
                                disabled={view_only}
                                name="date_of_birth"
                                label="Date Of Birth"
                                placeholder="Date Of Birth"
                                onChange={val => {
                                  formik.setFieldValue('date_of_birth', String(val))
                                  formik.setFieldValue('age', getAge(val))
                                }}
                                onBlur={e => {
                                  formik.handleBlur(e);
                                }}
                                value={formik.values.date_of_birth}
                                touched={formik.touched.date_of_birth}
                                errors={formik.errors.date_of_birth}
                                 />
            </Grid>
          </Grid>
          
          <Grid container spacing={3}style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={4}>
                <TextField
                disabled
                fullWidth
                label="Age"
                name="age"
                type="text"
                value={formik.values.age}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                />
                {formik.errors.age && formik.touched.age && (
                <p style={{color:"red"}}>{formik.errors.age}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                disabled={view_only}
                label="Qualification"
                name="qualification"
                type="text"
                value={formik.values.qualification}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.qualification && formik.touched.qualification && (
                <p style={{color:"red"}}>{formik.errors.qualification}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                label="Email ID"
                name="email"
                type="text"
                value={formik.values.email}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email && (
                <p style={{color:"red"}}>{formik.errors.email}</p>
                 )}
            </Grid>
          </Grid>


          <Grid container spacing={3}style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={4}>
            {/* <FormControl variant="outlined" fullWidth>
            <InputLabel fullWidth >Specialization<span>&nbsp;*</span></InputLabel>
            <Select
            required
            fullWidth
            multiple
              value={formik.values.specialization}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="specialization"
              label="specialization"
            >
              {
                    formik.values.specialization_list.map(
                      (item) => (
                      <MenuItem id={item.id} value={item.id}>{item.name}</MenuItem>
                      )
                    )
                  }
            </Select>
          </FormControl> */}
          <InputLabel fullWidth >Specialization<span>&nbsp;*</span></InputLabel>
                <MultiSelect
                disabled={view_only}
                  defaultValue={(formik.values.specialization && formik.values.specialization.map(item => ({ label: item.name, value: item.id }))) || []}
                  isMulti
                  onChange={value => {
                    value && formik.setFieldValue('specialization', value.map((item) => ({id: item.value, name: item.label})))
                    !value && formik.setFieldValue('specialization', [])
                  }}
                  options={formik.values.specialization_list && formik.values.specialization_list.map(item => ({ label: item.name, value: item.id }))}
                />
                {formik.errors.specialization && formik.touched.specialization && (
                <p style={{color:"red"}}>{formik.errors.specialization}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                disabled={view_only}
                style={{marginTop:"5%"}}
                label="Years Of Experience"
                name="years_of_experience"
                type="number"
                value={formik.values.years_of_experience}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.years_of_experience && formik.touched.years_of_experience && (
                <p style={{color:"red"}}>{formik.errors.years_of_experience}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            {/* <TextField
                fullWidth
                disabled={view_only}
                style={{marginTop:"5%"}}
                label="Clinic Name"
                name="clinic_name"
                type="text"
                value={formik.values.clinic_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.clinic_name && formik.touched.clinic_name && (
                <p style={{color:"red"}}>{formik.errors.clinic_name}</p>
                 )} */}
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={4}>
              
            <FormControl 
                style={{marginRight:"5%", marginTop:"3%", marginBottom:"2%", width:"30%"}}  variant="outlined">
            <FormLabel component="legend"></FormLabel>
            <Select
            disabled
                name="country_code"
                type="text"
                value={formik.values.country_code}
                variant="outlined"
                onChange={e => {
                  formik.setFieldValue("country_code", e.target.value)
                  setCurrentCountry(findCodeFromValue(e.target.value))
                }}
                >
                  {
                    options.map(
                      (item) => (
                      <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                      )
                    )
                  }
                </Select>
                </FormControl>
                <FormControl style={{width:"65%"}}  variant="outlined">
                <FormLabel component="legend">Mobile Number<span>&nbsp;*</span></FormLabel>
                {/* <InputLabel htmlFor="outlined-adornment-amount">Mobile Number<span>&nbsp;*</span></InputLabel> */}
                <OutlinedInput
                disabled
                    name="mobile_number"
                    type="number"
                    value={parseInt(formik.values.mobile_number)}
                    onChange={formik.handleChange}
                    startAdornment={<InputAdornment position="start">{formik.values.country_code}</InputAdornment>}
                    // labelWidth={100}
                    onBlur={formik.handleBlur}
                />
                {formik.errors.mobile_number && formik.touched.mobile_number && (
                <p style={{color:"red"}}>{formik.errors.mobile_number}</p>
                 )}
                </FormControl>
            
            
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <Select
                required
                disabled={view_only}
                style={{marginRight:"5%", marginBottom:"2%", width:"30%", marginTop:"5%"}}
                name="alt_country_code"
                type="text"
                value={formik.values.alt_country_code}
                variant="outlined"
                onChange={e => {
                  formik.setFieldValue("alt_country_code", e.target.value)
                  setAltCurrentCountry(findCodeFromValue(e.target.value))
                }}
                >
                  {
                    options.map(
                      (item) => (
                      <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                      )
                    )
                  }
                </Select>
                <FormControl style={{width:"65%", marginTop:"5%"}}  variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Alternative Number</InputLabel>
                <OutlinedInput
              fullWidth
              disabled={view_only}
                    name="alt_mobile_number"
                    type="number"
                    value={parseInt(formik.values.alt_mobile_number)}
                    onChange={formik.handleChange}
                    startAdornment={<InputAdornment position="start">{formik.values.alt_country_code}</InputAdornment>}
                    labelWidth={130}
                    onBlur={formik.handleBlur}
                />
                {formik.errors.alt_mobile_number && formik.touched.alt_mobile_number && (
                <p style={{color:"red"}}>{formik.errors.alt_mobile_number}</p>
                 )}
                </FormControl>
            </Grid>
          </Grid>
          <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Home Address</h6>
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
            <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth  variant="outlined">
                <FormLabel component="legend">Pincode<span>&nbsp;*</span></FormLabel>
            <TextField
                fullWidth
                required
                disabled={view_only}
                // label="Pincode"
                placeholder="Pincode"
                name="pincode"
                value={formik.values.pincode}
                variant="outlined"
                onBlur={(e) => {
                  changePincode(e)
                  formik.handleBlur(e)
                }}
                onChange={formik.handleChange}
                />
                {formik.errors.pincode && formik.touched.pincode && (
                <p style={{color:"red"}}>{formik.errors.pincode}</p>
                 )}
                 
            </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                style={{marginTop:"5%"}}
                required
                disabled={view_only}
                label="Country"
                name="country"
                type="text"
                value={formik.values.country}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                />
                {formik.errors.country && formik.touched.country && (
                <p style={{color:"red"}}>{formik.errors.country}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                disabled={view_only}
                style={{marginTop:"5%"}}
                label="State"
                name="state"
                type="text"
                value={formik.values.state}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                />
                {formik.errors.state && formik.touched.state && (
                <p style={{color:"red"}}>{formik.errors.state}</p>
                 )}
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
            <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth  variant="outlined">
                <FormLabel component="legend">District<span>&nbsp;*</span></FormLabel>
            <TextField
                fullWidth
                required
                disabled={view_only}
                // label="District"
                placeholder = "District"
                name="district"
                type="text"
                value={formik.values.district}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                />
                {formik.errors.district && formik.touched.district && (
                <p style={{color:"red"}}>{formik.errors.district}</p>
                 )}
                 
            </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                disabled={view_only}
                style={{marginTop:"5%"}}
                label="City/Village/Society"
                name="city_village"
                type="text"
                value={formik.values.city_village}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                
                />
                {formik.errors.city_village && formik.touched.city_village && (
                <p style={{color:"red"}}>{formik.errors.city_village}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                disabled={view_only}
                style={{marginTop:"5%"}}
                label="Area/Street/Sector"
                name="street_name"
                type="text"
                value={formik.values.street_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                  {formik.errors.street_name && formik.touched.street_name && (
                  <p style={{color:"red"}}>{formik.errors.street_name}</p>
                   )}
            </Grid>
          </Grid>
    
          
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} > 
            <Grid item xs={6} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            </Grid>
            {/* <Grid item xs={3}>
            </Grid>
            
            <Grid item xs={3}>
            </Grid> */}
            {!view_only && <Grid item xs={6} container justify={"flex-end"} >
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" type="submit" >Next</Button>
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickNext} >Next</Button> */}
                
            </Grid>}
            {view_only && <Grid item xs={6} container justify={"flex-end"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" type="submit" >Next</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.nextStep} >Next</Button>
                
            </Grid>}
          </Grid> 
          </form>
                
        </div>
      );



}