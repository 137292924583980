import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {getCode} from '../../../Utils/googleApi';
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import { editAddressinfo } from "../../../modules/Api/Pharmacy/NewPharmacyApi";
import {CurrencySelect} from '../../../Utils/Components/CurrencySelect';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Details(props) {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = React.useState('')

  const changePincode = (event) => {
    const pincodeVal = event.target.value
    // formik.setFieldValue("pincode", pincodeVal)
    getCode(pincodeVal, addr => {
      console.log(addr);
      if (!addr) {
        return
      }
      if (addr.country){
        formik.setFieldValue("country", addr.country)
      } else {
        formik.setFieldValue("country", '')
      }
      if (addr.state){
        formik.setFieldValue("state", addr.state)
      } else {
        formik.setFieldValue("state", '')
      }
      if (addr.district){
        formik.setFieldValue("district", addr.district)
      } else {
        formik.setFieldValue("district", '')
      }
      if (addr.position.lng){
        formik.setFieldValue("longitude", addr.position.lng)
      }
      if (addr.position.lat){
        formik.setFieldValue("latitude", addr.position.lat)
      }

    })
  }


  const initValues = props.values
  console.log(initValues);

    const onClickNext = (data) => {
      props.updateData(data)
        props.nextStep()
    }
    const onClickPrevious = () => {
        props.previosStep()
    }
    
    const editDetails = (data, localData) => {
      const id = props.values.id
      editAddressinfo(id, data)
        .then(items => {
          onClickNext(localData)
          if (items && items.data && items.data.message) {
            SuccessToast(items.data.message)
          }
          
        })
        .catch(err => {
          ErrorToast(err)
      })
    }
    
    const numberandfullRex = /^[1-9]\d*(\.\d+)?$/

    const formik = useFormik({
      initialValues: initValues,
      validationSchema: Yup.object({
        pincode: Yup.string()
        .matches(/^[0-9]*$/, 'Invalid Pincode')
        .required("Pincode is Required!")
        // .test('len', 'Invalid Pincode', val => val.length === 6)
        .nullable(),
        country: Yup.string()
         .required("Country is Required!"),
        state: Yup.string()
          .required("State is Required!"),
        district: Yup.string()
          .required("District is Required!"),
        city_village: Yup.string()
          .required("City/Village/Society is Required!"),
        street_name: Yup.string()
         .required("Area/Street/Sector is required!"),
         home_delivery : Yup.string(),
         order_amount: Yup.string().when('home_delivery', {
           is: "1",
           then: Yup.string()
           .required("Amount is Required!")
           .matches(numberandfullRex, 'Invalid Data!')
           .nullable(),
           }),
      }),
      onSubmit: values => {
        console.log(values);
        onClickNext(values)
      }
    });
    

    let valueField = <p></p>
    if (formik.values.home_delivery === "1") {
        valueField = <div>
          <CurrencySelect
              value={formik.values.order_amount}
              onChange={value => {
                formik.setFieldValue("order_amount", value)
              }}
              onBlur={formik.handleBlur}
              label='Minimum value of home delivery'
              name="order_amount"
              errors={formik.errors.order_amount}
                />
        
            </div>
    } else if (formik.values.home_delivery === "0") {
      formik.values.order_amount = 0.00
        valueField = <p></p>
    }

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Address</h6>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item md={4} xs={12}>
        <TextField
            fullWidth
            required
            label="Pincode"
            name="pincode"
            value={formik.values.pincode}
            variant="outlined"
            onBlur={(e) => {
              changePincode(e)
              formik.handleBlur(e)
            }}
            onChange={formik.handleChange}
            />
            {formik.errors.pincode && formik.touched.pincode && (
            <p style={{color:"red"}}>{formik.errors.pincode}</p>
             )}
        </Grid>
        <Grid item md={4} xs={12}>
        <TextField
            fullWidth
            required
            label="Country"
            name="country"
            type="text"
            value={formik.values.country}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.country && formik.touched.country && (
            <p style={{color:"red"}}>{formik.errors.country}</p>
             )}
        </Grid>
        <Grid item md={4} xs={12}>
        <TextField
            fullWidth
            required
            label="State"
            name="state"
            type="text"
            value={formik.values.state}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.state && formik.touched.state && (
            <p style={{color:"red"}}>{formik.errors.state}</p>
             )}
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item md={4} xs={12}>
        <TextField
            fullWidth
            required
            label="District"
            name="district"
            type="text"
            value={formik.values.district}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.district && formik.touched.district && (
            <p style={{color:"red"}}>{formik.errors.district}</p>
             )}
        </Grid>
        <Grid item md={4} xs={12}>
        <TextField
            fullWidth
            required
            label="City/Village/Society"
            name="city_village"
            type="text"
            value={formik.values.city_village}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.city_village && formik.touched.city_village && (
            <p style={{color:"red"}}>{formik.errors.city_village}</p>
             )}
        </Grid>
        <Grid item md={4} xs={12}>
        <TextField
            fullWidth
            required
            label="Area/Street/Sector"
            name="street_name"
            type="text"
            value={formik.values.street_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
              {formik.errors.street_name && formik.touched.street_name && (
              <p style={{color:"red"}}>{formik.errors.street_name}</p>
               )}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
        <FormControl component="fieldset" 
            fullWidth>
            <FormLabel component="legend">Home Delivery</FormLabel>
            <RadioGroup row aria-label="home_delivery" name="home_delivery" value={formik.values.home_delivery} onChange={formik.handleChange}>
                <FormControlLabel value= "1" control={<Radio color="primary"  />} label="Yes" />
                <FormControlLabel value= "0" control={<Radio color="primary"  />} label="No" />
            </RadioGroup>
            </FormControl>

            
        </Grid>
        <Grid item md={4} xs={12}>
        {valueField}
        </Grid>
      </Grid>
      
      {errorMessage}

<Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item md={4} xs={12} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
        </Grid>
        <Grid item md={4} xs={12}>
        </Grid>
        
        <Grid item md={4} xs={12}>
        </Grid>
        <Grid item md={4} xs={12} container justify={"flex-end"} >
            <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Next</Button>
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.nextStep} >Next</Button> */}
            </Grid>
      </Grid> 
      </form>
            
    </div>
  );
}
