import http from "../../../Utils/http";

export function loadTableData() {
    const pharams = "filter[approved]=0"
    return http.get(`api/administrator/list/doctor/?${pharams}`)
  }

  export function pageLoadData(no) {
    const pharams = 'page='+no+"&filter[approved]=0"
    return http.get(`api/administrator/list/doctor/?${pharams}`)
  }

  export function searchLoadData(text) {
    const pharams = "filter[approved]=0&filter[name]="+text
    return http.get(`api/administrator/list/doctor/?${pharams}`)
  }

  export function loadBasicinfo(id, data) {
    return http.get(`api/admin/doctor/profile/${id}` )
  }

  export function editBbasicinfo(id, data) {
    return http.post(`api/admin/doctor/profile/${id}`, data )
  }

  export function loadAddressinfo(id, data) {
    return http.get(`api/admin/doctor/address/${id}` )
  }

  export function editAddressinfo(id, data) {
    return http.post(`api/admin/doctor/address/${id}`, data )
  }

  export function editBankinfo(id, data) {
    return http.post(`api/admin/doctor/bankdetails/${id}`, data )
  }

  export function loadCareerinfo(id, data) {
    return http.get(`api/admin/doctor/additionalinformation/${id}` )
  }

  export function editCareerinfo(id, data) {
    return http.post(`api/admin/doctor/additionalinformation/${id}`, data )
  }

  export function confirmClick(id, comment) {
    const reqData = {
      user_id : id,
      action : "APPROVE",
      user_type : "DOCTOR",
      comment : comment ? comment : ''
    }
    return http.post(`api/admin/action/approveorreject`, reqData )
  }

  export function rejectClick(id, comment) {
    const reqData = {
      user_id : id,
      action : "REJECT",
      user_type : "DOCTOR",
      comment : comment ? comment : ''
    }
    return http.post(`api/admin/action/approveorreject`, reqData )
  }
