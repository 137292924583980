import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { HistoryForm } from "./HistoryForm";
class Example extends React.Component {
    constructor(props, context) {
      super(props, context);
  
      this.state = {
        show: false,
      };

      this.divStyle = {
        overflowY: 'scroll',
        height:'400px',
        position:'relative'
      };
  
      this.handleShow = () => {
        this.setState({ show: true });
      };
  
      this.handleHide = () => {
        this.setState({ show: false });
      };
    }

    
    
  
    render() {
      return (
        <>
        <Button style={{margin:'3px', width:"160px"}} type='submit' variant="primary"
        onClick={this.handleShow}
         >
           View History
         </Button>
  
          <Modal
            show={this.state.show}
            onHide={this.handleHide}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                History Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={this.divStyle}>
                <HistoryForm type={this.props.type} row={this.props.row} handleHide={this.handleHide} />
              </div>
            </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleHide}>
              Close
            </Button>
          </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
  
 export default Example;
  