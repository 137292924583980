import React, {useEffect} from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { countryCodeData as options } from '../../../Utils/countyCodeMapping';
import { genderData } from '../../../Utils/Gender';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import makeAnimated from 'react-select/animated';
import {getCode} from '../../../Utils/googleApi';
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import Spinner from "../../../Utils/Components/CustomSpinner";
import { EditBasicInfo } from "../../../modules/Api/Profile/profileApi";
import { uploadProfilePic } from "../../../modules/Api/me";
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import EditEmailModel from "./page-components/EditEmailModel";
import EditMobileModel from "./page-components/EditMobileModel";
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import img from './profile_logo.png'
import FileUpload from "../../../Utils/Components/FileUpload";

const animatedComponents = makeAnimated();
    


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function Details (props) {
  const [loading, setLoading] = React.useState(false)
  const theme = useTheme();
    const classes = useStyles();
    const {values, user_type, me, findMe} = props
    // console.log('values', values);
    const intValues = {
      first_name: values.user.first_name,
      middle_name: values.user.middle_name,
      last_name: values.user.last_name,
      father_first_name: values.father_first_name,
      father_middle_name: values.father_middle_name,
      father_last_name: values.father_last_name,
      gender: values.gender,
      date_of_birth: values.date_of_birth,
      age: values.age,
      country_code: values.user.country_code,
      mobile_number: values.user.mobile_number,
      email : values.user.email,
      date_of_joining: values.date_of_joining,
      role: values.user.role && values.user.role[0],
      resume : values.resume,
      prev_profile_photo : me.profile_photo,
      profile_photo : ''
    }
    
  const onOpenPdf = () => {
    window.open(intValues.resume)
  }

    const onClickNext = () => {
      props.nextStep()
    }

    const editDetails = (data) => {
      uploadProfilePic(data)
        .then(items => {
          setLoading(false)
          findMe()
          onClickNext()
        })
        .catch(err => {
          setLoading(false)
          ErrorToast(err)
      })
    }
    
        

          function getAge(dateString) {
            if(!dateString){
                return 0
            }
            const today = new Date();
            const birthDate = new Date(dateString);
            if (today < birthDate) {
                return 0
            }
            const m = today.getMonth() - birthDate.getMonth();
            let age = today.getFullYear() - birthDate.getFullYear();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }
        const numbRex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

        const IMAGE_FILE_SIZE = 1024 * 1024 * 2;
        const SUPPORTED_IMAGE_FORMATS = [
          "image/jpg",
          "image/jpeg",
          "image/png",
        ];
    
        const formik = useFormik({
          initialValues: intValues,
          validationSchema: Yup.object({
            prev_profile_photo: Yup.string()
                .nullable(),
            profile_photo: Yup.mixed()
                .nullable()
                    .test(
                        "required",
                        "Profile picture is required!",
                        function (value) {
                            const { prev_profile_photo } = this.parent;
                            if (value || prev_profile_photo) return true;
                            else return false;
                        })
                  .test(
                    "fileSize",
                    "Image file size should not be greater than 2mb!",
                    function (value) {
                        if (!value) return true;
                        return value.size <= IMAGE_FILE_SIZE
                    })
                    .test(
                    "fileFormat",
                    "Image format should be jpg, jpeg, png!",
                    function (value) {
                        if (!value) return true;
                        return SUPPORTED_IMAGE_FORMATS.includes(value.type)
                    }),
          }),
          onSubmit: values => {
            setLoading(true)
            if (values.profile_photo) {
              let formData = new FormData();
              formData.set('profile_photo', values.profile_photo)
              editDetails(formData)
            } else {
              onClickNext()
            }
          }
        });
            
        
        if (loading) {
          return (
            <Spinner />
          )
        }
          
        
    
      return (
        <div className={classes.root}>
          <form onSubmit={formik.handleSubmit}>
      <h5 style={{marginTop:"2%", marginBottom:"2%"}} >Personal Information</h5>
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Employee's Name</h6>
        <div className="ml-auto" style={{ border: "1px solid #ccc", overflow: "hidden", width: "120px", fontSize: "13px", marginRight: "20px" }}>

        <img src={formik.values.prev_profile_photo || img}
            width="120"
            style={{ merginTop: "10px", border: "1px solid #ccc" }}
            height="120" />

        {<FileUpload
            labelStyle={{ border: "none" }}
            errors={formik.errors.profile_photo}
            name="profile_photo"
            required
            onChange={value => {
                formik.setFieldValue("profile_photo", value)
            }}
            onBlur={formik.setTouched}
        />}
        </div>
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={6}>
            <TextField
                fullWidth
                required
                disabled
                label="First Name"
                name="first_name"
                type="text"
                value={formik.values.first_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.first_name && formik.touched.first_name && (
                <p style={{color:"red"}}>{formik.errors.first_name}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
            <TextField
                fullWidth
                disabled
                label="Middle Name"
                name="middle_name"
                type="text"
                value={formik.values.middle_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.middle_name && formik.touched.middle_name && (
                <p style={{color:"red"}}>{formik.errors.middle_name}</p>
                 )}
            </Grid>
          </Grid>
          
      
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={6}>
            <TextField
                fullWidth
                required
                disabled
                label="Last Name"
                name="last_name"
                type="text"
                value={formik.values.last_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.last_name && formik.touched.last_name && (
                <p style={{color:"red"}}>{formik.errors.last_name}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
            <TextField
              fullWidth
              required
              disabled
                label="Date Of Joining"
                name="date_of_joining"
                type="date"
                value={formik.values.date_of_joining}
                variant="outlined"
                onChange={formik.handleChange}
                InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={formik.handleBlur}
                  />
                  {formik.errors.date_of_joining && formik.touched.date_of_joining && (
                  <p style={{color:"red"}}>{formik.errors.date_of_joining}</p>
                   )}
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              fullWidth
              required
              disabled
                label="Date Of Birth"
                name="date_of_birth"
                type="date"
                value={formik.values.date_of_birth}
                variant="outlined"
                onChange={e => {
                  formik.setFieldValue('age', getAge(e.target.value))
                  formik.handleChange(e);
                }}
                InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={formik.handleBlur}
                  />
                  {formik.errors.date_of_birth && formik.touched.date_of_birth && (
                  <p style={{color:"red"}}>{formik.errors.date_of_birth}</p>
                   )}
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <TextField
                disabled
                fullWidth
                disabled
                label="Age"
                name="age"
                type="text"
                value={formik.values.age}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.age && formik.touched.age && (
                <p style={{color:"red"}}>{formik.errors.age}</p>
                 )}
            
            </Grid>
          </Grid>
          
          <Grid container spacing={3}style={{marginTop:"2%", marginBottom:"2%"}}>
          <Grid item xs={12} md={6} lg={6}>
            <FormControl variant="outlined" fullWidth>
            <InputLabel fullWidth >Gender</InputLabel>
            <Select
            required
            fullWidth
            disabled
              value={formik.values.gender}
              onChange={formik.handleChange}
              label="Gender"
              name="gender"
            >
              {
                    genderData.map(
                      (item) => (
                      <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                      )
                    )
                  }
            </Select>
                {formik.errors.gender && formik.touched.gender && (
                <p style={{color:"red"}}>{formik.errors.gender}</p>
                 )}
          </FormControl>
          </Grid>
            <Grid item xs={12} md={6} lg={6}>
            <TextField
                fullWidth
                disabled
                label="Role"
                type="text"
                value="Health Associate"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
            </Grid>
          </Grid>

          <Grid container spacing={3}style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={6}>
            <Select
                disabled
                style={{marginRight:"5%", width:"30%"}}
                name="country_code"
                type="text"
                value={formik.values.country_code}
                variant="outlined"
                onChange={formik.handleChange}
                >
                  {
                    options.map(
                      (item) => (
                      <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                      )
                    )
                  }
                </Select>
                <FormControl style={{width:"65%"}}  variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Mobile Number</InputLabel>
                <OutlinedInput
                required
                disabled
                    name="mobile_number"
                    type="number"
                    value={parseInt(formik.values.mobile_number)}
                    onChange={formik.handleChange}
                    startAdornment={<InputAdornment position="start">{formik.values.country_code}</InputAdornment>}
                    labelWidth={100}
                    onBlur={formik.handleBlur}
                />
                {formik.errors.mobile_number && formik.touched.mobile_number && (
                <p style={{color:"red"}}>{formik.errors.mobile_number}</p>
                 )}
                </FormControl>
                <div className="p-2 col-example text-right">
                   <EditMobileModel values={formik.values}  />
                </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
            <TextField
            fullWidth
                disabled
                label="Email ID"
                name="email"
                type="text"
                value={formik.values.email}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email && (
                <p style={{color:"red"}}>{formik.errors.email}</p>
                 )}
                 <div className="p-2 col-example text-right">
                  <EditEmailModel values={formik.values} />
                  </div>
            </Grid>
          </Grid>

          {intValues.resume && <Grid container spacing={3}style={{marginTop:"2%", marginBottom:"2%"}}>
          <Grid item xs={12} md={6} lg={4}>
            <InputLabel htmlFor="outlined-adornment-amount">CV/Resume</InputLabel>
            <a
                className="btn btn-icon btn-light btn-hover-success btn-sm mx-3"
                onClick={onOpenPdf}
            >
                        <VisibilitySharpIcon color='primary' />
            </a>
          </Grid>
          </Grid>}

      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Fathers's Name</h6>
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                disabled
                label="First Name"
                name="father_first_name"
                type="text"
                value={formik.values.father_first_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.father_first_name && formik.touched.father_first_name && (
                <p style={{color:"red"}}>{formik.errors.father_first_name}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                disabled
                label="Middle Name"
                name="father_middle_name"
                type="text"
                value={formik.values.father_middle_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.father_middle_name && formik.touched.father_middle_name && (
                <p style={{color:"red"}}>{formik.errors.father_middle_name}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                disabled
                label="Last Name"
                name="father_last_name"
                type="text"
                value={formik.values.father_last_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.father_last_name && formik.touched.father_last_name && (
                <p style={{color:"red"}}>{formik.errors.father_last_name}</p>
                 )}
            </Grid>
          </Grid>


    
          
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} > 
            <Grid item xs={6} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            </Grid>
            {/* <Grid item xs={3}>
            </Grid>
            
            <Grid item xs={3}>
            </Grid> */}
            <Grid item xs={6} container justify={"flex-end"} >
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" type="submit" >Next</Button>
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickNext} >Next</Button> */}
                
            </Grid>
          </Grid> 
          </form>
                
        </div>
      );



}