import React from "react";
import "./styles.css";

import { Line } from "react-chartjs-2";

export default function App(props) {
  const { graphData } = props;
  const salesList = []
  const EarningsList = []
  const labels = []
  graphData.map((item) => {
    salesList.push(item.total_amount)
    EarningsList.push(item.earnings)
    labels.push(item.month)
  })
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Earnings",
        data: EarningsList,
        fill: false,
        // backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      },
      {
        label: "Sales",
        data: salesList,
        fill: false,
        borderColor: "#742774"
      }
    ]
  };
  
  return (
    <div className="App">
      <Line data={data} />
    </div>
  );
}
