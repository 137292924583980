import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSubheader } from "../../../../_metronic/layout";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import { ListStores, DoctorCommissionList } from "./index"
export const Commission = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Commissions");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/paymentManagement/commission"
        to="/paymentManagement/commission/list"
      />
      <ContentRoute from="/paymentManagement/commission/list" component={ListStores} />
      <ContentRoute from="/paymentManagement/commission/doc-list" component={DoctorCommissionList} />
    </Switch>
  );
};
