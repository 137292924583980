import { useSelector } from "react-redux";

export function GetCurrencyCode () {
    const { user } = useSelector((state) => state.auth);
    var currency_code = user.currency_code
    if (currency_code == null || currency_code == 'null') {
        currency_code = 'INR'
    }

    return (currency_code)

}