import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import StoresTable from "./Table";
import { pageLoadData, LoadData, LoadTaxData } from "../../../modules/Api/PaymentManagement/ServiceTagApi";
import Spinner from "../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";

export const ListStores = (props) => {
  const [data, setData] = React.useState(undefined)
  const [taxData, setTaxData] = React.useState(undefined)
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)

  const loadData = () => {
      LoadData()
      .then(items => {
        setLoading(false)
        setData(items.data)
        // console.log('items.data', items.data);
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
        setLoading(false)
    })
  }

  const loadTaxData = () => {
      LoadTaxData()
      .then(items => {
        setLoading(false)
        setTaxData(items.data)
        // console.log('items.data', items.data);
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
        setLoading(false)
    })
  }

  const updateData = () => {
    setData(undefined)
    LoadData()
      .then(items => {
        setData(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
        setLoading(false)
    })
  }

  const loadPageData = (no) => {
    setData(undefined)
    setLoading(true)
    pageLoadData(no)
      .then(items => {
        setData(items.data)
        setLoading(false)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
        setLoading(false)
    })
  }

  React.useEffect(() => {
    if (mounted) {
      setMounted(false)
      loadData()
      loadTaxData()
    }
  }, [loadData, loadTaxData])

  if (loading) {
    return (
      <Spinner />
    )
  }

  return (
    <Card>
      <CardHeader title="Tax Rule Mapping">
        <CardHeaderToolbar>
          {/* <button
            type="button"
            className="btn btn-primary"
            onClick={() => props.history.push('/paymentManagement/tax/add')}
          >
            Add new tax
          </button> */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {
          taxData && data && <StoresTable loadPageData={loadPageData} data={data} taxData={taxData} updateData={updateData} />
        }
      </CardBody>
    </Card>
  );
};
