import React from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { countryCodeData as options } from '../../../../Utils/countyCodeMapping';
import { genderData } from '../../../../Utils/Gender';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import makeAnimated from 'react-select/animated';
import {getCode} from '../../../../Utils/googleApi';
import {ErrorToast} from "../../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../../modules/Toast/SuccessSnackBar";
import Spinner from "../../../../Utils/Components/CustomSpinner";
import { AddBasicInfo } from "../../../../modules/Api/Auth/RegisterEmployeeApi";
import { handleNumber, findCodeFromValue } from '../../../../Utils/CountryMobile';
import {FileUpload} from "../../../../Utils/Components/FileUpload/FileUpload";
import { DatePickerField } from "./../../../../../_metronic/_partials/controls/forms/DatePickerField";

const animatedComponents = makeAnimated();
    


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FILE_SIZE = 1024 * 1024 * 2;
const SUPPORTED_FORMATS = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export default function Details (props) {
  const [loading, setLoading] = React.useState(false)
  const [currentCountry, setCurrentCountry] = React.useState()
  const theme = useTheme();
    const classes = useStyles();
    const intValues = {
      first_name: '',
      middle_name: '',
      last_name: '',
      father_first_name: '',
      father_middle_name: '',
      father_last_name: '',
      gender: '',
      date_of_birth: '',
      age: '',
      country_code: '+91',
      mobile_number: '',
      email : '',
      date_of_joining: '',
    }


    

    const changePincode = (event) => {
      const pincodeVal = event.target.value
      formik.setFieldValue("pincode", pincodeVal)
      getCode(pincodeVal, addr => {
        if (!addr) {
          return
        }
        if (addr.country){
          formik.setFieldValue("country", addr.country)
        } else {
          formik.setFieldValue("country", '')
        }
        if (addr.state){
          formik.setFieldValue("state", addr.state)
        } else {
          formik.setFieldValue("state", '')
        }
        if (addr.district){
          formik.setFieldValue("district", addr.district)
        } else {
          formik.setFieldValue("district", '')
        }
        if (addr.position.lng){
          formik.setFieldValue("longitude", addr.position.lng)
        }
        if (addr.position.lat){
          formik.setFieldValue("latitude", addr.position.lat)
        }
  
      })
    }

    const onClickNext = (id, data) => {
      props.updateData(id, data)
      props.nextStep()
    }

    const editDetails = (data, localData) => {
      AddBasicInfo(data)
        .then(items => {
          setLoading(false)
          const id = items.data.data_id
          onClickNext(id, localData)
          // if (items && items.data && items.data.message) {
          //   SuccessToast(items.data.message)
          // }
          
        })
        .catch(err => {
          setLoading(false)
          ErrorToast(err)
      })
    }
    
        

          function getAge(dateString) {
            if(!dateString){
                return 0
            }
            const today = new Date();
            const birthDate = new Date(dateString);
            if (today < birthDate) {
                return 0
            }
            const m = today.getMonth() - birthDate.getMonth();
            let age = today.getFullYear() - birthDate.getFullYear();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }
        const numbRex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    
        const formik = useFormik({
          initialValues: intValues,
          validationSchema: Yup.object({
            first_name: Yup.string()
              .required("First name is Required!")
              .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field must be alphabets')
              .nullable(),
            last_name: Yup.string()
             .required("Last Name is Required!")
             .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field must be alphabets')
             .nullable(),
             middle_name: Yup.string()
             .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field must be alphabets')
             .nullable(),
             father_first_name: Yup.string()
               .required("First name is Required!")
               .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field must be alphabets')
               .nullable(),
             father_last_name: Yup.string()
              .required("Last Name is Required!")
              .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field must be alphabets')
              .nullable(),
              father_middle_name: Yup.string()
              .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field must be alphabets')
              .nullable(),
            gender: Yup.string()
              .required("Gender is Required!")
              .nullable(),
              date_of_birth: Yup.date('Must be a valid date')
              .max(new Date(), 'Date of birth cannot be a future date' )
              .required('The Date of birth field is required.')
              .nullable(),
            // date_of_joining: Yup.date('Must be a valid date')
            // .nullable()
            // .max(new Date(), 'Date of Joining cannot be a future date' )
            // .required('The Date of Joining field is required.'),
            email: Yup.string()
              .email("Invalid email format")
              .required("Email is Required!")
              .nullable(),
              mobile_number: Yup.string()
              .required("Mobile number is Required!")
              .nullable()
              .test('mobile-num', 'Invalid mobile number', function (value) {
                  if (!value) return false;
                  const currentCountrycode = currentCountry && currentCountry.code ? currentCountry.code : null
                  return handleNumber(value + "", currentCountrycode || "IN")
              }),
              resume: Yup.mixed()
              .required("CV/Resume is Required!")
                  .test(
                  "fileSize",
                  "CV/Resume file size should not be greater than 2mb",
                  function (value) {
                      if (!value) return true;
                      return value.size <= FILE_SIZE
                  })
                  .test(
                  "fileFormat",
                  "CV/Resume format should be pdf, doc, docx",
                  function (value) {
                      if (!value) return true;
                      return SUPPORTED_FORMATS.includes(value.type)
                  })
          }),
          onSubmit: values => {
            setLoading(true)
            const data = values
            data['mobile_number'] = String(values.mobile_number)
            // console.log('data', data);
            
          const formData = new FormData();
          formData.append("age", values.age)
          formData.append("country_code", values.country_code)
          formData.append("date_of_birth", values.date_of_birth)
          formData.append("email", values.email)
          formData.append("father_first_name", values.father_first_name)
          formData.append("father_last_name", values.father_last_name)
          formData.append("father_middle_name", values.father_middle_name)
          formData.append("first_name", values.first_name)
          formData.append("gender", values.gender)
          formData.append("last_name", values.last_name)
          formData.append("middle_name", values.middle_name)
          formData.append("mobile_number", values.mobile_number)
          if (values.resume) {
            formData.append("resume", values.resume)
          } else {
            formData.append("resume", '')
          }
          editDetails(formData, data)
          }
        });
            
        
        if (loading) {
          return (
            <Spinner />
          )
        }
          
        
    
      return (
        <div className={classes.root}>
          <form onSubmit={formik.handleSubmit}>
      <h5 style={{marginTop:"2%", marginBottom:"2%"}} >Personal Information</h5>
      {/* <h6 style={{marginTop:"2%"}} >Employee's Name</h6> */}
          <Grid container spacing={3} style={{marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                // margin="dense"
                label="First Name"
                name="first_name"
                type="text"
                value={formik.values.first_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.first_name && formik.touched.first_name && (
                <p style={{color:"red"}}>{formik.errors.first_name}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                // margin="dense"
                label="Middle Name"
                name="middle_name"
                type="text"
                value={formik.values.middle_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.middle_name && formik.touched.middle_name && (
                <p style={{color:"red"}}>{formik.errors.middle_name}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                // margin="dense"
                label="Last Name"
                name="last_name"
                type="text"
                value={formik.values.last_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.last_name && formik.touched.last_name && (
                <p style={{color:"red"}}>{formik.errors.last_name}</p>
                 )}
            </Grid>
          </Grid>
          
          <h6 style={{marginTop:"2%"}} >Father's Name</h6>
          <Grid container spacing={3} style={{marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                // margin="dense"
                label="First Name"
                name="father_first_name"
                type="text"
                value={formik.values.father_first_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.father_first_name && formik.touched.father_first_name && (
                <p style={{color:"red"}}>{formik.errors.father_first_name}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                // margin="dense"
                label="Middle Name"
                name="father_middle_name"
                type="text"
                value={formik.values.father_middle_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.father_middle_name && formik.touched.father_middle_name && (
                <p style={{color:"red"}}>{formik.errors.father_middle_name}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                // margin="dense"
                label="Last Name"
                name="father_last_name"
                type="text"
                value={formik.values.father_last_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.father_last_name && formik.touched.father_last_name && (
                <p style={{color:"red"}}>{formik.errors.father_last_name}</p>
                 )}
            </Grid>
          </Grid>
      
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
          <Grid item xs={12} md={6} lg={4}>
            {/* <TextField
              fullWidth
              required
              // margin="dense"
                label="Date Of Birth"
                name="date_of_birth"
                type="date"
                value={formik.values.date_of_birth}
                variant="outlined"
                onChange={e => {
                  if (new Date("9999-12-31") >= new Date(e.target.value)) {
                    formik.setFieldValue('age', getAge(e.target.value))
                    formik.handleChange(e);
                  }
                }}
                InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={formik.handleBlur}
                  />
                  {formik.errors.date_of_birth && formik.touched.date_of_birth && (
                  <p style={{color:"red"}}>{formik.errors.date_of_birth}</p>
                   )} */}

                   <DatePickerField 
                                mandatory
                                name="date_of_birth"
                                label="Date Of Birth"
                                placeholder="Date Of Birth"
                                onChange={val => {
                                  formik.setFieldValue('date_of_birth', String(val))
                                  formik.setFieldValue('age', getAge(val))
                                }}
                                onBlur={e => {
                                  formik.handleBlur(e);
                                }}
                                value={formik.values.date_of_birth}
                                touched={formik.touched.date_of_birth}
                                errors={formik.errors.date_of_birth}
                                 />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <TextField
                disabled
                fullWidth
                // margin="dense"
                label="Age"
                name="age"
                type="text"
                value={formik.values.age}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                />
                {formik.errors.age && formik.touched.age && (
                <p style={{color:"red"}}>{formik.errors.age}</p>
                 )}
            
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
            required
                fullWidth
                // margin="dense"
                label="Email ID"
                name="email"
                type="text"
                value={formik.values.email}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email && (
                <p style={{color:"red"}}>{formik.errors.email}</p>
                 )}
            </Grid>
            {/* <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              required
              // margin="dense"
                label="Date Of Joining"
                name="date_of_joining"
                type="date"
                value={formik.values.date_of_joining}
                variant="outlined"
                onChange={formik.handleChange}
                InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={formik.handleBlur}
                  />
                  {formik.errors.date_of_joining && formik.touched.date_of_joining && (
                  <p style={{color:"red"}}>{formik.errors.date_of_joining}</p>
                   )}
            </Grid> */}
          </Grid>

          <Grid container spacing={3}style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={12} lg={8}>
            <Select
                // margin="dense"
                style={{marginRight:"5%", width:"30%"}}
                name="country_code"
                type="text"
                value={formik.values.country_code}
                variant="outlined"
                onChange={e => {
                  formik.setFieldValue("country_code", e.target.value)
                  setCurrentCountry(findCodeFromValue(e.target.value))
                }}
                >
                  {
                    options.map(
                      (item) => (
                      <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                      )
                    )
                  }
                </Select>
                <FormControl style={{width:"65%"}}  variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Mobile Number<span>&nbsp;*</span></InputLabel>
                <OutlinedInput
                required
                // margin="dense"
                    name="mobile_number"
                    type="number"
                    value={parseInt(formik.values.mobile_number)}
                    onChange={formik.handleChange}
                    startAdornment={<InputAdornment position="start">{formik.values.country_code}</InputAdornment>}
                    labelWidth={100}
                    onBlur={formik.handleBlur}
                />
                {formik.errors.mobile_number && formik.touched.mobile_number && (
                <p style={{color:"red"}}>{formik.errors.mobile_number}</p>
                 )}
                </FormControl>
            
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <FormControl variant="outlined" fullWidth>
            <InputLabel fullWidth >Gender<span>&nbsp;*</span></InputLabel>
            <Select
            required
            fullWidth
            // margin="dense"
              value={formik.values.gender}
              onChange={formik.handleChange}
              label="Gender"
              name="gender"
              onBlur={formik.handleBlur}
            >
              {
                    genderData.map(
                      (item) => (
                      <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                      )
                    )
                  }
            </Select>
                {formik.errors.gender && formik.touched.gender && (
                <p style={{color:"red"}}>{formik.errors.gender}</p>
                 )}
          </FormControl>
            </Grid>
            
          </Grid>

          <Grid container spacing={3}style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={4}>
            <FileUpload
                                mandatory
                label='Upload CV/Resume'
                errors={formik.errors.resume}
                name="resume"
                value={formik.values.resume}
                onChange={value => {
                  formik.setFieldValue("resume", value)
                }}
            />
            </Grid>
          </Grid>
    
          
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} > 
            <Grid item xs={6} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            </Grid>
            {/* <Grid item xs={3}>
            </Grid>
            
            <Grid item xs={3}>
            </Grid> */}
            <Grid item xs={6} container justify={"flex-end"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" type="submit" >Next</Button> */}
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.nextStep} >Next</Button> */}
            <button
            type="submit"
            className="btn btn-primary font-weight-bold"
          >Next</button>
                
            </Grid>
          </Grid> 
          </form>
                
        </div>
      );



}