
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { GetCurrencyCode } from "../../../../../../Utils/GetData";
import getSymbolFromCurrency from 'currency-symbol-map';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    marginBottom: 20,
    backgroundColor: '#c9c9c9',
  },
}));

export default function PaperSheet(props) {
  const { recentData } = props
  const classes = useStyles();
  const currency_code = GetCurrencyCode()
  // console.log('recentData', recentData);

  return (
    <div>
      {recentData.map((item) => (
        <Paper className={classes.root}>
          <div className="row">
                <div className="col-lg-8">
                <Typography variant="h6" component="h3">
                  {item.pharmacy && item.pharmacy.pharmacy_name && item.pharmacy.pharmacy_name}
                  {item.labortory && item.labortory.laboratory_name && item.labortory.laboratory_name}
                  {item.doctor && item.doctor.first_name && item.doctor.first_name} {item.doctor && item.doctor.middle_name && item.doctor.middle_name} {item.doctor && item.doctor.last_name && item.doctor.last_name}
                </Typography>
                </div>
                <div className="col-lg-4">
                <Typography variant="h6" component="h3">
                {getSymbolFromCurrency(currency_code)} {item.total}
                </Typography>
                </div>
            </div>
        
        <Typography component="p">
          {item.created_at}
        </Typography>
      </Paper>
      ))}
    </div>
  );
}
