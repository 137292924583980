import React from 'react'
import { Spinner } from 'react-bootstrap';


export default function CustomSpinner() {
    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
            <Spinner
                style={{ width: "4rem", height: "4rem" }}
                animation="border"
                variant="primary"
            />
        </div>
    )
}

export const SimpleSpinner = props => {
    return <div className="d-flex justify-content-center align-items-center">
        <Spinner
            style={{ width: "4rem", height: "4rem" }}
            animation="border"
            variant="primary"
            {...props}
        />
    </div>
}