// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { AddData } from "../../modules/Api/Medicine/MedicineApi";
import Spinner from "../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../modules/Toast/SuccessSnackBar";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {FileUpload} from "../../Utils/Components/FileUpload/FileUpload";
import {CurrencySelect} from '../../Utils/Components/CurrencySelect';


const FILE_SIZE = 1024 * 1024 * 2;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
];

const numberandfullRex = /^[1-9]\d*(\.\d+)?$/
// Validation schema
const CategoryEditSchema = Yup.object().shape({
    name: Yup.string()
        .required("Medicine Name field is required"),
    medicine_type: Yup.string()
        .required("Medicine Type field is required"),
    drug_type: Yup.string()
        .required("Drug Type field is required"),
    category_id: Yup.string()
        .required("Category field is required"),
    weight: Yup.string()
        .required("Weight field is required")
        .matches(numberandfullRex, 'Invalid Data!')
        .nullable(),
    weight_unit: Yup.string()
        .required("Unit of Weight field is required"),
    qty_per_strip: Yup.string()
        .required("Quantity/Strip field is required")
        .matches(/^[0-9]*$/, 'Invalid Data!')
        .nullable(),
    rate_per_unit: Yup.string()
        .required("Rate/Strip field is required")
        .matches(numberandfullRex, 'Invalid Data!')
        .nullable(),
    price_per_strip: Yup.string()
        .required("Price/Strip field is required")
        .matches(numberandfullRex, 'Invalid Data!')
        .nullable(),
    rx_required: Yup.string()
        .required("RX field is required"),
    composition: Yup.string()
        .required("Composition field is required"),
    manufacturer: Yup.string()
        .required("Manufacturer field is required"),
    image: Yup.mixed()
        .test(
        "fileSize",
        "Image file size should not be greater than 2mb",
        function (value) {
            if (!value) return true;
            return value.size <= FILE_SIZE
        })
        .test(
        "fileFormat",
        "Image format should be jpg, jpeg, png",
        function (value) {
            if (!value) return true;
            return SUPPORTED_FORMATS.includes(value.type)
        })
});

export function StoreEditForm(props) 
{
    const {
        btnRef,
        resetbtnRef,
    } = props

    const options = [{
        id : 1,
        value : 1,
        label : 'one'
    }]

    const initialValues = props.initialValues
    const nullValues = {
        category_id: "",
        composition: "",
        weight: "",
        weight_unit: "",
        name: "",
        manufacturer: "",
        medicine_type: "",
        drug_type: "",
        qty_per_strip: "",
        price_per_strip: "",
        rate_per_unit: "",
        rx_required: "",
        short_desc: "",
        long_desc: "",
        cart_desc: "",
        image: "",
    }
    const [loading, setLoading] = React.useState(false)
    const imgRef= React.createRef();
    if (loading) {
        return (
        <Spinner />
        )
    }
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={CategoryEditSchema}
                onSubmit={(values) => {
                    let formData = new FormData();
                    formData.set('name', values.name)
                    formData.set('image', values.image)
                    formData.set('category_id', values.category_id)
                    formData.set('composition', values.composition)
                    formData.set('weight', values.weight)
                    formData.set('manufacturer', values.manufacturer)
                    formData.set('weight_unit', values.weight_unit)
                    formData.set('medicine_type', values.medicine_type)
                    formData.set('drug_type', values.drug_type)
                    formData.set('qty_per_strip', values.qty_per_strip)
                    formData.set('price_per_strip', values.price_per_strip)
                    formData.set('rate_per_unit', values.rate_per_unit)
                    formData.set('rx_required', values.rx_required)
                    formData.set('short_desc', values.short_desc)
                    formData.set('long_desc', values.long_desc)
                    formData.set('cart_desc', values.cart_desc)
                    formData.set('currency_code', "INR")
                    setLoading(true)
                    AddData(formData)
                        .then(items => {
                            setLoading(false)
                            props.setInitialValues(nullValues)
                            if (items && items.data && items.data.message) {
                            SuccessToast(items.data.message)
                            }
                            
                        })
                        .catch(err => {
                            ErrorToast(err)
                            setLoading(false)
                            props.setInitialValues(values)
                        })
                }}
            >
                {({ handleSubmit, setFieldValue, resetForm, handleChange, handleBlur, touched, values, errors }) => (
                    <>
                        <Form className="form form-label-right">
                            <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
                                <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Medicine Name"
                                    name="name"
                                    type="text"
                                    value={values.name}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    />
                                    {errors.name && touched.name && (
                                    <p style={{color:"red"}}>{errors.name}</p>
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-amount">Medicine type<span>&nbsp;*</span></InputLabel>
                                    <Select
                                    required
                                    label="Medicine type"
                                    name="medicine_type"
                                    type="text"
                                    value={values.medicine_type}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    >
                                        <MenuItem value="Capsules">Capsules</MenuItem>
                                        <MenuItem value="Topical medicines">Topical medicines</MenuItem>
                                        <MenuItem value="Suppositories">Suppositories</MenuItem>
                                        <MenuItem value="Drops">Drops</MenuItem>
                                        <MenuItem value="Inhalers">Inhalers</MenuItem>
                                        <MenuItem value="Injections">Injections</MenuItem>
                                </Select>
                                </FormControl>
                                    {errors.medicine_type && touched.medicine_type && (
                                    <p style={{color:"red"}}>{errors.medicine_type}</p>
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-amount">Drug Type <span>&nbsp;*</span></InputLabel>
                                    <Select
                                    required
                                    label="Drug Type"
                                    name="drug_type"
                                    type="text"
                                    value={values.drug_type}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    >
                                        <MenuItem value="Generic">Generic</MenuItem>
                                        <MenuItem value="Branded">Branded</MenuItem>
                                </Select>
                                </FormControl>
                                    {errors.drug_type && touched.drug_type && (
                                    <p style={{color:"red"}}>{errors.drug_type}</p>
                                    )}
                                </Grid>

                                
                                <Grid item xs={3}>
                                
                                    <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-amount">Category <span>&nbsp;*</span></InputLabel>
                                    <Select
                                    required
                                    label="Category"
                                    name="category_id"
                                    type="text"
                                    value={values.category_id}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    >
                                    {
                                        props.Categorydata.map(
                                        (item) => (
                                        <MenuItem id={item.id} value={item.id}>{item.name}</MenuItem>
                                        )
                                        )
                                    }
                                </Select>
                                </FormControl>
                                    {errors.category_id && touched.category_id && (
                                    <p style={{color:"red"}}>{errors.category_id}</p>
                                    )}
                                </Grid>
                            </Grid>

                            
                            <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
                                <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Qty per strip"
                                    name="qty_per_strip"
                                    type="text"
                                    value={values.qty_per_strip}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    />
                                    {errors.qty_per_strip && touched.qty_per_strip && (
                                    <p style={{color:"red"}}>{errors.qty_per_strip}</p>
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Rate per unit"
                                    name="rate_per_unit"
                                    type="text"
                                    value={values.rate_per_unit}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    />
                                    {errors.rate_per_unit && touched.rate_per_unit && (
                                    <p style={{color:"red"}}>{errors.rate_per_unit}</p>
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Manufacturer"
                                    name="manufacturer"
                                    type="text"
                                    value={values.manufacturer}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    />
                                    {errors.manufacturer && touched.manufacturer && (
                                    <p style={{color:"red"}}>{errors.manufacturer}</p>
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Composition"
                                    name="composition"
                                    type="text"
                                    value={values.composition}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    />
                                    {errors.composition && touched.composition && (
                                    <p style={{color:"red"}}>{errors.composition}</p>
                                    )}
                                </Grid>
                            </Grid>


                            <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
                                <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    required
                                    style={{marginRight:'2%', width:'63%'}}
                                    label="Weight"
                                    name="weight"
                                    type="text"
                                    value={values.weight}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    />
                                    <FormControl 
                                        style={{width:'35%'}} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-amount">Unit of Weight<span>&nbsp;*</span></InputLabel>
                                    <Select
                                    required
                                    label="Unit of Weight"
                                    name="weight_unit"
                                    type="text"
                                    value={values.weight_unit}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputLabelProps={{
                                        shrink: true,
                                      }}
                                    >
                                        <MenuItem value="kg">kg</MenuItem>
                                        <MenuItem value="g">g</MenuItem>
                                        <MenuItem value="mg">mg</MenuItem>
                                        <MenuItem value="mcg">mcg</MenuItem>
                                </Select>
                                </FormControl>
                                        {errors.weight && touched.weight && (
                                        <p style={{color:"red"}}>{errors.weight}</p>
                                        )}
                                        {errors.weight_unit && touched.weight_unit && (
                                        <p style={{color:"red"}}>{errors.weight_unit}</p>
                                        )}
                                </Grid>
                                <Grid item xs={6}>
                                        <CurrencySelect
                                        value={values.price_per_strip}
                                        onChange={value => {
                                        setFieldValue("price_per_strip", value)
                                        }}
                                        onBlur={handleBlur}
                                        label='Price'
                                        name="price_per_strip"
                                        errors={errors.price_per_strip}
                                         />
                                </Grid>
                            </Grid>

                            
<Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
    <Grid item xs={3}>
    <FormControl fullWidth variant="outlined">
    <InputLabel htmlFor="outlined-adornment-amount">RX required<span>&nbsp;*</span></InputLabel>
    <Select
    required
    label="RX required"
    name="rx_required"
    type="text"
    value={values.rx_required}
    variant="outlined"
    onChange={handleChange}
    onBlur={handleBlur}
    >
        <MenuItem value={1}>yes</MenuItem>
        <MenuItem value={0}>No</MenuItem>
</Select>
</FormControl>
        {errors.rx_required && touched.rx_required && (
        <p style={{color:"red"}}>{errors.rx_required}</p>
        )}
    </Grid>
<Grid item xs={3}>
<FileUpload
    // mandatory
    label='Upload Product Image'
    errors={errors.image}
    name="image"
    value={values.image}
    onChange={value => {
    setFieldValue("image", value)
    }}
/>
</Grid>
</Grid>

                            
                            <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
                                <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    label="Product Description"
                                    name="short_desc"
                                    type="text"
                                    value={values.short_desc}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    />
                                    {errors.short_desc && touched.short_desc && (
                                    <p style={{color:"red"}}>{errors.short_desc}</p>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    label="Product Cart Description"
                                    name="cart_desc"
                                    type="text"
                                    value={values.cart_desc}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    />
                                    {errors.cart_desc && touched.cart_desc && (
                                    <p style={{color:"red"}}>{errors.cart_desc}</p>
                                    )}
                                </Grid>
                            </Grid>
                            <button
                                type="submit"
                                style={{ display: "none" }}
                                ref={btnRef}
                                onSubmit={() => handleSubmit()}
                            ></button>
                            <button
                                type="button"
                                style={{ display: "none" }}
                                ref={resetbtnRef}
                                onClick={() => resetForm()}
                            ></button>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
