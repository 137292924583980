// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Card } from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Spinner from "../../../../../../../Utils/Components/CustomSpinner";
import { makeStyles } from '@material-ui/core/styles';
import getSymbolFromCurrency from 'currency-symbol-map'
import { GetCurrencyCode } from "../../../../../../../Utils/GetData";

const useStyles = makeStyles(theme => ({
    label: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '26px',
        letterSpacing: '-0.008em',
  
      },
      content1 : {
        position: 'absolute',
        bottom:0,
        marginBottom: '5px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '26px',
        letterSpacing: '-0.008em',
  
      },
      content2 : {
        color: '#77777A',
        // height: '21px',
        marginTop: '10px',
        marginBottom: '5px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '21px',
      }
    }));


export function ViewCard(props) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false)
    const { data } = props
    // console.log('data', data);

    if (loading) {
        return (
        <Spinner />
        )
    }
    return (
        <div>
            <Card border="dark" >
                <Card.Body>
                    
                <Grid container spacing={3}>
                    <Grid item xs={4}>

                    <Typography className={classes.content2}>
                      {data.pharmacy && data.pharmacy.pharmacy_name && data.pharmacy.pharmacy_name}
                      {data.labortory && data.labortory.laboratory_name && data.labortory.laboratory_name}
                      {data.doctor && data.doctor.first_name && data.doctor.first_name} {data.doctor && data.doctor.middle_name && data.doctor.middle_name} {data.doctor && data.doctor.last_name && data.doctor.last_name}
                    </Typography>
                    <Typography className={classes.content2}>
                    {data.address.street_name}, {data.address.city_village}
                    </Typography>
                    <Typography className={classes.content2}>
                    {data.address.district}, {data.address.state}
                    </Typography>
                    <Typography className={classes.content2}>
                    {data.address.country}, {data.address.pincode}
                    </Typography>
                    <Typography className={classes.content1}>
                      Previous Due: {getSymbolFromCurrency(GetCurrencyCode())} {data.previous_due}
                    </Typography>
                    </Grid>
                    <Grid item xs={4}>

                    <Typography className={classes.content2}>
                      Payout Cycle: {data.cycle}
                    </Typography>

                    <Typography className={classes.content2}>
                      Last Payout On:
                    </Typography>

                    <Typography className={classes.content2}>
                      {data.payout_history_latest  && data.payout_history_latest.created_at && data.payout_history_latest.created_at}
                    </Typography>

                    <Typography className={classes.content2}>
                      Current Payout Period:
                    </Typography>
                    <Typography className={classes.content2}>
                      {data.period}
                    </Typography>
                    <Typography className={classes.content1}>
                      Current Due: {getSymbolFromCurrency(GetCurrencyCode())} {data.current_due}
                    </Typography>
                    </Grid>
                    <Grid item xs={4}>
                    <Typography className={classes.label}>
                      Payout Bank Details
                    </Typography>
                    <Typography className={classes.content2}>
                      Holder Name: {data.bank_account_details.bank_account_holder}
                    </Typography>
                    <Typography className={classes.content2}>
                      Bank Name: {data.bank_account_details.bank_name}
                    </Typography>
                    <Typography className={classes.content2}>
                      A/C No: {data.bank_account_details.bank_account_number}
                    </Typography>
                    <Typography className={classes.content2}>
                      Branch: {data.bank_account_details.bank_city}
                    </Typography>
                    <Typography className={classes.content2}>
                      IFSC: {data.bank_account_details.bank_ifsc}
                    </Typography>
                    </Grid>
                </Grid>
                </Card.Body>
            </Card>
            <br />
        </div>
    );
}
