import http from "../../../../Utils/http";

  export function loadTableData(type) {
    const pharams = `type=${type}&status=&name=`
    return http.get(`api/payout/?${pharams}`);
  }

  export function pageLoadData(no, name, status, type) {
    const pharams = 'page='+no+`&status=${status}&type=${type}&name=${name}`
    return http.get(`api/payout/?${pharams}`);
  }

  export function searchLoadData(name, status, type) {
    const pharams = `type=${type}&status=${status}&name=${name}`
    return http.get(`api/payout/?${pharams}`);
  }

  export function paymentLoadData(id, type) {
    const pharams = `type=${type}&payout_id=${id}`
    return http.get(`api/payout/payment?${pharams}`);
  }

  export function SinglePayment(data, type) {
    data['type']=type
    return http.post(`api/payout/payment/single`, data )
  }

  export function MultiplePayment(data, type) {
    data['type']=type
    return http.post(`api/payout/payment/bulk/`, data )
  }