import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AddressCard from "./Address/AddressCard";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import Spinner from "../../../Utils/Components/CustomSpinner";
import { GetAddress } from "../../../modules/Api/Patient/ListPatientApi";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Details(props) {
  const classes = useStyles();
  let view_only = false
  if (props.type == 'view') {
    view_only = true
  } else {
    view_only = false
  }
  
  const [mount, setMount] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const [addressData, setAddressData] = React.useState(undefined)

  // setAddressData(props.data.address)
  const loadData = () => {
    const id = props.data.user.id
    setAddressData(undefined)
    GetAddress(id)
      .then(items => {
        setLoading(false)
        console.log('items.data', items.data.data);
        setAddressData(items.data.data)
        // if (items && items.data && items.data.message) {
        //   SuccessToast(items.data.message)
        // }
        
      })
      .catch(err => {
        ErrorToast(err)
        setLoading(false)
    })
  }

  React.useEffect(() => {
    if(mount) {
      setMount(false)
      loadData()}
  }, [loadData])

  console.log(addressData);

  const onClickPrevious = () => {
    props.previosStep()
}

const onClickNext = (data) => {
    props.nextStep()
  }

  return (
        <div className={classes.root}>
        <form>
        <h5 style={{marginTop:"2%", marginBottom:"2%"}} >Address</h5>
        {/* <div>
        {
            addressData && addressData.map((item) => 
            <div>
                <AddressCard data={item}/>
            </div>
            )
        }
        </div> */}
        {/* <AddButton /> */}{addressData && 
          <AddressCard view_only={view_only} loadData={loadData} updateAddressData={props.updateAddressData} values={addressData} updateMdelData={props.updateMdelData} data={props.data} />
        }
        <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item xs={3} container justify={"flex-start"} >
        {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
        <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
        </Grid>
        <Grid item xs={3}>
        </Grid>

        <Grid item xs={3}>
        </Grid>
        {!view_only && <Grid item xs={3} container justify={"flex-end"} >
        <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickNext} >Next</Button>
        </Grid>}
        {view_only && <Grid item xs={3} container justify={"flex-end"} >
        <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.nextStep} >Next</Button>
        </Grid>}
        </Grid> 
      </form>
            
    </div>
  );
}
