import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Form from "./Form";

export const View = (props) => {

  const backToProductsList = () => props.history.goBack()
  const saveProduct = () => { }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  const saveProductClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
      
    }
  };
  const resetForm = () => {
    if(resetbtnRef && resetbtnRef.current){
      resetbtnRef.current.click()
    }
  }

  return (
    <Card>
      <CardHeader title="Change Password">
      </CardHeader>
        
      <CardBody>
        <Form />
      </CardBody>
    </Card>
  )
};
