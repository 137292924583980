import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
// import { StoresTable } from "./SpecializationTable";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import StoresTable from "./TableApprove";
import { loadTableData } from "../../modules/Api/Emloyee/ListEmployeeApi";
import Spinner from "../../Utils/Components/CustomSpinner";
import NoRecordFound from "../../Utils/Components/NoRecordFound";
import {ErrorToast} from "../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../modules/Toast/SuccessSnackBar";
import {loadListRole } from "../../modules/Api/Emloyee/ListEmployeeApi";
import Search from "./Search";

export const ListApproved = (props) => {
  const [data, setData] = React.useState(undefined)
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)
  const [roledata, setRoleData] = React.useState(undefined)
  const [user_type, set_user_type] = React.useState("EMPLOYEE")
  const [name, set_name] = React.useState("")
  const [active, set_active] = React.useState(1)

  const loadData = (data) => {
    setLoading(true)
    setData(undefined)
      loadTableData(data)
      .then(items => {
        // console.log(items);
        setLoading(false)
        setData(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
        setLoading(false)
    })
  }

  React.useEffect(() => {
    const data = {
      no : 1,
      user_type : user_type,
      name : name,
      active : active,
    }
    if (mounted) {
    loadData(data)
    loadRoleLit()
    setMounted(false)
  }
  }, [loadData, loadRoleLit])

  const SearchData = (text) => {
    const data = {
      no : 1,
      user_type : user_type,
      name : text,
      active : active,
    }
    loadData(data)
  }

  const loadPageData = (no) => {
    const data = {
      no : no,
      user_type : user_type,
      name : name,
      active : active,
    }
    loadData(data)
  }

  const loadRoleLit = () => {
    loadListRole()
    .then(items => {
      setRoleData(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
    })
}

const onChangeRole = (type) => {
  const data = {
    no : 1,
    user_type : type,
    name : name,
    active : active,
  }
  loadData(data)
}


  if (loading) {
    return (
      <Spinner />
    )
  }

  return (
    <Card>
      <CardHeader title="Employee list">
        <CardHeaderToolbar>
        <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-amount">Category</InputLabel>
              <Select
              style={{marginRight:'10px', width:'200px'}}
              margin="dense"
              required
              label="Category"
              type="text"
              value={user_type}
              variant="outlined"
              onChange={e => {
                set_user_type(e.target.value)
                onChangeRole(e.target.value)
              }}
              >
                <MenuItem value="EMPLOYEE">EMPLOYEE</MenuItem>
                <MenuItem value="HEALTHASSOCIATE">HEALTH ASSOCIATE</MenuItem>
          </Select>
          </FormControl>
        <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-amount">Status</InputLabel>
              <Select
              style={{marginRight:'10px', width:'200px'}}
              margin="dense"
              required
              label="Status"
              type="text"
              value={active}
              variant="outlined"
              onChange={e => {
                set_active(e.target.value)
                const data = {
                  no : 1,
                  user_type : user_type,
                  name : name,
                  active : e.target.value,
                }
                loadData(data)
              }}
              >
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={2}>Deactivate</MenuItem>
          </Select>
          </FormControl>
          <Search 
          set_name={set_name}
          name={name}
          SearchData={SearchData} />
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {
          data && roledata && <StoresTable 
          loadPageData={loadPageData}
          data={data}
          roledata={roledata}
          userType={user_type}
          active={active}
          />
        }
        {
          !data && <NoRecordFound/>
        }

      </CardBody>
    </Card>
  );
};
