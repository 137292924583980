import React, { Component } from 'react';
import Details from './Details';
import Address from './Address';
import AdditionalContacts from './AdditionalContacts';
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import {loadListRole } from "../../../modules/Api/Emloyee/ListEmployeeApi";


export default class UserForm extends Component {
    constructor(props) {
        super(props);
        
        // this.updateData = this.updateData.bind(this)
    
        this.state = {
            step: 1,
            mounted:true,
            data: {
                title : '',
                first_name	: '',
                middle_name	: '',
                last_name : '',
                gender : '',
                date_of_birth : '',
                age	: '',
                blood_group	: '',
                height	: '',
                weight	: '',
                marital_status	: '',
                occupation	: '',
                email	: '',
                mobile_number	: '',
                country_code	: '+91',
                profile_photo	: '',
                alt_mobile_number	: '',
                alt_country_code	: '+91',
                pincode	: '',
                street_name	: '',
                city_village	: '',
                district	: '',
                state	: '',
                country	: '',
                address_type	: 'HOME',
                medication_check : false,
                current_medication	: '',
                povert_line_checked : false,
                bpl_file_number	: '',
                bpl_file	: '',
                first_name_primary	: '',
                middle_name_primary	: '',
                last_name_primary	: '',
                mobile_number_primary	: '',
                country_code_primary	: '+91',
                relationship_primary	: '',
                secondary_check : false,
                first_name_secondary	: '',
                middle_name_secondary	: '',
                last_name_secondary	: '',
                mobile_number_secondary	: '',
                country_code_secondary	: '+91',
                relationship_secondary	: '',
                national_health_id : '',
            },
        }
        };

    
    // next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        })
    }

    updateData = (data) => {
        console.log(data);
        this.setState({data:data})
    }

    updateStep = (no) => {
        const { step } = this.state;
        this.setState({
            step: no
        })
    }

    // previous step
    previosStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        })
    }


    render() {

        const { step } = this.state

        switch (step) {
            case 1 :
                return (
                    <div>
                        {<Details
                    nextStep={this.nextStep}
                    updateData={this.updateData}
                    data={this.state.data}
                    />}
                    </div>
                )
            case 2 :
                return (
                    <Address
                    data={this.state.data}
                    nextStep={this.nextStep}
                    previosStep={this.previosStep}
                    updateData={this.updateData}
                    />
                )
                case 3 :
                    return (
                        <AdditionalContacts
                        data={this.state.data}
                        nextStep={this.nextStep}
                        previosStep={this.previosStep}
                        updateData={this.updateData}
                        updateStep={this.updateStep}
                        />
                    )
            default:
                return (
                    <Details
                    values={this.state.formData}
                    nextStep={this.nextStep}
                    previosStep={this.previosStep}
                    updateData={this.updateData}
                    />
                )
        }
    }
}