import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Details(props) {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = React.useState('')


  const initValues = {
            clinic_name:'',
            pincode: '',
            country: '',
            state: '',
            district:'',
            city_village:'',
            street_name: '',
            latitude: '',
            longitude: '',
            country_code: '',
            contact_number: '',
      }
    const onClickNext = (data) => {
        props.nextStep(data)
    }
    const onClickPrevious = () => {
        props.previosStep()
    }
    
    


    const formik = useFormik({
      initialValues: initValues,
      validationSchema: Yup.object({
        clinic_name: Yup.string()
          .required("Clinic name is Required!"),
          pincode: Yup.string()
          .matches(/^[0-9]*$/, 'Invalid Pincode')
          .required("Pincode is Required!")
          // .test('len', 'Invalid Pincode', val => val.length === 6)
          .nullable(),
        country: Yup.string()
         .required("Country is Required!"),
        state: Yup.string()
          .required("State is Required!"),
        district: Yup.string()
          .required("District is Required!"),
        city_village: Yup.string()
          .required("City/Village/Society is Required!"),
        street_name: Yup.string()
         .required("Area/Street/Sector is Required!"),
        //  country_code: Yup.string()
        //   .required("Country code is Required!"),
        //   contact_number: Yup.string()
        //  .required("contact number is Required!"),
      }),
      onSubmit: values => {
        console.log('submit', values);

        const data = props.values

        // data["address"][0]["pincode"] = values.pincode
        // data["address"][0]["country"] = values.country
        // data["address"][0]["state"] = values.state
        // data["address"][0]["city_village"] = values.city_village
        // data["address"][0]["street_name"] = values.street_name
        // data["address"][0]["district"] = values.district
        // data["currency_code"] = values.currency_code
        // data["sample_collection"] = values.sample_collection
        // data["order_amount"] = values.order_amount
        // editDetails(values, data)
        // onClickNext(data)
      }
    });
    

    

  return (
    <div className={classes.root}>
        <h3 style={{margin:"2%"}} >Add Address</h3>
      <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}  >
        <Grid item xs={3}>
        <TextField
            fullWidth
            required
            label="Clinic Name"
            name="clinic_name"
            type="text"
            value={formik.values.clinic_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.clinic_name && formik.touched.clinic_name && (
            <p style={{color:"red"}}>{formik.errors.clinic_name}</p>
             )}
        </Grid>
        <Grid item xs={3}>
        <TextField
            fullWidth
            required
            label="Pincode"
            name="pincode"
            value={formik.values.pincode}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.pincode && formik.touched.pincode && (
            <p style={{color:"red"}}>{formik.errors.pincode}</p>
             )}
        </Grid>
        <Grid item xs={3}>
        <TextField
            fullWidth
            required
            label="Country"
            name="country"
            type="text"
            value={formik.values.country}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.country && formik.touched.country && (
            <p style={{color:"red"}}>{formik.errors.country}</p>
             )}
        </Grid>
        <Grid item xs={3}>
        <TextField
            fullWidth
            required
            label="State"
            name="state"
            type="text"
            value={formik.values.state}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.state && formik.touched.state && (
            <p style={{color:"red"}}>{formik.errors.state}</p>
             )}
        </Grid>
      </Grid>
      <Grid container spacing={3} >
        
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="District"
            name="district"
            type="text"
            value={formik.values.district}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.district && formik.touched.district && (
            <p style={{color:"red"}}>{formik.errors.district}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="City/Village/Society"
            name="city_village"
            type="text"
            value={formik.values.city_village}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.city_village && formik.touched.city_village && (
            <p style={{color:"red"}}>{formik.errors.city_village}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="Area/Street/Sector"
            name="street_name"
            type="text"
            value={formik.values.street_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
              {formik.errors.street_name && formik.touched.street_name && (
              <p style={{color:"red"}}>{formik.errors.street_name}</p>
               )}
        </Grid>
      </Grid>

      
      
      {errorMessage}

<Grid container spacing={3} >
        <Grid item xs={3} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button>
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button> */}
        </Grid>
        <Grid item xs={3}>
        </Grid>
        
        <Grid item xs={3}>
        </Grid>
        <Grid item xs={3} container justify={"flex-end"} >
            <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Add</Button>
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickNext} >Next</Button> */}
            </Grid>
      </Grid> 
      </form>
            
    </div>
  );
}
