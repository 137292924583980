import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { countryCodeData as options } from '../../../Utils/countyCodeMapping';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import { editBbasicinfo } from "../../../modules/Api/Pharmacy/NewPharmacyApi";
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import {FileUpload} from "../../../Utils/Components/FileUpload/FileUpload";
import { handleNumber, findCodeFromValue } from '../../../Utils/CountryMobile';
import { DatePickerField } from "./../../../../_metronic/_partials/controls/forms/DatePickerField";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const gstinRegex = new RegExp(/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/)

export default function Details(props) {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = React.useState('')
  const [currentCountry, setCurrentCountry] = React.useState()
  let intValues = props.values

const FILE_SIZE = 1024 * 1024 * 2;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
   "application/pdf"
];

  

  const onOpenPdf = () => {
    window.open(intValues.dl_file)
  }

    const onClickNext = (data) => {
      props.updateData(data)
      props.nextStep()
    }

    const editDetails = (data, localData) => {
      const id = props.values.id
      editBbasicinfo(id, data)
        .then(items => {
          onClickNext(localData)
          if (items && items.data && items.data.message) {
            SuccessToast(items.data.message)
          }
          
        })
        .catch(err => {
          ErrorToast(err)
      })
    }

    

    const formik = useFormik({
      initialValues: intValues,
      validationSchema: Yup.object({
        pharmacy_name: Yup.string()
          .required("Pharmacy name is Required!"),
        email: Yup.string()
          .email("Invalid email format")
          .required("Email is Required!"),
          mobile_number: Yup.string()
          .required('Mobile number is required')
          .nullable()
          .test('mobile-num', 'Invalid mobile number', function (value) {
              if (!value) return false;
              const currentCountrycode = currentCountry && currentCountry.code ? currentCountry.code : null
              return handleNumber(value + "", currentCountrycode || "IN")
          })
          ,
        gstin: Yup.string()
          .matches(gstinRegex, "Must be a standard gst number.")
          .max(15, 'Must be 15 characters.')
          .min(15, 'Must be 15 characters.')
          .required('GSTIN number field is required '),
        dl_number: Yup.string()
          .required("DL number is Required!"),
        dl_issuing_authority: Yup.string()
         .required("DL issuing authority is Required!"),
        dl_date_of_issue: Yup.date('Must be a valid date')
          .nullable()
          .max(new Date(), 'Date of issue cannot be a future date' )
          .required('The Date of issue field is required.'),
        dl_valid_upto: Yup.date('Must be a valid date')
          // .matches(/[0-9]{2}-[0-9]{2}-[0-9]{4}/, "Must be a standard gst number.")
          .min(new Date(), "Date must be in the future")
          .required('The Date of validity field is required.')
          .nullable(),
          dl_file:   Yup.mixed()
          .required("Drug licence is required")
          .test(
            "fileSize",
            "File size too large",
            value => value && value.size <= FILE_SIZE
          )
          .test(
            "fileFormat",
            "Invalid File Format",
            value => value && SUPPORTED_FORMATS.includes(value.type)
          )
      }),
      onSubmit: values => {
        onClickNext(values)


      
      }
    });
        
    

      
    

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Basic Information</h6>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item md={3} xs={12}>
          <TextField
            fullWidth
            required
            label="Pharmacy Name"
            name="pharmacy_name"
            type="text"
            value={formik.values.pharmacy_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.pharmacy_name && formik.touched.pharmacy_name && (
            <p style={{color:"red"}}>{formik.errors.pharmacy_name}</p>
             )}
        </Grid>
        <Grid item md={3} xs={12}>
        <Select
            required
            style={{marginRight:"2%", marginBottom:"2%", width:"30%"}}
            name="country_code"
            type="text"
            value={formik.values.country_code}
            variant="outlined"
            onChange={e => {
              formik.setFieldValue("country_code", e.target.value)
              setCurrentCountry(findCodeFromValue(e.target.value))
            }}
            >
              {
                options.map(
                  (item) => (
                  <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                  )
                )
              }
            </Select>
            <FormControl style={{width:"65%"}} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Mobile<span>&nbsp;*</span></InputLabel>
            <OutlinedInput
          fullWidth
                name="mobile_number"
                type="number"
                value={formik.values.mobile_number}
                onChange={formik.handleChange}
                startAdornment={<InputAdornment position="start">{formik.values.country_code}</InputAdornment>}
                labelWidth={50}
                onBlur={formik.handleBlur}
            />
            {formik.errors.mobile_number && formik.touched.mobile_number && (
            <p style={{color:"red"}}>{formik.errors.mobile_number}</p>
             )}
            </FormControl>
        </Grid>
        <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          required
            label="Email ID"
            name="email"
            type="email"
            value={formik.values.email}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.email && formik.touched.email && (
            <p style={{color:"red"}}>{formik.errors.email}</p>
             )}
        </Grid>
        <Grid item md={3} xs={12}>
            <TextField
          fullWidth
          required
            // style={{margin:"2%", width:"200px"}}
            label="GSTIN Number"
            name="gstin"
            type="text"
            value={formik.values.gstin}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.gstin && formik.touched.gstin && (
            <p style={{color:"red"}}>{formik.errors.gstin}</p>
             )}
        </Grid>
      </Grid>
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Drug License</h6>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          required
            label="Drug Licence Number"
            name="dl_number"
            type="text"
            value={formik.values.dl_number}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.dl_number && formik.touched.dl_number && (
            <p style={{color:"red"}}>{formik.errors.dl_number}</p>
             )}
        </Grid>
        <Grid item md={3} xs={12}>
        <TextField
          fullWidth
          required
            label="Issuing Authority"
            name="dl_issuing_authority"
            type="text"
            value={formik.values.dl_issuing_authority}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.dl_issuing_authority && formik.touched.dl_issuing_authority && (
            <p style={{color:"red"}}>{formik.errors.dl_issuing_authority}</p>
             )}
        </Grid>
        <Grid item md={3} xs={12}>
        {/* <TextField
          fullWidth
          required
            label="Date Of Issue"
            name="dl_date_of_issue"
            type="date"
            value={formik.values.dl_date_of_issue}
            variant="outlined"
            onChange={e => {
              if (new Date("9999-12-31") >= new Date(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            InputLabelProps={{
                shrink: true,
              }}
              onBlur={formik.handleBlur}
              />
              {formik.errors.dl_date_of_issue && formik.touched.dl_date_of_issue && (
              <p style={{color:"red"}}>{formik.errors.dl_date_of_issue}</p>
               )} */}

                   <DatePickerField 
                                mandatory
                                name="dl_date_of_issue"
                                label="Date Of Issue"
                                onChange={val => {
                                  formik.setFieldValue('dl_date_of_issue', String(val))
                                }}
                                onBlur={e => {
                                  formik.handleBlur(e);
                                }}
                                value={formik.values.dl_date_of_issue}
                                touched={formik.touched.dl_date_of_issue}
                                errors={formik.errors.dl_date_of_issue}
                                 />
        </Grid>
        <Grid item md={3} xs={12}>
        {/* <TextField
          fullWidth
          required
            label="Drug License Valid Upto"
            name="dl_valid_upto"
            type="date"
            value={formik.values.dl_valid_upto}
            variant="outlined"
            onChange={e => {
              if (new Date("9999-12-31") >= new Date(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            InputLabelProps={{
                shrink: true,
              }}
              onBlur={formik.handleBlur}
              />
              {formik.errors.dl_valid_upto && formik.touched.dl_valid_upto && (
              <p style={{color:"red"}}>{formik.errors.dl_valid_upto}</p>
               )} */}

                   <DatePickerField 
                                mandatory
                                name="dl_valid_upto"
                                label="Drug License Valid Upto"
                                onChange={val => {
                                  formik.setFieldValue('dl_valid_upto', String(val))
                                }}
                                onBlur={e => {
                                  formik.handleBlur(e);
                                }}
                                value={formik.values.dl_valid_upto}
                                touched={formik.touched.dl_valid_upto}
                                errors={formik.errors.dl_valid_upto}
                                 />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"5%"}}>
        <Grid item md={3} xs={12}>
        <FileUpload
            mandatory
            label='Drug Licence'
            errors={formik.errors.dl_file}
            name="dl_file"
            value={formik.values.dl_file}
            onChange={value => {
              formik.setFieldValue("dl_file", value)
            }}
        />
        </Grid>
        </Grid>
      
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} > 
        <Grid item md={3} xs={12} container justify={"flex-start"} >
        {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
        </Grid>
        <Grid item md={3} xs={12}>
        </Grid>
        
        <Grid item md={3} xs={12}>
        </Grid>
        <Grid item md={3} xs={12} container justify={"flex-end"} >
        <Button  variant="contained" color="primary" type="submit" >Next</Button>
        {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.nextStep} >Next</Button> */}
            
        </Grid>
      </Grid> 
      </form>
            
    </div>
  );
}
