export const countryCodeData = [
    { code: "AF", id: 1, label: "Afghanistan", value: "+93" },
    { code: "AL", id: 2, label: "Albania", value: "+355" },
    { code: "DZ", id: 3, label: "Algeria", value: "+213" },
    { code: "AS", id: 4, label: "AmericanSamoa", value: "+1 684" },
    { code: "AD", id: 5, label: "Andorra", value: "+376" },
    { code: "AO", id: 6, label: "Angola", value: "+244" },
    { code: "AI", id: 7, label: "Anguilla", value: "+1 264" },
    { code: "AQ", id: 8, label: "Antarctica", value: "+672" },
    { code: "AG", id: 9, label: "Antigua and Barbuda", value: "+1268" },
    { code: "AR", id: 10, label: "Argentina", value: "+54" },
    { code: "AM", id: 11, label: "Armenia", value: "+374" },
    { code: "AW", id: 12, label: "Aruba", value: "+297" },
    { code: "AU", id: 13, label: "Australia", value: "+61" },
    { code: "AT", id: 14, label: "Austria", value: "+43" },
    { code: "AZ", id: 15, label: "Azerbaijan", value: "+994" },
    { code: "BS", id: 16, label: "Bahamas", value: "+1 242" },
    { code: "BH", id: 17, label: "Bahrain", value: "+973" },
    { code: "BD", id: 18, label: "Bangladesh", value: "+880" },
    { code: "BB", id: 19, label: "Barbados", value: "+1 246" },
    { code: "BY", id: 20, label: "Belarus", value: "+375" },
    { code: "BE", id: 21, label: "Belgium", value: "+32" },
    { code: "BZ", id: 22, label: "Belize", value: "+501" },
    { code: "BJ", id: 23, label: "Benin", value: "+229" },
    { code: "BM", id: 24, label: "Bermuda", value: "+1 441" },
    { code: "BT", id: 25, label: "Bhutan", value: "+975" },
    { code: "BO", id: 26, label: "Bolivia, Plurinational State of", value: "+591" },
    { code: "BA", id: 27, label: "Bosnia and Herzegovina", value: "+387" },
    { code: "BW", id: 28, label: "Botswana", value: "+267" },
    { code: "BR", id: 29, label: "Brazil", value: "+55" },
    { code: "IO", id: 30, label: "British Indian Ocean Territory", value: "+246" },
    { code: "BN", id: 31, label: "Brunei Darussalam", value: "+673" },
    { code: "BG", id: 32, label: "Bulgaria", value: "+359" },
    { code: "BF", id: 33, label: "Burkina Faso", value: "+226" },
    { code: "BI", id: 34, label: "Burundi", value: "+257" },
    { code: "KH", id: 35, label: "Cambodia", value: "+855" },
    { code: "CM", id: 36, label: "Cameroon", value: "+237" },
    { code: "CA", id: 37, label: "Canada", value: "+1" },
    { code: "CV", id: 38, label: "Cape Verde", value: "+238" },
    { code: "KY", id: 39, label: "Cayman Islands", value: "+ 345" },
    { code: "CF", id: 40, label: "Central African Republic", value: "+236" },
    { code: "TD", id: 41, label: "Chad", value: "+235" },
    { code: "CL", id: 42, label: "Chile", value: "+56" },
    { code: "CN", id: 43, label: "China", value: "+86" },
    { code: "CX", id: 44, label: "Christmas Island", value: "+61" },
    { code: "CC", id: 45, label: "Cocos (Keeling) Islands", value: "+61" },
    { code: "CO", id: 46, label: "Colombia", value: "+57" },
    { code: "KM", id: 47, label: "Comoros", value: "+269" },
    { code: "CG", id: 48, label: "Congo", value: "+242" },
    { code: "CD", id: 49, label: "Congo, The Democratic Republic of the", value: "+243" },
    { code: "CK", id: 50, label: "Cook Islands", value: "+682" },
    { code: "CR", id: 51, label: "Costa Rica", value: "+506" },
    { code: "CI", id: 52, label: "Cote d'Ivoire", value: "+225" },
    { code: "HR", id: 53, label: "Croatia", value: "+385" },
    { code: "CU", id: 54, label: "Cuba", value: "+53" },
    { code: "CY", id: 55, label: "Cyprus", value: "+537" },
    { code: "CZ", id: 56, label: "Czech Republic", value: "+420" },
    { code: "DK", id: 57, label: "Denmark", value: "+45" },
    { code: "DJ", id: 58, label: "Djibouti", value: "+253" },
    { code: "DM", id: 59, label: "Dominica", value: "+1 767" },
    { code: "DO", id: 60, label: "Dominican Republic", value: "+1 849" },
    { code: "EC", id: 61, label: "Ecuador", value: "+593" },
    { code: "EG", id: 62, label: "Egypt", value: "+20" },
    { code: "SV", id: 63, label: "El Salvador", value: "+503" },
    { code: "GQ", id: 64, label: "Equatorial Guinea", value: "+240" },
    { code: "ER", id: 65, label: "Eritrea", value: "+291" },
    { code: "EE", id: 66, label: "Estonia", value: "+372" },
    { code: "ET", id: 67, label: "Ethiopia", value: "+251" },
    { code: "FK", id: 68, label: "Falkland Islands (Malvinas)", value: "+500" },
    { code: "FO", id: 69, label: "Faroe Islands", value: "+298" },
    { code: "FJ", id: 70, label: "Fiji", value: "+679" },
    { code: "FI", id: 71, label: "Finland", value: "+358" },
    { code: "FR", id: 72, label: "France", value: "+33" },
    { code: "GF", id: 73, label: "French Guiana", value: "+594" },
    { code: "PF", id: 74, label: "French Polynesia", value: "+689" },
    { code: "GA", id: 75, label: "Gabon", value: "+241" },
    { code: "GM", id: 76, label: "Gambia", value: "+220" },
    { code: "GE", id: 77, label: "Georgia", value: "+995" },
    { code: "DE", id: 78, label: "Germany", value: "+49" },
    { code: "GH", id: 79, label: "Ghana", value: "+233" },
    { code: "GI", id: 80, label: "Gibraltar", value: "+350" },
    { code: "GR", id: 81, label: "Greece", value: "+30" },
    { code: "GL", id: 82, label: "Greenland", value: "+299" },
    { code: "GD", id: 83, label: "Grenada", value: "+1 473" },
    { code: "GP", id: 84, label: "Guadeloupe", value: "+590" },
    { code: "GU", id: 85, label: "Guam", value: "+1 671" },
    { code: "GT", id: 86, label: "Guatemala", value: "+502" },
    { code: "GG", id: 87, label: "Guernsey", value: "+44" },
    { code: "GN", id: 88, label: "Guinea", value: "+224" },
    { code: "GW", id: 89, label: "Guinea-Bissau", value: "+245" },
    { code: "GY", id: 90, label: "Guyana", value: "+595" },
    { code: "HT", id: 91, label: "Haiti", value: "+509" },
    { code: "VA", id: 92, label: "Holy See (Vatican City State)", value: "+379" },
    { code: "HN", id: 93, label: "Honduras", value: "+504" },
    { code: "HK", id: 94, label: "Hong Kong", value: "+852" },
    { code: "HU", id: 95, label: "Hungary", value: "+36" },
    { code: "IS", id: 96, label: "Iceland", value: "+354" },
    { code: "IN", id: 97, label: "India", value: "+91" },
    { code: "ID", id: 98, label: "Indonesia", value: "+62" },
    { code: "IR", id: 99, label: "Iran, Islamic Republic of", value: "+98" },
    { code: "IQ", id: 100, label: "Iraq", value: "+964" },
    { code: "IE", id: 101, label: "Ireland", value: "+353" },
    { code: "IM", id: 102, label: "Isle of Man", value: "+44" },
    { code: "IL", id: 103, label: "Israel", value: "+972" },
    { code: "IT", id: 104, label: "Italy", value: "+39" },
    { code: "JM", id: 105, label: "Jamaica", value: "+1 876" },
    { code: "JP", id: 106, label: "Japan", value: "+81" },
    { code: "JE", id: 107, label: "Jersey", value: "+44" },
    { code: "JO", id: 108, label: "Jordan", value: "+962" },
    { code: "KZ", id: 109, label: "Kazakhstan", value: "+7 7" },
    { code: "KE", id: 110, label: "Kenya", value: "+254" },
    { code: "KI", id: 111, label: "Kiribati", value: "+686" },
    { code: "KP", id: 112, label: "Korea, Democratic People's Republic of", value: "+850" },
    { code: "KR", id: 113, label: "Korea, Republic of", value: "+82" },
    { code: "KW", id: 114, label: "Kuwait", value: "+965" },
    { code: "KG", id: 115, label: "Kyrgyzstan", value: "+996" },
    { code: "LA", id: 116, label: "Lao People's Democratic Republic", value: "+856" },
    { code: "LV", id: 117, label: "Latvia", value: "+371" },
    { code: "LB", id: 118, label: "Lebanon", value: "+961" },
    { code: "LS", id: 119, label: "Lesotho", value: "+266" },
    { code: "LR", id: 120, label: "Liberia", value: "+231" },
    { code: "LY", id: 121, label: "Libyan Arab Jamahiriya", value: "+218" },
    { code: "LI", id: 122, label: "Liechtenstein", value: "+423" },
    { code: "LT", id: 123, label: "Lithuania", value: "+370" },
    { code: "LU", id: 124, label: "Luxembourg", value: "+352" },
    { code: "MO", id: 125, label: "Macao", value: "+853" },
    { code: "MK", id: 126, label: "Macedonia, The Former Yugoslav Republic of", value: "+389" },
    { code: "MG", id: 127, label: "Madagascar", value: "+261" },
    { code: "MW", id: 128, label: "Malawi", value: "+265" },
    { code: "MY", id: 129, label: "Malaysia", value: "+60" },
    { code: "MV", id: 130, label: "Maldives", value: "+960" },
    { code: "ML", id: 131, label: "Mali", value: "+223" },
    { code: "MT", id: 132, label: "Malta", value: "+356" },
    { code: "MH", id: 133, label: "Marshall Islands", value: "+692" },
    { code: "MQ", id: 134, label: "Martinique", value: "+596" },
    { code: "MR", id: 135, label: "Mauritania", value: "+222" },
    { code: "MU", id: 136, label: "Mauritius", value: "+230" },
    { code: "YT", id: 137, label: "Mayotte", value: "+262" },
    { code: "MX", id: 138, label: "Mexico", value: "+52" },
    { code: "FM", id: 139, label: "Micronesia, Federated States of", value: "+691" },
    { code: "MD", id: 140, label: "Moldova, Republic of", value: "+373" },
    { code: "MC", id: 141, label: "Monaco", value: "+377" },
    { code: "MN", id: 142, label: "Mongolia", value: "+976" },
    { code: "ME", id: 143, label: "Montenegro", value: "+382" },
    { code: "MS", id: 144, label: "Montserrat", value: "+1664" },
    { code: "MA", id: 145, label: "Morocco", value: "+212" },
    { code: "MZ", id: 146, label: "Mozambique", value: "+258" },
    { code: "MM", id: 147, label: "Myanmar", value: "+95" },
    { code: "NA", id: 148, label: "Namibia", value: "+264" },
    { code: "NR", id: 149, label: "Nauru", value: "+674" },
    { code: "NP", id: 150, label: "Nepal", value: "+977" },
    { code: "NL", id: 151, label: "Netherlands", value: "+31" },
    { code: "AN", id: 152, label: "Netherlands Antilles", value: "+599" },
    { code: "NC", id: 153, label: "New Caledonia", value: "+687" },
    { code: "NZ", id: 154, label: "New Zealand", value: "+64" },
    { code: "NI", id: 155, label: "Nicaragua", value: "+505" },
    { code: "NE", id: 156, label: "Niger", value: "+227" },
    { code: "NG", id: 157, label: "Nigeria", value: "+234" },
    { code: "NU", id: 158, label: "Niue", value: "+683" },
    { code: "NF", id: 159, label: "Norfolk Island", value: "+672" },
    { code: "MP", id: 160, label: "Northern Mariana Islands", value: "+1 670" },
    { code: "NO", id: 161, label: "Norway", value: "+47" },
    { code: "OM", id: 162, label: "Oman", value: "+968" },
    { code: "PK", id: 163, label: "Pakistan", value: "+92" },
    { code: "PW", id: 164, label: "Palau", value: "+680" },
    { code: "PS", id: 165, label: "Palestinian Territory, Occupied", value: "+970" },
    { code: "PA", id: 166, label: "Panama", value: "+507" },
    { code: "PG", id: 167, label: "Papua New Guinea", value: "+675" },
    { code: "PY", id: 168, label: "Paraguay", value: "+595" },
    { code: "PE", id: 169, label: "Peru", value: "+51" },
    { code: "PH", id: 170, label: "Philippines", value: "+63" },
    { code: "PN", id: 171, label: "Pitcairn", value: "+872" },
    { code: "PL", id: 172, label: "Poland", value: "+48" },
    { code: "PT", id: 173, label: "Portugal", value: "+351" },
    { code: "PR", id: 174, label: "Puerto Rico", value: "+1 939" },
    { code: "QA", id: 175, label: "Qatar", value: "+974" },
    { code: "RO", id: 176, label: "Romania", value: "+40" },
    { code: "RU", id: 177, label: "Russia", value: "+7" },
    { code: "RW", id: 178, label: "Rwanda", value: "+250" },
    { code: "RE", id: 179, label: "Réunion", value: "+262" },
    { code: "BL", id: 180, label: "Saint Barthélemy", value: "+590" },
    { code: "SH", id: 181, label: "Saint Helena, Ascension and Tristan Da Cunha", value: "+290" },
    { code: "KN", id: 182, label: "Saint Kitts and Nevis", value: "+1 869" },
    { code: "LC", id: 183, label: "Saint Lucia", value: "+1 758" },
    { code: "MF", id: 184, label: "Saint Martin", value: "+590" },
    { code: "PM", id: 185, label: "Saint Pierre and Miquelon", value: "+508" },
    { code: "VC", id: 186, label: "Saint Vincent and the Grenadines", value: "+1 784" },
    { code: "WS", id: 187, label: "Samoa", value: "+685" },
    { code: "SM", id: 188, label: "San Marino", value: "+378" },
    { code: "ST", id: 189, label: "Sao Tome and Principe", value: "+239" },
    { code: "SA", id: 190, label: "Saudi Arabia", value: "+966" },
    { code: "SN", id: 191, label: "Senegal", value: "+221" },
    { code: "RS", id: 192, label: "Serbia", value: "+381" },
    { code: "SC", id: 193, label: "Seychelles", value: "+248" },
    { code: "SL", id: 194, label: "Sierra Leone", value: "+232" },
    { code: "SG", id: 195, label: "Singapore", value: "+65" },
    { code: "SK", id: 196, label: "Slovakia", value: "+421" },
    { code: "SI", id: 197, label: "Slovenia", value: "+386" },
    { code: "SB", id: 198, label: "Solomon Islands", value: "+677" },
    { code: "SO", id: 199, label: "Somalia", value: "+252" },
    { code: "ZA", id: 200, label: "South Africa", value: "+27" },
    { code: "GS", id: 201, label: "South Georgia and the South Sandwich Islands", value: "+500" },
    { code: "ES", id: 202, label: "Spain", value: "+34" },
    { code: "LK", id: 203, label: "Sri Lanka", value: "+94" },
    { code: "SD", id: 204, label: "Sudan", value: "+249" },
    { code: "SR", id: 205, label: "Suriname", value: "+597" },
    { code: "SJ", id: 206, label: "Svalbard and Jan Mayen", value: "+47" },
    { code: "SZ", id: 207, label: "Swaziland", value: "+268" },
    { code: "SE", id: 208, label: "Sweden", value: "+46" },
    { code: "CH", id: 209, label: "Switzerland", value: "+41" },
    { code: "SY", id: 210, label: "Syrian Arab Republic", value: "+963" },
    { code: "TW", id: 211, label: "Taiwan, Province of China", value: "+886" },
    { code: "TJ", id: 212, label: "Tajikistan", value: "+992" },
    { code: "TZ", id: 213, label: "Tanzania, United Republic of", value: "+255" },
    { code: "TH", id: 214, label: "Thailand", value: "+66" },
    { code: "TL", id: 215, label: "Timor-Leste", value: "+670" },
    { code: "TG", id: 216, label: "Togo", value: "+228" },
    { code: "TK", id: 217, label: "Tokelau", value: "+690" },
    { code: "TO", id: 218, label: "Tonga", value: "+676" },
    { code: "TT", id: 219, label: "Trinidad and Tobago", value: "+1 868" },
    { code: "TN", id: 220, label: "Tunisia", value: "+216" },
    { code: "TR", id: 221, label: "Turkey", value: "+90" },
    { code: "TM", id: 222, label: "Turkmenistan", value: "+993" },
    { code: "TC", id: 223, label: "Turks and Caicos Islands", value: "+1 649" },
    { code: "TV", id: 224, label: "Tuvalu", value: "+688" },
    { code: "UG", id: 225, label: "Uganda", value: "+256" },
    { code: "UA", id: 226, label: "Ukraine", value: "+380" },
    { code: "AE", id: 227, label: "United Arab Emirates", value: "+971" },
    { code: "GB", id: 228, label: "United Kingdom", value: "+44" },
    { code: "US", id: 229, label: "United States", value: "+1" },
    { code: "UY", id: 230, label: "Uruguay", value: "+598" },
    { code: "UZ", id: 231, label: "Uzbekistan", value: "+998" },
    { code: "VU", id: 232, label: "Vanuatu", value: "+678" },
    { code: "VE", id: 233, label: "Venezuela, Bolivarian Republic of", value: "+58" },
    { code: "VN", id: 234, label: "Viet Nam", value: "+84" },
    { code: "VG", id: 235, label: "Virgin Islands, British", value: "+1 284" },
    { code: "VI", id: 236, label: "Virgin Islands, U.S.", value: "+1 340" },
    { code: "WF", id: 237, label: "Wallis and Futuna", value: "+681" },
    { code: "YE", id: 238, label: "Yemen", value: "+967" },
    { code: "ZM", id: 239, label: "Zambia", value: "+260" },
    { code: "ZW", id: 240, label: "Zimbabwe", value: "+263" },
    { code: "AX", id: 241, label: "Åland Islands", value: "+358" }
]