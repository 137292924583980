import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import { AddStores, ListStores } from "./index"
export const Specialization = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("specialization");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/specialization"
        to="/specialization/list"
      />
      <ContentRoute from="/specialization/add" component={AddStores} />
      <ContentRoute from="/specialization/list" component={ListStores} />
    </Switch>
  );
};
