import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { UserTypeEditForm } from "./UserTypeEditForm";
import { UserTypeTable } from "./UserTypeTable";

export const AddUserTypes = (props) => {
  const [data, setdata] = useState({ name: '' });

  const backToList = () => props.history.goBack()
  const save = () => { }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  const saveClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };
  const resetForm = () => {
    if (resetbtnRef && resetbtnRef.current) {
      resetbtnRef.current.click()
    }
  }
  return (
    <Card>
      <CardHeader title="Add user types">
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
              Back
            </button>
          {`  `}
          <button
            className="btn btn-light ml-2"
            onClick={resetForm}>
            <i className="fa fa-redo"></i>
              Reset
            </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveClick}
          >
            Save
            </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          <UserTypeEditForm
            // actionsLoading={actionsLoading}
            data={data}
            btnRef={btnRef}
            resetbtnRef={resetbtnRef}
            saveProduct={save}
          />
        </div>
      </CardBody>
    </Card>
  )
};
