import http from "../../../Utils/http";

export function CommentData(id) {
    return http.get(`api/comments/?paginate=0&to_user_id=${id}`);
  }

  export function AddCommentData(data) {
    return http.post(`api/comments`, data);
  }

  export function DeleteCommentData(id) {
    return http.post(`api/comments/${id}`, {
      _method:'DELETE',
      });
  }