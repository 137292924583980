import { sub } from "date-fns"

export const MenuItems = [
    {
        path: 'dashboard',
        label: 'Dashboard',
        hasSubMenu: false,
        iconSvg: "/media/svg/icons/Design/Layers.svg",
        subMenuItems: [],
        accessTypes: ['SUPERADMIN', 'EMPLOYEE', 'HEALTHASSOCIATE', "PAYMENTADMIN"],
        roleTypes :['super_admin', 'employee', 'health_associate', "payment_admin"]
    },

    {
        path: 'profile',
        label: 'Profile Info',
        hasSubMenu: false,
        iconSvg: "/media/svg/icons/Design/Layers.svg",
        subMenuItems: [],
        accessTypes: ['EMPLOYEE', 'HEALTHASSOCIATE'],
        roleTypes :['employee', 'health_associate']
    },
    {
        path: 'patient',
        label: 'Patient',
        hasSubMenu: true,
        iconSvg: "/media/svg/icon/employee.svg",
        subMenuItems: [{
            path: 'list',
            label: 'List',
            hasSubMenu: false,
            accessTypes: ['SUPERADMIN', 'EMPLOYEE', 'HEALTHASSOCIATE'],
            roleTypes :['super_admin', 'employee', 'health_associate']
        }, {
            path: 'add',
            label: 'Add',
            hasSubMenu: false,
            accessTypes: ['SUPERADMIN', 'EMPLOYEE', 'HEALTHASSOCIATE'],
            roleTypes :['super_admin', 'employee', 'health_associate']
        }],
        accessTypes: ['SUPERADMIN', 'EMPLOYEE', 'HEALTHASSOCIATE'],
        roleTypes :['super_admin', 'employee', 'health_associate']
    },
    {
        path: 'employee',
        label: 'Employee',
        hasSubMenu: true,
        iconSvg: "/media/svg/icon/employee.svg",
        subMenuItems: [{
            path: 'approved',
            label: 'List',
            hasSubMenu: false,
            accessTypes: ['SUPERADMIN'],
            roleTypes :['super_admin']
        }, {
            path: 'new',
            label: 'New List',
            hasSubMenu: false,
            accessTypes: ['SUPERADMIN'],
            roleTypes :['super_admin']
        }, {
            path: 'add',
            label: 'Add',
            hasSubMenu: false,
            accessTypes: ['SUPERADMIN'],
            roleTypes :['super_admin']
        }],
        accessTypes: ['SUPERADMIN'],
        roleTypes :['super_admin']
    },
    {
        path: 'pharmacy',
        label: 'Pharmacy',
        hasSubMenu: true,
        iconSvg: "/media/svg/icons/Shopping/Bag2.svg",
        subMenuItems: [ {
            path: 'approved',
            label: 'List',
            hasSubMenu: false,
            accessTypes: ['SUPERADMIN', 'EMPLOYEE', 'HEALTHASSOCIATE'],
            roleTypes :['super_admin', 'employee', 'health_associate']
        }, {
            path: 'new',
            label: 'New List',
            hasSubMenu: false,
            accessTypes: ['SUPERADMIN'],
            roleTypes :['super_admin']
        },{
            path: 'add',
            label: 'Add',
            hasSubMenu: false,
            accessTypes: ['SUPERADMIN', 'EMPLOYEE', 'HEALTHASSOCIATE'],
            roleTypes :['super_admin', 'employee', 'health_associate']
        }],
        accessTypes: ['SUPERADMIN', 'EMPLOYEE', 'HEALTHASSOCIATE'],
        roleTypes :['super_admin', 'employee', 'health_associate']
    },
    {
        path: 'laboratory',
        label: 'Laboratory',
        hasSubMenu: true,
        iconSvg: "/media/svg/icon/Laboratory.svg",
        subMenuItems: [ {
            path: 'approved',
            label: 'List',
            hasSubMenu: false,
            accessTypes: ['SUPERADMIN', 'EMPLOYEE', 'HEALTHASSOCIATE'],
            roleTypes :['super_admin', 'employee', 'health_associate']
        },{
            path: 'new',
            label: 'New List',
            hasSubMenu: false,
            accessTypes: ['SUPERADMIN'],
            roleTypes :['super_admin']
        }, {
            path: 'add',
            label: 'Add',
            hasSubMenu: false,
            accessTypes: ['SUPERADMIN', 'EMPLOYEE', 'HEALTHASSOCIATE'],
            roleTypes :['super_admin', 'employee', 'health_associate']
        }],
        accessTypes: ['SUPERADMIN', 'EMPLOYEE', 'HEALTHASSOCIATE'],
        roleTypes :['super_admin', 'employee', 'health_associate']
    },
    {
        path: 'doctor',
        label: 'Doctor',
        hasSubMenu: true,
        iconSvg: "/media/svg/icon/Doctor.svg",
        subMenuItems: [ {
            path: 'approved',
            label: 'List',
            hasSubMenu: false,
            accessTypes: ['SUPERADMIN', 'EMPLOYEE', 'HEALTHASSOCIATE'],
            roleTypes :['super_admin', 'employee', 'health_associate']
        },{
            path: 'new',
            label: 'Approval Pending',
            hasSubMenu: false,
            accessTypes: ['SUPERADMIN'],
            roleTypes :['super_admin']
        }, {
            path: 'add',
            label: 'Add New',
            hasSubMenu: false,
            accessTypes: ['SUPERADMIN', 'EMPLOYEE', 'HEALTHASSOCIATE'],
            roleTypes :['super_admin', 'employee', 'health_associate']
        }],
        accessTypes: ['SUPERADMIN', 'EMPLOYEE', 'HEALTHASSOCIATE'],
        roleTypes :['super_admin', 'employee', 'health_associate']
    },

    {
        path: 'specialization',
        label: 'Specialization',
        hasSubMenu: true,
        iconSvg: "/media/svg/icon/Specialization.svg",
        subMenuItems: [{
            path: 'list',
            label: 'List',
            hasSubMenu: false,
            accessTypes: ['SUPERADMIN'],
            roleTypes :['super_admin']
        }, {
            path: 'add',
            label: 'Add',
            hasSubMenu: false,
            accessTypes: ['SUPERADMIN'],
            roleTypes :['super_admin']
        }],
        accessTypes: ['SUPERADMIN'],
        roleTypes :['super_admin']
    },
    {
        path: 'test',
        label: 'Test',
        hasSubMenu: true,
        iconSvg: "/media/svg/icon/Test.svg",
        subMenuItems: [{
            path: 'list',
            label: 'List',
            hasSubMenu: false,
            accessTypes: ['SUPERADMIN'],
            roleTypes :['super_admin']
        }, {
            path: 'add',
            label: 'Add',
            hasSubMenu: false,
            accessTypes: ['SUPERADMIN'],
            roleTypes :['super_admin']
        }],
        accessTypes: ['SUPERADMIN'],
        roleTypes :['super_admin']
    },
    {
        path: 'medicine',
        label: 'Medicine',
        hasSubMenu: true,
        iconSvg: "/media/svg/icon/medicine.svg",
        subMenuItems: [{
            path: 'list',
            label: 'List',
            hasSubMenu: false,
            accessTypes: ['SUPERADMIN'],
            roleTypes :['super_admin']
        }, {
            path: 'add',
            label: 'Add',
            hasSubMenu: false,
            accessTypes: ['SUPERADMIN'],
            roleTypes :['super_admin']
        }, {
            path: 'category',
            label: 'Category',
            hasSubMenu: true,
            subMenuItems: [{
                path: 'list',
                label: 'List',
                hasSubMenu: false,
                accessTypes: ['SUPERADMIN'],
                roleTypes :['super_admin']
            }, {
                path: 'add',
                label: 'Add',
                hasSubMenu: false,
                accessTypes: ['SUPERADMIN'],
                roleTypes :['super_admin']
            }],
            accessTypes: ['SUPERADMIN'],
            roleTypes :['super_admin']
        }],
        accessTypes: ['SUPERADMIN'],
        roleTypes :['super_admin']
    },
    {
        path: 'appointments',
        label: 'Appointments',
        hasSubMenu: false,
        iconSvg: "/media/svg/icons/Design/Layers.svg",
        subMenuItems: [],
        accessTypes: ['SUPERADMIN'],
        roleTypes :['super_admin']
    },
    {
        path: 'paymentManagement',
        label: 'Payment Management',
        hasSubMenu: true,
        iconSvg: "media/svg/icons/Shopping/Money.svg",
        subMenuItems: [ {
            path: 'tax',
            label: 'Tax Catalog',
            hasSubMenu: true,
            subMenuItems: [{
                path: 'list',
                label: 'List',
                hasSubMenu: false,
                accessTypes: ['SUPERADMIN'],
                roleTypes :['super_admin']
            }, {
                path: 'add',
                label: 'Add',
                hasSubMenu: false,
                accessTypes: ['SUPERADMIN'],
                roleTypes :['super_admin']
            }],
            accessTypes: ['SUPERADMIN'],
            roleTypes :['super_admin']
        }, {
            path: 'service',
            label: 'Services',
            hasSubMenu: true,
            subMenuItems: [{
                path: 'list',
                label: 'List',
                hasSubMenu: false,
                accessTypes: ['SUPERADMIN'],
                roleTypes :['super_admin']
            }, 
            // {
            //     path: 'add',
            //     label: 'Add',
            //     hasSubMenu: false,
            //     accessTypes: ['SUPERADMIN']
            // }
        ],
            accessTypes: ['SUPERADMIN'],
            roleTypes :['super_admin']
        }, {
            path: 'commission',
            label: 'Commission',
            hasSubMenu: true,
            subMenuItems: [{
                path: 'list',
                label: 'List',
                hasSubMenu: false,
                accessTypes: ['SUPERADMIN'],
                roleTypes :['super_admin']
            }, 
            {
                path: 'doc-list',
                label: 'Doctor Commission',
                hasSubMenu: false,
                accessTypes: ['SUPERADMIN'],
                roleTypes :['super_admin']
            }
        ],
            accessTypes: ['SUPERADMIN'],
            roleTypes :['super_admin']
        }],
        accessTypes: ['SUPERADMIN'],
        roleTypes :['super_admin']
    },
    {
        path: 'paymentAdmin/paymentPharmacy',
        label: 'Pharmacy',
        hasSubMenu: true,
        iconSvg: "/media/svg/icons/Shopping/Bag2.svg",
        subMenuItems: [{
            path: 'currentSales',
            label: 'Current Sales',
            hasSubMenu: false,
            accessTypes: ['PAYMENTADMIN'],
            roleTypes :['payment_admin']
        }, {
            path: 'payouts',
            label: 'Payouts',
            hasSubMenu: false,
            accessTypes: ['PAYMENTADMIN'],
            roleTypes :['payment_admin']
        }],
        accessTypes: ['PAYMENTADMIN'],
        roleTypes :['payment_admin']
    },
    
    {
        path: 'paymentAdmin/paymentLaboratory',
        label: 'Laboratory',
        hasSubMenu: true,
        iconSvg: "/media/svg/icon/Laboratory.svg",
        subMenuItems: [{
            path: 'currentSales',
            label: 'Current Sales',
            hasSubMenu: false,
            accessTypes: ['PAYMENTADMIN'],
            roleTypes :['payment_admin']
        }, {
            path: 'payouts',
            label: 'Payouts',
            hasSubMenu: false,
            accessTypes: ['PAYMENTADMIN'],
            roleTypes :['payment_admin']
        }],
        accessTypes: ['PAYMENTADMIN'],
        roleTypes :['payment_admin']
    },
    
    {
        path: 'paymentAdmin/paymentDoctor',
        label: 'Doctor',
        hasSubMenu: true,
        iconSvg: "/media/svg/icon/Doctor.svg",
        subMenuItems: [{
            path: 'currentSales',
            label: 'Current Sales',
            hasSubMenu: false,
            accessTypes: ['PAYMENTADMIN'],
            roleTypes :['payment_admin']
        }, {
            path: 'payouts',
            label: 'Payouts',
            hasSubMenu: false,
            accessTypes: ['PAYMENTADMIN'],
            roleTypes :['payment_admin']
        }],
        accessTypes: ['PAYMENTADMIN'],
        roleTypes :['payment_admin']
    },
    {
        path: 'paymentAdmin',
        label: 'Payment Admin',
        hasSubMenu: true,
        iconSvg: "media/svg/icons/Shopping/ATM.svg",
        subMenuItems: [ {
            path: 'paymentPharmacy',
            label: 'Pharmacy',
            hasSubMenu: true,
            subMenuItems: [{
                path: 'currentSales',
                label: 'Current Sales',
                hasSubMenu: false,
                accessTypes: ['SUPERADMIN'],
                roleTypes :['super_admin']
            }, {
                path: 'payouts',
                label: 'Payouts',
                hasSubMenu: false,
                accessTypes: ['SUPERADMIN'],
                roleTypes :['super_admin']
            }],
            accessTypes: ['SUPERADMIN'],
            roleTypes :['super_admin']
        },{
            path: 'paymentLaboratory',
            label: 'Laboratory',
            hasSubMenu: true,
            subMenuItems: [{
                path: 'currentSales',
                label: 'Current Sales',
                hasSubMenu: false,
                accessTypes: ['SUPERADMIN'],
                roleTypes :['super_admin']
            }, {
                path: 'payouts',
                label: 'Payouts',
                hasSubMenu: false,
                accessTypes: ['SUPERADMIN'],
                roleTypes :['super_admin']
            }],
            accessTypes: ['SUPERADMIN'],
            roleTypes :['super_admin']
        },{
            path: 'paymentDoctor',
            label: 'Doctor',
            hasSubMenu: true,
            subMenuItems: [{
                path: 'currentSales',
                label: 'Current Sales',
                hasSubMenu: false,
                accessTypes: ['SUPERADMIN'],
                roleTypes :['super_admin']
            }, {
                path: 'payouts',
                label: 'Payouts',
                hasSubMenu: false,
                accessTypes: ['SUPERADMIN'],
                roleTypes :['super_admin']
            }],
            accessTypes: ['SUPERADMIN'],
            roleTypes :['super_admin']
        }],
        accessTypes: ['SUPERADMIN'],
        roleTypes :['super_admin']
    },
    {
        path: 'change-password',
        label: 'Change Password',
        hasSubMenu: false,
        iconSvg: "/media/svg/icons/Files/File.svg",
        subMenuItems: [],
        accessTypes: ['EMPLOYEE', 'HEALTHASSOCIATE'],
        roleTypes :['employee', 'health_associate']
    },
]