import React from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { countryCodeData as options } from '../../../Utils/countyCodeMapping';
import { genderData } from '../../../Utils/Gender';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import makeAnimated from 'react-select/animated';
import {getCode} from '../../../Utils/googleApi';
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import Spinner from "../../../Utils/Components/CustomSpinner";
import { EditBasicInfo } from "../../../modules/Api/Emloyee/ListEmployeeApi";

const animatedComponents = makeAnimated();
    


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function Details (props) {
  const [loading, setLoading] = React.useState(false)
  const theme = useTheme();
    const classes = useStyles();
    const {values, user_type} = props

    const intValues = {
      first_name: values.user.first_name,
      middle_name: values.user.middle_name,
      last_name: values.user.last_name,
      father_first_name: values.father_first_name,
      father_middle_name: values.father_middle_name,
      father_last_name: values.father_last_name,
      gender: values.gender,
      date_of_birth: values.date_of_birth,
      age: values.age,
      country_code: values.user.country_code,
      mobile_number: values.user.mobile_number,
      email : values.user.email,
      date_of_joining: values.date_of_joining,
      role: values.user.role && values.user.role[0],
      pincode: '',
      country: '',
      state: '',
      district: '',
      city_village: '',
      street_name: '',
      latitude: '',
      longitude: '',
    }

    if (values.address && values.address[0]) {
      intValues['pincode']= values.address[0].pincode
      intValues['country']= values.address[0].country
      intValues['state']= values.address[0].state
      intValues['district']= values.address[0].district
      intValues['city_village']= values.address[0].city_village
      intValues['street_name']= values.address[0].street_name
      intValues['latitude']= values.address[0].latitude
      intValues['longitude']= values.address[0].longitude
    }

    

    

    const changePincode = (event) => {
      const pincodeVal = event.target.value
      formik.setFieldValue("pincode", pincodeVal)
      getCode(pincodeVal, addr => {
        if (!addr) {
          return
        }
        if (addr.country){
          formik.setFieldValue("country", addr.country)
        } else {
          formik.setFieldValue("country", '')
        }
        if (addr.state){
          formik.setFieldValue("state", addr.state)
        } else {
          formik.setFieldValue("state", '')
        }
        if (addr.district){
          formik.setFieldValue("district", addr.district)
        } else {
          formik.setFieldValue("district", '')
        }
        if (addr.position.lng){
          formik.setFieldValue("longitude", addr.position.lng)
        }
        if (addr.position.lat){
          formik.setFieldValue("latitude", addr.position.lat)
        }
  
      })
    }

    const onClickNext = () => {
      props.nextStep()
    }

    const editDetails = (data) => {
      const id = props.data.user.id
      console.log(id);
      EditBasicInfo(id, data)
        .then(items => {
          setLoading(false)
          onClickNext()
          if (items && items.data && items.data.message) {
            SuccessToast(items.data.message)
          }
          
        })
        .catch(err => {
          setLoading(false)
          ErrorToast(err)
      })
    }
    
        

          function getAge(dateString) {
            if(!dateString){
                return 0
            }
            const today = new Date();
            const birthDate = new Date(dateString);
            if (today < birthDate) {
                return 0
            }
            const m = today.getMonth() - birthDate.getMonth();
            let age = today.getFullYear() - birthDate.getFullYear();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }
        const numbRex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    
        const formik = useFormik({
          initialValues: intValues,
          validationSchema: Yup.object({
            first_name: Yup.string()
              .required("First name is Required!")
              .nullable(),
            last_name: Yup.string()
             .required("Last Name is Required!")
             .nullable(),
             father_first_name: Yup.string()
               .required("First name is Required!")
               .nullable(),
             father_last_name: Yup.string()
              .required("Last Name is Required!")
              .nullable(),
            gender: Yup.string()
              .required("Gender is Required!")
              .nullable(),
              role: Yup.string()
              .required("Role is Required!")
              .nullable(),
            date_of_birth: Yup.date('Must be a valid date')
            .nullable()
            .max(new Date(), 'Date of birth cannot be a future date' )
            .required('The Date of birth field is required.'),
            date_of_joining: Yup.date('Must be a valid date')
            .nullable()
            .required('The Date of Joining field is required.'),
            email: Yup.string()
              .email("Invalid email format")
              .required("Email is Required!")
              .nullable(),
              mobile_number: Yup.string()
              .required("Mobile number is Required!")
              .max(10, 'Invalid phone number')
              .min(10, 'Invalid phone number')
              .nullable()
              ,
          }),
          onSubmit: values => {
            setLoading(true)
            const data = values
            data['mobile_number'] = String(values.mobile_number)
            console.log('submit');
          editDetails(data)
          }
        });
            
        
        if (loading) {
          return (
            <Spinner />
          )
        }
          
        
    
      return (
        <div className={classes.root}>
          <form onSubmit={formik.handleSubmit}>
      <h5 style={{marginTop:"2%", marginBottom:"2%"}} >Personal Information</h5>
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Employee's Name</h6>
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                disabled
                label="First Name"
                name="first_name"
                type="text"
                value={formik.values.first_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.first_name && formik.touched.first_name && (
                <p style={{color:"red"}}>{formik.errors.first_name}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                disabled
                label="Middle Name"
                name="middle_name"
                type="text"
                value={formik.values.middle_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.middle_name && formik.touched.middle_name && (
                <p style={{color:"red"}}>{formik.errors.middle_name}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                disabled
                label="Last Name"
                name="last_name"
                type="text"
                value={formik.values.last_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.last_name && formik.touched.last_name && (
                <p style={{color:"red"}}>{formik.errors.last_name}</p>
                 )}
            </Grid>
          </Grid>
          
      
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              required
              disabled
                label="Date Of Birth"
                name="date_of_birth"
                type="date"
                value={formik.values.date_of_birth}
                variant="outlined"
                onChange={e => {
                  formik.setFieldValue('age', getAge(e.target.value))
                  formik.handleChange(e);
                }}
                InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={formik.handleBlur}
                  />
                  {formik.errors.date_of_birth && formik.touched.date_of_birth && (
                  <p style={{color:"red"}}>{formik.errors.date_of_birth}</p>
                   )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <TextField
                disabled
                fullWidth
                disabled
                label="Age"
                name="age"
                type="text"
                value={formik.values.age}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.age && formik.touched.age && (
                <p style={{color:"red"}}>{formik.errors.age}</p>
                 )}
            
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              required
              disabled
                label="Date Of Joining"
                name="date_of_joining"
                type="date"
                value={formik.values.date_of_joining}
                variant="outlined"
                onChange={formik.handleChange}
                InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={formik.handleBlur}
                  />
                  {formik.errors.date_of_joining && formik.touched.date_of_joining && (
                  <p style={{color:"red"}}>{formik.errors.date_of_joining}</p>
                   )}
            </Grid>
          </Grid>
          
          <Grid container spacing={3}style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={4}>
            <Select
                disabled
                style={{marginRight:"5%", marginBottom:"2%", width:"30%"}}
                name="country_code"
                type="text"
                value={formik.values.country_code}
                variant="outlined"
                onChange={formik.handleChange}
                >
                  {
                    options.map(
                      (item) => (
                      <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                      )
                    )
                  }
                </Select>
                <FormControl style={{width:"65%"}}  variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Mobile Number</InputLabel>
                <OutlinedInput
                required
                disabled
                    name="mobile_number"
                    type="number"
                    value={parseInt(formik.values.mobile_number)}
                    onChange={formik.handleChange}
                    startAdornment={<InputAdornment position="start">{formik.values.country_code}</InputAdornment>}
                    labelWidth={100}
                    onBlur={formik.handleBlur}
                />
                {formik.errors.mobile_number && formik.touched.mobile_number && (
                <p style={{color:"red"}}>{formik.errors.mobile_number}</p>
                 )}
                </FormControl>
            
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Role ID & Role Name</InputLabel>
              <Select
                disabled
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                // value={age}
                // onChange={handleChange}
                label="Role ID & Role Name"
                value={formik.values.role}
                name='role'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {props.roleList.map((item) =>
                <MenuItem value={item.id}>{item.unique_id+'-'+item.name}</MenuItem>
                )}
              </Select>
            </FormControl>
                {formik.errors.role && formik.touched.role && (
                <p style={{color:"red"}}>{formik.errors.role}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                disabled
                label="Email ID"
                name="email"
                type="text"
                value={formik.values.email}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email && (
                <p style={{color:"red"}}>{formik.errors.email}</p>
                 )}
            </Grid>
          </Grid>

          <Grid container spacing={3}style={{marginTop:"2%", marginBottom:"2%"}}>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl variant="outlined" fullWidth>
            <InputLabel fullWidth >Gender</InputLabel>
            <Select
            required
            fullWidth
            disabled
              value={formik.values.gender}
              onChange={formik.handleChange}
              label="Gender"
              name="gender"
            >
              {
                    genderData.map(
                      (item) => (
                      <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                      )
                    )
                  }
            </Select>
                {formik.errors.gender && formik.touched.gender && (
                <p style={{color:"red"}}>{formik.errors.gender}</p>
                 )}
          </FormControl>
          </Grid>
          </Grid>

          
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Address</h6>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            disabled
            label="Pincode"
            name="pincode"
            type="number"
            value={formik.values.pincode}
            variant="outlined"
            // onChange={(e) => changePincode.bind(e)}
            // onChange={changePincode}
            onBlur={formik.handleBlur}
            />
            {formik.errors.pincode && formik.touched.pincode && (
            <p style={{color:"red"}}>{formik.errors.pincode}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            disabled
            label="Country"
            name="country"
            type="text"
            value={formik.values.country}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.errors.country && formik.touched.country && (
            <p style={{color:"red"}}>{formik.errors.country}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            disabled
            label="State"
            name="state"
            type="text"
            value={formik.values.state}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.errors.state && formik.touched.state && (
            <p style={{color:"red"}}>{formik.errors.state}</p>
             )}
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            disabled
            label="District"
            name="district"
            type="text"
            value={formik.values.district}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.errors.district && formik.touched.district && (
            <p style={{color:"red"}}>{formik.errors.district}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            disabled
            required
            label="City/Village/Society"
            name="city_village"
            type="text"
            value={formik.values.city_village}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.city_village && formik.touched.city_village && (
            <p style={{color:"red"}}>{formik.errors.city_village}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            disabled
            label="Area/Street/Sector"
            name="street_name"
            type="text"
            value={formik.values.street_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
              {formik.errors.street_name && formik.touched.street_name && (
              <p style={{color:"red"}}>{formik.errors.street_name}</p>
               )}
        </Grid>
      </Grid>

      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Fathers's Name</h6>
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                disabled
                label="First Name"
                name="father_first_name"
                type="text"
                value={formik.values.father_first_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.father_first_name && formik.touched.father_first_name && (
                <p style={{color:"red"}}>{formik.errors.father_first_name}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                disabled
                label="Middle Name"
                name="father_middle_name"
                type="text"
                value={formik.values.father_middle_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.father_middle_name && formik.touched.father_middle_name && (
                <p style={{color:"red"}}>{formik.errors.father_middle_name}</p>
                 )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <TextField
                fullWidth
                required
                disabled
                label="Last Name"
                name="father_last_name"
                type="text"
                value={formik.values.father_last_name}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.errors.father_last_name && formik.touched.father_last_name && (
                <p style={{color:"red"}}>{formik.errors.father_last_name}</p>
                 )}
            </Grid>
          </Grid>


    
          
          <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} > 
            <Grid item xs={6} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            </Grid>
            {/* <Grid item xs={3}>
            </Grid>
            
            <Grid item xs={3}>
            </Grid> */}
            <Grid item xs={6} container justify={"flex-end"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" type="submit" >Next</Button> */}
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickNext} >Next</Button> */}
                
            </Grid>
          </Grid> 
          </form>
                
        </div>
      );



}