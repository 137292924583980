import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSubheader } from "../../../../_metronic/layout";
import { CurrentSales, Payout } from "./index"
export const PaymentDoctor = () => {
  // const type = "MED"
  const type = "DOC"
  const suhbeader = useSubheader();
  suhbeader.setTitle("Doctor");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/paymentAdmin/paymentDoctor"
        to="/paymentAdmin/paymentDoctor/currentSales"
      />
      <Route path="/paymentAdmin/paymentDoctor/currentSales/" render={() => <CurrentSales type={type} />} /> 
      <Route path="/paymentAdmin/paymentDoctor/payouts/" render={() => <Payout type={type} />} /> 
    </Switch>
  );
};
