import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
// import { StoresTable } from "./SpecializationTable";
import StoresTable from "./TableApprove";
import { loadTableData } from "../../modules/Api/Pharmacy/ApprovedPharmacyApi";
import Spinner from "../../Utils/Components/CustomSpinner";
import NoRecordFound from "../../Utils/Components/NoRecordFound";
import {ErrorToast} from "../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../modules/Toast/SuccessSnackBar";
import Search from "./Search";
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const ListApproved = (props) => {
  const user_type = props && props.me && props.me.user_type
  const [data, setData] = React.useState(undefined)
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)
  const [name, set_name] = React.useState("")
  const [active, set_active] = React.useState(1)

  const loadData = (data) => {
    setLoading(true)
    setData(undefined)
    loadTableData(data)
    .then(items => {
      console.log(items);
      setLoading(false)
      setData(items.data)
      if (items && items.data && items.data.message) {
        SuccessToast(items.data.message)
      }
      
    })
    .catch(err => {
      ErrorToast(err)
      setLoading(false)
  })
  }

  React.useEffect(() => {
    const data = {
      no : 1,
      active : active,
      name : name
    }
    if (mounted) {
      setMounted(false)
      loadData(data)
    }
  }, [loadData])

  if (loading) {
    return (
      <Spinner />
    )
  }

  const SearchData = (text) => {
    const data = {
      no : 1,
      active : active,
      name : text
    }
    loadData(data)
  }

  const loadPageData = (no) => {
    const data = {
      no : no,
      active : active,
      name : name
    }
    loadData(data)
  }



  return (
    <Card>
      <CardHeader title="Pharmacy list">
        <CardHeaderToolbar>
          
        <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-amount">Status</InputLabel>
              <Select
              style={{marginRight:'10px', width:'200px'}}
              margin="dense"
              required
              label="Status"
              type="text"
              value={active}
              variant="outlined"
              onChange={e => {
                set_active(e.target.value)
                const data = {
                  no : 1,
                  active : e.target.value,
                  name : name
                }
                loadData(data)
              }}
              >
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={2}>Deactivate</MenuItem>
          </Select>
          </FormControl>
          
          <Search SearchData={SearchData} name={name} set_name={set_name} />
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {
          data && <StoresTable loadPageData={loadPageData} data={data} user_type={user_type} 
          active={active}/>
        }
        {
          !data && <NoRecordFound/>
        }

      </CardBody>
    </Card>
  );
};
const mapStateToProps = function (state) {
  return {
    me: state.me,
  }
}

export default connect(mapStateToProps)(ListApproved);
