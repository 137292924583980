import http from "../../../Utils/http";

export function loadTableData(data) {
  const pharams = `filter[approved]=${data.active}&filter[name]=${data.name}&page=${data.no}`
    return http.get(`api/administrator/list/patient/?${pharams}`);
  }

  export function EditBasicInfo(id, data) {
    return http.post(`api/admin/patient/profile/${id}`, data )
  }

  export function GetAddress(id) {
    return http.get(`api/admin/patient/address/${id}`)
  }

  export function EditAddress(id, data) {
    return http.post(`api/admin/patient/address/${id}`, data )
  }

  export function loadListRole() {
    return http.get(`api/admin/list/roles/`);
  }

  export function GetAdditionalConatact(id) {
    return http.get(`api/admin/patient/emergency/${id}`)
  }

  export function EditAdditionalContact(id, data) {
    return http.post(`api/admin/patient/emergency/${id}`, data )
  }

  export function DownloadBPLFile(id) {
    return http.get(`api/admin/patient/bplfile/${id}`,{responseType: 'blob'})
  }

  export function deleteData(id) {
    return http.post(`api/admin/employee/${id}`, {
      _method:'DELETE',
      });
  }

  export function rejectClick(id) {
    const reqData = {
      user_id : id,
      action : "REJECT",
      user_type : "PATIENT",
      comment : ''
    }
    return http.post(`api/admin/action/approveorreject`, reqData )
  }

  export function confirmClick(id) {
    const reqData = {
      user_id : id,
      action : "APPROVE",
      user_type : "PATIENT",
      comment : ''
    }
    return http.post(`api/admin/action/approveorreject`, reqData )
  }
