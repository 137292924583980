// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form} from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { AddData } from "../../../modules/Api/PaymentManagement/TaxApi";
import Spinner from "../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";


const numberandfullRex = /^[1-9]\d*(\.\d+)?$/
// Validation schema
const CategoryEditSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Minimum 2 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Tax Name is required"),
    percent: Yup.string()
        .required("Percentage is required")
        .matches(numberandfullRex, 'Percentage field accepts only integer or decimal')
        .nullable(),
});

export function StoreEditForm({
    data,
    btnRef,
    resetbtnRef,
    saveProduct,
}) 
{
    const [loading, setLoading] = React.useState(false)
    if (loading) {
        return (
        <Spinner />
        )
    }
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={data}
                validationSchema={CategoryEditSchema}
                onSubmit={(values) => {
                    setLoading(true)
                    console.log(values);
                    AddData(values)
                        .then(items => {
                            setLoading(false)
                            if (items && items.data && items.data.message) {
                            SuccessToast(items.data.message)
                            }
                            
                        })
                        .catch(err => {
                            ErrorToast(err)
                            setLoading(false)
                        })
                }}
            >
                {({ handleSubmit, resetForm, handleChange, handleBlur, touched, values, errors }) => (
                    <>
                        <Form className="form form-label-right">
                            <div className="form-group row">
                                <div className="col-lg-8"
                                    style={{marginBottom:"3%"}}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Tax Name"
                                    name="name"
                                    type="text"
                                    margin="dense"
                                    value={values.name}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    />
                                    {errors.name && touched.name && (
                                    <p style={{color:"red"}}>{errors.name}</p>
                                    )}
                                </div>
                                <div className="col-lg-8">
                                <TextField
                                    id="outlined-adornment-height"
                                    fullWidth
                                    required
                                    margin="dense"
                                    variant="outlined"
                                    label="Percentage"
                                    name='percent'
                                    value={values.percent}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    />
                                    {errors.percent && touched.percent && (
                                    <p style={{color:"red"}}>{errors.percent}</p>
                                    )}
                                </div>
                            </div>
                            <button
                                type="submit"
                                style={{ display: "none" }}
                                ref={btnRef}
                                onSubmit={() => handleSubmit()}
                            ></button>
                            <button
                                type="button"
                                style={{ display: "none" }}
                                ref={resetbtnRef}
                                onClick={() => resetForm()}
                            ></button>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
