import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import StoresTable from "./DoctorCommissionTable";
import { LoadData } from "../../../modules/Api/PaymentManagement/DoctorCommissionApi";
import Spinner from "../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import Search from "./Search";

export const DoctorCommissionList = (props) => {
  const [data, setData] = React.useState(undefined)
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)
  const [search, set_search] = React.useState("")

  const loadData = (data) => {
    setData(undefined)
      LoadData(data)
      .then(items => {
        setLoading(false)
        setData(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
        setLoading(false)
    })
  }

  const loadPageData = (no) => {
    const data = {
      no : no,
      search : search
    }
    loadData(data)
  }

  const SearchData = (text) => {
    const data = {
      no : 1,
      search : text
    }
    loadData(data)
  }

  React.useEffect(() => {
    const data = {
      no : 1,
      search : search
    }
    if (mounted) {
      setMounted(false)
      loadData(data)
    }
  }, [loadData])

  if (loading) {
    return (
      <Spinner />
    )
  }

  return (
    <Card>
      <CardHeader title="Doctor Commission">
        <CardHeaderToolbar>
          <Search SearchData={SearchData} name={search} set_name={set_search} />
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {
          data && <StoresTable loadPageData={loadPageData} data={data}/>
        }
      </CardBody>
    </Card>
  );
};
