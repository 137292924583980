import http from "../../../Utils/http";

export function SpecialiationData() {
    return http.get(`api/admin/specialization`);
  }

  export function pageLoadData(no) {
    const pageNo = 'page='+no
    return http.get(`api/admin/specialization?${pageNo}`);
  }

  export function AddSpecialiationData(data) {
    return http.post(`api/admin/specialization`, data);
  }

  export function deleteData(id) {
    return http.post(`api/admin/specialization/${id}`, {
      _method:'DELETE',
      });
  }

  export function editSpecialiationData(data, id) {
    return http.post(`api/admin/specialization/${id}`, data);
  }