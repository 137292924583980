import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { StoreEditForm } from "./EditForm";
class Example extends React.Component {
    constructor(props, context) {
      super(props, context);
  
      this.state = {
        show: false,
      };
  
      this.handleShow = () => {
        this.setState({ show: true });
      };
  
      this.handleHide = () => {
        this.setState({ show: false });
      };
    }

    
  
    render() {
      return (
        <>
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={this.handleShow}
        >
            <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                />
            </span>
        </a>
  
          <Modal
            show={this.state.show}
            onHide={this.handleHide}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
              Doctor Commission Edit
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <StoreEditForm onEdit={this.props.onEdit} handleHide={this.handleHide} row={this.props.row} />
            </Modal.Body>
          </Modal>
        </>
      );
    }
  }
  
 export default Example;
  