import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import ListApproved from "./ListApprove"
import {ApprovedForm} from "./Details/Patient";
import { AddForm } from "./Add/Patient";

export const Patient = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Patient");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/patient"
        to="/patient/list"
      />
      <ContentRoute from="/patient/list" component={ListApproved} />
      <ContentRoute from="/patient/add" component={AddForm} />
      <ContentRoute from="/patient/view" component={ApprovedForm} />
    </Switch>
  );
};
