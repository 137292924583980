import http from "../../../Utils/http";

  export function SendEmailAndMobileOtp(data) {
    return http.post(`api/oauth/password/forgot`, data )
  }

  export function VerifyMobileAndMobileOtp(data) {
    return http.post(`api/oauth/password/forgot/verify`, data )
  }

  export function ChangePassword(data) {
    return http.post(`api/oauth/password/change`, data )
  }