import React from "react";
import {Alert} from 'react-bootstrap';
import {CommentBox} from "./CommentBox"
import {Add} from "./Add"
import Spinner from "../../Utils/Components/CustomSpinner";
import { AddCommentData, CommentData, DeleteCommentData } from "../../modules/Api/commen/CommentsApi";
import {ErrorToast} from "../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../modules/Toast/SuccessSnackBar";

export function View (props) {
    const {id} = props
    const [loading, setLoading] = React.useState(true)
    const [data, setData] = React.useState()
    const [mount, setMount] = React.useState(true)

    const divStyle = {
        overflowY: 'scroll',
        height:'250px',
        position:'relative'
      };

      const loadData = () => {
        setData(undefined)
        CommentData(id)
        .then(items => {
          setLoading(false)
          setData(items.data)
          if (items && items.data && items.data.message) {
            SuccessToast(items.data.message)
          }
          
        })
        .catch(err => {
        //   ErrorToast(err)
          setLoading(false)
      })
      }

      React.useEffect(() => {
          if (mount) {
            loadData()
            setMount(false)
          }
      }, [loadData])

      const OnAdd = (values) => {
        setLoading(true)
        AddCommentData(values)
            .then(items => {
                loadData()
                if (items && items.data && items.data.message) {
                SuccessToast(items.data.message)
                }
                
            })
            .catch(err => {
                ErrorToast(err)
                setLoading(false)
            })
      }

      const OnDelete = (id) => {
        setLoading(true)
        DeleteCommentData(id)
            .then(items => {
                loadData()
                if (items && items.data && items.data.message) {
                SuccessToast(items.data.message)
                }
                
            })
            .catch(err => {
                ErrorToast(err)
                setLoading(false)
            })
      }

      if (loading) {
          return <Spinner />
      }

    return (
        <>
        <div style={divStyle}>
        {
            data ? (data.map(item => (
                <CommentBox item={item} OnDelete={OnDelete} />
            ))) :
            (
                <Alert variant="danger">
                    No comments found!
                </Alert>
            )
        }
        </div>
        <Add id={id} OnAdd={OnAdd}/>
        </>
    )
}