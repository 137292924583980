import React, { useState, useMemo } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../../../modules/Auth/_redux/authRedux";
import { ErrorToast } from "../../../../modules/Toast/ErrorSnakBar";
import { SuccessToast } from "../../../../modules/Toast/SuccessSnackBar";
import Alert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";
import {Timer} from "../../../../Utils/Components/Timer";
import { handleNumber, findCodeFromValue } from '../../../../Utils/CountryMobile';
import { CheckUniqueEmailOrMobile, SendEmailOrMobileOTP, EditBasicInfo, VerifyEmailOrMobileOTP } from "../../../../modules/Api/Profile/profileApi";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { countryCodeData as options } from '../../../../Utils/countyCodeMapping';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';


function Registration(props) {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(<p></p>);
  const [disableEmailSend, setDisableEmailSend] = useState(true);
  const [disableMobileSend, setDisableMobileSend] = useState(true);
  const [mounted, setMounted] = useState(true);
  const [currentCountry, setCurrentCountry] = React.useState()

  const { values } = props

  const email = values.email
  const country_code = values.country_code
  const mobile_number = values.mobile_number
  
  const initialValues = {
    otp: "",
    country_code:country_code,
    mobile_number:mobile_number,
  };

  const [loading, setLoading] = useState(false);

  
  const otp6 = new RegExp(/^[0-9]+$/)
  const RegistrationSchema = Yup.object().shape({
    mobile_number: Yup.string()
    .required("Mobile number is Required!")
    .nullable()
    .test('mobile-num', 'Invalid mobile number', function (value) {
        if (!value) return false;
        const currentCountrycode = currentCountry && currentCountry.code ? currentCountry.code : null
        return handleNumber(value + "", currentCountrycode || "IN")
    }),
    otp: Yup.string()
    .nullable()
    .matches(otp6,"Must be a valid number")
    .length(6, "Must be a 6 digit")
    .required('The Mobile OTP field is required'),
      });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

    

    
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      VerifyEmailOrMobileOTP({email_or_mobile: values.mobile_number, country_code: values.country_code, otp: values.otp})
        .then((data) => {
          if (data && data.data && data.data.message) {
            SuccessToast(data.data.message)
          }
          EditBasicInfo({email: email, country_code: values.country_code, mobile_number: values.mobile_number})
          .then((items) => {
            if (items && items.data && items.data.message) {
              SuccessToast(items.data.message)
            }
          })
          .catch((err) => {
              ErrorToast(err)
              setDisableEmailSend(true)
          });
          disableLoading();
          setSubmitting(false);
          history.push('/profile')
        })
        .catch((err) => {
          ErrorToast(err)
          setSubmitting(false);
          disableLoading();
        });
    },
  });

  

  const sendEmailOTP = () => {
    CheckUniqueEmailOrMobile({'email_or_mobile': formik.values.mobile_number})
    .then((items) => {
      SendEmailOrMobileOTP({'email_or_mobile': formik.values.mobile_number, country_code: formik.values.country_code})
      .then((items) => {
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
      })
      .catch((err) => {
          ErrorToast(err)
          setDisableEmailSend(true)
      });
    })
    .catch((err) => {
        ErrorToast(err)
        setDisableEmailSend(true)
    });
  }
const onClickSendEmailOTP = () => {
  setDisableEmailSend(false)
  sendEmailOTP()
};

// React.useEffect(() => {
//   if(mounted) {
//     setMounted(false)
//     onClickSendMObileOTP()
//     onClickSendEmailOTP()
//   }
  
// }, [onClickSendEmailOTP, onClickSendMObileOTP])

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        {/* <h3 className="font-size-h1"> */}
          {/* <FormattedMessage id="AUTH.REGISTER.TITLE" /> */}
          {/* OTP Verification */}
        {/* </h3> */}
        {/* <p className="text-muted font-weight-bold">
          Enter your new password
        </p> */}
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}


        {/* begin: Email */}
        {<div className="form-group fv-plugins-icon-container">
        <Select
                style={{marginRight:"5%", marginBottom:"2%", width:"30%"}}
                name="country_code"
                type="text"
                value={formik.values.country_code}
                variant="outlined"
                onChange={e => {
                  formik.setFieldValue("country_code", e.target.value)
                  setCurrentCountry(findCodeFromValue(e.target.value))
                }}
                >
                  {
                    options.map(
                      (item) => (
                      <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                      )
                    )
                  }
                </Select>
                <FormControl style={{width:"65%"}}  variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Mobile Number</InputLabel>
                <OutlinedInput
                required
                    name="mobile_number"
                    type="number"
                    value={parseInt(formik.values.mobile_number)}
                    onChange={formik.handleChange}
                    startAdornment={<InputAdornment position="start">{formik.values.country_code}</InputAdornment>}
                    labelWidth={100}
                    onBlur={formik.handleBlur}
                />
                </FormControl>
          {formik.touched.mobile_number && formik.errors.mobile_number ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.mobile_number}</div>
            </div>
          ) : null}
        {/* <div class="text-right">
          <Countdown
              date={emailTime}
              renderer={renderer_email}
            /></div> */}
        
        {disableEmailSend && <div class="text-right">
            <button
            disabled={(formik.values.mobile_number == values.mobile_number && formik.values.country_code == values.country_code) || formik.errors.mobile_number}
            onClick={onClickSendEmailOTP}
              type="button"
              className="btn btn-light-primary font-weight-bold py-4 my-3"
            >
              Save Changes
            </button>
            </div>}
        </div>}
        {/* end: Email */}

        {!disableEmailSend && <Timer 
        id='mobile_number'
            setDisable={setDisableEmailSend}
            />}

        <div style={{marginTop:'20px'}} className="form-group fv-plugins-icon-container">
          <input
            placeholder="Mobile OTP"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "otp"
            )}`}
            name="otp"
            {...formik.getFieldProps("otp")}
          />
          {formik.touched.otp && formik.errors.otp ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.otp}</div>
            </div>
          ) : null}
        </div>
        
        {errorMessage}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid 
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Verify</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
            <button
              onClick={props.handleHide}
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
