import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import PharmacyForm from "./Form";
import { useHistory } from "react-router-dom";

export const AddForm = (props) => {


  const history = useHistory();
  const saveStore = () => { }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  return (
    <Card>
      <CardHeader title="Add Patient">
        <CardHeaderToolbar>
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={() => history.push('/patient')}
          >
            Back to List
            </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
        {<PharmacyForm
            // actionsLoading={actionsLoading}
            // data={data}
            btnRef={btnRef}
            resetbtnRef={resetbtnRef}
            saveProduct={saveStore}
          >
          </PharmacyForm>}
        </div>
      </CardBody>
    </Card>
  )
};
