import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditButton from "./EditButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    // justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(30),
      height: theme.spacing(20),
    },
  },
  card: {
    maxWidth: 245,
    margin: 10
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  edit: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function SimplePaper(props) {
  const classes = useStyles();
  const [data, setData] = useState(props.values)
  const view_only = props.view_only

  

  const updateData = (val) => {
    setData([...val])
    console.log('updateData', data);
  }

  return (
    <div className={classes.root}>
        {data &&
            data.map((clinicAddress, index) =>
            <Paper elevation={3} >
                {/* <Card className={classes.card}> */}
          <CardHeader
            avatar={
              <Avatar aria-label="Recipe" className={classes.avatar}>
                {clinicAddress.clinic_name && clinicAddress.clinic_name[0].toUpperCase()}
              </Avatar>
            }
            // action={
            //   <IconButton aria-label="Settings" color="secondary">
            //     <DeleteTwoToneIcon />
            //   </IconButton>
            // }
            title={clinicAddress.clinic_name}
            subheader={String(clinicAddress.street_name)+', '+String(clinicAddress.city_village)+', '+String(clinicAddress.district)+', '+String(clinicAddress.state)+', '+String(clinicAddress.country)+', '+String(clinicAddress.pincode)}
          />
          {/* <CardContent> */}
          {/* <Typography variant="body2" color="textSecondary" component="p">
            {String(clinicAddress.clinic_name)+', '+String(clinicAddress.street_name)+', '+String(clinicAddress.city_village)+', '+String(clinicAddress.district)+', '+String(clinicAddress.state)+', '+String(clinicAddress.country)+', '+String(clinicAddress.pincode)}
          </Typography> */}



            {/* <Typography variant="body2" color="textSecondary" component="p">
            Clinic Name: {clinicAddress.clinic_name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Street Name: {clinicAddress.street_name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              City/Village: {clinicAddress.city_village}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              District: {clinicAddress.district}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              State: {clinicAddress.state}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Country: {clinicAddress.country}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Pincode: {clinicAddress.pincode}
            </Typography> */}
          {/* </CardContent> */}
          <CardActions disableSpacing >
           {!view_only && <div className={clsx(classes.edit)}> 
              <EditButton pharmacyList={props.pharmacyList} laboratoryList={props.laboratoryList} updateAddressData={props.updateAddressData} updateData={updateData}  address={clinicAddress} updateMdelData={props.updateMdelData} data={data} index={index} />
           </div>}
            
          </CardActions>
          
        {/* </Card> */}
            </Paper>
            )
        }
      
    </div>
  );
}
