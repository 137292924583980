import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { UserTypes } from "./pages/user-types";
import { DashboardPage } from "./pages/DashboardPage";
import { Specialization } from "./pages/Specialization";
import { Test } from "./pages/Test";
import { Medicine } from "./pages/Medicine";
import { Pharmacy } from "./pages/Pharmacy";
import { Laboratory } from "./pages/Laboratory";
import { Doctor } from "./pages/Doctor";
import { Employee } from "./pages/Employee";
import { Patient } from "./pages/Patient";
import { Profile } from "./pages/Profile";
import { PaymentManagement } from "./pages/PaymentManagement";
import { PaymentPharmacy } from "./pages/PaymentAdmin/Payment";
import { PaymentAdmin } from "./pages/PaymentAdmin";
import { ChangePassword } from "./pages/ChangePassword";
import { Appointments } from "./pages/Appointments";

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard" />
                }
                <ContentRoute path="/dashboard" component={DashboardPage} />
                <ContentRoute path="/user-types" component={UserTypes} />
                <ContentRoute path="/specialization" component={Specialization} />
                <ContentRoute path="/test" component={Test} />
                <ContentRoute path="/pharmacy" component={Pharmacy} />
                <ContentRoute path="/laboratory" component={Laboratory} />
                <ContentRoute path="/doctor" component={Doctor} />
                <ContentRoute path="/employee" component={Employee} />
                <ContentRoute path="/appointments" component={Appointments} />
                <ContentRoute path="/patient" component={Patient} />
                <ContentRoute path="/profile" component={Profile} />
                <ContentRoute path="/medicine" component={Medicine} />
                <ContentRoute path="/paymentManagement" component={PaymentManagement} />
                <ContentRoute path="/paymentAdmin" component={PaymentAdmin} />
                <ContentRoute path="/paymentPharmacy" component={PaymentPharmacy} />
                <ContentRoute path="/change-password" component={ChangePassword} />
                <Redirect to="error/error-v1" />
            </Switch>
        </Suspense>
    );
}
