import React from 'react';
import { Card } from 'react-bootstrap';
import getSymbolFromCurrency from 'currency-symbol-map'
import { GetCurrencyCode } from "../../../../../../Utils/GetData";

export default function (props) {
    const { type, data } = props
    const currency_code = GetCurrencyCode()
    return (
        <>
        <div className="d-flex justify-content-center" style={{width:'100%', margin:'30px' }} >
                <div className="p-2 col-example text-left" style={{width:'100%'}} >
                    <Card bg="primary" text="white">
                        <Card.Body>
                        <Card.Title>Total Payable</Card.Title>
                        <Card.Text>
                        {getSymbolFromCurrency(currency_code)} {data.total_payable}
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="p-2 col-example text-left" style={{width:'100%'}} >
                    <Card bg="primary" text="white">
                        <Card.Body>
                        <Card.Title>Total Paid</Card.Title>
                        <Card.Text>
                        {getSymbolFromCurrency(currency_code)} {data.total_paid}
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="p-2 col-example text-left" style={{width:'100%'}} >
                <Card bg="primary" text="white">
                    <Card.Body>
                    <Card.Title>Balance</Card.Title>
                    <Card.Text>
                    {getSymbolFromCurrency(currency_code)} {data.balance}
                    </Card.Text>
                    </Card.Body>
                </Card>
            </div>
                <div className="p-2 col-example text-left" style={{width:'100%'}} >
                <Card bg="primary" text="white">
                    <Card.Body>
                    <Card.Title>Our Earnings</Card.Title>
                    <Card.Text>
                    {getSymbolFromCurrency(currency_code)} {data.earnings}
                    </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </div>
</>
    )
}