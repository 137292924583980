import http from "../../../Utils/http";

export function LoadCommissionData() {
    return http.get(`api/taxservice/commission?paginate=0`);
  }

  export function AddData(data) {
    return http.post(`api/admin/taxservice/commission`, data);
  }

  export function deleteData(id) {
    return http.post(`api/admin/taxservice/${id}/commission`, {
      _method:'DELETE',
      });
  }

  export function editData(data, id) {
    return http.post(`api/admin/taxservice/${id}/commission`, data);
  }