// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Card } from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Spinner from "../../../../../../../Utils/Components/CustomSpinner";
import { makeStyles } from '@material-ui/core/styles';
import getSymbolFromCurrency from 'currency-symbol-map'
import { GetCurrencyCode } from "../../../../../../../Utils/GetData";

const useStyles = makeStyles(theme => ({
    label: {
        // width: '150px',
  
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '26px',
        letterSpacing: '-0.008em',
  
      },
      content: {
          color: '#77777A',
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '26px',
          letterSpacing: '-0.008em',
    
        },
    }));


export function ViewCardList(props) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false)
    const { data } = props

    
    const onOpenPdf = () => {
      // window.open(Quotedata.prescription.pdf_url)
    }
    if (loading) {
        return (
        <Spinner />
        )
    }
    return (
        <div>
            {data.payout_history.map((item) => (
              <Card border="dark" style={{marginBottom: '5px'}} >
              <Card.Body>
              <Grid container spacing={3}>
                  <Grid item xs={6}>
                  <Typography className={classes.label}>
                    Date & Time:
                  </Typography>
                  </Grid>
                  <Grid item xs={6}>
                  <Typography className={classes.content}>
                  {item.created_at}
                  </Typography>
                  </Grid>
              </Grid>
              <Grid container spacing={3}>
                  <Grid item xs={6}>
                  <Typography className={classes.label}>
                  Paid Amount:
                  </Typography>
                  </Grid>
                  <Grid item xs={6}>
                  <Typography className={classes.content}>
                  {getSymbolFromCurrency(GetCurrencyCode())} {item.amount}
                  </Typography>
                  </Grid>
              </Grid>
              <Grid container spacing={3}>
                  <Grid item xs={6}>
                  <Typography className={classes.label}>
                  Reference Id:
                  </Typography>
                  </Grid>
                  <Grid item xs={6}>
                  <Typography className={classes.content}>
                  {item.reference}
                  </Typography>
                  </Grid>
              </Grid>
              </Card.Body>
          </Card>
            ))}
        </div>
    );
}
