import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
// import { StoresTable } from "./SpecializationTable";
import StoresTable from "./Table";
import { loadTableData, pageLoadData } from "../../modules/Api/Test/TestApi";
import Spinner from "../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../modules/Toast/SuccessSnackBar";

export const ListStores = (props) => {
  const [data, setData] = React.useState(undefined)
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)

  const loadData = () => {
    if (mounted) {
      setMounted(false)
      loadTableData()
      .then(items => {
        setMounted(false)
        setLoading(false)
        setData(items.data)
        // console.log(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
        setMounted(false)
        setLoading(false)
    })
    }
  }

  const updateData = () => {
    setData(undefined)
    loadTableData()
      .then(items => {
        setData(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
    })
  }

  const loadPageData = (no) => {
    setData(undefined)
    setLoading(true)
    pageLoadData(no)
      .then(items => {
        setData(items.data)
        setLoading(false)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
    })
  }

  React.useEffect(() => {
    loadData()
  }, [loadData])

  if (loading) {
    return (
      <Spinner />
    )
  }

  return (
    <Card>
      <CardHeader title="Test list">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => props.history.push('/test/add')}
          >
            Add new Test
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {
          data && <StoresTable loadPageData={loadPageData} data={data} updateData={updateData} />
        }
      </CardBody>
    </Card>
  );
};
