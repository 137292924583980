import React from "react";
import {Button, Modal} from "react-bootstrap";
import getSymbolFromCurrency from 'currency-symbol-map'
import { GetCurrencyCode } from "../../Utils/GetData";
import moment from "moment";


export function AppointmentModal (props) {
    const {row} = props
    const [show, setShow] = React.useState(false)
    // console.log('row', row);
  
    const handleClose = () => {
        setShow(false)
    }
  
    const handleShow = () => {
        setShow(true)
    }

      return (
        <>
          <Button variant="link" onClick={handleShow}>{row?.appointment_unique_id}</Button>
  
          <Modal centered show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Appointment Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="row form-row">
            <div className="col-lg-6 col-md-12">
              Consultation Fee:
            </div>
            <div className="col-lg-6 col-md-12">
            {getSymbolFromCurrency(GetCurrencyCode())} {Math.round((parseFloat(row?.total) - parseFloat(row?.tax) + Number.EPSILON) * 100) / 100
}
            </div>
            </div>
            
            <div style={{marginTop:"10px"}} className="row form-row">
            <div className="col-lg-6 col-md-12">
              Tax and services fee:
            </div>
            <div className="col-lg-6 col-md-12">
            {getSymbolFromCurrency(GetCurrencyCode())} {parseFloat(row?.tax)}
            </div>
            </div>
            
            <div style={{marginTop:"10px"}} className="row form-row">
            <div className="col-lg-6 col-md-12">
              Verified consultation fee:
            </div>
            <div className="col-lg-6 col-md-12">
            {getSymbolFromCurrency(GetCurrencyCode())} {parseFloat(row?.total)}
            </div>
            </div>
            
            <div style={{marginTop:"10px"}} className="row form-row">
            <div className="col-lg-6 col-md-12">
              Assured meet with:
            </div>
            <div className="col-lg-6 col-md-12">
            Dr. {row?.doctor?.first_name} {row?.doctor?.middle_name ? row?.doctor?.middle_name : ""} {row?.doctor?.last_name}
            </div>
            </div>
            
            <div style={{marginTop:"10px"}} className="row form-row">
            <div className="col-lg-6 col-md-12">
              Patient name:
            </div>
            <div className="col-lg-6 col-md-12">
            {row?.current_patient_info?.info?.first_name} {row?.current_patient_info?.info?.middle_name ? row?.current_patient_info?.info?.middle_name : ""} {row?.current_patient_info?.info?.last_name}
            </div>
            </div>
            
            <div style={{marginTop:"10px"}} className="row form-row">
            <div className="col-lg-6 col-md-12">
              Email:
            </div>
            <div className="col-lg-6 col-md-12">
            {row?.current_patient_info?.info?.email}
            </div>
            </div>
            
            <div style={{marginTop:"10px"}} className="row form-row">
            <div className="col-lg-6 col-md-12">
              Mobile Number:
            </div>
            <div className="col-lg-6 col-md-12">
            {row?.current_patient_info?.info?.country_code} {row?.current_patient_info?.info?.mobile_number}
            </div>
            </div>
            
            <div style={{marginTop:"10px"}} className="row form-row">
            <div className="col-lg-6 col-md-12">
              Booking Date:
            </div>
            <div className="col-lg-6 col-md-12">
            {row?.booking_date ? row?.booking_date : "NA"}
            </div>
            </div>
            
            <div style={{marginTop:"10px"}} className="row form-row">
            <div className="col-lg-6 col-md-12">
              Appointment Date:
            </div>
            <div className="col-lg-6 col-md-12">
            {row?.date ? row?.date : "NA"}
            </div>
            </div>
            
            <div style={{marginTop:"10px"}} className="row form-row">
            <div className="col-lg-6 col-md-12">
              Appointment Time:
            </div>
            <div className="col-lg-6 col-md-12">
            {row?.time ? (moment(row?.time, 'hh:mm:ss').format('hh:mm A')) : "NA"}
            </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
  }
  