import {toast} from 'react-toastify';  
import 'react-toastify/dist/ReactToastify.css';  
import { logOut } from "../Auth/Logout";
    
toast.configure()  
export function ErrorToast(err){ 
    const notify = ()=>{ 
        if (err.response && err.response.data && err.response.data.message && !err.response.data.errors && err.response && err.response.status !== 401){
            toast.error(err.response.data.message, {autoClose:3000})
          } 
        if (err.response && err.response.data && err.response.data.errors){
        for (const [key, value] of Object.entries(err.response.data.errors)) {
            value.map((item) =>
                toast.error(item, {autoClose:3000})
            )
            
        }
        }
        if (err.response && err.response.status === 401) {
            // toast.error('Need to Loggedin again', {autoClose:3000})
            logOut()
        }
    } 
    notify()  
} 