import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Pagination } from "../../../_metronic/_partials/controls";
import {
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
} from "../../../_metronic/_helpers"
import { ActionsColumnFormatter } from "../page-components/TableActionFieldFormatter"
export function UserTypeTable() {
    const paginationOptions = {
        custom: true,
        totalSize: 100,
        sizePerPageList: [
            { text: "3", value: 3 },
            { text: "5", value: 5 },
            { text: "10", value: 10 }
        ],
        sizePerPage: 10,
        page: 1,
    };
    const sampledata = [
        {
            slNo: 1,
            name: 'Admin',
        },
        {
            slNo: 2,
            name: 'Contributers',
        }
    ]
    const { totalCount, entities, listLoading } = { totalCount: 0, entities: [...sampledata], listLoading: false };


    const columns = [
        {
            dataField: "slNo",
            text: "SL NO",
            sort: true,
            sortCaret: sortCaret,
        },
        {
            dataField: "name",
            text: "Name",
            sort: true,
            sortCaret: sortCaret,
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: ActionsColumnFormatter,
            formatExtraData: {
                openEditProductPage: () => { console.log('openEditProductPage') },
                openDeleteProductDialog: () => { console.log('openEditProductPage') },
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        },
    ];

    return (
        <>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination
                            isLoading={listLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                bordered={false}
                                remote
                                keyField="slNo"
                                data={entities === null ? [] : entities}
                                columns={columns}
                                // defaultSorted={[{ dataField: "slNo", order: "asc" }]}
                                onTableChange={() => { console.log("table changed") }}
                                // selectRow={()=>{}}
                                {...paginationTableProps}
                            >
                                <PleaseWaitMessage entities={entities} />
                                <NoRecordsFoundMessage entities={entities} />
                            </BootstrapTable>
                        </Pagination>
                    );
                }}
            </PaginationProvider>
        </>
    );
}