import React, { Component } from 'react';
import EmployeeDetails from './EmployeeDetails';
import HealthDetails from './HealthDetails';
import Address from './Address';
// import Details from "./error";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import {loadListRole } from "../../../modules/Api/Emloyee/ListEmployeeApi";
import {me } from "../../../modules/Api/me";


export default class UserForm extends Component {
    constructor(props) {
        super(props);
        
        // this.updateData = this.updateData.bind(this)
    
        this.state = {
            step: 1,
            user_id:undefined,
            roleList:'',
            mounted:true,
            me:undefined,
        }
        };

        

        loadRoleLit = () => {
            loadListRole()
            .then(items => {
                this.setState({roleList:items.data})
                if (items && items.data && items.data.message) {
                  SuccessToast(items.data.message)
                }
                
              })
              .catch(err => {
                ErrorToast(err)
            })
        }

        findMe = () => {
            me()
            .then(items => {
                this.setState({me:items.data})
                if (items && items.data && items.data.message) {
                  SuccessToast(items.data.message)
                }
                
              })
              .catch(err => {
                ErrorToast(err)
            })
        }

        componentWillMount() {
            if (this.state.mounted) {
            this.setState({mounted:false})
            this.findMe()
                if(this.props.user_type == 'EMPLOYEE') {
                    this.loadRoleLit()
                }
            }
            
        }

        

    

    
    // next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        })
    }

    updateData = (data) => {
        this.setState({user_id:data})
    }

    // previous step
    previosStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        })
    }


    render() {

        const { step } = this.state

        switch (step) {
            case 1 :
                return (
                    <div>
                        {this.props.user_type == 'EMPLOYEE' && this.state.roleList && 
                        <EmployeeDetails
                    nextStep={this.nextStep}
                    updateData={this.updateData}
                    values={this.props.data}
                    roleList={this.state.roleList}
                    user_type={this.props.user_type}
                    />}
                    {this.props.user_type == 'HEALTHASSOCIATE' && this.state.me &&
                    <HealthDetails
                    nextStep={this.nextStep}
                    updateData={this.updateData}
                    values={this.props.data}
                    roleList={this.state.roleList}
                    user_type={this.props.user_type}
                    me ={this.state.me}
                    findMe={this.findMe}
                    />}
                    </div>
                )
            case 2 :
                return (
                    <Address
                    user_id={this.state.user_id}
                    nextStep={this.nextStep}
                    previosStep={this.previosStep}
                    updateData={this.updateData}
                    values={this.props.data}
                    />
                )
            default:
                return (
                    <div></div>
                )
        }
    }
}