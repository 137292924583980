// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { AddData } from "../../../modules/Api/Medicine/CategoryApi";
import Spinner from "../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import TextField from '@material-ui/core/TextField';
import {FileUpload} from "../../../Utils/Components/FileUpload/FileUpload";

const FILE_SIZE = 1024 * 1024 * 2;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
];

// Validation schema
const CategoryEditSchema = Yup.object().shape({
    name: Yup.string()
        .required("Category Name field is required"),
        image: Yup.mixed()
            .test(
            "fileSize",
            "Image file size should not be greater than 2mb",
            function (value) {
                if (!value) return true;
                return value.size <= FILE_SIZE
            })
            .test(
            "fileFormat",
            "Image format should be jpg, jpeg, png",
            function (value) {
                if (!value) return true;
                return SUPPORTED_FORMATS.includes(value.type)
            }),
});

export function StoreEditForm(props) 
{
    const {
        data,
        btnRef,
        resetbtnRef,
    } = props
    const [loading, setLoading] = React.useState(false)
    const imgRef= React.createRef();
    if (loading) {
        return (
        <Spinner />
        )
    }
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={data}
                validationSchema={CategoryEditSchema}
                onSubmit={(values) => {
                    setLoading(true)
                    let formData = new FormData();
                    formData.set('name', values.name)
                    formData.set('image', values.image)
                    AddData(formData)
                        .then(items => {
                            setLoading(false)
                            if (items && items.data && items.data.message) {
                            SuccessToast(items.data.message)
                            }
                            
                        })
                        .catch(err => {
                            ErrorToast(err)
                            setLoading(false)
                        })
                }}
            >
                {({ handleSubmit, setFieldValue, resetForm, handleChange, handleBlur, touched, values, errors }) => (
                    <>
                        <Form className="form form-label-right">
                            {/* <label>Medicine name<span>&nbsp;*</span></label> */}
                            <div className=" row">
                                <div className="col-lg-8">
                                    <TextField
                                    style={{marginBottom:'3%'}}
                                    fullWidth
                                    required
                                        label="Category Name"
                                        name="name"
                                        type="text"
                                        value={values.name}
                                        variant="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        // error={errors.name}
                                        margin='dense'
                                        />
                                        {errors.name && touched.name && (
                                        <p style={{color:"red"}}>{errors.name}</p>
                                        )}
                                </div>
                                <div className="col-lg-4">
                                    
                                    <FileUpload
                                        // mandatory
                                        label='Upload Category Image'
                                        errors={errors.image}
                                        name="image"
                                        value={values.image}
                                        onChange={value => {
                                        setFieldValue("image", value)
                                        }}
                                    />
                                </div>
                            </div>
                            <button
                                type="submit"
                                style={{ display: "none" }}
                                ref={btnRef}
                                onSubmit={() => handleSubmit()}
                            ></button>
                            <button
                                type="button"
                                style={{ display: "none" }}
                                ref={resetbtnRef}
                                onClick={() => resetForm()}
                            ></button>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
