import React from 'react'
import { paymentLoadData } from "../../../../../../../modules/Api/PaymentAdmin/Payment/PayOutsApi";
import Spinner from "../../../../../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../../../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../../../../../modules/Toast/SuccessSnackBar";
import { ViewCardList } from "./ViewCardList";
import { ViewCard } from "./ViewCard";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    label: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '26px',
        letterSpacing: '-0.008em',
  
      },
      content: {
          color: '#77777A',
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '26px',
          letterSpacing: '-0.008em',
    
        },
    }));


export function HistoryForm (props) {
  const classes = useStyles();
    const { type, row } = props
    const [data, setData] = React.useState(undefined)
    const [mounted, setMounted] = React.useState(true)
    const [loading, setLoading] = React.useState(true)

    const loadData = () => {
        paymentLoadData(row.id, type)
        .then(items => {
          setData((items.data)[0])
          if (items && items.data && items.data.message) {
            SuccessToast(items.data.message)
          }
          
        })
        .catch(err => {
          ErrorToast(err)
      })
    }
    React.useEffect(() => {
        if (mounted) {
          loadData()
          setMounted(false)
          setLoading(false)
        }
      }, [loadData])
    
      if (loading) {
        return (
          <Spinner />
        )
      }
    return (
        <div>
          {data && 
          <div>
            <ViewCard data={data} />
            <ViewCardList data={data} />
          </div>}
        </div>
    )
}