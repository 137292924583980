
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';
import { useHistory } from "react-router-dom";

import { rejectClick, confirmClick } from "../../modules/Api/Patient/ListPatientApi";
import Spinner from "../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../modules/Toast/SuccessSnackBar";
import DeleteModal from "./ApprovedDetails/DeleteModal";
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import ApproveModel from "./ApprovedDetails/ApproveModel";
import CommentModel from "../../Components/Comment/Model";


const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));



const useStyles2 = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

export default function CustomPaginationActionsTable(props) {
  const history = useHistory();
  const classes = useStyles2();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(false)

  const rows = props.data
  const {loadPageData, user_type, active} = props
  const [page, setPage] = React.useState(rows.current_page - 1);

  var is_health_assosiate = false
  if (user_type === "HEALTHASSOCIATE") {
    is_health_assosiate = true
  }

  var is_admin = false
  if (user_type === "SUPERADMIN") {
    is_admin = true
  }

  
  const fakeData = []
  for (var i=1; i<=page*10; i++)  
  {  
    fakeData.push(rows.data[0]) 
  }  

  const data = []
  rows.data.map((item, index) =>
  data.push({
    slNo: (page)*10+(index+1),
    item: item
}) )

  // const data = rows.data
  const rowData = [...fakeData, ...data];


  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    function handleFirstPageButtonClick(event) {
      onChangePage(event, 0);
      loadPageData(1)
    }
  
    function handleBackButtonClick(event) {
      onChangePage(event, page - 1);
      loadPageData(page)
    }
  
    function handleNextButtonClick(event) {
      onChangePage(event, page + 1);
      loadPageData(page + 2)
    }
  
    function handleLastPageButtonClick(event) {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      loadPageData(Math.max(0, Math.ceil(count / rowsPerPage)))
    }
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: '#64ADED',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
  }

  const onDelete = (id) => {
    setLoading(true)
    let newPage = page + 1
    if (rows.data.length == 1) {
        newPage = page
    } else {
        newPage = page + 1
    }
    rejectClick(id)
        .then(items => {
          loadPageData(newPage)
            setLoading(false)
            if (items && items.data && items.data.message) {
            SuccessToast(items.data.message)
            }
            
        })
        .catch(err => {
            setLoading(false)
            ErrorToast(err)
        })
}

const onConfirm = (id) => {
  setLoading(true)
  let newPage = page + 1
  if (rows.data.length == 1) {
      newPage = page
  } else {
      newPage = page + 1
  }
  confirmClick(id)
      .then(items => {
        loadPageData(newPage)
          setLoading(false)
          if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
          }
          
      })
      .catch(err => {
          setLoading(false)
          ErrorToast(err)
      })
}

const onEdit = (values, id) => {
  
}
if (loading) {
  return (
    <Spinner />
  )
}

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
        <TableHead>
          <TableRow>
          			 		
            {/* <StyledTableCell component="th" scope="row">SL no</StyledTableCell> */}
            <StyledTableCell  align="center">SL NO</StyledTableCell>
            <StyledTableCell  align="center">PATIENT ID</StyledTableCell>
            <StyledTableCell align="center">PATIENT NAME</StyledTableCell>
            <StyledTableCell align="center">AGE</StyledTableCell>
            <StyledTableCell  align="center">COUNTRY</StyledTableCell>
            <StyledTableCell align="center">STATE</StyledTableCell>
            <StyledTableCell  align="center">CITY/VILLAGE</StyledTableCell>
            <StyledTableCell  align="center">MOBILE NUMBER</StyledTableCell>
            <StyledTableCell  align="center">EMAIL</StyledTableCell>
            <StyledTableCell  align="center">Status</StyledTableCell>
            <StyledTableCell align="center">ACTIONS</StyledTableCell>
          </TableRow>
        </TableHead>
          <TableBody>
            {rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">{row.slNo}</TableCell>
                <TableCell align="center">{row.item.patient_unique_id}</TableCell>
              {row.item.user.middle_name == null && <TableCell align="center">{row.item.user.first_name+' '+row.item.user.last_name}</TableCell>}
              {row.item.user.middle_name !== null && <TableCell align="center">{row.item.user.first_name+' '+row.item.user.middle_name+' '+row.item.user.last_name}</TableCell>}
              <TableCell align="center"> {row.item.age}</TableCell>
              {row.item.address[0] && <TableCell align="center">{row.item.address[0].country}</TableCell>}
              {!row.item.address[0] && <TableCell align="center"> </TableCell>}
              {row.item.address[0] && <TableCell align="center">{row.item.address[0].state}</TableCell>}
              {!row.item.address[0] && <TableCell align="center"> </TableCell>}
              {row.item.address[0] && <TableCell align="center">{row.item.address[0].city_village}</TableCell>}
              {!row.item.address[0] && <TableCell align="center"> </TableCell>}
              <TableCell align="center">{row.item.user.mobile_number}</TableCell>
              <TableCell align="center">{row.item.user.email}</TableCell>
              <TableCell align="center">{active === 1 ? "Active" : "Deactivated"}</TableCell>
                
                <TableCell >
                <div className="d-flex justify-content-center">
                  <div className="p-2 col-example text-left">
                  <a
                    className="btn btn-icon btn-light btn-hover-success btn-sm"
                    onClick={() => history.push({
                      pathname: '/patient/view',
                      state: { detail: row.item,
                      type: 'view'}
                    })}
                >
                <VisibilitySharpIcon color='primary' />
            </a>
                  </div>
                  {!is_health_assosiate && <>
                  <div className="p-2 col-example text-left">
                  <a
                    className="btn btn-icon btn-light btn-hover-success btn-sm"
                    onClick={() => history.push({
                      pathname: '/patient/view',
                      state: { detail: row.item,
                        type: 'edit'}
                    })}
                >
                    <EditTwoToneIcon color='primary' />
                </a>
                  </div>
                  {
                   active === 1 ? <div className="p-2 col-example text-left">
                  <DeleteModal row={row.item} onDelete={onDelete} />
                  </div>
                  : <div className="p-2 col-example text-left">
                  <ApproveModel row={row.item} onConfirm={onConfirm} />
                  </div>
                  }
                  {is_admin && <div className="p-2 col-example text-left">
                  <CommentModel row={row.item} />
                  </div>}
                  </>}
                </div>
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 48 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={10}
                // colSpan={3}
                count={rows.total}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'Rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </Paper>
  );
}
