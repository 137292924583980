import http from "../../../../Utils/http";

  export function loadMainTableData(data, type) {
    const pharams = `type=${type}&name=&today=${data.today}&last_30_days=${data.last_30_days}&financial_year=${data.financial_year}&custom[start_date]=${data.start_date}&custom[end_date]=${data.end_date}`
    return http.get(`api/sales/?${pharams}`);
  }

  export function pageMainLoadData(no, data, type) {
    const pharams = 'page='+no+`&type=${type}&name=&today=${data.today}&last_30_days=${data.last_30_days}&financial_year=${data.financial_year}&custom[start_date]=${data.start_date}&custom[end_date]=${data.end_date}`
    return http.get(`api/sales/?${pharams}`);
  }

  export function searchMainLoadData(text, data, type) {
    const pharams = `type=${type}&today=${data.today}&last_30_days=${data.last_30_days}&financial_year=${data.financial_year}&custom[start_date]=${data.start_date}&custom[end_date]=${data.end_date}&name=`+text
    return http.get(`api/sales/?${pharams}`);
  }

  export function graphLoadData(type) {
    const pharams = `type=${type}`
    return http.get(`api/sales/chart/?${pharams}`);
  }

  export function recentTransactionLoadData(type) {
    const pharams = `type=${type}`
    return http.get(`api/sales/recent/?${pharams}`);
  }