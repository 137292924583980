import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Spinner from "../../../../Utils/Components/CustomSpinner";
import {getCode} from '../../../../Utils/googleApi';
import { countryCodeData as options } from '../../../../Utils/countyCodeMapping';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { AddressType } from '../../../../Utils/AddressType';
import {ErrorToast} from "../../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../../modules/Toast/SuccessSnackBar";
import { EditAddress } from "../../../../modules/Api/Patient/ListPatientApi";
import { handleNumber, findCodeFromValue } from '../../../../Utils/CountryMobile';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Details(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [currentCountry, CurrentCountry] = React.useState()
  const data = props.address

  const editDetails = (data) => {
    const id = data.id
    EditAddress(id, data)
      .then(items => {
        props.loadData()
        props.handleClose()
        setLoading(false)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
        setLoading(false)
    })
  }

  const changePincode = (event) => {
    const pincodeVal = event.target.value
    // formik.setFieldValue("pincode", pincodeVal)
    getCode(pincodeVal, addr => {
      if (!addr) {
        return
      }
      if (addr.country){
        formik.setFieldValue("country", addr.country)
      } else {
        formik.setFieldValue("country", '')
      }
      if (addr.state){
        formik.setFieldValue("state", addr.state)
      } else {
        formik.setFieldValue("state", '')
      }
      if (addr.district){
        formik.setFieldValue("district", addr.district)
      } else {
        formik.setFieldValue("district", '')
      }
      if (addr.position.lng){
        formik.setFieldValue("longitude", addr.position.lng)
      }
      if (addr.position.lat){
        formik.setFieldValue("latitude", addr.position.lat)
      }

    })
  }
  var initValues = {
    pincode	: '',
    street_name	: '',
    city_village	: '',
    district	: '',
    state	: '',
    country	: '',
    address_type	: 'HOME',
    latitude: '',
    longitude: '',
    contact_number: '',
    country_code: '+91',
    land_mark: ''
}

  if (data) {
  initValues = {
                pincode	: data.pincode,
                street_name	: data.street_name,
                city_village	: data.city_village,
                district	: data.district,
                state	: data.state,
                country	: data.country,
                address_type	: data.address_type,
                latitude: data.latitude,
                longitude: data.longitude,
                id: data.id,
                contact_number: data.contact_number,
                country_code: data.country_code ? data.country_code : '+91',
                land_mark: data.land_mark
              }
}

if (initValues.address_type == null) {
  initValues['address_type'] = 'HOME'
}

console.log('initValues', initValues);

    
    
    


    const formik = useFormik({
      initialValues: initValues,
      validationSchema: Yup.object({
        pincode: Yup.string()
        .matches(/^[0-9]*$/, 'Invalid Pincode')
        .required("Pincode is Required!")
        // .test('len', 'Invalid Pincode', val => val.length === 6)
        .nullable(),
        country: Yup.string()
        .nullable()
         .required("Country is Required!"),
        state: Yup.string()
        .nullable()
          .required("State is Required!"),
        district: Yup.string()
        .nullable()
          .required("District is Required!"),
        city_village: Yup.string()
        .nullable()
          .required("City/Village/Society is Required!"),
        street_name: Yup.string()
        .nullable()
         .required("Area/Street/Sector is required!"),
         address_type: Yup.string()
         .nullable()
         .required("Address type is required!"),
         contact_number: Yup.string()
        //  .required('Contact number is required')
         .nullable()
         .test('mobile-num', 'Invalid mobile number', function (value) {
             if (!value) return true;
             const currentCountrycode = currentCountry && currentCountry.code ? currentCountry.code : null
             return handleNumber(value + "", currentCountrycode || "IN")
         })
         ,
         land_mark : Yup.string()
         .nullable()
        //  .required("Landmark is required!"),
      }),
      onSubmit: values => {
        console.log(values);
        editDetails(values)
      }
    });
    

    if (loading) {
      return (
        <Spinner />
      )
    }

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Address</h6>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="Pincode"
            name="pincode"
            value={formik.values.pincode}
            variant="outlined"
            onBlur={(e) => {
              changePincode(e)
              formik.handleBlur(e)
            }}
            onChange={formik.handleChange}
            />
            {formik.errors.pincode && formik.touched.pincode && (
            <p style={{color:"red"}}>{formik.errors.pincode}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="Country"
            name="country"
            type="text"
            value={formik.values.country}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.errors.country && formik.touched.country && (
            <p style={{color:"red"}}>{formik.errors.country}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="State"
            name="state"
            type="text"
            value={formik.values.state}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.errors.state && formik.touched.state && (
            <p style={{color:"red"}}>{formik.errors.state}</p>
             )}
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="District"
            name="district"
            type="text"
            value={formik.values.district}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.errors.district && formik.touched.district && (
            <p style={{color:"red"}}>{formik.errors.district}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="City/Village/Society"
            name="city_village"
            type="text"
            value={formik.values.city_village}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.city_village && formik.touched.city_village && (
            <p style={{color:"red"}}>{formik.errors.city_village}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
            fullWidth
            required
            label="Area/Street/Sector"
            name="street_name"
            type="text"
            value={formik.values.street_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
              {formik.errors.street_name && formik.touched.street_name && (
              <p style={{color:"red"}}>{formik.errors.street_name}</p>
               )}
        </Grid>
      </Grid>

      
<Grid container spacing={3}style={{marginTop:"2%", marginBottom:"2%"}}>
<Grid item xs={12} md={6} lg={4}>
  <FormControl variant="outlined" fullWidth>
  <InputLabel fullWidth >Address Type<span>&nbsp;*</span></InputLabel>
  <Select
  required
  fullWidth
    value={formik.values.address_type}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    label="Address Type"
    name="address_type"
  >
    {
          AddressType.map(
            (item) => (
            <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
            )
          )
        }
  </Select>
      {formik.errors.address_type && formik.touched.address_type && (
      <p style={{color:"red"}}>{formik.errors.address_type}</p>
       )}
</FormControl>
</Grid>
<Grid item xs={12} md={6} lg={4}>
            <Select
                style={{marginRight:"5%", marginBottom:"2%", width:"30%"}}
                name="country_code"
                type="text"
                value={formik.values.country_code}
                variant="outlined"
                onChange={e => {
                  formik.setFieldValue("country_code", e.target.value)
                  CurrentCountry(findCodeFromValue(e.target.value))
                }}
                onBlur={formik.handleBlur}
                >
                  {
                    options.map(
                      (item) => (
                      <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                      )
                    )
                  }
                </Select>
                <FormControl style={{width:"65%"}}  variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Mobile Number</InputLabel>
                <OutlinedInput
                    name="contact_number"
                    type="number"
                    label='Mobile Number'
                    value={parseInt(formik.values.contact_number)}
                    onChange={formik.handleChange}
                    startAdornment={<InputAdornment position="start">{formik.values.country_code}</InputAdornment>}
                    labelWidth={100}
                    onBlur={formik.handleBlur}
                />
                {formik.errors.contact_number && formik.touched.contact_number && (
                <p style={{color:"red"}}>{formik.errors.contact_number}</p>
                 )}
                 </FormControl>
</Grid>
<Grid item xs={12} md={6} lg={4}><TextField
            fullWidth
            label="Landmark"
            name="land_mark"
            type="text"
            value={formik.values.land_mark}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
              {formik.errors.land_mark && formik.touched.land_mark && (
              <p style={{color:"red"}}>{formik.errors.land_mark}</p>
               )}
</Grid>
</Grid>

<Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}} >
        <Grid item xs={3} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.handleClose}>Close</Button>
        </Grid>
        <Grid item xs={3}>
        </Grid>
        
        <Grid item xs={3}>
        </Grid>
        <Grid item xs={3} container justify={"flex-end"} >
            <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Save</Button>
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.nextStep} >Save</Button> */}
            </Grid>
      </Grid> 
      </form>
            
    </div>
  );
}
