import { ME_DATA } from './meTypes';

const meReducer = (state = null, action) => {
    switch (action.type) {
        case ME_DATA:
            return {
                ...action.payload
            }
        default:
            return state
    }
}

export default meReducer;