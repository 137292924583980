import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import LaboratoryForm from "./LaboratoryForm";
import { useHistory } from "react-router-dom";

export const AddForm = (props) => {
  const [data, setData] = React.useState()
  console.log(data);
  const history = useHistory();
  // setdata(props.location.state)
  const backToStoresList = () => props.history.goBack()
  const saveStore = () => { }
  const btnRef = useRef();
  const resetbtnRef = useRef()
  const saveStoreClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };
  const resetForm = () => {
    if (resetbtnRef && resetbtnRef.current) {
      resetbtnRef.current.click()
    }
  }
  return (
    <Card>
      <CardHeader title="Add Laboratory">
        <CardHeaderToolbar>
          {/* <button
            type="button"
            onClick={backToStoresList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
              Back
            </button> */}
          {`  `}
          {/* <button
            className="btn btn-light ml-2"
            onClick={resetForm}>
            <i className="fa fa-redo"></i>
              Reset
            </button>
          {`  `} */}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={() => history.push('/laboratory/new')}
          >
            Back to List
            </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
        { <LaboratoryForm
            // actionsLoading={actionsLoading}
            data={data}
            btnRef={btnRef}
            resetbtnRef={resetbtnRef}
            saveProduct={saveStore}
          >
          </LaboratoryForm>}
        </div>
      </CardBody>
    </Card>
  )
};
