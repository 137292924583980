import http from "../../../Utils/http";

export function loadTableData(data) {
    return http.get(`api/admin/list/employee/?filter[active]=${data.active}&user_type=${data.user_type}&filter[name]=${data.name}&page=${data.no}`);
  }

  export function EditBasicInfo(id, data) {
    return http.post(`api/admin/employee/basicinfo/${id}`, data )
  }

  export function EditAddress(id, data) {
    return http.post(`api/admin/employee/address/${id}`, data )
  }

  export function loadListRole() {
    return http.get(`api/admin/list/roles/`);
  }

  export function deleteUser(id) {
    return http.post(`api/admin/employee/${id}`, {
      _method:'DELETE',
      });
  }

  export function rejectClick(id) {
    const reqData = {
      user_id : id,
      action : "REJECT",
      user_type : "HEALTHASSOCIATE",
      comment : ''
    }
    return http.post(`api/admin/action/approveorreject`, reqData )
  }

  export function confirmHealthAssosiate(id) {
    const reqData = {
      user_id : id,
      action : "APPROVE",
      user_type : "HEALTHASSOCIATE",
      comment : ""
    }
    return http.post(`api/admin/action/approveorreject`, reqData )
  }

  export function confirmUser(id) {
    const data = {
      user_id : id
    }
    return http.post(`api/admin/action/activate/`, data )
  }
