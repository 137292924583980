import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../../_metronic/_partials/controls";
import StoresTable from "./TableNew";
import { loadTableData, pageLoadData, searchLoadData } from "../../../../../../modules/Api/PaymentAdmin/Payment/PayOutsApi";
import Spinner from "../../../../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../../../../modules/Toast/SuccessSnackBar";
import Search from "./Search";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

export const ListNew = (props) => {
  const { type, payList } = props
  const [data, setData] = React.useState(payList)
  const [searchText, setSearchText] = React.useState('')
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)
  const [state, setState] = React.useState({
    status: ' ',
    date: ''
  });

  

  const loadData = () => {
      loadTableData(type)
      .then(items => {
        setData(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
    })
  }

  const SearchData = (text) => {
    setLoading(true)
    setData(undefined)
    searchLoadData(text, state.status, type)
      .then(items => {
        setData(items.data)
        setLoading(false)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        setLoading(false)
        ErrorToast(err)
    })
  }

  const loadPageData = (no) => {
    // setData(undefined)
    setLoading(true)
    pageLoadData(no, searchText, state.status, type)
      .then(items => {
        setData(items.data)
        setLoading(false)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        setLoading(false)
        ErrorToast(err)
    })
  }

  
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    setData(undefined)
    searchLoadData(searchText, event.target.value, type)
      .then(items => {
        setData(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
      })
      .catch(err => {
        ErrorToast(err)
    })
  };

  React.useEffect(() => {
    if (mounted) {
      loadData()
      setMounted(false)
      setLoading(false)
    }
  }, [loadData])

  if (loading) {
    return (
      <Spinner />
    )
  }

  return (
    <Card style={{width: '100%'}}>
      <CardHeader>
        <CardHeaderToolbar>
          <Search SearchData={SearchData} value={searchText} setSearchText={setSearchText} />
          <FormControl variant="outlined" style={{marginLeft:"25px"}}  >
            <InputLabel htmlFor="outlined-age-native-simple">Status</InputLabel>
            <Select
              native
              value={state.status}
              onChange={handleChange}
              label="status"
              margin="dense"
              inputProps={{
                name: 'status',
                id: 'outlined-native-simple',
              }}
            >
              <option value=" ">All</option>
              <option value={1}>Paid</option>
              <option value={0}>Unpaid</option>
            </Select>
          </FormControl>
          {/* <TextField
           style={{marginLeft:"25px"}}  
            id="date"
            label="Date"
            type="date"
            variant="outlined"
            onChange={handleChange}
            margin="dense"
            defaultValue={state.date}
            InputLabelProps={{
              shrink: true,
            }}
          /> */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {
          data && <StoresTable loadPageData={loadPageData} data={data} type={type}/>
        }
      </CardBody>
    </Card>
  );
};
