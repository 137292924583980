import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { countryCodeData as options } from '../../../Utils/countyCodeMapping';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useHistory } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Spinner from "../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import { editCareerinfo, confirmClick, rejectClick } from "../../../modules/Api/Doctor/NewDoctorApi";
import img from './profile_logo.png'
import FileUpload from "../../../Utils/Components/FileUpload";
import {currencyCodeData} from '../../../Utils/currencyCodeMapping';
import getSymbolFromCurrency from 'currency-symbol-map'
import {CurrencySelect} from '../../../Utils/Components/CurrencySelect';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  buttonProgress: {
    color: "green",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function Details(props) {
  const history = useHistory();
    const classes = useStyles();
    let view_only = false
    if (props.type == 'view') {
      view_only = true
    } else {
      view_only = false
    }

    const onClickBackList = () => {
      history.push('/doctor/approved')
    }

    const [loading, setLoading] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [comment, setComment] = React.useState('')
    
    const initValues = props.CareerData

    if (!initValues.currency_code || initValues.currency_code == '' || null) {
      initValues['currency_code'] = 'INR'
    }
    initValues['profile_photo'] = null
    // console.log(props.CareerData);
    if (props.CareerData.appointment_type_offline == 0) {
      initValues["appointment_type_offline"] = false
    } else {
      initValues["appointment_type_offline"] = true
    }

    if (props.CareerData.appointment_type_online == 0) {
      initValues["appointment_type_online"] = false
    } else {
      initValues["appointment_type_online"] = true
    }

    if (initValues.alt_country_code == null) {
      initValues["alt_country_code"] = '+91'
    }
    if (initValues.alt_mobile_number == null) {
      initValues["alt_mobile_number"] = ''
    }

    if (initValues.emergency_appointment == null  || initValues.emergency_fee == null || initValues.emergency_appointment == ''  || initValues.emergency_fee == '' || initValues.emergency_appointment == 0  || initValues.emergency_fee == 0) {
      initValues['emergency_appointment_ticked'] = false
      initValues['emergency_fee'] = 0
      initValues['emergency_appointment'] = 0
    } else {
      initValues['emergency_appointment_ticked'] = true
    }

    if (initValues.service == null || initValues.service == '') {
      initValues["service"] = 'INPATIENT'
    }
    if (initValues.no_of_followup == null || initValues.no_of_followup == '') {
      initValues["no_of_followup"] = 0
    }
    if (initValues.followups_after == null || initValues.followups_after == '') {
      initValues["followups_after"] = 1
    }
    
    if (initValues.clinical_focus == null || initValues.clinical_focus == 'null') {
      initValues["clinical_focus"] = ''
    }
    if (initValues.awards_achievements == null || initValues.awards_achievements == 'null') {
      initValues["awards_achievements"] = ''
    }
    if (initValues.memberships == null || initValues.memberships == 'null') {
      initValues["memberships"] = ''
    }
    if (initValues.experience == null || initValues.experience == 'null') {
      initValues["experience"] = ''
    }


    const onupdateData = (data) => {
      props.updateCareerData(data)
  }

    const onClickPrevious = () => {
        props.previousStep()
    }

    

    

    

    const [openApprove, setOpenApprove] = React.useState(false)

    const handleCloseApprove = () => {
      setOpenApprove(false)
    }
  
    const handleShowApprove = () => {
      // console.log('handleShowApprove');
      setOpenApprove(true)
    }

    const onConfirm = () => {
      const id = props.data.user.id
      setLoading(true)
      handleCloseApprove()
      confirmClick(id, comment)
        .then(items => {
          setLoading(false)
          history.push('/doctor/new')
          if (items && items.data && items.data.message) {
            SuccessToast(items.data.message)
          }
          
        })
        .catch(err => {
          setLoading(false)
          ErrorToast(err)
      })
    }

    const ApproveModel = <Modal show={openApprove} onHide={handleCloseApprove}>
                            <Modal.Header closeButton>
                              <Modal.Title>Doctor Approve</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to approve ?</Modal.Body>
                            <Modal.Footer>
                              <Button color="secondary" onClick={handleCloseApprove}>
                                Cancel
                              </Button>
                              <Button color="primary" onClick={onConfirm}>
                                Approve
                              </Button>
                            </Modal.Footer>
                          </Modal>

const [openReject, setOpenReject] = React.useState(false)

const handleCloseReject = () => {
  setOpenReject(false)
}

const handleShowReject = () => {
  setOpenReject(true)
}

var onReject = ''
if (props.type == 'edit_and_approve'){
onReject = () => {
  const id = props.data.user.id
  handleCloseReject()
  setLoading(true)
  rejectClick(id, comment)
    .then(items => {
      setLoading(false)
      history.push('/doctor/new')
      if (items && items.data && items.data.message) {
        SuccessToast(items.data.message)
      }
      
    })
    .catch(err => {
      setLoading(false)
      ErrorToast(err)
  })
}}


if (props.type == 'edit'){
  onReject = () => {
    const id = props.data.user.id
    handleCloseReject()
    setLoading(true)
    rejectClick(id, comment)
      .then(items => {
        setLoading(false)
        history.push('/doctor/approved')
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        setLoading(false)
        ErrorToast(err)
    })
  }}

const RejectModel = <Modal show={openReject} onHide={handleCloseReject}>
                        <Modal.Header closeButton>
                          <Modal.Title>Doctor Reject</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to Reject ?</Modal.Body>
                        <Modal.Footer>
                          <Button color="secondary" onClick={handleCloseReject}>
                            Cancel
                          </Button>
                          <Button color="primary" onClick={onReject}>
                            Reject
                          </Button>
                        </Modal.Footer>
                      </Modal>
      var editDetails = ''
      if (props.type == 'edit_and_approve')
      {editDetails = (data) => {
        const id = props.data.user.id
        editCareerinfo(id, data)
          .then(items => {
            onupdateData(data)
            setLoading(false)
            handleShowApprove()
            
          })
          .catch(err => {
            setLoading(false)
            ErrorToast(err)
        })
      }}

      
      if (props.type == 'edit')
      {editDetails = (data) => {
        const id = props.data.user.id
        editCareerinfo(id, data)
          .then(items => {
            onupdateData(data)
            setLoading(false)
            history.push('/doctor/approved')
            if (items && items.data && items.data.message) {
              SuccessToast(items.data.message)
            }
            
          })
          .catch(err => {
            setLoading(false)
            ErrorToast(err)
        })
      }}

    

      const FILE_SIZE = 1024 * 1024 * 2;
      const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/png",
      ];

    
      

    const numberandfullRex = /^[0-9]\d*(\.\d+)?$/
    const formik = useFormik({
      initialValues: initValues,
      validationSchema: Yup.object({
        career_profile: Yup.string()
          .nullable()
          .required("Career Profile is Required!"),
          registration_number: Yup.string()
          .nullable()
          .required('Registration Number field is required'),
          payout_period: Yup.string()
          .nullable()
          .required('Payout period field is required'),
        education_training: Yup.string()
          .nullable()
          .required("Education&Training name is Required!"),

        appointment_type_offline : Yup.boolean(),
        consulting_offline_fee: Yup.string().when('appointment_type_offline', {
          is: true,
          then: Yup.string()
          .required("Consultation fee is Required!")
          .matches(numberandfullRex, 'Invalid Data!')
          .nullable(),
          }),

        appointment_type_online : Yup.boolean(),
        consulting_online_fee: Yup.string().when('appointment_type_online', {
          is: true,
          then: Yup.string()
          .required("Consultation fee is Required!")
          .matches(numberandfullRex, 'Invalid Data!')
          .nullable(),
          }),
          
        emergency_appointment_ticked : Yup.boolean(),
        emergency_fee: Yup.string().when('emergency_appointment_ticked', {
          is: true,
          then: Yup.string()
          .required("Emergency Appointment is Required!")
          .matches(numberandfullRex, 'Invalid Data!')
          .nullable(),
          }),
          emergency_appointment: Yup.string().when('emergency_appointment_ticked', {
            is: true,
            then: Yup.string()
            .required("Number of emergency consultation per shift is Required!")
            .matches(numberandfullRex, 'Invalid Data!')
            .nullable(),
            }),
          
        profile_photo: Yup.mixed()
          .test(
          "fileSize",
          "Image file size should not be greater than 2mb",
          function (value) {
              if (!value) return true;
              return value.size <= FILE_SIZE
          })
          .test(
          "fileFormat",
          "Image format should be jpg, jpeg, png",
          function (value) {
              if (!value) return true;
              return SUPPORTED_FORMATS.includes(value.type)
          })
      }),
      onSubmit: values => {
        if (!values.appointment_type_offline && !values.appointment_type_online) {
          setErrorMessage(<p style={{color:"red"}}>Select atleast one of Appointment Fees</p> )
        } else {
          setLoading(true)
          const data = {...values}
        if (values.appointment_type_offline) {
          data["appointment_type_offline"] = 1
        } else {
          data["appointment_type_offline"] = 0
        }

        if (values.appointment_type_online) {
          data["appointment_type_online"] = 1
        } else {
          data["appointment_type_online"] = 0
        }

        let formData = new FormData();
        formData.set('career_profile', values.career_profile)
        formData.set('education_training', values.education_training)
        formData.set('clinical_focus', values.clinical_focus)
        formData.set('memberships', values.memberships)
        formData.set('awards_achievements', values.awards_achievements)
        formData.set('experience', values.experience)
        if (values.profile_photo) {
          formData.set('profile_photo', values.profile_photo)
        }
        
        formData.set('service', values.service)
        formData.set('appointment_type_online', data.appointment_type_online)
        formData.set('appointment_type_offline', data.appointment_type_offline)
        formData.set('consulting_online_fee', values.consulting_online_fee)
        formData.set('consulting_offline_fee', values.consulting_offline_fee)
        formData.set('emergency_fee', values.emergency_fee)
        formData.set('emergency_appointment', values.emergency_appointment)
        formData.set('no_of_followup', values.no_of_followup)
        formData.set('followups_after', values.followups_after)
        formData.set('cancel_time_period', values.cancel_time_period)
        formData.set('reschedule_time_period', values.reschedule_time_period)
        formData.set('bank_account_number', values.bank_account_number)
        formData.set('bank_account_holder', values.bank_account_holder)
        formData.set('bank_name', values.bank_name)
        formData.set('bank_city', values.bank_city)
        formData.set('bank_ifsc', values.bank_ifsc)
        formData.set('bank_account_type', values.bank_account_type)
        formData.set('currency_code', values.currency_code)
        formData.set('comment', values.comment)
        formData.set('registration_number', values.registration_number)
        formData.set('payout_period', values.payout_period)

      editDetails(formData)
        }
      }
    });
    

            
    if (loading) {
      return (
        <Spinner />
      )
    }
    

            

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Career Profile</h6>
        {ApproveModel}
        {RejectModel}
        <div className="ml-auto" style={{ border: "1px solid #ccc", overflow: "hidden", width: "120px", fontSize: "13px", marginRight: "20px" }}>

        <img src={formik.values.doctor_profile_photo || img}
            width="120"
            style={{ merginTop: "10px", border: "1px solid #ccc" }}
            height="120" />

        {!view_only && <FileUpload
            labelStyle={{ border: "none" }}
            errors={formik.errors.profile_photo}
            name="profile_photo"
            required
            onChange={value => {
                formik.setFieldValue("profile_photo", value)
            }}
            onBlur={formik.setTouched}
        />}
        </div>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          required
          disabled={view_only}
            label="Career Profile"
            name="career_profile"
            type="text"
            value={formik.values.career_profile}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.career_profile && formik.touched.career_profile && (
            <p style={{color:"red"}}>{formik.errors.career_profile}</p>
             )}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          required
          disabled={view_only}
            label="Education & Training"
            name="education_training"
            type="text"
            value={formik.values.education_training}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.education_training && formik.touched.education_training && (
            <p style={{color:"red"}}>{formik.errors.education_training}</p>
             )}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          disabled={view_only}
            label="Clinical Focus"
            name="clinical_focus"
            type="text"
            value={formik.values.clinical_focus}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.clinical_focus && formik.touched.clinical_focus && (
            <p style={{color:"red"}}>{formik.errors.clinical_focus}</p>
             )}
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          disabled={view_only}
            label="Awards and Achievements"
            name="awards_achievements"
            type="text"
            value={formik.values.awards_achievements}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.awards_achievements && formik.touched.awards_achievements && (
            <p style={{color:"red"}}>{formik.errors.awards_achievements}</p>
             )}
        </Grid>
        
        <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          disabled={view_only}
            label="Membership"
            name="memberships"
            type="text"
            value={formik.values.memberships}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.memberships && formik.touched.memberships && (
            <p style={{color:"red"}}>{formik.errors.memberships}</p>
             )}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          disabled={view_only}
            label="Experience"
            name="experience"
            type="text"
            value={formik.values.experience}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.experience && formik.touched.experience && (
            <p style={{color:"red"}}>{formik.errors.experience}</p>
             )}
            
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={12} md={6} lg={4}>
        <FormControl variant="outlined" fullWidth>
            <InputLabel fullWidth >Service</InputLabel>
            <Select
            fullWidth
            disabled={view_only}
              value={formik.values.service}
              onChange={formik.handleChange}
              label="Service"
              name="service"
            >
              <MenuItem value={'OUTPATIENT'}>OUTPATIENT</MenuItem>
          <MenuItem value={'INPATIENT'}>INPATIENT</MenuItem>
          <MenuItem value={'BOTH'}>BOTH</MenuItem>
            </Select>
          </FormControl>
          {formik.errors.service && formik.touched.service && (
            <p style={{color:"red"}}>{formik.errors.service}</p>
             )}
        </Grid>
        
        <Grid item xs={12} md={6} lg={4}>
        <FormControl variant="outlined" fullWidth>
            <InputLabel fullWidth >Cancel Time Period</InputLabel>
            <Select
            fullWidth
            disabled={view_only}
              value={formik.values.cancel_time_period}
              onChange={formik.handleChange}
              label="Cancel Time Period"
              name="cancel_time_period"
              
            >
            {[
      {text: '0 hours', value: '0'},
      {text: '2 hours', value: '2'},
      {text: '4 hours', value: '4'},
      {text: '6 hours', value: '6'},
      {text: '8 hours', value: '8'},
      {text: '12 hours', value: '12'},
      {text: '24 hours', value: '24'},
      {text: '48 hours', value: '48'}
  ].map((item) => (
    <MenuItem value={item.value}>{item.text}</MenuItem>
  ))}
            {/* <MenuItem value={12}>12 HOURS</MenuItem>
            <MenuItem value={24}>24 HOURS</MenuItem>
            <MenuItem value={48}>48 HOURS</MenuItem>
            <MenuItem value={72}>72 HOURS</MenuItem>
            <MenuItem value={96}>96 HOURS</MenuItem>
            <MenuItem value={120}>120 HOURS</MenuItem> */}
            </Select>
          </FormControl>
          {formik.errors.cancel_time_period && formik.touched.cancel_time_period && (
            <p style={{color:"red"}}>{formik.errors.cancel_time_period}</p>
             )}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
      <FormControl variant="outlined" fullWidth>
            <InputLabel fullWidth >Reschedule Time Period</InputLabel>
            <Select
            fullWidth
            disabled={view_only}
              value={formik.values.reschedule_time_period}
              onChange={formik.handleChange}
              label="Reschedule Time Period"
              name="reschedule_time_period"
            >
            {[
      {text: '0 hours', value: '0'},
      {text: '2 hours', value: '2'},
      {text: '4 hours', value: '4'},
      {text: '6 hours', value: '6'},
      {text: '8 hours', value: '8'},
      {text: '12 hours', value: '12'},
      {text: '24 hours', value: '24'},
      {text: '48 hours', value: '48'}
  ].map((item) => (
    <MenuItem value={item.value}>{item.text}</MenuItem>
  ))}
            {/* <MenuItem value={12}>12 HOURS</MenuItem>
            <MenuItem value={24}>24 HOURS</MenuItem>
            <MenuItem value={48}>48 HOURS</MenuItem>
            <MenuItem value={72}>72 HOURS</MenuItem>
            <MenuItem value={96}>96 HOURS</MenuItem>
            <MenuItem value={120}>120 HOURS</MenuItem> */}
            </Select>
          </FormControl>
          {formik.errors.reschedule_time_period && formik.touched.reschedule_time_period && (
            <p style={{color:"red"}}>{formik.errors.reschedule_time_period}</p>
             )}
            
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          required
          disabled={view_only}
            label="Registration Number"
            name="registration_number"
            type="text"
            value={formik.values.registration_number}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.registration_number && formik.touched.registration_number && (
            <p style={{color:"red"}}>{formik.errors.registration_number}</p>
             )}
        </Grid>
        
        <Grid item xs={12} md={6} lg={4}>
      <FormControl variant="outlined" fullWidth>
            <InputLabel fullWidth >Payout Period</InputLabel>
            <Select
            fullWidth
            disabled={view_only}
              value={formik.values.payout_period}
              onChange={formik.handleChange}
              label="Payout Period"
              name="payout_period"
            >
            <MenuItem value={0}>Monthly</MenuItem>
            <MenuItem value={1}>Weekly</MenuItem>
            </Select>
          </FormControl>
          {formik.errors.payout_period && formik.touched.payout_period && (
            <p style={{color:"red"}}>{formik.errors.payout_period}</p>
             )}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            
        </Grid>
      </Grid>
      
      
      <FormLabel component="legend">Appointment Fees<span>&nbsp;*</span></FormLabel>
      {errorMessage}
      <Grid container spacing={3} style={{marginBottom:"2px"}}>
        <Grid item xs={12} md={6} lg={4}>
        <FormControlLabel 
        control={
          <Checkbox 
          disabled={view_only}
          checked={formik.values.appointment_type_offline} 
          onChange={e => {
            if (!!formik.values.appointment_type_offline) {
                formik.setFieldValue('consulting_offline_fee', 0)
                formik.setFieldValue('emergency_appointment_ticked', false)
                formik.setFieldValue('emergency_fee', 0)
                formik.setFieldValue('emergency_appointment', 0)
            }
            if (!formik.values.appointment_type_offline) {
              formik.setFieldValue('consulting_offline_fee', '')
          }
            formik.setFieldValue('appointment_type_offline', !formik.values.appointment_type_offline)
            if (!formik.values.appointment_type_offline || formik.values.appointment_type_online) {
              setErrorMessage('')
            } else {
              setErrorMessage(<p style={{color:"red"}}>Select atleast one of Appointment Fees</p>)
            }
        }}
          value={formik.values.appointment_type_offline}
          name="appointment_type_offline"
          color='primary'
           />
        }
        label="Offline"
      />
        </Grid>
        
        <Grid item xs={12} md={6} lg={4}>
    <FormControlLabel
        control={
          <Checkbox 
          disabled={view_only}
          checked={formik.values.appointment_type_online} 
          onChange={e => {
            if (!!formik.values.appointment_type_online) {
                formik.setFieldValue('consulting_online_fee', 0)
            }
            if (!formik.values.appointment_type_online) {
                formik.setFieldValue('consulting_online_fee', '')
            }
            formik.setFieldValue('appointment_type_online', !formik.values.appointment_type_online)
            if (formik.values.appointment_type_offline || !formik.values.appointment_type_online) {
              setErrorMessage('')
            } else {
              setErrorMessage(<p style={{color:"red"}}>Select atleast one of Appointment Fees</p>)
            }
        }}
          name="appointment_type_online"
          color='primary'
           />
        }
        label="Online"
      />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{marginBottom:"2px"}}>
        <Grid item xs={12} md={6} lg={4}>
             <CurrencySelect
              disabled={!formik.values.appointment_type_offline || view_only}
              value={formik.values.consulting_offline_fee}
              onChange={value => {
                formik.setFieldValue("consulting_offline_fee", value)
              }}
              onBlur={formik.handleBlur}
              label='Consultation Fee'
              name="consulting_offline_fee"
              errors={formik.errors.consulting_offline_fee}
                />
        </Grid>
        
        <Grid item xs={12} md={6} lg={4}>
             <CurrencySelect
              disabled={!formik.values.appointment_type_online || view_only}
              value={formik.values.consulting_online_fee}
              onChange={value => {
                formik.setFieldValue("consulting_online_fee", value)
              }}
              onBlur={formik.handleBlur}
              label='Consultation Fee'
              name="consulting_online_fee"
              errors={formik.errors.consulting_online_fee}
                />
        </Grid>
      </Grid>

<FormLabel component="legend">Emergency</FormLabel>
<FormControlLabel
  control={
    <Checkbox 
    disabled={!formik.values.appointment_type_offline || view_only}
    checked={formik.values.emergency_appointment_ticked} 
    name="emergency_appointment_ticked"
    onChange={e => {
      if (!!formik.values.emergency_appointment_ticked) {
          formik.setFieldValue('emergency_fee', 0)
          formik.setFieldValue('emergency_appointment', 0)
      }
      if (!formik.values.emergency_appointment_ticked) {
          formik.setFieldValue('emergency_fee', '')
          formik.setFieldValue('emergency_appointment', '')
      }
      formik.setFieldValue('emergency_appointment_ticked', !formik.values.emergency_appointment_ticked)
  }}
    color='primary'
     />
  }
  label="Emergency Appointment Fee"
/>
<Grid container spacing={3} style={{marginBottom:"2%"}}>
  <Grid item xs={12} md={6} lg={4}>
       <CurrencySelect
      disabled={!formik.values.emergency_appointment_ticked || view_only}
        value={formik.values.emergency_fee}
        onChange={value => {
          formik.setFieldValue("emergency_fee", value)
        }}
        onBlur={formik.handleBlur}
        label='Emergency Appointment Fee'
        name="emergency_fee"
        errors={formik.errors.emergency_fee}
          />
    </Grid>
  <Grid item xs={12} md={6} lg={4}>
  <TextField
    fullWidth
    required
    disabled={!formik.values.emergency_appointment_ticked || view_only}
      label="Number of Emergency Consultation Per Shift"
      name="emergency_appointment"
      // type="number"
      value={formik.values.emergency_appointment}
      variant="outlined"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      InputLabelProps={{
        shrink: true,
      }}
      />
      {formik.errors.emergency_appointment && formik.touched.emergency_appointment && (
        <p style={{color:"red"}}>{formik.errors.emergency_appointment}</p>
         )}
  </Grid>
</Grid>

<Grid container spacing={3} style={{marginBottom:"2px"}}>
  
  <Grid item xs={12} md={6} lg={4}>
  <FormControl variant="outlined" fullWidth>
      <InputLabel fullWidth >Free Number of followups</InputLabel>
      <Select
      fullWidth
      disabled={view_only}
        value={formik.values.no_of_followup}
        onChange={formik.handleChange}
        label="Free Number of followups"
        name="no_of_followup"
      
      >
      <MenuItem value={0}>0</MenuItem>
      <MenuItem value={1}>1</MenuItem>
      <MenuItem value={2}>2</MenuItem>
      <MenuItem value={3}>3</MenuItem>
      <MenuItem value={4}>4</MenuItem>
      <MenuItem value={5}>5</MenuItem>
      <MenuItem value={6}>6</MenuItem>
      <MenuItem value={7}>7</MenuItem>
      <MenuItem value={8}>8</MenuItem>
      <MenuItem value={9}>9</MenuItem>
      <MenuItem value={10}>10</MenuItem>
      </Select>
    </FormControl>
    {formik.errors.no_of_followup && formik.touched.no_of_followup && (
      <p style={{color:"red"}}>{formik.errors.no_of_followup}</p>
       )}
  </Grid>
  <Grid item xs={12} md={6} lg={4}>


      <FormControl variant="outlined" fullWidth>
      <InputLabel fullWidth >Free Followup Duration</InputLabel>
      <Select
      fullWidth
      disabled={view_only}
        value={formik.values.followups_after}
        onChange={formik.handleChange}
        label="Free Followup Duration"
        name="followups_after"
      >
      <MenuItem value={1}>1 Week</MenuItem>
      <MenuItem value={2}>2 Week</MenuItem>
      <MenuItem value={3}>3 Week</MenuItem>
      <MenuItem value={4}>4 Week</MenuItem>
      </Select>
    </FormControl>
    {formik.errors.followups_after && formik.touched.followups_after && (
      <p style={{color:"red"}}>{formik.errors.followups_after}</p>
       )}
  </Grid>
</Grid>


{/* {props.type == 'edit_and_approve' && <Grid container spacing={3} style={{marginTop:"2%"}}>
  <Grid item xs={12} md={6} lg={8}>
  <TextField
    fullWidth
      label="Comment"
      name="comment"
      multiline
      rows={4}
      value={comment}
      variant="outlined"
      onChange={e => {
        setComment(e.target.value)
      }}
      onBlur={formik.handleBlur}
      InputLabelProps={{
        shrink: true,
      }}
      />
  </Grid>
</Grid>} */}
      
      {props.type == 'edit_and_approve' && <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={4} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
        </Grid>
        <Grid item xs={4}>
        </Grid>
        <Grid item xs={2}>
        </Grid>
        
        <Grid item xs={1} container justify={"flex-end"}>
            <Button onClick={handleShowReject} style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="secondary" >Reject</Button>
        </Grid>
        <Grid item xs={1} container justify={"flex-end"}>
            <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Approve</Button>
            </Grid>
      </Grid> }

      {props.type == 'edit' && <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={4} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
        </Grid>
        <Grid item xs={7}>
        </Grid>
        <Grid item xs={1} container justify={"flex-end"}>
            <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Save</Button>
            </Grid>
      </Grid> }
      {view_only && <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={4} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
        </Grid>
        <Grid item xs={7}>
        </Grid>
        <Grid item xs={1} container justify={"flex-end"}>
            <Button onClick={onClickBackList} style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Back</Button>
            </Grid>
      </Grid> }
      </form>
            
    </div>
  );
}
