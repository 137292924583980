import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { countryCodeData as options } from '../../../Utils/countyCodeMapping';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from "react-router-dom";
import Spinner from "../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import { editAdditionalinfo, confirmClick, rejectClick } from "../../../modules/Api/Pharmacy/NewPharmacyApi";
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import { handleNumber, findCodeFromValue } from '../../../Utils/CountryMobile';
import { DatePickerField } from "./../../../../_metronic/_partials/controls/forms/DatePickerField";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  buttonProgress: {
    color: "green",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

export default function Details(props) {
  const history = useHistory();
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false)
    const [altCurrentCountry, setAltCurrentCountry] = React.useState()
    let view_only = false
    if (props.type == 'view') {
      view_only = true
    } else {
      view_only = false
    }

    const onClickBackList = () => {
      history.push('/pharmacy/approved')
    }

    var initValues = {
      pharmacist_name: props.values.pharmacist_name,
      course: props.values.course,
      pharmacist_reg_number: props.values.pharmacist_reg_number,
      issuing_authority: props.values.issuing_authority,
      reg_certificate: props.values.reg_certificate,
      alt_country_code: props.values.alt_country_code,
      alt_mobile_number: props.values.alt_mobile_number,
      reg_date: props.values.reg_date,
      reg_valid_upto: props.values.reg_valid_upto,
      bank_account_number: props.values.bank_account_details[0].bank_account_number,
      bank_account_holder: props.values.bank_account_details[0].bank_account_holder,
      bank_name:props.values.bank_account_details[0].bank_name,
      bank_city: props.values.bank_account_details[0].bank_city,
      bank_ifsc: props.values.bank_account_details[0].bank_ifsc,
      bank_account_type: props.values.bank_account_details[0].bank_account_type,
    }


    if (initValues.course !== 'D.Pharm' && initValues.course !== 'B.Pharm' && initValues.course !== 'Pharm.D') {
      initValues['course'] = 'Others'
      initValues['course_name'] = props.values.course
    }
    if (initValues.alt_country_code == null) {
      initValues['alt_country_code'] = '+91'
    }
    if (initValues.alt_mobile_number == "null") {
      initValues['alt_mobile_number'] = ''
    }

    const onOpenPdf = () => {
      window.open(initValues.reg_certificate)
    }

    const onupdateData = (data) => {
      props.updateData(data)
  }

    const onClickPrevious = () => {
        props.previosStep()
    }

    

    

    

    const [openApprove, setOpenApprove] = React.useState(false)

    const handleCloseApprove = () => {
      setOpenApprove(false)
    }
  
    const handleShowApprove = () => {
      setOpenApprove(true)
    }

    const onConfirm = () => {
      const id = props.values.user.id
      setLoading(true)
      handleCloseApprove()
      confirmClick(id)
        .then(items => {
          setLoading(false)
          history.push('/pharmacy/new')
          if (items && items.data && items.data.message) {
            SuccessToast(items.data.message)
          }
          
        })
        .catch(err => {
          setLoading(false)
          ErrorToast(err)
      })
    }

    const ApproveModel = <Modal show={openApprove} onHide={handleCloseApprove}>
                            <Modal.Header closeButton>
                              <Modal.Title>Pharmacy Approve</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to approve ?</Modal.Body>
                            <Modal.Footer>
                              <Button color="secondary" onClick={handleCloseApprove}>
                                Cancel
                              </Button>
                              <Button color="primary" onClick={onConfirm}>
                                Approve
                              </Button>
                            </Modal.Footer>
                          </Modal>

const [openReject, setOpenReject] = React.useState(false)

const handleCloseReject = () => {
  setOpenReject(false)
}

const handleShowReject = () => {
  setOpenReject(true)
}
var onReject = ''
if (props.type == 'edit_and_approve') 
  {onReject = () => {
  const id = props.values.user.id
  handleCloseReject()
  setLoading(true)
  rejectClick(id)
    .then(items => {
      setLoading(false)
      history.push('/pharmacy/new')
      if (items && items.data && items.data.message) {
        SuccessToast(items.data.message)
      }
      
    })
    .catch(err => {
      setLoading(false)
      ErrorToast(err)
  })
}}


if (props.type == 'edit') 
  {onReject = () => {
  const id = props.values.user.id
  handleCloseReject()
  setLoading(true)
  rejectClick(id)
    .then(items => {
      setLoading(false)
      history.push('/pharmacy/approved')
      if (items && items.data && items.data.message) {
        SuccessToast(items.data.message)
      }
      
    })
    .catch(err => {
      setLoading(false)
      ErrorToast(err)
  })
}}


const RejectModel = <Modal show={openReject} onHide={handleCloseReject}>
                        <Modal.Header closeButton>
                          <Modal.Title>Pharmacy Reject</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to Reject ?</Modal.Body>
                        <Modal.Footer>
                          <Button color="secondary" onClick={handleCloseReject}>
                            Cancel
                          </Button>
                          <Button color="primary" onClick={onReject}>
                            Reject
                          </Button>
                        </Modal.Footer>
                      </Modal>
      var editDetails = ''

      if (props.type == 'edit_and_approve') 
      {editDetails = (data, localData) => {
        const id = props.values.id
        editAdditionalinfo(id, data)
          .then(items => {
            onupdateData(localData)
            setLoading(false)
            handleShowApprove()
            
          })
          .catch(err => {
            setLoading(false)
            ErrorToast(err)
        })
      }}

      if (props.type == 'edit') 
      {editDetails = (data, localData) => {
        const id = props.values.id
        editAdditionalinfo(id, data)
          .then(items => {
            onupdateData(localData)
            setLoading(false)
            history.push('/pharmacy/approved')
            if (items && items.data && items.data.message) {
              SuccessToast(items.data.message)
            }
            
          })
          .catch(err => {
            setLoading(false)
            ErrorToast(err)
        })
      }}

      const ifsc_code = new RegExp(/^[A-Za-z]{4}[a-zA-Z0-9]{7}$/)
      const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


    const formik = useFormik({
      initialValues: initValues,
      validationSchema: Yup.object({
        pharmacist_name: Yup.string()
        .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field must be alphabets')
          .required("Pharmacist name is Required!"),
        course: Yup.string()
         .required("Course is Required!"),
        
         course_name: Yup.string().when('course', {
           is: 'Others',
           then: Yup.string().required('Course name is required')
         }),
        pharmacist_reg_number: Yup.string()
          .required("Register number Required!"),
        reg_date: Yup.date('Must be a valid date')
          .nullable()
          .max(new Date(), 'Date of issue cannot be future dates' )
          .required('The Date of issue field is required.'),
        reg_valid_upto: Yup.date('Must be a valid date')
          .nullable()
          .min(new Date(), "Date must be in the future")
          .required('The Date of validity field is required.'),
        issuing_authority: Yup.string()
          .required("Issuing authority is Required!"),
          alt_mobile_number: Yup.string()
          .nullable()
          .test('mobile-num', 'Invalid mobile number', function (value) {
              if (!value) return true;
              const currentCountrycode = altCurrentCountry && altCurrentCountry.code ? altCurrentCountry.code : null
              return handleNumber(value + "", currentCountrycode || "IN")
          })
          ,
        // bank_account_number: Yup.string()
        // .matches(/^[0-9]*$/, 'Account number must be number')
        //   .required("Account number is Required!"),
        // bank_account_holder: Yup.string()
        // .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field must be alphabets')
        //   .required("Account holder name is Required!"),
        // bank_name: Yup.string()
        //   .required("Bank name is Required!"),
        // bank_city: Yup.string()
        //  .required("Bank city is Required!"),
        // bank_ifsc: Yup.string()
        // .matches(ifsc_code, 'Must be a valid IFSC code')
        // .required('The IFSC field is required.'),
      }),
      onSubmit: values => {
        setLoading(true)
        console.log('submit', values);

        const data = props.values

        data["pharmacist_name"] = values.pharmacist_name
        data["pharmacist_reg_number"] = values.pharmacist_reg_number
        data["issuing_authority"] = values.issuing_authority
        data["reg_certificate"] = values.reg_certificate
        data["alt_country_code"] = values.alt_country_code
        data["alt_mobile_number"] = values.alt_mobile_number
        data["reg_date"] = values.reg_date
        data["reg_valid_upto"] = values.reg_valid_upto
        data["bank_account_details"][0]["bank_account_number"] = values.bank_account_number
        data["bank_account_details"][0]["bank_account_holder"] = values.bank_account_holder
        data["bank_account_details"][0]["bank_name"] = values.bank_name
        data["bank_account_details"][0]["bank_city"] = values.bank_city
        data["bank_account_details"][0]["bank_ifsc"] = values.bank_ifsc
        data["bank_account_details"][0]["bank_account_type"] = values.bank_account_type


        if (values.course !== 'Others'){
          data["course"] = values.course
      } else {
          data["course"] = values.course_name
      }

      const updateValues = {
        pharmacist_name: values.pharmacist_name,
        course: data.course,
        pharmacist_reg_number: values.pharmacist_reg_number,
        issuing_authority: values.issuing_authority,
        reg_certificate: props.values.bank_account_details[0].reg_certificate,
        alt_country_code: values.alt_country_code,
        alt_mobile_number: values.alt_mobile_number,
        reg_date: values.reg_date,
        reg_valid_upto: values.reg_valid_upto,
        bank_account_number: values.bank_account_number,
        bank_account_holder: values.bank_account_holder,
        bank_name:values.bank_name,
        bank_city: values.bank_city,
        bank_ifsc: values.bank_ifsc,
        bank_account_type: values.bank_account_type,
      }
      editDetails(updateValues, data)
      }
    });
    

            

    if (loading) {
      return (
        <Spinner />
      )
    }

            

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
        {ApproveModel}
        {RejectModel}
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Additional Details</h6>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={4}>
        <TextField
          fullWidth
          required
          disabled={view_only}
            label="Name of Pharmacist"
            name="pharmacist_name"
            type="text"
            value={formik.values.pharmacist_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.pharmacist_name && formik.touched.pharmacist_name && (
            <p style={{color:"red"}}>{formik.errors.pharmacist_name}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <FormControl fullWidth variant="outlined" className={classes.formControl}>
          <InputLabel fullWidth htmlFor="outlined-age-native-simple">Course</InputLabel>
          <Select
          fullWidth
          disabled={view_only}
            value={formik.values.course}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="course"
            name="course"
          >
            <option value={'D.Pharm'}>D.Pharm</option>
            <option value={'B.Pharm'}>B.Pharm</option>
            <option value={'Pharm.D'}>Pharm.D</option>
            <option value={'Others'}>Others</option>
          </Select>
        </FormControl>
        {formik.values.course === 'Others' &&(
                <TextField
                style={{marginTop:'10px'}}
                fullWidth
                required
                disabled={view_only}
                  label="Course Name"
                  name="course_name"
                  type="text"
                  value={formik.values.course_name}
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  />
                )}
                
                {formik.errors.course_name && formik.touched.course_name && (
                  <p style={{color:"red"}}>{formik.errors.course_name}</p>
                   )}
            {formik.errors.course && formik.touched.course && (
            <p style={{color:"red"}}>{formik.errors.course}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
          fullWidth
          required
          disabled={view_only}
            label="Registration Number of pharmacist"
            name="pharmacist_reg_number"
            type="text"
            value={formik.values.pharmacist_reg_number}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.pharmacist_reg_number && formik.touched.pharmacist_reg_number && (
            <p style={{color:"red"}}>{formik.errors.pharmacist_reg_number}</p>
             )}
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={4}>
        <TextField
          fullWidth
          required
          disabled={view_only}
            label="Issuing Authority"
            name="issuing_authority"
            type="text"
            value={formik.values.issuing_authority}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.issuing_authority && formik.touched.issuing_authority && (
            <p style={{color:"red"}}>{formik.errors.issuing_authority}</p>
             )}
        </Grid>
        
        <Grid item xs={4}>
        <Select
        required
        disabled={view_only}
            style={{marginRight:"2%", marginBottom:"2%", width:"30%"}}
            name="alt_country_code"
            type="text"
            value={formik.values.alt_country_code}
            variant="outlined"
            onChange={e => {
              formik.setFieldValue("alt_country_code", e.target.value)
              setAltCurrentCountry(findCodeFromValue(e.target.value))
            }}
            onBlur={formik.handleBlur}
            >
              {
                options.map(
                  (item) => (
                  <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                  )
                )
              }
            </Select>
            <FormControl style={{width:"65%"}} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Alternative Number</InputLabel>
            <OutlinedInput
            disabled={view_only}
                name="alt_mobile_number"
                type="number"
                value={formik.values.alt_mobile_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                startAdornment={<InputAdornment position="start">{formik.values.alt_country_code}</InputAdornment>}
                labelWidth={130}
            />
            </FormControl>
            {formik.errors.alt_mobile_number && formik.touched.alt_mobile_number && (
            <p style={{color:"red"}}>{formik.errors.alt_mobile_number}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        {/* <TextField
          fullWidth
          required
          disabled={view_only}
            label="Registration date"
            name="reg_date"
            type="date"
            value={formik.values.reg_date}
            variant="outlined"
            onChange={e => {
              if (new Date("9999-12-31") >= new Date(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            onBlur={formik.handleBlur}
            InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.errors.reg_date && formik.touched.reg_date && (
            <p style={{color:"red"}}>{formik.errors.reg_date}</p>
             )} */}

<DatePickerField 
                                mandatory
                                disabled={view_only}
                                name="reg_date"
                                label="Registration date"
                                onChange={val => {
                                  formik.setFieldValue('reg_date', String(val))
                                }}
                                onBlur={e => {
                                  formik.handleBlur(e);
                                }}
                                value={formik.values.reg_date}
                                touched={formik.touched.reg_date}
                                errors={formik.errors.reg_date}
                                 />
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={4}>
        {/* <TextField
          fullWidth
          required
          disabled={view_only}
            label="Registration valid up to"
            name="reg_valid_upto"
            type="date"
            value={formik.values.reg_valid_upto}
            variant="outlined"
            onChange={e => {
              if (new Date("9999-12-31") >= new Date(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            onBlur={formik.handleBlur}
            InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.errors.reg_valid_upto && formik.touched.reg_valid_upto && (
            <p style={{color:"red"}}>{formik.errors.reg_valid_upto}</p>
             )} */}

<DatePickerField 
                                mandatory
                                disabled={view_only}
                                name="reg_valid_upto"
                                label="Registration valid up to"
                                onChange={val => {
                                  formik.setFieldValue('reg_valid_upto', String(val))
                                }}
                                onBlur={e => {
                                  formik.handleBlur(e);
                                }}
                                value={formik.values.reg_valid_upto}
                                touched={formik.touched.reg_valid_upto}
                                errors={formik.errors.reg_valid_upto}
                                 />
        </Grid>
        
        <Grid item xs={4}>
      <InputLabel htmlFor="outlined-adornment-amount">Registration Certificate</InputLabel>
        <a
            className="btn btn-icon btn-light btn-hover-success btn-sm mx-3"
            onClick={onOpenPdf}
        >
            {/* <span className="svg-icon svg-icon-md svg-icon-success">
                <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")}
                />
            </span> */}
                    <VisibilitySharpIcon color='primary' />
        </a>
        </Grid>
        <Grid item xs={4}>
        </Grid>
      </Grid>

      
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Banking Details</h6>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={4}>
        <TextField
          fullWidth
          
          disabled={view_only}
            label="Bank Account Number"
            name="bank_account_number"
            type="text"
            value={formik.values.bank_account_number}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_account_number && formik.touched.bank_account_number && (
            <p style={{color:"red"}}>{formik.errors.bank_account_number}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
          fullWidth
          
          disabled={view_only}
            label="Account Holder Full Name"
            name="bank_account_holder"
            type="text"
            value={formik.values.bank_account_holder}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_account_holder && formik.touched.bank_account_holder && (
            <p style={{color:"red"}}>{formik.errors.bank_account_holder}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
          fullWidth
          
          disabled={view_only}
            label="Bank Name"
            name="bank_name"
            type="text"
            value={formik.values.bank_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_name && formik.touched.bank_name && (
            <p style={{color:"red"}}>{formik.errors.bank_name}</p>
             )}
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
      <Grid item xs={4}>
        <TextField
          fullWidth
          
          disabled={view_only}
            label="Bank City"
            name="bank_city"
            type="text"
            value={formik.values.bank_city}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_city && formik.touched.bank_city && (
            <p style={{color:"red"}}>{formik.errors.bank_city}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <TextField
          fullWidth
          
          disabled={view_only}
            label="Bank IFSC Code"
            name="bank_ifsc"
            type="text"
            value={formik.values.bank_ifsc}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            
            {formik.errors.bank_ifsc && formik.touched.bank_ifsc && (
            <p style={{color:"red"}}>{formik.errors.bank_ifsc}</p>
             )}
        </Grid>
        <Grid item xs={4}>
        <FormControl fullWidth variant="outlined" className={classes.formControl}>
        <InputLabel fullWidth htmlFor="outlined-age-native-simple">Account Type</InputLabel>
        <Select
        fullWidth
        disabled={view_only}
          value={formik.values.bank_account_type}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label="Account Type"
          name="bank_account_type"
        >
          <option value={'CURRENT'}>CURRENT</option>
          <option value={'SAVINGS'}>SAVINGS</option>
          <option value={'SALARY'}>SALARY</option>
          <option value={'FIXED DEPOSIT'}>FIXED DEPOSIT</option>
          <option value={'RECURRING DEPOSIT'}>RECURRING DEPOSIT</option>
          <option value={'NRI'}>NRI</option>
          <option value={'NRO'}>NRO</option>
          <option value={'NRE'}>NRE</option>
          <option value={'FCNR'}>FCNR</option>
        </Select>
      </FormControl>
            {formik.errors.bank_account_type && formik.touched.bank_account_type && (
            <p style={{color:"red"}}>{formik.errors.bank_account_type}</p>
             )}
        </Grid>
      </Grid>
      
      {props.type == 'edit_and_approve' && <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={4} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
        </Grid>
        <Grid item xs={4}>
        </Grid>
        <Grid item xs={2}>
        </Grid>
        
        <Grid item xs={1} container justify={"flex-end"}>
            <Button onClick={handleShowReject} style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="secondary" >Reject</Button>
        </Grid>
        <Grid item xs={1} container justify={"flex-end"}>
            <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Approve</Button>
        </Grid>
      </Grid> }

      {props.type == 'edit' && <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={4} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
        </Grid>
        <Grid item xs={7}>
        </Grid>
        <Grid item xs={1} container justify={"flex-end"}>
            <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Save</Button>
        </Grid>
      </Grid> }

      {view_only && <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={4} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
        </Grid>
        <Grid item xs={7}>
        </Grid>
        <Grid item xs={1} container justify={"flex-end"}>
            <Button onClick={onClickBackList} style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Back</Button>
        </Grid>
      </Grid> }
      </form>
            
    </div>
  );
}
