import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
// import { selectData as currencyOptions } from '../../../../Utils/countyCodeMapping';
import {getCode} from '../../../../Utils/googleApi';
import {ErrorToast} from "../../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../../modules/Toast/SuccessSnackBar";
import { editAddressinfo } from "../../../../modules/Api/Doctor/NewDoctorApi";
import Select from '@material-ui/core/Select';
import makeAnimated from 'react-select/animated';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Details(props) {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = React.useState('')
  const [successMessage, setSuccessMessage] = React.useState('')

  const changePincode = (event) => {
    const pincodeVal = event.target.value
    // formik.setFieldValue("pincode", pincodeVal)
    getCode(pincodeVal, addr => {
      if (!addr) {
        return
      }
      if (addr.country){
        formik.setFieldValue("country", addr.country)
      } else {
        formik.setFieldValue("country", '')
      }
      if (addr.state){
        formik.setFieldValue("state", addr.state)
      } else {
        formik.setFieldValue("state", '')
      }
      if (addr.district){
        formik.setFieldValue("district", addr.district)
      } else {
        formik.setFieldValue("district", '')
      }
      if (addr.position.lng){
        formik.setFieldValue("longitude", addr.position.lng)
      }
      if (addr.position.lat){
        formik.setFieldValue("latitude", addr.position.lat)
      }

    })
  }


  const initValues = {
            clinic_name: props.address.clinic_name,
            pincode: props.address.pincode,
            country: props.address.country,
            state: props.address.state,
            district: props.address.district,
            city_village: props.address.city_village,
            street_name: props.address.street_name,
            latitude: props.address.latitude,
            longitude: props.address.longitude,
            country_code: props.address.alt_country_code,
            contact_number: props.address.alt_mobile_number,
            laboratory_list: props.address.clinic_info.laboratory_list || [],
            pharmacy_list: props.address.clinic_info.pharmacy_list || [],
            clinic_info:props.address.clinic_info,
            contact_number:null,
            country_code:null
  }
  initValues['pharmacy_list'] = initValues.pharmacy_list.map((item) => parseInt(item))
  initValues['laboratory_list'] = initValues.laboratory_list.map((item) => parseInt(item))

  const editDetails = (data, localData) => {
    const id = props.address.id
    editAddressinfo(id, data)
      .then(items => {
        props.updateAddressData(localData)
        props.handleClose()
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
    })
  }
    
    


    const formik = useFormik({
      initialValues: initValues,
      validationSchema: Yup.object({
        clinic_name: Yup.string()
          .required("Clinic name is Required!")
          .nullable(),
          pincode: Yup.string()
          .matches(/^[0-9]*$/, 'Invalid Pincode')
          .required("Pincode is Required!")
          // .test('len', 'Invalid Pincode', val => val.length === 6)
          .nullable(),
        country: Yup.string()
         .required("Country is Required!")
         .nullable(),
        state: Yup.string()
          .required("State is Required!")
          .nullable(),
        district: Yup.string()
          .required("District is Required!")
          .nullable(),
        city_village: Yup.string()
          .required("City/Village/Society is Required!")
          .nullable(),
        street_name: Yup.string()
         .required("Area/Street/Sector is Required!")
         .nullable(),
        //  pharmacy_list: Yup.array()
        //  .required('Pharmacy is Required!' ),
        //  laboratory_list: Yup.array()
        //  .required('Laboratory is Required!' ),
        //  country_code: Yup.string()
        //   .required("Country code is Required!"),
        //   contact_number: Yup.string()
        //  .required("contact number is Required!"),
      }),
      onSubmit: values => {

        const data = props.data
        const addData = values
        addData['id'] = props.address.id
        addData['clinic_info']['pharmacy_list'] = values.pharmacy_list
        addData['clinic_info']['laboratory_list'] = values.laboratory_list

        data[props.index] = addData
        editDetails(values, data)
      }
    });
    

    

  return (
    <div className={classes.root}>
      <h3 style={{margin:"2%"}} >Edit Address</h3>
      <form onSubmit={formik.handleSubmit}>
      
      <Grid container spacing={3}  >
          <Grid item xs={12} md={6} lg={4}>
          <TextField
              fullWidth
              required
              label="Clinic Name"
              name="clinic_name"
              type="text"
              value={formik.values.clinic_name}
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              />
              {formik.errors.clinic_name && formik.touched.clinic_name && (
              <p style={{color:"red"}}>{formik.errors.clinic_name}</p>
               )}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
          <TextField
              fullWidth
              required
              label="Pincode"
              name="pincode"
              value={formik.values.pincode}
              variant="outlined"
              onBlur={(e) => {
                changePincode(e)
                formik.handleBlur(e)
              }}
              onChange={formik.handleChange}
              />
              {formik.errors.pincode && formik.touched.pincode && (
              <p style={{color:"red"}}>{formik.errors.pincode}</p>
               )}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
          <TextField
              fullWidth
              required
              label="Country"
              name="country"
              type="text"
              value={formik.values.country}
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              />
              {formik.errors.country && formik.touched.country && (
              <p style={{color:"red"}}>{formik.errors.country}</p>
               )}
          </Grid>
        </Grid>
        <Grid container spacing={3} >
          
        <Grid item xs={12} md={6} lg={4}>
          <TextField
              fullWidth
              required
              label="State"
              name="state"
              type="text"
              value={formik.values.state}
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              />
              {formik.errors.state && formik.touched.state && (
              <p style={{color:"red"}}>{formik.errors.state}</p>
               )}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
          <TextField
              fullWidth
              required
              label="District"
              name="district"
              type="text"
              value={formik.values.district}
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              />
              {formik.errors.district && formik.touched.district && (
              <p style={{color:"red"}}>{formik.errors.district}</p>
               )}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
          <TextField
              fullWidth
              required
              label="City/Village/Society"
              name="city_village"
              type="text"
              value={formik.values.city_village}
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              />
              {formik.errors.city_village && formik.touched.city_village && (
              <p style={{color:"red"}}>{formik.errors.city_village}</p>
               )}
          </Grid>
        </Grid>
        <Grid container spacing={3} >
          <Grid item xs={12} md={6} lg={4}>
          <TextField
              fullWidth
              required
              // margin='dense'
              label="Area/Street/Sector"
              name="street_name"
              type="text"
              value={formik.values.street_name}
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              />
                {formik.errors.street_name && formik.touched.street_name && (
                <p style={{color:"red"}}>{formik.errors.street_name}</p>
                 )}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel fullWidth >Pharmacy</InputLabel>
            <Select
            fullWidth
            multiple
              value={formik.values.pharmacy_list}
              onChange={formik.handleChange}
              name="pharmacy_list"
              label='Pharmacy'
            >
              {
                    props.pharmacyList.map(
                      (item) => (
                      <MenuItem id={item.id} value={parseInt(item.id)}>{item.pharmacy_name}</MenuItem>
                      )
                    )
                  }
            </Select>
          </FormControl>
              {formik.errors.pharmacy_list && formik.touched.pharmacy_list && (
              <p style={{color:"red"}}>{formik.errors.pharmacy_list}</p>
               )}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel fullWidth >Laboratory</InputLabel>
            <Select
            fullWidth
            multiple
            value={formik.values.laboratory_list}
              onChange={formik.handleChange}
              name="laboratory_list"
              label='Laboratory'
            >
              {
                    props.laboratoryList.map(
                      (item) => (
                      <MenuItem id={item.id} value={parseInt(item.id)}>{item.laboratory_name}</MenuItem>
                      )
                    )
                  }
            </Select>
          </FormControl>
              {formik.errors.laboratory_list && formik.touched.laboratory_list && (
              <p style={{color:"red"}}>{formik.errors.laboratory_list}</p>
               )}

          </Grid>
        </Grid>

      
      
      {errorMessage}
      {successMessage}

<Grid container spacing={3} >
        <Grid item xs={6} container justify={"flex-start"} >
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button>
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button> */}
        </Grid>
        <Grid item xs={6} container justify={"flex-end"} >
            <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Save Changes</Button>
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickNext} >Next</Button> */}
            </Grid>
      </Grid> 
      </form>
            
    </div>
  );
}
