import React from 'react';
import './style.css'



export function FileUpload (props) {

    
    const { name, value, label = 'Upload File', mandatory, onChange = () => { }, errors, onBlur = () => { } } = props

    return (
    <div>
        {/* <div className="container"> */}
        <div className="button-wrap">
            <label className="button" for={name}>{label}{mandatory && <span>*</span>}</label>
            {
                value && value.name && (
                <div className="row">
                    <div className="col-lg-12">
                    {/* <i className="fa fa-file text-success"></i> */}
                    <p className="ml-2">{value.name}</p>
                    </div>
                </div>)
            }
            <input
                style={{ display: "none" }}
                id={name}
                name={name}
                type="file"
                onBlur={() => onBlur(name, true)}
                onChange={event => {
                    onChange(event.currentTarget.files[0])
                }}
                className="form-control"
            />
            
            {errors && <label style={{ display: "block", position: "absolute", color:'red' }}
                >{errors}</label>}
        </div>
        {/* </div> */}
    </div>
    )
}
