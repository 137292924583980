/* eslint-disable no-restricted-imports */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { useHistory } from "react-router-dom";
import Spinner from "../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import { AddLaboratory } from "../../../modules/Api/Laboratory/AddLaboratory";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  buttonProgress: {
    color: "green",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function Details(props) {
  const history = useHistory();
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false)
    

const onupdateData = (data) => {
  props.updateData(data)
}

      const addDetails = (data) => {
        AddLaboratory(data)
          .then(items => {
            setLoading(false)
            history.push('/laboratory/approved')
            if (items && items.data && items.data.message) {
              SuccessToast(items.data.message)
            }
            
          })
          .catch(err => {
            setLoading(false)
            ErrorToast(err)
            if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.mobile_number){
              props.updateStep(1)
            }
            if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.email){
              props.updateStep(1)
            }
        })
      }

    const initValues = props.values


    

    const onClickPrevious = () => {
        props.previosStep()
    }

    

      

      const ifsc_code = new RegExp(/^[A-Za-z]{4}[a-zA-Z0-9]{7}$/)

    const formik = useFormik({
      initialValues: initValues,
      validationSchema: Yup.object({
        
      }),
      onSubmit: values => {
        setLoading(true)
        console.log('submit', values);
        onupdateData(values)
        let formData = new FormData();
        formData.set('laboratory_name', values.laboratory_name)
        formData.set('country_code', values.country_code)
        formData.set('mobile_number', values.mobile_number)
        formData.set('email', values.email)
        formData.set('gstin', values.gstin)
        formData.set('lab_reg_number', values.lab_reg_number)
        formData.set('lab_issuing_authority', values.lab_issuing_authority)
        formData.set('lab_date_of_issue', values.lab_date_of_issue)
        formData.set('lab_valid_upto', values.lab_valid_upto)
        formData.set('lab_file', values.lab_file)
        formData.set('pincode', values.pincode)
        formData.set('country', values.country)
        formData.set('state', values.state)
        formData.set('district', values.district)
        formData.set('city_village', values.city_village)
        formData.set('street_name', values.street_name)
        formData.set('sample_collection', values.sample_collection)
        formData.set('order_amount', values.order_amount)
        formData.set('currency_code', values.currency_code)
        for (var i = 0; i < values.lab_tests.length; i++) {
          for ( var key in values.lab_tests[i] ) {
            formData.append('row['+i+']['+key+']', values.lab_tests[i][key]);
        }
        }
        formData.set('latitude', values.latitude)
        formData.set('longitude', values.longitude)
        formData.set('alt_country_code', values.alt_country_code)
        formData.set('alt_mobile_number', values.alt_mobile_number)
        formData.set('bank_account_number', values.bank_account_number)
        formData.set('bank_account_holder', values.bank_account_holder)
        formData.set('bank_name', values.bank_name)
        formData.set('bank_city', values.bank_city)
        formData.set('bank_ifsc', values.bank_ifsc)
        formData.set('bank_account_type', values.bank_account_type)
        addDetails(formData)

        
      }
    });
    

            

            
    if (loading) {
      return (
        <Spinner />
      )
    }
            

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
    <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Banking Details</h6>
    
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          
            label="Bank Account Number"
            name="bank_account_number"
            type="text"
            value={formik.values.bank_account_number}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_account_number && formik.touched.bank_account_number ? (
            <p style={{color:"red"}}>{formik.errors.bank_account_number}</p>
             ) : <p></p>}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          
            label="Account Holder Full Name"
            name="bank_account_holder"
            type="text"
            value={formik.values.bank_account_holder}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_account_holder && formik.touched.bank_account_holder ? (
            <p style={{color:"red"}}>{formik.errors.bank_account_holder}</p>
             ) : <p> </p>}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          
            label="Bank Name"
            name="bank_name"
            type="text"
            value={formik.values.bank_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_name && formik.touched.bank_name ? (
            <p style={{color:"red"}}>{formik.errors.bank_name}</p>
             ) : <p> </p>}
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        
      <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          
            label="Bank City"
            name="bank_city"
            type="text"
            value={formik.values.bank_city}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_city && formik.touched.bank_city ? (
            <p style={{color:"red"}}>{formik.errors.bank_city}</p>
             ) : <p> </p>}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          
            label="Bank IFSC Code"
            name="bank_ifsc"
            type="text"
            value={formik.values.bank_ifsc}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            
            {formik.errors.bank_ifsc && formik.touched.bank_ifsc ? (
            <p style={{color:"red"}}>{formik.errors.bank_ifsc}</p>
             ) : <p> </p>}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
        <FormControl fullWidth variant="outlined" className={classes.formControl}>
        <InputLabel fullWidth htmlFor="outlined-age-native-simple">Account Type</InputLabel>
        <Select
        fullWidth
          value={formik.values.bank_account_type}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label="Account Type"
          name="bank_account_type"
        >
          <option value={'CURRENT'}>CURRENT</option>
          <option value={'SAVINGS'}>SAVINGS</option>
          <option value={'SALARY'}>SALARY</option>
          <option value={'FIXED DEPOSIT'}>FIXED DEPOSIT</option>
          <option value={'RECURRING DEPOSIT'}>RECURRING DEPOSIT</option>
          <option value={'NRI'}>NRI</option>
          <option value={'NRO'}>NRO</option>
          <option value={'NRE'}>NRE</option>
          <option value={'FCNR'}>FCNR</option>
        </Select>
      </FormControl>
            {formik.errors.bank_account_type && formik.touched.bank_account_type ? (
            <p style={{color:"red"}}>{formik.errors.bank_account_type}</p>
             ) : <p> </p>}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={3} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
        </Grid>
        <Grid item xs={7}>
        </Grid>
        
        <Grid item xs={1} container justify={"flex-end"}>
            {/* <Button onClick={handleShowReject} style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="secondary" >Reject</Button> */}
        </Grid>
        <Grid item xs={1} container justify={"flex-end"}>
            <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Add</Button>
            </Grid>
      </Grid> 
      </form>
            
    </div>
  );
}
