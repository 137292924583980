import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function ButtonSizes(props) {
  const classes = useStyles();

  const onClickDelete = () => {
    props.onDelete(props.row.id)
  }

  return (
    <div>
      <div>
        <IconButton aria-label="delete" color="secondary" onClick={onClickDelete} className={classes.margin}>
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
}
