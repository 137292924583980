import http from "../../../Utils/http";

export function loadEmployeeProfileData() {
    return http.get(`api/employee/profile/`);
  }

export function loadHealthAssosiateProfileData() {
    return http.get(`api/healthassociate/profile/`);
}

export function EditBasicInfo(data) {
    return http.post(`api/healthassociate/profile/`, data);
}

export function EditAddress(data) {
    return http.post(`api/healthassociate/address/`, data);
}

export function CheckUniqueEmailOrMobile(data) {
    return http.post(`api/checkunique/`, data);
}

export function SendEmailOrMobileOTP(data) {
    return http.post(`api/otp/send/`, data);
}

export function VerifyEmailOrMobileOTP(data) {
    return http.post(`api/otp/verify/`, data);
}