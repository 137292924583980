import getSymbolFromCurrency from 'currency-symbol-map'


export const currencyCodeData = [

    { code: "Afghanistan", id: 1, label: getSymbolFromCurrency("AFN"), value: "AFN" },
    { code: "Akrotiri and Dhekelia (UK)", id: 2, label: getSymbolFromCurrency("EUR"), value: "EUR" },	
    //{ code: "Aland Islands (Finland)", id: 3, label: getSymbolFromCurrency("EUR"), value: "EUR" },
    { code: "Albania", id: 4, label: getSymbolFromCurrency("ALL"), value: "ALL" },
    { code: "Algeria", id:5, label: getSymbolFromCurrency("DZD"), value: "DZD" },
    { code: "American Samoa (USA)", id: 6, label: getSymbolFromCurrency("USD"), value: "USD" },
    //{ code: "Andorra", id: 7, label: getSymbolFromCurrency("EUR"), value: "EUR" },
    { code: "Angola", id: 8, label: getSymbolFromCurrency("AOA"), value: "AOA" },
    { code: "Anguilla (UK)", id: 9, label: getSymbolFromCurrency("XCD"), value: "XCD" },
    //{ code: "Antigua and Barbuda", id: 10, label: getSymbolFromCurrency("XCD"), value: "XCD" },
    { code: "Argentina", id: 11, label: getSymbolFromCurrency("ARS"), value: "ARS" },
    { code: "Armenia", id: 12, label: getSymbolFromCurrency("AMD"), value: "AMD" },
    { code: "Aruba (Netherlands)", id: 13, label: getSymbolFromCurrency("AWG"), value: "AWG" },
    { code: "Ascension Island (UK)", id: 14, label: getSymbolFromCurrency("SHP"), value: "SHP" },
    { code: "Australia", id: 15, label: getSymbolFromCurrency("AUD"), value: "AUD" },
    //{ code: "Austria", id: 16, label: getSymbolFromCurrency("EUR"), value: "EUR" },
    { code: "Azerbaijan", id: 17, label: getSymbolFromCurrency("AZN"), value: "AZN" },
    { code: "Bahamas", id: 18, label: getSymbolFromCurrency("BSD"), value: "BSD" },
    { code: "Bahrain", id: 19, label: getSymbolFromCurrency("BHD"), value: "BHD" },
    { code: "Bangladesh", id: 20, label: getSymbolFromCurrency("BDT"), value: "BDT" },
    { code: "Barbados", id: 21, label: getSymbolFromCurrency("BBD"), value: "BBD" },
    { code: "Belarus", id: 22, label: getSymbolFromCurrency("BYN"), value: "BYN" },
    //{ code: "Belgium", id: 23, label: "EUR", value: "EUR" },
    { code: "Belize", id: 24, label: getSymbolFromCurrency("BZD"), value: "BZD" },
    { code: "Benin", id: 25, label: getSymbolFromCurrency("XOF"), value: "XOF" },
    { code: "Bermuda (UK)", id: 26, label: getSymbolFromCurrency("BMD"), value: "BMD" },
    { code: "Bhutan", id: 27, label: getSymbolFromCurrency("BTN"), value: "BTN" },
    { code: "Bolivia", id: 28, label: getSymbolFromCurrency("BOB"), value: "BOB" },
    //{ code: "Bonaire (Netherlands)", id: 29, label: "USD", value: "USD" },
    { code: "Bosnia and Herzegovina", id: 30, label: getSymbolFromCurrency("BAM"), value: "BAM" },
    { code: "Botswana", id: 31, label: getSymbolFromCurrency("BWP"), value: "BWP" },
    { code: "Brazil", id: 32, label: getSymbolFromCurrency("BRL"), value: "BRL" },
    //{ code: "British Indian Ocean Territory (UK)", id: 33, label: "USD", value: "USD" },  
    //{ code: "British Virgin Islands (UK)", id: 34, label: "USD", value: "USD" }, 
    { code: "Brunei", id: 35, label: getSymbolFromCurrency("BND"), value: "BND" },    
    { code: "Bulgaria", id: 36, label: getSymbolFromCurrency("BGN"), value: "BGN" },    
    { code: "Burkina Faso", id: 37, label: getSymbolFromCurrency("XOF"), value: "XOF" }, 
    { code: "Burundi", id: 38, label: getSymbolFromCurrency("BIF"), value: "BIF" },
    { code: "Cabo Verde", id: 39, label: getSymbolFromCurrency("CVE"), value: "CVE" },
    { code: "Cambodia", id: 40, label: getSymbolFromCurrency("KHR"), value: "KHR" },
    { code: "Cameroon", id: 41, label: getSymbolFromCurrency("XAF"), value: "XAF" },
    { code: "Canada", id: 42, label: getSymbolFromCurrency("CAD"), value: "CAD" },
    //{ code: "Caribbean Netherlands (Netherlands)", id: 43, label: "USD", value: "USD" },
    { code: "Cayman Islands (UK)", id: 44, label: getSymbolFromCurrency("KYD"), value: "KYD" },
    { code: "Central African Republic", id: 45, label: getSymbolFromCurrency("XAF"), value: "XAF" },
    { code: "Chad", id: 46, label: getSymbolFromCurrency("XAF"), value: "XAF" },
    { code: "Chatham Islands (New Zealand)", id: 47, label: getSymbolFromCurrency("NZD"), value: "NZD" },
    { code: "Chile", id: 48, label: getSymbolFromCurrency("CLP"), value: "CLP" },
    { code: "China", id: 49, label: getSymbolFromCurrency("CNY"), value: "CNY" },
    //{ code: "Christmas Island (Australia)", id: 50, label: "AUD", value: "AUD" },
    //{ code: "Cocos (Keeling) Islands (Australia)", id:51, label: "AUD", value: "AUD" },
    { code: "Colombia", id:52, label: getSymbolFromCurrency("COP"), value: "COP" },
    { code: "Comoros", id:53, label: getSymbolFromCurrency("KMF"), value: "KMF" },
    { code: "Congo, Democratic Republic of the", id:54, label: getSymbolFromCurrency("CDF"), value: "CDF" },
    { code: "Congo, Republic of the", id:55, label: getSymbolFromCurrency("XAF"), value: "XAF" },
    { code: "Cook Islands (New Zealand)", id:56, label: getSymbolFromCurrency("none"), value: "none" },
    { code: "Costa Rica", id:57, label: getSymbolFromCurrency("CRC"), value: "CRC" },
    { code: "Cote d'Ivoire", id:58, label: getSymbolFromCurrency("XOF"), value: "XOF" },
    { code: "Croatia", id:59, label: getSymbolFromCurrency("HRK"), value: "HRK" },
    { code: "Cuba", id:60, label: getSymbolFromCurrency("CUP"), value: "CUP" },
    { code: "Curacao (Netherlands)", id: 61, label: getSymbolFromCurrency("ANG"), value: "ANG" },
    //{ code: "Cyprus", id: 62, label: "EUR", value: "EUR" },
    { code: "Czechia", id: 63, label: getSymbolFromCurrency("CZK"), value: "CZK" },
    { code: "Denmark", id: 64, label: getSymbolFromCurrency("DKK"), value: "DKK" },
    { code: "Djibouti", id: 65, label: getSymbolFromCurrency("DJF"), value: "DJF" },
    //{ code: "Dominica", id: 66, label: "XCD", value: "XCD" },
    { code: "Dominican Republic", id: 67, label: getSymbolFromCurrency("DOP"), value: "DOP" },
    //{ code: "Ecuador", id: 68, label: "USD", value: "USD" },
    { code: "Egypt", id: 69, label: getSymbolFromCurrency("EGP"), value: "EGP" },
    //{ code: "El Salvador", id: 70, label: "USD", value: "USD" },
    { code: "Equatorial Guinea", id: 71, label: getSymbolFromCurrency("XAF"), value: "XAF" },
    { code: "Eritrea", id: 72, label: getSymbolFromCurrency("ERN"), value: "ERN" },
    //{ code: "Estonia", id: 73, label: "EUR", value: "EUR" },
    { code: "Eswatini (formerly Swaziland)", id: 74, label: getSymbolFromCurrency("SZL"), value: "SZL" },
    { code: "Ethiopia", id: 75, label: getSymbolFromCurrency("ETB"), value: "ETB" },
    { code: "Falkland Islands (UK)", id: 76, label: getSymbolFromCurrency("FKP"), value: "FKP" },
    { code: "Faroe Islands (Denmark)", id: 77, label: getSymbolFromCurrency("none"), value: "none" },
    { code: "Fiji", id: 78, label: getSymbolFromCurrency("FJD"), value: "FJD" },
    //{ code: "Finland", id: 79, label: "EUR", value: "EUR" },
    //{ code: "France", id: 80, label: "EUR", value: "EUR" },
    //{ code: "French Guiana (France)", id: 81, label: "EUR", value: "EUR" },
    { code: "French Polynesia (France)", id: 82, label: "XPF", value: "XPF" },
    { code: "Gabon", id: 83, label: getSymbolFromCurrency("XAF"), value: "XAF" },
    { code: "Gambia", id: 84, label: getSymbolFromCurrency("GMD"), value: "GMD" },
    { code: "Georgia", id: 85, label: getSymbolFromCurrency("GEL"), value: "GEL" },
    //{ code: "Germany", id: 86, label: "EUR", value: "EUR" },
    { code: "Ghana", id: 87, label: getSymbolFromCurrency("GHS"), value: "GHS" },
    { code: "Gibraltar (UK)", id: 88, label: "GIP", value: "GIP" },
    //{ code: "Greece", id: 89, label: "EUR", value: "EUR" },
    { code: "Greenland (Denmark)", id: 90, label: "DKK", value: "DKK" },
    //{ code: "Grenada", id: 91, label: "XCD", value: "XCD" },
    //{ code: "Guadeloupe (France)", id: 92, label: "EUR", value: "EUR" },
    //{ code: "Guam (USA)", id: 93, label: "USD", value: "USD" },
    { code: "Guatemala", id: 94, label: getSymbolFromCurrency("GTQ"), value: "GTQ" },
    { code: "Guernsey (UK)", id: 95, label: getSymbolFromCurrency("GGP"), value: "GGP" },
    { code: "Guinea", id: 96, label: getSymbolFromCurrency("GNF"), value: "GNF" },
    { code: "Guinea-Bissau", id: 97, label: getSymbolFromCurrency("XOF"), value: "XOF" },
    { code: "Guyana", id: 98, label: getSymbolFromCurrency("GYD"), value: "GYD" },
    { code: "Haiti", id: 99, label: getSymbolFromCurrency("HTG"), value: "HTG" },
    { code: "Honduras", id: 100, label: getSymbolFromCurrency("HNL"), value: "HNL" },
    { code: "Hong Kong (China)", id: 101, label: getSymbolFromCurrency("HKD"), value: "HKD" },
    { code: "Hungary", id: 102, label: getSymbolFromCurrency("HUF"), value: "HUF" },
    { code: "Iceland", id: 103, label: getSymbolFromCurrency("ISK"), value: "ISK" },
    { code: "India", id: 104, label: getSymbolFromCurrency("INR"), value: "INR" },
    { code: "Indonesia", id: 105, label: getSymbolFromCurrency("IDR"), value: "IDR" },
    { code: "International Monetary Fund (IMF)", id: 106, label: getSymbolFromCurrency("XDR"), value: "XDR" },
    { code: "Iran", id: 107, label: getSymbolFromCurrency("IRR"), value: "IRR" },
    { code: "Iraq", id: 108, label: getSymbolFromCurrency("IQD"), value: "IQD" },
    //{ code: "Ireland", id: 109, label: "EUR", value: "EUR" },
    { code: "Isle of Man (UK)", id: 110, label: getSymbolFromCurrency("IMP"), value: "IMP" },
    { code: "Israel", id: 111, label: getSymbolFromCurrency("ILS"), value: "ILS" },
   // { code: "Italy", id: 112, label: "EUR", value: "EUR" },
    { code: "Jamaica", id: 113, label: getSymbolFromCurrency("JMD"), value: "JMD" },
    { code: "Japan", id: 114, label: getSymbolFromCurrency("JPY"), value: "JPY" },
    { code: "Jersey (UK)", id: 115, label: getSymbolFromCurrency("JEP"), value: "JEP" },
    { code: "Jordan", id: 116, label: getSymbolFromCurrency("JOD"), value: "JOD" },
    { code: "Kazakhstan", id: 117, label: getSymbolFromCurrency("KZT"), value: "KZT" },
    { code: "Kenya", id: 118, label: getSymbolFromCurrency("KES"), value: "KES" },
    //{ code: "Kiribati", id: 119, label: "AUD", value: "AUD" },
    //{ code: "Kosovo", id: 120, label: "EUR", value: "EUR" },
    { code: "Kuwait", id: 121, label: getSymbolFromCurrency("KWD"), value: "KWD" },
    { code: "Kyrgyzstan", id: 122, label: getSymbolFromCurrency("KGS"), value: "KGS" },
    { code: "Laos", id: 123, label: getSymbolFromCurrency("LAK"), value: "LAK" },
    //{ code: "Latvia", id: 124, label: "EUR", value: "EUR" },
    { code: "Lebanon", id: 125, label:getSymbolFromCurrency("LBP"), value: "LBP" },
    { code: "Lesotho", id: 126, label: getSymbolFromCurrency("LSL"), value: "LSL" },
    { code: "Liberia", id: 127, label: getSymbolFromCurrency("LRD"), value: "LRD" },
    { code: "Libya", id: 128, label: getSymbolFromCurrency("LYD"), value: "LYD" },
    { code: "Liechtenstein", id: 129, label: getSymbolFromCurrency("CHF"), value: "CHF" },
    //{ code: "Lithuania", id: 130, label: "EUR", value: "EUR" },
    //{ code: "Luxembourg", id: 131, label: "EUR", value: "EUR" },
    { code: "Macau (China)", id: 132, label: getSymbolFromCurrency("MOP"), value: "MOP" },
    { code: "Madagascar", id: 133, label: getSymbolFromCurrency("MGA"), value: "MGA" },
    { code: "Malawi", id: 134, label: getSymbolFromCurrency("MWK"), value: "MWK" },
    { code: "Malaysia", id: 135, label: getSymbolFromCurrency("MYR"), value: "MYR" },
    { code: "Maldives", id: 136, label: getSymbolFromCurrency("MVR"), value: "MVR" },
    { code: "Mali", id: 137, label: getSymbolFromCurrency("XOF"), value: "XOF" },
    //{ code: "Malta", id: 138, label: "EUR", value: "EUR" },
    //{ code: "Marshall Islands", id: 139, label: "USD", value: "USD" },
    { code: "Martinique (France)", id: 140, label: getSymbolFromCurrency("EUR"), value: "EUR" },
    { code: "Mauritania", id: 141, label: getSymbolFromCurrency("MRU"), value: "MRU" },
    { code: "Mauritius", id: 142, label: getSymbolFromCurrency("MUR"), value: "MUR" },
    //{ code: "Mayotte (France)", id: 143, label: "EUR", value: "EUR" },
    { code: "Mexico", id: 144, label: getSymbolFromCurrency("MXN"), value: "MXN" },
    //{ code: "Micronesia", id: 145, label: "USD", value: "USD" },
    { code: "Moldova", id: 146, label: getSymbolFromCurrency("MDL"), value: "MDL" },
    //{ code: "Monaco", id: 147, label: "EUR", value: "EUR" },
    { code: "Mongolia", id: 148, label: getSymbolFromCurrency("MNT"), value: "MNT" },
    //{ code: "Montenegro", id: 149, label: "EUR", value: "EUR" },
    //{ code: "Montserrat (UK)", id: 150, label: "XCD", value: "XCD" },
    { code: "Morocco", id: 151, label: getSymbolFromCurrency("MAD"), value: "MAD" },
    { code: "Mozambique", id: 152, label: getSymbolFromCurrency("MZN"), value: "MZN" },
    { code: "Myanmar (formerly Burma)", id: 153, label: getSymbolFromCurrency("MMK"), value: "MMK" },
    { code: "Namibia", id: 154, label: getSymbolFromCurrency("NAD"), value: "NAD" },
    //{ code: "Nauru", id: 155, label: "AUD", value: "AUD" },
    { code: "Nepal", id: 156, label: getSymbolFromCurrency("NPR"), value: "NPR" },
   // { code: "Netherlands", id: 157, label: "EUR", value: "EUR" },
    { code: "New Caledonia (France)", id: 158, label: getSymbolFromCurrency("XPF"), value: "XPF" },
    { code: "New Zealand", id: 159, label: getSymbolFromCurrency("NZD"), value: "NZD" },
    { code: "Nicaragua", id: 160, label: getSymbolFromCurrency("NIO"), value: "NIO" },
    { code: "Niger", id: 161, label: getSymbolFromCurrency("XOF"), value: "XOF" },
    { code: "Nigeria", id: 162, label: getSymbolFromCurrency("NGN"), value: "NGN" },
    { code: "Niue (New Zealand)", id: 163, label: getSymbolFromCurrency("NZD"), value: "NZD" },
    //{ code: "Norfolk Island (Australia)", id: 164, label: "AUD", value: "AUD" },
    //{ code: "Northern Mariana Islands (USA)", id: 165, label: "USD", value: "USD" },
    { code: "North Korea", id: 166, label: getSymbolFromCurrency("KPW"), value: "KPW" },  
    { code: "North Macedonia (formerly Macedonia)", id: 167, label: getSymbolFromCurrency("MKD"), value: "MKD" },
    { code: "Norway", id: 168, label: getSymbolFromCurrency("NOK"), value: "NOK" },
    { code: "Oman", id: 169, label: getSymbolFromCurrency("OMR"), value: "OMR" },
    { code: "Pakistan", id: 170, label: getSymbolFromCurrency("PKR"), value: "PKR" },
    //{ code: "Palau", id: 171, label: "USD", value: "USD" },
    { code: "Palestine", id: 172, label: getSymbolFromCurrency("ILS"), value: "ILS" },
    //{ code: "Panama", id: 173, label: "USD", value: "USD" },
    { code: "Papua New Guinea", id: 174, label: getSymbolFromCurrency("PGK"), value: "PGK" },
    { code: "Paraguay", id: 175, label: getSymbolFromCurrency("PYG"), value: "PYG" },
    { code: "Peru", id: 176, label: getSymbolFromCurrency("PEN"), value: "PEN" },
    { code: "Philippines", id: 177, label: getSymbolFromCurrency("PHP"), value: "PHP" },
    { code: "Pitcairn Islands (UK)", id: 178, label: getSymbolFromCurrency("NZD"), value: "NZD" },
    { code: "Poland", id: 179, label: getSymbolFromCurrency("PLN"), value: "PLN" },
    //{ code: "Portugal", id: 180, label: "EUR", value: "EUR" },
    //{ code: "Puerto Rico (USA)", id: 181, label: "USD", value: "USD" },
    { code: "Qatar", id: 182, label: getSymbolFromCurrency("QAR"), value: "QAR" },
    //{ code: "Reunion (France)", id: 183, label: "EUR", value: "EUR" },
    { code: "Romania", id: 184, label: getSymbolFromCurrency("RON"), value: "RON" },
    { code: "Russia", id: 185, label: getSymbolFromCurrency("RUB"), value: "RUB" },
    { code: "Rwanda", id: 186, label: getSymbolFromCurrency("RWF"), value: "RWF" },
    //{ code: "Saba (Netherlands)", id: 187, label: "USD", value: "USD" },
    //{ code: "Saint Barthelemy (France)", id: 188, label: "EUR", value: "EUR" },
    { code: "Saint Helena (UK)", id: 189, label: getSymbolFromCurrency("SHP"), value: "SHP" },
    { code: "Saint Kitts and Nevis", id: 190, label: getSymbolFromCurrency("XCD"), value: "XCD" },
    //{ code: "Saint Lucia", id: 191, label: "XCD", value: "XCD" },
    //{ code: "Saint Martin (France)", id: 192, label: "EUR", value: "EUR" },
    //{ code: "Saint Pierre and Miquelon (France)", id: 193, label: "EUR", value: "EUR" },
    //{ code: "Saint Vincent and the Grenadines", id: 194, label: "XCD", value: "XCD" },
    { code: "Samoa", id: 195, label: getSymbolFromCurrency("WST"), value: "WST" },
    //{ code: "San Marino", id: 196, label: "EUR", value: "EUR" },
    { code: "Sao Tome and Principe", id: 197, label: getSymbolFromCurrency("STN"), value: "STN" },
    { code: "Saudi Arabia", id: 198, label: getSymbolFromCurrency("SAR"), value: "SAR" },
    { code: "Senegal", id: 199, label: getSymbolFromCurrency("XOF"), value: "XOF" },
    { code: "Serbia", id: 200, label: getSymbolFromCurrency("RSD"), value: "RSD" },
    { code: "Seychelles", id: 201, label: getSymbolFromCurrency("SCR"), value: "SCR" },
    { code: "Sierra Leone", id: 202, label: getSymbolFromCurrency("SLL"), value: "SLL" },
    { code: "Singapore", id: 203, label: getSymbolFromCurrency("SGD"), value: "SGD" },
    //{ code: "Sint Eustatius (Netherlands)", id: 204, label: "USD", value: "USD" },
    { code: "Sint Maarten (Netherlands)", id: 205, label: getSymbolFromCurrency("ANG"), value: "ANG" },
    //{ code: "Slovakia", id: 206, label: "EUR", value: "EUR" },
    //{ code: "Slovenia", id: 207, label: "EUR", value: "EUR" },
    { code: "Solomon Islands", id: 208, label: getSymbolFromCurrency("SBD"), value: "SBD" },
    { code: "Somalia", id: 209, label: getSymbolFromCurrency("SOS"), value: "SOS" },
    { code: "South Africa", id: 210, label: getSymbolFromCurrency("ZAR"), value: "ZAR" },
    { code: "South Georgia Island (UK)", id: 211, label: getSymbolFromCurrency("GBP"), value: "GBP" },
    { code: "South Korea", id: 212, label: getSymbolFromCurrency("KRW"), value: "KRW" },
    { code: "South Sudan", id: 213, label: getSymbolFromCurrency("SSP"), value: "SSP" },
    //{ code: "Spain", id: 214, label: "EUR", value: "EUR" },
    { code: "Sri Lanka", id: 215, label: getSymbolFromCurrency("LKR"), value: "LKR" },
    { code: "Sudan", id: 216, label: getSymbolFromCurrency("SDG"), value: "XOSDGF" },
    { code: "Suriname", id: 217, label: getSymbolFromCurrency("SRD"), value: "SRD" },
    { code: "Svalbard and Jan Mayen (Norway)", id: 218, label: getSymbolFromCurrency("NOK"), value: "NOK" },
    { code: "Sweden", id: 219, label: getSymbolFromCurrency("SEK"), value: "SEK" },
    { code: "Switzerland", id: 220, label: getSymbolFromCurrency("CHF"), value: "CHF" },
    { code: "Syria", id: 221, label: getSymbolFromCurrency("SYP"), value: "SYP" },
    { code: "Taiwan", id: 222, label: getSymbolFromCurrency("TWD"), value: "TWD" },
    { code: "Tajikistan", id: 223, label: "TJS", value: "TJS" },
    { code: "Tanzania", id: 224, label: getSymbolFromCurrency("TZS"), value: "TZS" },
    { code: "Thailand", id: 225, label: getSymbolFromCurrency("THB"), value: "THB" },
    //{ code: "Timor-Leste", id: 226, label: "USD", value: "USD" },
    { code: "Togo", id: 227, label: getSymbolFromCurrency("XOF"), value: "XOF" },
    { code: "Tokelau (New Zealand)", id: 228, label: getSymbolFromCurrency("NZD"), value: "NZD" },
    { code: "Tonga", id: 229, label: getSymbolFromCurrency("TOP"), value: "TOP" },
    { code: "Trinidad and Tobago", id: 230, label: getSymbolFromCurrency("TTD"), value: "TTD" },
    { code: "Tristan da Cunha (UK)", id: 231, label: getSymbolFromCurrency("GBP"), value: "GBP" },
    { code: "Tunisia", id: 232, label: getSymbolFromCurrency("TND"), value: "TND" },
    { code: "Turkey", id: 233, label: getSymbolFromCurrency("TRY"), value: "TRY" },
    { code: "Turkmenistan", id: 234, label: getSymbolFromCurrency("TMT"), value: "TMT" },
    //{ code: "Turks and Caicos Islands (UK)", id: 235, label: "USD", value: "USD" },
    //{ code: "Tuvalu", id: 236, label: "AUD", value: "AUD" },
    { code: "Uganda", id: 237, label: getSymbolFromCurrency("UGX"), value: "UGX" },
    { code: "Ukraine", id: 238, label: "UAH", value: "UAH" },
    { code: "United Arab Emirates", id: 239, label: getSymbolFromCurrency("AED"), value: "AED" },
    { code: "United Kingdom", id: 240, label: getSymbolFromCurrency("GBP"), value: "GBP" },
    //{ code: "United States of America", id: 241, label: "USD", value: "USD" },
    { code: "Uruguay", id: 242, label: getSymbolFromCurrency("UYU"), value: "UYU" },
    //{ code: "US Virgin Islands (USA)", id: 243, label: "USD", value: "USD" },
    { code: "Uzbekistan", id: 244, label: getSymbolFromCurrency("UZS"), value: "UZS" },
    { code: "Vanuatu", id: 245, label: getSymbolFromCurrency("VUV"), value: "VUV" },
    //{ code: "Vatican City (Holy See)", id: 246, label: "EUR", value: "EUR" },
    { code: "Venezuela", id: 247, label: getSymbolFromCurrency("VES"), value: "VES" },
    { code: "Vietnam", id: 248, label: getSymbolFromCurrency("VND"), value: "VND" },
    //{ code: "Wake Island (USA)", id: 249, label: "USD", value: "USD" },
    { code: "Wallis and Futuna (France)", id: 250, label: getSymbolFromCurrency("XPF"), value: "XPF" },
    { code: "Yemen", id: 251, label: getSymbolFromCurrency("YER"), value: "YER" },
    { code: "Zambia", id: 252, label: getSymbolFromCurrency("ZMW"), value: "ZMW" },
    //{ code: "Zimbabwe", id: 253, label: "USD", value: "USD" },

 ]