import React, { useState, useMemo } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../../../modules/Auth/_redux/authRedux";
import { ErrorToast } from "../../../../modules/Toast/ErrorSnakBar";
import { SuccessToast } from "../../../../modules/Toast/SuccessSnackBar";
import Alert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";
import {Timer} from "../../../../Utils/Components/Timer";
import { CheckUniqueEmailOrMobile, SendEmailOrMobileOTP, EditBasicInfo, VerifyEmailOrMobileOTP } from "../../../../modules/Api/Profile/profileApi";


function Registration(props) {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(<p></p>);
  const [disableEmailSend, setDisableEmailSend] = useState(true);
  const [disableMobileSend, setDisableMobileSend] = useState(true);
  const [mounted, setMounted] = useState(true);

  const { values } = props

  const email = values.email
  const country_code = values.country_code
  const mobile_number = values.mobile_number
  
  const initialValues = {
    email: email,
    email_otp: "",
    country_code:""
  };

  const [loading, setLoading] = useState(false);

  
  const otp6 = new RegExp(/^[0-9]+$/)
  const RegistrationSchema = Yup.object().shape({
    email: Yup.string()
    .nullable()
    .email('Invalid email')
    .required('Email field is required'),
    email_otp: Yup.string()
    .nullable()
    .matches(otp6,"Must be a valid number")
    .length(6, "Must be a 6 digit")
    .required('The email OTP field is required'),
      });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

    

    
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      VerifyEmailOrMobileOTP({email_or_mobile: values.email, country_code: '', otp: values.email_otp})
        .then((data) => {
          if (data && data.data && data.data.message) {
            SuccessToast(data.data.message)
          }
          EditBasicInfo({email: values.email, country_code: country_code, mobile_number: mobile_number})
          .then((items) => {
            if (items && items.data && items.data.message) {
              SuccessToast(items.data.message)
            }
          })
          .catch((err) => {
              ErrorToast(err)
              setDisableEmailSend(true)
          });
          disableLoading();
          setSubmitting(false);
          history.push('/profile')
        })
        .catch((err) => {
          ErrorToast(err)
          setSubmitting(false);
          disableLoading();
        });
    },
  });

  

  const sendEmailOTP = () => {
    CheckUniqueEmailOrMobile({'email_or_mobile': formik.values.email})
    .then((items) => {
      SendEmailOrMobileOTP({'email_or_mobile': formik.values.email, country_code: ''})
      .then((items) => {
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
      })
      .catch((err) => {
          ErrorToast(err)
          setDisableEmailSend(true)
      });
    })
    .catch((err) => {
        ErrorToast(err)
        setDisableEmailSend(true)
    });
  }
const onClickSendEmailOTP = () => {
  setDisableEmailSend(false)
  sendEmailOTP()
};

// React.useEffect(() => {
//   if(mounted) {
//     setMounted(false)
//     onClickSendMObileOTP()
//     onClickSendEmailOTP()
//   }
  
// }, [onClickSendEmailOTP, onClickSendMObileOTP])

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        {/* <h3 className="font-size-h1"> */}
          {/* <FormattedMessage id="AUTH.REGISTER.TITLE" /> */}
          {/* OTP Verification */}
        {/* </h3> */}
        {/* <p className="text-muted font-weight-bold">
          Enter your new password
        </p> */}
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}


        {/* begin: Email */}
        {<div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        {/* <div class="text-right">
          <Countdown
              date={emailTime}
              renderer={renderer_email}
            /></div> */}
        
        {disableEmailSend && <div class="text-right">
            <button
            disabled={formik.values.email == values.email || formik.errors.email}
            onClick={onClickSendEmailOTP}
              type="button"
              className="btn btn-light-primary font-weight-bold py-4 my-3"
            >
              Save Changes
            </button>
            </div>}
        </div>}
        {/* end: Email */}

        {!disableEmailSend && <Timer 
        id='email'
            setDisable={setDisableEmailSend}
            />}

        <div style={{marginTop:'20px'}} className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email OTP"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email_otp"
            )}`}
            name="email_otp"
            {...formik.getFieldProps("email_otp")}
          />
          {formik.touched.email_otp && formik.errors.email_otp ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email_otp}</div>
            </div>
          ) : null}
        </div>
        
        {errorMessage}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid 
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Verify</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
            <button
              onClick={props.handleHide}
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
