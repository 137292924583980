import React from "react";
import SaleCard from "./page-components/SaleCard/SaleCard";
import GraphCard from "./page-components/Graph/GraphCard";
import SalesTableCard from "./page-components/SalesTable/SalesTableCard";
import RecentTransactionsCard from "./page-components/RecentTransactions/RecentTransactionsCard";
export function CurrentSaleData(props) {
    const {type, data, filterData } = props
    return (<>
            <div className="row">
                <div className="col-lg-8">
                    <SaleCard type={type} data={data} />
                    <GraphCard type={type} />
                </div>
                <div className="col-lg-4">
                    <RecentTransactionsCard type={type} />
                </div>
                <div className="col-lg-12">
                    <SalesTableCard type={type} data={data} filterData={filterData} />
                </div>
            </div>
    </>);
}
