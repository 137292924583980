import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../../_metronic/_partials/controls";
import StoresTable from "./TableNew";
import { pageMainLoadData, searchMainLoadData } from "../../../../../../modules/Api/PaymentAdmin/Payment/CurrentSalesApi";
import Spinner from "../../../../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../../../../modules/Toast/SuccessSnackBar";
import Search from "./Search";

export const ListNew = (props) => {
  const {type, tableData, filterData } = props
  const [data, setData] = React.useState(undefined)
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)
  const [search_text, set_search_text] = React.useState("")

  const SearchData = (text) => {
    setLoading(true)
    setData(undefined)
    set_search_text(text)
    searchMainLoadData(text, filterData, type)
      .then(items => {
        setLoading(false)
        setData(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        setLoading(false)
        ErrorToast(err)
    })
  }

  const loadPageData = (no) => {
    setLoading(true)
    setData(undefined)
    pageMainLoadData(no, filterData, type)
      .then(items => {
        setLoading(false)
        setData(items.data)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        setLoading(false)
        ErrorToast(err)
    })
  }

  React.useEffect(() => {
    if (mounted) {
      setData(tableData)
      setMounted(false)
      setLoading(false)
    }
  })

  if (loading) {
    return (
      <Spinner />
    )
  }

  return (
    <Card>
      <CardHeader>
        <CardHeaderToolbar>
          <Search SearchData={SearchData} value={search_text} />
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {
          data && <StoresTable loadPageData={loadPageData} data={data}/>
        }
      </CardBody>
    </Card>
  );
};
