import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

export default function FormControlLabelPlacement(props) {

  const onClickButton = (event) => {
    const data = {
      "id": props.value.id,
      "sample_collect":parseInt(event.target.value)
    }
    
    props.onChangeSampleCollect(data, props.index)
  }
  return (
    <FormControl component="fieldset">
      <RadioGroup row aria-label="position" name="position" onChange={onClickButton} value={String(props.value.sample_collect)}>
        <FormControlLabel
          value="1"
          control={<Radio color="primary" />}
          label="Yes"
          labelPlacement="start"
        />
        <FormControlLabel
          value="0"
          control={<Radio color="primary" />}
          label="No"
          labelPlacement="start"
        />
      </RadioGroup>
    </FormControl>
  );
}
