import React, { Component } from 'react';
import Details from './LaboratoryDetails';
import Address from './LaboratoryAddress';
import BankDetails from './LaboratoryBankDetails';
// import Details from "./error";
import LaboratoryListOfTests from "./LaboratoryListOfTests";
import { loadTestData } from "../../../modules/Api/Laboratory/NewLaboratoryApi";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";


export default class UserForm extends Component {
    constructor(props) {
        super(props);
        
        // this.updateData = this.updateData.bind(this)
    
        this.state = {
            step: 1,
            formData:{
                laboratory_name: '',
                country_code: '+91',
                mobile_number: '',
                email: '',
                gstin: '',
                lab_reg_number: '',
                lab_issuing_authority: '',
                lab_date_of_issue: '',
                lab_valid_upto: '',
                lab_file: '',
                alt_country_code: '+91',
                alt_mobile_number: '',
                pincode: '',
                country: '',
                state: '',
                district: '',
                city_village: '',
                street_name: '',
                sample_collection: '0',
                order_amount: 0,
                currency_code: 'INR',
                latitude: '',
                longitude: '',
                lab_tests: [],
                bank_account_number: '',
                bank_account_holder: '',
                bank_name: '',
                bank_city: '',
                bank_ifsc: '',
                bank_account_type: '',
              },
            selectTestData:[]
        }
        };

        

    

    // loadData = () => {
    //     this.setState({formData : this.props.data})
    // }

    loadSelectTestData = () => {
        loadTestData()
          .then(items => {
              console.log('test',items);
            this.setState({selectTestData:items.data})
            if (items && items.data && items.data.message) {
              SuccessToast(items.data.message)
            }
            
          })
          .catch(err => {
            ErrorToast(err)
        })
        }

        
    componentWillMount (){
        // this.loadData()
        this.loadSelectTestData()
        
      }

    // next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        })
    }

    

    updateStep = (no) => {
        const { step } = this.state;
        this.setState({
            step: no
        })
    }

    updateData = (data) => {
        this.setState({formData:data})
    }

    // previous step
    previosStep = (data) => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        })
    }


    render() {

        const { step } = this.state

        switch (step) {
            case 1 :
                return (
                    <Details
                    values={this.state.formData}
                    nextStep={this.nextStep}
                    previosStep={this.previosStep}
                    updateData={this.updateData}
                    />
                )
            case 2 :
                return (
                    <Address
                    values={this.state.formData}
                    nextStep={this.nextStep}
                    previosStep={this.previosStep}
                    updateData={this.updateData}
                    />
                )

            case 3 :
                return (
                    <LaboratoryListOfTests
                    selectTestData={this.state.selectTestData}
                    values={this.state.formData}
                    nextStep={this.nextStep}
                    previosStep={this.previosStep}
                    updateData={this.updateData}
                    />
                )
            case 4 :
                return (
                    <BankDetails
                    values={this.state.formData}
                    updateData={this.updateData}
                    previosStep={this.previosStep}
                    updateStep={this.updateStep}
                    />
                )
            default:
                return (
                    <Details
                    values={this.state.formData}
                    nextStep={this.nextStep}
                    previosStep={this.previosStep}
                    />
                )
        }
    }
}