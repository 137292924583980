import React from "react";
import SaleCards from "./page-components/SaleCard/SaleCards";
import MultiplePayoutModel from "./page-components/MultiplePayout/MultiplePayoutModel";
import {ListNew} from "./page-components/SalesTable/ListNew";
import { loadTableData } from "../../../../modules/Api/PaymentAdmin/Payment/PayOutsApi";
// import Spinner from "../../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../../modules/Toast/SuccessSnackBar";

export function PayoutData(props) {
    const { type, data } = props
    const [payList, setPayList] = React.useState(undefined)
    const [loading, setLoading] = React.useState(true)

    const loadData = () => {
        setPayList(undefined)
        loadTableData(type)
        .then(items => {
            setPayList(items.data)
          if (items && items.data && items.data.message) {
            SuccessToast(items.data.message)
          }
          
        })
        .catch(err => {
          ErrorToast(err)
      })
    }

    const refreshPage = ()=>{
        window.location.reload();  }
    return (<>
            <div className="row">
                    {data && <SaleCards type={type} data={data} />}
            </div>
            <div className="d-flex justify-content-center" style={{width:'100%', marginTop:"30px"}} >
                <ListNew type={type} payList={payList} />
            </div>
            <div className="float-right">
                <MultiplePayoutModel type={type} loadData={loadData} />
            </div>
    </>);
}
