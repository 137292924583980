import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { countryCodeData as options } from '../../../Utils/countyCodeMapping';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from "react-router-dom";
import Spinner from "../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import { editBankinfo } from "../../../modules/Api/Doctor/NewDoctorApi";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  buttonProgress: {
    color: "green",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function Details(props) {
  const history = useHistory();
    const classes = useStyles();
    let view_only = false
    if (props.type == 'view') {
      view_only = true
    } else {
      view_only = false
    }
    const [errorMessage, setErrorMessage] = React.useState('')
    const [successMessage, setSuccessMessage] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    



    const initValues = props.data.bank_account_details[0]
    // console.log(initValues);


    

    const onClickPrevious = () => {
        props.previousStep()
    }

    const editDetails = (data, localData) => {
      const id = initValues.id
      editBankinfo(id, data)
          .then(items => {
            setLoading(false)
            props.updateData(localData)
            props.nextStep()
            // if (items && items.data && items.data.message) {
            //   SuccessToast(items.data.message)
            // }
            
          })
          .catch(err => {
            setLoading(false)
            ErrorToast(err)
        })
    }

    

    

      

      const ifsc_code = new RegExp(/^[A-Za-z]{4}[a-zA-Z0-9]{7}$/)

    const formik = useFormik({
      initialValues: initValues,
      validationSchema: Yup.object({
        // bank_account_number: Yup.string()
        // .matches(/^[0-9]*$/, 'Account number must be number')
        //   .required("Account number is Required!")
        //   .nullable(),
        // bank_account_holder: Yup.string()
        //   .matches(/^[\.\s+a-zA-Z ]*$/, 'Name field only accepts alphabets')
        //   .required("Account holder name is Required!")
        //   .nullable(),
        // bank_name: Yup.string()
        //   .required("Bank name is Required!")
        //   .nullable(),
        // bank_city: Yup.string()
        //  .required("Bank city is Required!")
        //  .nullable(),
        // bank_ifsc: Yup.string()
        // .matches(ifsc_code, 'Must be a valid IFSC code')
        // .required('The IFSC field is required.')
        // .nullable(),
        // bank_account_type: Yup.string()
        //   .required("Account type is Required!")
        //   .nullable(),
      }),
      onSubmit: values => {
        // console.log('submit', values);
        const localData = props.data
        localData["bank_account_details"][0]["bank_account_number"] = values.bank_account_number
        localData["bank_account_details"][0]["bank_account_holder"] = values.bank_account_holder
        localData["bank_account_details"][0]["bank_account_type"] = values.bank_account_type
        localData["bank_account_details"][0]["bank_city"] = values.bank_city
        localData["bank_account_details"][0]["bank_ifsc"] = values.bank_ifsc
        localData["bank_account_details"][0]["bank_name"] = values.bank_name
        editDetails(values, localData)
      }
    });
    

            

            
    if (loading) {
      return (
        <Spinner />
      )
    }
            

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
    <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Banking Details</h6>
    
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          
          disabled={view_only}
            label="Bank Account Number"
            name="bank_account_number"
            type="text"
            value={formik.values.bank_account_number}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_account_number && formik.touched.bank_account_number ? (
            <p style={{color:"red"}}>{formik.errors.bank_account_number}</p>
             ) : <p> </p>}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          
          disabled={view_only}
            label="Account Holder Full Name"
            name="bank_account_holder"
            type="text"
            value={formik.values.bank_account_holder}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_account_holder && formik.touched.bank_account_holder ? (
            <p style={{color:"red"}}>{formik.errors.bank_account_holder}</p>
             ) : <p> </p>}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          
          disabled={view_only}
            label="Bank Name"
            name="bank_name"
            type="text"
            value={formik.values.bank_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_name && formik.touched.bank_name ? (
            <p style={{color:"red"}}>{formik.errors.bank_name}</p>
             ) : <p> </p>}
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        
      <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          
          disabled={view_only}
            label="Bank City"
            name="bank_city"
            type="text"
            value={formik.values.bank_city}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.bank_city && formik.touched.bank_city ? (
            <p style={{color:"red"}}>{formik.errors.bank_city}</p>
             ) : <p> </p>}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          
          disabled={view_only}
            label="Bank IFSC Code"
            name="bank_ifsc"
            type="text"
            value={formik.values.bank_ifsc}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            
            {formik.errors.bank_ifsc && formik.touched.bank_ifsc ? (
            <p style={{color:"red"}}>{formik.errors.bank_ifsc}</p>
             ) : <p> </p>}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
        <FormControl fullWidth variant="outlined" className={classes.formControl}>
        <InputLabel fullWidth htmlFor="outlined-age-native-simple">Account Type</InputLabel>
        <Select
        fullWidth
        disabled={view_only}
          value={formik.values.bank_account_type}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label="Account Type"
          name="bank_account_type"
        >
          <option value={'CURRENT'}>CURRENT</option>
          <option value={'SAVINGS'}>SAVINGS</option>
          <option value={'SALARY'}>SALARY</option>
          <option value={'FIXED DEPOSIT'}>FIXED DEPOSIT</option>
          <option value={'RECURRING DEPOSIT'}>RECURRING DEPOSIT</option>
          <option value={'NRI'}>NRI</option>
          <option value={'NRO'}>NRO</option>
          <option value={'NRE'}>NRE</option>
          <option value={'FCNR'}>FCNR</option>
        </Select>
      </FormControl>
            {formik.errors.bank_account_type && formik.touched.bank_account_type ? (
            <p style={{color:"red"}}>{formik.errors.bank_account_type}</p>
             ) : <p> </p>}
        </Grid>
      </Grid>
      
      {errorMessage}
        {successMessage}
      <Grid container spacing={3}>
        <Grid item xs={3} container justify={"flex-start"} >
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={onClickPrevious}>Previous</Button>
        </Grid>
        <Grid item xs={7}>
        </Grid>
        
        <Grid item xs={1} container justify={"flex-end"}>
            {/* <Button onClick={handleShowReject} style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="secondary" >Reject</Button> */}
        </Grid>
        {!view_only && <Grid item xs={1} container justify={"flex-end"}>
            <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Next</Button>
            {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.nextStep} >Next</Button> */}
            </Grid>}
        {view_only && <Grid item xs={1} container justify={"flex-end"}>
            {/* <Button type="submit" style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" >Next</Button> */}
            <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.nextStep} >Next</Button>
            </Grid>}
      </Grid> 
      </form>
            
    </div>
  );
}
