import http from "../../../Utils/http";

export function loadTableData() {
    const pharams = "filter[approved]=0"
    return http.get(`api/administrator/list/pharmacy/?${pharams}`);
  }

  export function pageLoadData(no) {
    const pharams = 'page='+no+"&filter[approved]=0"
    return http.get(`api/administrator/list/pharmacy/?${pharams}`);
  }

  export function searchLoadData(text) {
    const pharams = "filter[approved]=0&filter[name]="+text
    return http.get(`api/administrator/list/pharmacy/?${pharams}`);
  }

  export function editBbasicinfo(id, data) {
    return http.post(`api/admin/pharmacy/basicinfo/${id}`, data )
  }

  export function editAddressinfo(id, data) {
    return http.post(`api/admin/pharmacy/address/${id}`, data )
  }

  export function editAdditionalinfo(id, data) {
    return http.post(`api/admin/pharmacy/additionaldetails/${id}`, data )
  }

  export function confirmClick(id) {
    const reqData = {
      user_id : id,
      action : "APPROVE",
      user_type : "PHARMACIST",
      comment : ''
    }
    return http.post(`api/admin/action/approveorreject`, reqData )
  }

  export function rejectClick(id) {
    const reqData = {
      user_id : id,
      action : "REJECT",
      user_type : "PHARMACIST",
      comment : ''
    }
    return http.post(`api/admin/action/approveorreject`, reqData )
  }