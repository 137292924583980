import React, { Component } from 'react';
import Details from './Details';
// import Address from './Address';
import Address from './AddressDetails';
import AdditionalContacts from './AdditionalContacts';
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import {loadListRole } from "../../../modules/Api/Emloyee/ListEmployeeApi";


export default class UserForm extends Component {
    constructor(props) {
        super(props);
        
        // this.updateData = this.updateData.bind(this)
    
        this.state = {
            step: 1,
            mounted:true,
            data: {},
        }
        };

    
    // next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        })
    }

    updateData = (data) => {
        this.setState({data:data})
    }

    updateStep = (no) => {
        const { step } = this.state;
        this.setState({
            step: no
        })
    }

    // previous step
    previosStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        })
    }

    loadData = () => {
        this.setState({data: this.props.data})
    }

    componentWillMount() {
        this.loadData()
    }


    render() {

        const { step } = this.state

        switch (step) {
            case 1 :
                return (
                    <div>
                        {<Details
                    nextStep={this.nextStep}
                    updateData={this.updateData}
                    data={this.state.data}
                    type={this.props.type}
                    />}
                    </div>
                )
            case 2 :
                return (
                    <Address
                    data={this.state.data}
                    type={this.props.type}
                    nextStep={this.nextStep}
                    previosStep={this.previosStep}
                    updateData={this.updateData}
                    />
                )
                case 3 :
                    return (
                        <AdditionalContacts
                        data={this.state.data}
                        type={this.props.type}
                        nextStep={this.nextStep}
                        previosStep={this.previosStep}
                        updateData={this.updateData}
                        updateStep={this.updateStep}
                        />
                    )
            default:
                return (
                    <Details
                    values={this.state.formData}
                    nextStep={this.nextStep}
                    previosStep={this.previosStep}
                    updateData={this.updateData}
                    />
                )
        }
    }
}