import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { UserTypeTable } from "./UserTypeTable";
export const ListUserTypes = (props) => {
  return (
    <Card>
      <CardHeader title="User types">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => props.history.push('/user-types/add')}
          >
            Add new type
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <UserTypeTable />
      </CardBody>
    </Card>
  );
};
