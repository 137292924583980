import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { loadTableData } from "../../../../modules/Api/PaymentAdmin/Payment/PayOutsApi";
import Spinner from "../../../../Utils/Components/CustomSpinner";
import {ErrorToast} from "../../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../../modules/Toast/SuccessSnackBar";
import { PayoutData } from "./PayoutData";

export const Payout = (props) => {
  const {type} = props
  const [data, setData] = React.useState(undefined)
  const [mounted, setMounted] = React.useState(true)
  const [loading, setLoading] = React.useState(true)

  const loadData = () => {
    loadTableData(type)
    .then(items => {
      setData(items.data)
      if (items && items.data && items.data.message) {
        SuccessToast(items.data.message)
      }
      
    })
    .catch(err => {
      ErrorToast(err)
  })
}

React.useEffect(() => {
  if (mounted) {
    loadData()
    setMounted(false)
    setLoading(false)
  }
}, [loadData, setLoading])

if (loading) {
  return (
    <Spinner />
  )
}

  return (
    <Card>
      <CardHeader title="Payouts">
        <CardHeaderToolbar>
          {/* <button
            type="button"
            className="btn btn-primary"
            onClick={() => props.history.push('/test/add')}
          >
            Add new Test
          </button> */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {<PayoutData data={data} type={type} />}
      </CardBody>
    </Card>
  );
};
