import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { countryCodeData as options } from '../../../Utils/countyCodeMapping';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import {ErrorToast} from "../../../modules/Toast/ErrorSnakBar";
import {SuccessToast} from "../../../modules/Toast/SuccessSnackBar";
import { editBbasicinfo } from "../../../modules/Api/Laboratory/NewLaboratoryApi";
import { handleNumber, findCodeFromValue } from '../../../Utils/CountryMobile';
import { DatePickerField } from "./../../../../_metronic/_partials/controls/forms/DatePickerField";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Details(props) {
  const classes = useStyles();
  const [currentCountry, setCurrentCountry] = React.useState()
  const [altCurrentCountry, setAltCurrentCountry] = React.useState()
  let view_only = false
  if (props.type == 'view') {
    view_only = true
  } else {
    view_only = false
  }
  let intValues = {
    laboratory_name: props.values.laboratory_name,
    country_code: props.values.user.country_code,
    mobile_number: props.values.user.mobile_number,
    email: props.values.user.email,
    gstin: props.values.gstin,
    lab_reg_number: props.values.lab_reg_number,
    lab_issuing_authority: props.values.lab_issuing_authority,
    lab_date_of_issue: props.values.lab_date_of_issue,
    lab_valid_upto: props.values.lab_valid_upto,
    lab_file: props.values.lab_file,
    alt_country_code: props.values.alt_country_code,
    alt_mobile_number: props.values.alt_mobile_number,
  }

  if (intValues.alt_country_code == null) {
    intValues['alt_country_code'] = '+91'
  }

  if (intValues.alt_mobile_number == 'null') {
    intValues['alt_mobile_number'] = ''
  }

  const onClickNext = (data) => {
    props.nextStep()
    props.updateData(data)
  }

  const editDetails = (data, localData) => {
    const id = props.values.id
    editBbasicinfo(id, data)
      .then(items => {
        onClickNext(localData)
        if (items && items.data && items.data.message) {
          SuccessToast(items.data.message)
        }
        
      })
      .catch(err => {
        ErrorToast(err)
    })
  }

  

  const onOpenPdf = () => {
    window.open(intValues.lab_file)
  }

    

    
      
const gstinRegex = new RegExp(/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/)

    const formik = useFormik({
      initialValues: intValues,
      validationSchema: Yup.object({
        laboratory_name: Yup.string()
          .required("laboratory name is Required!"),
        // mobile_number: Yup.string()
        //  .nullable()
        //  .test('len', 'Invalid phone number', val => val.length === 10),
        email: Yup.string()
          .email("Invalid email format")
          .required("Email is Required!"),
        gstin: Yup.string()
        .matches(gstinRegex, "Must be a standard gst number.")
        .min(15, 'Must be 15 characters.')
        .max(15, 'Must be 15 characters.')
        .required('GSTIN number field is required '),
          lab_reg_number: Yup.string()
          .required("Lab registration number is Required!"),
        lab_issuing_authority: Yup.string()
         .required("Lab issuing authority is Required!"),
         lab_date_of_issue: Yup.date('Must be a valid date')
         .nullable()
         .max(new Date(), 'Date of issue cannot be a future date' )
         .required('The Date of issue field is required.'),
        lab_valid_upto: Yup.date('Must be a valid date')
        .nullable()
        .min(new Date(), "Date must be in the future")
        .required('The Date of validity field is required.'),
        alt_mobile_number: Yup.string()
        .nullable()
        .test('mobile-num', 'Invalid mobile number', function (value) {
            if (!value) return true;
            const currentCountrycode = altCurrentCountry && altCurrentCountry.code ? altCurrentCountry.code : null
            return handleNumber(value + "", currentCountrycode || "IN")
        })
        ,
        mobile_number: Yup.string()
        .required('Mobile number is required')
        .nullable()
        .test('mobile-num', 'Invalid mobile number', function (value) {
            if (!value) return false;
            const currentCountrycode = currentCountry && currentCountry.code ? currentCountry.code : null
            return handleNumber(value + "", currentCountrycode || "IN")
        })
        ,
      }),
      onSubmit: values => {


      const data = props.values

      data["laboratory_name"] = values.laboratory_name
      data["user"]["country_code"] = values.country_code
      data["user"]["mobile_number"] = values.mobile_number
      data["user"]["email"] = values.email
      data["gstin"] = values.gstin
      data["lab_reg_number"] = values.lab_reg_number
      data["lab_issuing_authority"] = values.lab_issuing_authority
      data["lab_date_of_issue"] = values.lab_date_of_issue
      data["lab_valid_upto"] = values.lab_valid_upto
      data["alt_country_code"] = values.alt_country_code
      data["alt_mobile_number"] = values.alt_mobile_number
    
      const updateValue = {
        laboratory_name: values.laboratory_name,
        country_code: values.country_code,
        mobile_number: values.mobile_number,
        email: values.email,
        gstin: values.gstin,
        lab_reg_number: values.lab_reg_number,
        lab_issuing_authority: values.lab_issuing_authority,
        lab_date_of_issue: values.lab_date_of_issue,
        lab_valid_upto: values.lab_valid_upto,
        lab_file: null,
        alt_country_code: values.alt_country_code,
        alt_mobile_number: values.alt_mobile_number,
      }
      editDetails(updateValue, data)
      }
    });
        
    

      
    

  return (
    <div className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
      <h6 style={{marginTop:"2%", marginBottom:"2%"}} >Basic Information</h6>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={3}>
          <TextField
            fullWidth
            required
            disabled={view_only}
            label="Laboratory Name"
            name="laboratory_name"
            type="text"
            value={formik.values.laboratory_name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.laboratory_name && formik.touched.laboratory_name && (
            <p style={{color:"red"}}>{formik.errors.laboratory_name}</p>
             )}
        </Grid>
        <Grid item xs={3}>
        <Select
            required
            disabled
            style={{marginRight:"2%", marginBottom:"2%", width:"33%"}}
            name="country_code"
            type="text"
            value={formik.values.country_code}
            variant="outlined"
            onChange={e => {
              formik.setFieldValue("country_code", e.target.value)
              setCurrentCountry(findCodeFromValue(e.target.value))
            }}
            >
              {
                options.map(
                  (item) => (
                  <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                  )
                )
              }
            </Select>
            <FormControl style={{width:"65%"}} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Mobile<span>&nbsp;*</span></InputLabel>
            <OutlinedInput
          fullWidth
            required
            disabled
                name="mobile_number"
                type="number"
                value={formik.values.mobile_number}
                onChange={formik.handleChange}
                startAdornment={<InputAdornment position="start">{formik.values.country_code}</InputAdornment>}
                labelWidth={50}
                onBlur={formik.handleBlur}
            />
            {formik.errors.mobile_number && formik.touched.mobile_number && (
            <p style={{color:"red"}}>{formik.errors.mobile_number}</p>
             )}
            </FormControl>
        </Grid>
        <Grid item xs={3}>
        <TextField
          fullWidth
          required
          disabled
            label="Email ID"
            name="email"
            type="email"
            value={formik.values.email}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.email && formik.touched.email && (
            <p style={{color:"red"}}>{formik.errors.email}</p>
             )}
        </Grid>
        <Grid item xs={3}>
            <TextField
          fullWidth
          required
          disabled={view_only}
            // style={{margin:"2%", width:"200px"}}
            label="GSTIN Number"
            name="gstin"
            type="text"
            value={formik.values.gstin}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.gstin && formik.touched.gstin && (
            <p style={{color:"red"}}>{formik.errors.gstin}</p>
             )}
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{marginTop:"2%", marginBottom:"2%"}}>
        <Grid item xs={3}>
        <TextField
          fullWidth
          required
          disabled={view_only}
            label="Registration Number"
            name="lab_reg_number"
            type="text"
            value={formik.values.lab_reg_number}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.lab_reg_number && formik.touched.lab_reg_number && (
            <p style={{color:"red"}}>{formik.errors.lab_reg_number}</p>
             )}
        </Grid>
        <Grid item xs={3}>
        <TextField
          fullWidth
          required
          disabled={view_only}
            label="Issuing Authority"
            name="lab_issuing_authority"
            type="text"
            value={formik.values.lab_issuing_authority}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            {formik.errors.lab_issuing_authority && formik.touched.lab_issuing_authority && (
            <p style={{color:"red"}}>{formik.errors.lab_issuing_authority}</p>
             )}
        </Grid>
        <Grid item xs={3}>
        {/* <TextField
          fullWidth
          required
          disabled={view_only}
            label="Date Of Registration"
            name="lab_date_of_issue"
            type="date"
            value={formik.values.lab_date_of_issue}
            variant="outlined"
            onChange={e => {
              if (new Date("9999-12-31") >= new Date(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            InputLabelProps={{
                shrink: true,
              }}
              onBlur={formik.handleBlur}
              />
              {formik.errors.lab_date_of_issue && formik.touched.lab_date_of_issue && (
              <p style={{color:"red"}}>{formik.errors.lab_date_of_issue}</p>
               )} */}

                   <DatePickerField 
                                disabled={view_only}
                                mandatory
                                name="lab_date_of_issue"
                                label="Date Of Registration"
                                onChange={val => {
                                  formik.setFieldValue('lab_date_of_issue', String(val))
                                }}
                                onBlur={e => {
                                  formik.handleBlur(e);
                                }}
                                value={formik.values.lab_date_of_issue}
                                touched={formik.touched.lab_date_of_issue}
                                errors={formik.errors.lab_date_of_issue}
                                 />
        </Grid>
        <Grid item xs={3}>
        {/* <TextField
          fullWidth
          required
          disabled={view_only}
            label="Registration valid upto"
            name="lab_valid_upto"
            type="date"
            value={formik.values.lab_valid_upto}
            variant="outlined"
            onChange={e => {
              if (new Date("9999-12-31") >= new Date(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            InputLabelProps={{
                shrink: true,
              }}
              onBlur={formik.handleBlur}
              />
              {formik.errors.lab_valid_upto && formik.touched.lab_valid_upto && (
              <p style={{color:"red"}}>{formik.errors.lab_valid_upto}</p>
               )} */}
               <DatePickerField 
               disabled={view_only}
               mandatory
               name="lab_valid_upto"
               label="Registration valid upto"
               onChange={val => {
                 formik.setFieldValue('lab_valid_upto', String(val))
               }}
               onBlur={e => {
                 formik.handleBlur(e);
               }}
               value={formik.values.lab_valid_upto}
               touched={formik.touched.lab_valid_upto}
               errors={formik.errors.lab_valid_upto}
                />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{marginTop:"2%"}}>
        <Grid item xs={3}  >

      <InputLabel htmlFor="outlined-adornment-amount">Registration Certificate</InputLabel>
        <a
            className="btn btn-icon btn-light btn-hover-success btn-sm mx-3"
            onClick={onOpenPdf}
        >
            {/* <span className="svg-icon svg-icon-md svg-icon-success">
                <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")}
                />
            </span> */}
                    <VisibilitySharpIcon color='primary' />
        </a>
        </Grid>
        <Grid item xs={3} >
        <Select
            required
            disabled={view_only}
            style={{marginRight:"2%", marginBottom:"2%", width:"33%"}}
            name="alt_country_code"
            type="text"
            value={formik.values.alt_country_code}
            variant="outlined"
            onChange={e => {
              formik.setFieldValue("alt_country_code", e.target.value)
              setCurrentCountry(findCodeFromValue(e.target.value))
            }}
            >
              {
                options.map(
                  (item) => (
                  <MenuItem id={item.id} value={item.value}>{item.label}</MenuItem>
                  )
                )
              }
            </Select>
            <FormControl style={{width:"65%"}} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Alternative Number</InputLabel>
            <OutlinedInput
          fullWidth
          disabled={view_only}
                name="alt_mobile_number"
                type="number"
                value={formik.values.alt_mobile_number}
                onChange={formik.handleChange}
                startAdornment={<InputAdornment position="start">{formik.values.alt_country_code}</InputAdornment>}
                labelWidth={130}
                onBlur={formik.handleBlur}
            />
            {formik.errors.alt_mobile_number && formik.touched.alt_mobile_number && (
            <p style={{color:"red"}}>{formik.errors.alt_mobile_number}</p>
             )}
            </FormControl>
        </Grid>
      </Grid>
      
      <Grid container spacing={3} > 
        <Grid item xs={3} container justify={"flex-start"} >
        {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} onClick={props.handleClose} variant="contained" color="secondary">Close</Button> */}
        </Grid>
        <Grid item xs={3}>
        </Grid>
        
        <Grid item xs={3}>
        </Grid>
        {!view_only && <Grid item xs={3} container justify={"flex-end"} >
        <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" type="submit" >Next</Button>
        {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.nextStep} >Next</Button> */}
            
        </Grid>}
        {view_only && <Grid item xs={3} container justify={"flex-end"} >
        <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" type="submit" >Next</Button>
        {/* <Button style={{marginRight:"2%", marginTop:"2%", marginBottom:"2%"}} variant="contained" color="primary" onClick={props.nextStep} >Next</Button> */}
            
        </Grid>}
      </Grid> 
      </form>
            
    </div>
  );
}
