// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';

const validationSchema = Yup.object().shape({
    comment: Yup.string()
      .nullable()
      .required("Comment field is Required!"),
})

export function Add(props) {
    const {id, OnAdd} = props

    const initialValues = {
        to_user_id : id,
        comment : ""
    }


     return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    console.log("sub", values);
                    OnAdd(values)
                }
    }
            >
                {({ handleSubmit, setFieldValue, resetForm, handleChange, handleBlur, touched, values, errors, dirty }) => (
                    <>
                        {<Form className="form form-label-right"> 

                <div className=" row" style={{marginTop:"10px"}} >
                <div className="col-lg-12">
                    <TextField
                        required
                        fullWidth
                        id="outlined-textarea"
                        label="Comment"
                        placeholder="Comment"
                        multiline
                        rows={4}
                        variant="outlined"
                        value={values.comment}
                        name='comment'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                        {errors.comment && touched.comment && (
                          <p style={{color:"red"}}>{errors.comment}</p>
                           )}
                </div>
                    </div>
                <div className=" row" style={{marginTop:"10px"}} >
                    <div className="col-lg-12">
                        <div style={{ display: "flex" }}>
                            <button style={{marginLeft: "auto", width:'130px'}}
                                disabled={!dirty}
                                type="submit"
                                className="btn btn-primary"
                                >
                                Add Comment
                            </button>
                        </div>
                    </div>
                </div>
                        </Form>}
                    </>
                )}
            </Formik>
        </>
    );
}
