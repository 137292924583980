import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSubheader } from "../../../../_metronic/layout";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import { AddStores, ListStores } from "./index"
export const Category = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Category");

  return (
    <Switch>
      <Redirect
        exact={true}
        from="/medicine/category"
        to="/medicine/category/list"
      />
      <ContentRoute from="/medicine/category/add" component={AddStores} />
      <ContentRoute from="/medicine/category/list" component={ListStores} />
    </Switch>
  );
};
